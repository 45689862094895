
import { MainAction, ListAction } from './const'

// TODO parece insensato lanzar notificaciones desde aquí
import { message } from '../utils/notify'

/**
 * Crea una función que despacha tantas acciones como argumentos recibe.
 */
export function multipleDispatcher (dispatch) {
  return (...actions) => dispatch(
    actions.length > 1
      ? { type: MainAction.MULTIACTION, payload: actions }
      : actions[0]
  )
}

export function cardErrors (dispatch, model) {
  // model es esencial para handlear las señales pickCreate
  // e identificar de qué tipo es la tarjeta seleccionada
  return (error, init = {}) => dispatch({
    type: MainAction.CARD_ERRORS,
    payload: (error.errors || [error]).reduce(
      (obj, error) => {
        if (!error.field) {
          console.warn('Missing error field', error)
          error.field = '_unknown'
        }

        if (!obj[error.field]) obj[error.field] = []

        obj[error.field].push(error)

        return obj
      },
      init
    ),
    model
  })
}

export function listReloader (dispatch, model) {
  return (sense = model.plural) => {
    dispatch({
      type: ListAction.LOAD_REFRESH,
      plural: model.plural,
      payload: sense
    })
  }
}

export function cardErrorHandler (dispatch, model) {
  const cardError = cardErrors(dispatch, model)

  return err => {
    if (!err.response) {
      message('No se obtuvo respuesta', 500)
      // cuando no es un problema con el response, elevamos el error
      throw err
    }

    const { response } = err

    if (response.status !== 422) {
      console.error(err)
    } else if (response.data.error) {
      // OJO: Existe la posibilidad de que el código 422 no sea un
      // error de validación y no traiga un "error" asociado
      cardError(response.data.error)
    }

    // NUNCA hacemos un reject aquí,
    // sino en el responsable de evaluar la respuesta
    return response
  }
}

export function filterController (dispatch) {
  return {
    addFilter (filter, order = 0) {
      dispatch({ type: ListAction.ADD_FILTER, payload: { filter, order } })
    },
    popFilter (order = 0, quantity = 1) {
      dispatch({ type: ListAction.POP_FILTER, payload: { quantity, order } })
    }
  }
}

export function historyLocker (dispatch) {
  return (message, callback) => {
    if (callback) throw new Error('Esta api ha cambiado crack')
    dispatch({
      type: MainAction.LOCK_HISTORY,
      payload: { message }
    })
  }
}

export function historyUnlocker (dispatch) {
  return () => {
    dispatch({
      type: MainAction.LOCK_HISTORY,
      payload: null
    })
  }
}

export function pickerEmpresa (dispatch) {
  return (id) => {
    dispatch({
      type: MainAction.SET_EMPRESA,
      payload: id
    })
  }
}
