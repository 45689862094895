import React from 'react'
// import PropTypes from 'prop-types'

import { NavLink } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { MainAction } from '../../../store/const'

import {
  selectorEmpresaBase
} from '../../../store/selector'

import { Virtuoso, GroupedVirtuoso } from 'react-virtuoso'

import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Loading from '../Loading'

import {
  useListLoader,
  useLocationDetails,
  useCardPicker
} from '../../../utils/hooks'

import * as fecha from '../../../utils/fecha'
import { Divider } from '@mui/material'

// TODO SHOW_CREATE es un MenuToggle
const { SHOW_CREATE } = MainAction

const selectEmpresaBase = selectorEmpresaBase()

export default function TarjetaListHOC (ListComponent, config = {}) {
  config = {
    /**
     * Configuración requerida (ergo no son realmente opciones)
     */
    model: null,
    ItemComponent: null,
    // Configuración opcional (ergo son realmente opciones)
    shouldPost: (id) => !id || id === SHOW_CREATE,
    shouldLoad: (empresa) => !!empresa,
    emptyListAlert: (empresa, model) => (
      empresa
        ? `${empresa.alias} no tiene ${model.plural}`
        : `No tienes ninguna ${model.single}`
    ),
    noResultsAlert: (empresa, model) => (
      `Ninguna ${model.single} de ${empresa.alias} coincide con los filtros`
    ),
    ...config
  }

  const shouldPost = (id) => (id === SHOW_CREATE) || config.shouldPost(id)
  const {
    model,
    ItemComponent,
    shouldLoad,
    windowScroll = true,
    grouped = false
  } = config
  const { pk, single /*, plural */ } = model

  /**
   * Por convención, se extraen las props que no se pasan al Wrapped
   * y se hace forwarding de las props restantes.
   * El componente específico (ListComponent) puede conectarse
   * previamente para añadir props de estado o dispatchers
   * TODO esta implementación debería usar hooks en lugar de connect()()
   */
  const TarjetaList = (props) => {
    const confEmpresa = useSelector(selectEmpresaBase)
    const detalles = useLocationDetails()
    const pickCard = useCardPicker(model)
    const { list: lista, editable, stringValue } = props

    // TODO Falta tener en cuenta la posibilidad "empresa = null"
    const {
      empresa,
      filters,
      listado: list,
      // firstLoad,
      loading,
      loadNext,
      total,
      hasMore
    } = useListLoader({
      model,
      shouldLoad
    })

    // Necesario para tener acceso al estado virtual de la tarjeta en edición
    const voRef = React.useRef(null)
    // Necesario para tener acceso a los cambios existentes en la tarjeta
    const changesRef = React.useRef([])
    const hoy = fecha.input(new Date())

    // Construye las propiedades necesarias en cualquier Item de la lista
    // OJO: Aquí inicializamos "model" a config.model
    const itemPropsFactory = React.useCallback((vo, model = config.model) => {
      if (!vo) throw new Error('NULL item')
      // TODO OJO: blockNavigation es una forma barata de ocultar los
      // modales automáticos
      return {
        // Props funcionales comunes que nunca deberían sobreescribirse
        empresa,
        confEmpresa,
        model,
        config,
        shouldPost,
        voRef,
        item: vo,
        changesRef,
        errored: {},
        // errored: selected ? card.errored : {},
        filters
      }
    }, [/* card.errored, */confEmpresa, empresa, filters])

    const memoizedList = React.useMemo(() => {
      return (stringValue ? lista : list).map(vo => {
        const props = itemPropsFactory(vo, model)
        return (
          <ItemComponent
            key={`${single}-${vo[pk]}`}
            editable={editable}
            {...props}
          />
        )
      })
    }, [list, itemPropsFactory, lista, stringValue, editable])

    const groupedList = React.useMemo(() => {
      return (stringValue ? lista : list).reduce((o, a) => {
        const key = a.fecha_prevista
        return {
          ...o,
          [key]: [...o[key] || [], a]
        }
      }, {
        [hoy]: []
      })
    }, [list, lista, stringValue, hoy])

    const groups = Object.keys(groupedList).sort().reverse()
    const groupCounts = groups.map(key => groupedList[key].length)
    const todayIndex = groupCounts
      .slice(0, groups.indexOf(hoy))
      .reduce((a, b) => a + b, 0)

    const showCreate = React.useMemo(() => {
      return detalles.itemId === SHOW_CREATE
    }, [detalles.itemId])

    /**
     * HOOKS END HERE
     * console.info('Render TarjetaList', model, { card, list, empresa, handleSelectDialog })
    **/

    // console.info('Render TarjetaList (HOC)', { hasMore, lista, list, total })

    if (loading && !total) return <Loading />

    return (
      <>
        <Collapse
          in={!showCreate}
          mountOnEnter
          unmountOnExit
          className='newCard'
          data-cy='new-card'
          title='Crear'
        >
          <Paper
            component='article'
            onClick={() => {
              pickCard('crear')
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
          >
            {detalles.icon}
          </Paper>
        </Collapse>
        <section
          className={'card-list ' + single}
          data-cy={single + '-list'}
          // {...props}
        >
          <ListComponent
            {...{
              list,
              model,
              empresa,
              options: config,
              ItemComponent,
              itemPropsFactory,
              confEmpresa
            }}
            {...props}
          >
            {!shouldLoad(empresa) && (
              <Typography align='center'>
                {'No tienes seleccionada ninguna '}
                <NavLink to='/usuario/empresas'>empresa</NavLink>
              </Typography>
            )}
            <Collapse in={showCreate} mountOnEnter unmountOnExit className='createCard'>
              <ItemComponent
                {
                  ...itemPropsFactory(
                    {
                      ...model.defaults(confEmpresa, filters),
                      [pk]: SHOW_CREATE
                    },
                    model
                  )
                }
              />
            </Collapse>
            {(!list || !list.length) && shouldLoad(empresa) && (
              <Typography align='center'>
                {
                  filters.length
                    ? config.noResultsAlert(empresa, model, filters)
                    : config.emptyListAlert(empresa, model)
                }
              </Typography>
            )}
            {total > 0 && grouped
              ? (
                <GroupedVirtuoso
                  groupCounts={groupCounts}
                  useWindowScroll={!!windowScroll}
                  style={{ height: 600 }}
                  endReached={hasMore ? loadNext : undefined}
                  /* atBottomStateChange={bottom => {
                    if (bottom && hasMore) loadNext()
                  }} */
                  totalCount={total || 0}
                  overscan={{ main: 15, reverse: 15 }}
                  initialTopMostItemIndex={{
                    index: todayIndex,
                    align: 'center'
                  }}
                  groupContent={index => {
                    return (
                      <Grid
                        container
                        className='group-separator-container'
                        sx={groupCounts[index] === 0
                          ? { visibility: 'hidden' }
                          : fecha.formatSlash(groups[index]) === fecha.formatSlash(new Date())
                            ? { fontWeight: 500 }
                            : {}}
                        // sx={{height: '1px'}}
                      >
                        <Grid
                          item
                          className='group-separator'
                        >
                          <Grid
                            item
                            xs={2}
                            sm={2}
                            md={1}
                          >
                            {fecha.formatSlash(groups[index])}
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            sm={10}
                            md={11}
                            sx={{ alignSelf: 'center', marginLeft: '10px' }}
                          >
                            <Divider />
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  }}
                  itemContent={(index, thing) =>
                    <div key={index}>
                      {memoizedList[index]}
                    </div> ||
                      <div>Placeholder</div>}
                  components={{
                    // Header: () => null,
                    Footer: () => hasMore && filters.length < 1
                      ? <div style={{ textAlign: 'center' }}><Loading /></div>
                      : <span />
                  }}
                />
                )
              : (
                <Virtuoso
                  useWindowScroll={!!windowScroll}
                  style={single === 'concepto' && {
                    height: (itemHeight => {
                      const calc = itemHeight * memoizedList.length
                      return calc > 600 ? 600 : calc
                    })(70) + 'px'
                  }}
                  data={memoizedList}
                  endReached={hasMore ? loadNext : undefined}
                  totalCount={total?.total || total || 0}
                  overscan={{ main: 15, reverse: 15 }}
                  itemContent={(index, thing) =>
                    <div key={index}>
                      {thing}
                    </div> ||
                      <div>Placeholder</div>}
                  components={{
                    // Header: () => null,
                    Footer: () => hasMore && filters.length < 1
                      ? <div style={{ textAlign: 'center' }}><Loading /></div>
                      : <span />
                  }}
                />
                )}
          </ListComponent>
        </section>
      </>
    )
  }

  /*
  TarjetaList.propTypes = {
  }
  */

  return TarjetaList
}
