import React from 'react'

import { Typography, Autocomplete } from '@mui/material'

import Field from './Field'

import { GridItemHOC } from './Grid'

import './Combo.scss'

export function Combo ({
  name,
  title,
  label,
  options,
  value = null,
  handleChange,
  ...props
}) {
  // console.log('Render Combo', { name, value, options })

  const selected = options.find(option => option.value === value)

  return (
    <Autocomplete
      fullWidth
      size='small'
      name={name}
      options={options}
      getOptionLabel={option => option.title}
      getOptionSelected={(option, value) => option.value === value.value}
      value={selected}
      onChange={(event, newValue) => {
        const value = newValue ? newValue.value : newValue
        handleChange({ ...event, target: { name, value } })
      }}
      renderOption={option => <Typography noWrap>{option.title}</Typography>}
      renderInput={
        params =>
          <Field
            {...params}
            name={name}
            label={title || label}
          />
      }
    />
  )
}

export default GridItemHOC(Combo)
