import Grid from '@mui/material/Grid'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'

import * as fecha from '../../../utils/fecha'

export default function Lopd (props) {
  const { contacto, empresa, logotipo, date } = props

  return (
    <Grid container>
      <figure className='logo'>
        {logotipo}
      </figure>
      <Grid item xs={12} className='titulo'>
        <h4>
          CONSENTIMIENTO INFORMADO PARA EL USO DE DATOS PERSONALES
        </h4>
      </Grid>
      <Collapse in={contacto !== null} mountOnEnter unmountOnExit>
        <Grid
          item
          xs={6}
          component='figure'
          className='datos cliente'
          data-title='datos del cliente'
        >
          <table>
            <tbody>
              <tr>
                <td colSpan={2}>
                  {
                    contacto && contacto.razon
                      ? contacto.razon
                      : contacto && contacto.alias
                        ? contacto.alias
                        : ''
                  }
                </td>
              </tr>
              <tr>
                <td>
                  N.I.F.: {contacto?.nif || ''}
                </td>
              </tr>
              <tr>
                <td>
                  {'Fecha de nacimiento: ' + (fecha.formatSlash(contacto?.fecha_nacimiento) || '')}
                </td>
              </tr>
              <tr>
                <td>
                  Domicilio: {contacto?.domicilio || ''}
                </td>
              </tr>
              <tr>
                <td>
                  {contacto?.codigo_postal +
                  ' ' + contacto?.ayuntamiento || ''}
                </td>
              </tr>
              <tr>
                <td>{contacto?.provincia || ''}</td>
              </tr>
              <tr>
                <td>Tlfno: {contacto?.telefono || ''}</td>
              </tr>
              <tr>
                <td>Email: {contacto?.email_general || ''}</td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Typography variant='body1'>
          {
          'Los datos personales facilitados serán tratados únicamente a efectos de archivo' +
          ' del historial de tratamientos recibidos y a efectos de las comunicaciónes entre ' +
          ` ${empresa.razon} y el cliente, referidas estrictamente a la prestación` +
          'de los servicios de fisioterapia. No se permite ningún otro uso de los datos.\n\n'
        }
        </Typography>
        <Typography variant='body1'>
          Los datos personales facilitados serán almacenados mientras sean necesarios para la
          prestación del servicio.
        </Typography>
        <Typography variant='body1'>
          Este documento constituye la base legal que legitima el tratamiento de los datos.
        </Typography>
        <Typography variant='body1'>
          {'No está permitida ninguna cesión de estos datos, salvo las legal o judicialmente ' +
          `exigidas a ${empresa.razon}.`}
        </Typography>
        <Typography variant='body1'>
          El cliente tiene derecho a acceder a sus datos personales, así como a solicitar su
          rectificación o su eliminación.
        </Typography>
        <Typography variant='body1'>
          Y en señal de conformidad firmo el presente documento
        </Typography>
        <Typography variant='subtitle2' className='firma'>
          {`En ${empresa.ayuntamiento}, a ${date}\n`}
        </Typography>
        <Typography variant='subtitle2'>
          {contacto?.razon}
        </Typography>
        <footer>
          <address>
            {empresa.razon}{' · '}{empresa.nif}
            {' · '}{empresa.domicilio}{', '}
            {empresa.codigo_postal} {empresa.ayuntamiento} {'('}{empresa.provincia}{')'}
          </address>
          {empresa.email && <address>{empresa.email}</address>}
          {empresa.pie_factura && <address>{empresa.pie_factura}</address>}
        </footer>
      </Collapse>
    </Grid>
  )
}
