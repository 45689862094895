import BaseModel from './AbstractModel'

import {
  CONCEPTO,
  filterType
} from '../../../store/filters/const'

export default class Concepto extends BaseModel {
  static get pk () { return 'id_concepto' }
  static get single () { return 'concepto' }
  static get plural () { return 'conceptos' }

  static defaults (confEmpresa, filters) {
    return {
      id_concepto: null,
      fk_iva: confEmpresa ? confEmpresa.fk_iva_defecto : 1,
      fk_empresa: confEmpresa?.fk_empresa
    }
  }

  static getFrontItemUrl (fkEmpresa, id) {
    return `/${fkEmpresa}/configuracion/conceptos/${id}`
  }

  static getFrontListUrl (fkEmpresa, id) {
    return `/${fkEmpresa}/configuracion/conceptos`
  }

  static async findAll (filters, params) {
    const filtroContacto = filters.find(vo => filterType(vo) === CONCEPTO)
    if (filtroContacto) {
      const results = await super.findAll({
        [this.pk]: filtroContacto[this.pk]
      }, params)

      return results
    }
    return super.findAll({}, params)
  }
}
