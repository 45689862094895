import React from 'react'

/*
export function useSaveHandlerAntiguo (conf = {}) {
  // OJO deps
  const { pk, shouldPost } = conf
  const { voRef } = conf
  const { empresa, card, changesRef, postList, saveItem, pickCard } = conf

  return React.useCallback(
    // recibe el id de la tarjeta a enfocar si tiene éxito
    (id = null, force = false, catcher = true) => {
      if (!changesRef.current.length && !force) {
        if (id === true) throw new Error('No se puede enfocar si no existe')
        return pickCard(id)
      }
      // shouldPost es una opcion funcional que determina
      // cuando se debe hacer un POST a la lista o un PUT al ítem
      return (
        shouldPost(card.id) ? postList : saveItem
      )(empresa, voRef.current)
        .then(response => {
          switch (response.status) {
            case 200:
            case 201:
              voRef.current = null
              changesRef.current = []
              pickCard(id === true ? response.data.item[pk] : id)
              break
            case 422: // Errores de validación
            case 413: // El logo es demasiado grande
            default:
          }
          return response
        })
        .catch(
          catcher ? err => console.error(err) : err => Promise.reject(err)
        )
    },
    [empresa, card, voRef, pk, shouldPost, changesRef, postList, saveItem, pickCard]
  )
}
*/

export function useSaveHandlerTransitional (conf = {}) {
  const {
    saveItem,
    changesRef,
    handleClose
  } = conf

  // TODO por el momento esto es sólo un adaptador
  return React.useCallback(
    ({ catcher, forceSave = false, pickNext }) => {
      if (!changesRef.current.length && !forceSave) {
        handleClose()
        return Promise.resolve(null)
      }

      return saveItem({ catcher, pickNext })
    },
    [saveItem, changesRef, handleClose]
  )
}
