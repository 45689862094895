import React from 'react'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

export default function Copyright () {
  return (
    <footer className='footer'>
      <Typography variant='body2' color='textSecondary' align='center'>
        {'Copyright © '}
        <Link color='inherit' href='https://mareagestion.com'>
          Grupo Marea
        </Link>
        {' ' + new Date().getFullYear()}
      </Typography>
    </footer>
  )
}
