import React from 'react'
import PropTypes from 'prop-types'

// @see https://fullcalendar.io/docs/react
import FullCalendar from '@fullcalendar/react'
import esLocale from '@fullcalendar/core/locales/es'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import { addMinutes } from 'date-fns'
import * as fecha from '../../utils/fecha'

import { getAllClasses } from '../Actividad/ActividadItemBadge'

import EventActividad from './EventActividad'

export function Calendar ({
  events,
  mapper = mapActividad,
  apiRef,
  ...props
}) {
  // console.log('Render full calendar', props)

  return (
    <FullCalendar
      ref={apiRef}
      // https://fullcalendar.io/docs/localization
      locale={esLocale}
      //
      // Plugins. Con esto debería bastar para lo que necesitamos
      //
      // https://fullcalendar.io/docs/timegrid-view
      // https://fullcalendar.io/docs/daygrid-view
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      // https://fullcalendar.io/docs/toolbar
      headerToolbar={false}
      /*
        left: 'title', // 'prev,next today',
        center: '',
        right: 'prev,next'
        // las vistas de día/semana "day" son sin las horas a la izquierda
        // right: 'dayGridMonth,dayGridWeek,timeGridWeek,timeGridDay'
      */
      // https://fullcalendar.io/docs/event-model
      // https://fullcalendar.io/docs/event-object
      events={events.map(mapper)}
      eventContent={EventInfo}
      eventDidMount={handleEventMount}
      // Facilitar la composición
      {...props}
    />
  )
}

function handleEventMount (args) {
  // ver https://fullcalendar.io/docs/event-render-hooks
  const { event: info, el: $link } = args
  const { vo } = info.extendedProps

  // console.warn($link)

  // Esto no deja de ser una trampa brillante debido a que no
  // podemos especificar mejor el contenido y el <a> que genera
  // fullcalendar "molesta" para meter elementos interactivos dentro de él
  /*
  const parent = $link.parentElement
  parent.onclick = $link.onclick
  parent.replaceChildren(...$link.children)
  parent.children[0].classNames = $link.classNames
  */

  $link.dataset.cy = `actividad-${vo.id_actividad}`
}

Calendar.propTypes = {
  events: PropTypes.array.isRequired,
  mapper: PropTypes.func
}

export default React.forwardRef(
  (props, ref) => <Calendar apiRef={ref} {...props} />
)

export function EventInfo (props) {
  const { event: info /* , view, ...other */ } = props

  // console.log('Render Event info', { info })

  const { vo /*, isComercial */ } = info.extendedProps

  return (
    <EventActividad vo={vo} />
  )
}

/**
 * Mapea un objeto actividad a un objeto "Event" de fullcalendar
 * @see https://fullcalendar.io/docs/event-model
 * @see https://fullcalendar.io/docs/event-object
 */
export function mapActividad (vo) {
  const start = !vo.hora_prevista
    ? fecha.combine(vo.fecha_prevista)
    : fecha.combine(vo.fecha_prevista, vo.hora_prevista)

  const isFacturada = vo.isParalela
    ? vo.actividades.some(item => item.fk_factura)
    : !!vo.fk_factura

  return {
    id: `${vo.fk_empresa}:${vo.id_actividad_paralela}`,
    // groupId,
    allDay: !vo.hora_prevista,
    start,
    end: vo.hora_prevista ? addMinutes(start, vo.actividades[0].duracion) : null,
    // startStr,
    // endStr,
    title: `${vo.contacto.alias}`,
    // url,
    classNames: getAllClasses(vo),
    editable: !isFacturada,
    // startEditable,
    // durationEditable,
    // resourceEditable,
    // display,
    // overlap,
    // constraint,
    // background-color,
    // borderColor,
    // textColor,
    extendedProps: {
      vo,
      isComercial: vo.precio > 0
    }
    // source
  }
}
