import React from 'react'

import Paper from '@mui/material/Paper'

import {
  useCardPicker,
  useLocationDetails,
  useItemSaver,
  useItemEraser,
  useAcordeon,
  useChangeHandlers,
  useHistoryBlocker
} from '../../../utils/hooks'

import { useSaveHandlerTransitional } from '../../../utils/hooks/useSaveHandler'

import { useDestroyDialog, useBlockDialog } from './Dialogs'

import './Tarjetas.scss'

export default function TarjetaItemHOC (ItemComponent, options = {}) {
  const {
    classes = props => '',
    afterSave = (sent, response) => response.item
  } = options

  const TarjetaItem = ({
    item,
    ...props
  }) => {
    const { model } = props
    const { single, pk } = props.model
    const { voRef, changesRef } = props

    const articleRef = React.useRef()

    const pickCard = useCardPicker(model)

    const onBack = React.useCallback(() => {
      // TODO esto pinta que debería ser una llamada a resetAllChanges
      changesRef.current = []
      voRef.current = null
      pickCard(null)
    }, [voRef, pickCard, changesRef])

    const onForceClose = React.useCallback(() => {
      pickCard(null)
    }, [pickCard])

    const handleBlock = useHistoryBlocker()

    const {
      vo,
      // resetVO,
      // hasChanges,
      onChangeObject: handleChangeObject,
      onChangeEvent: handleChangeEvent,
      onUndo: handleBack,
      resetAllChanges
    } = useChangeHandlers(voRef, item, changesRef, onBack, handleBlock)

    const detalles = useLocationDetails()

    const selected = React.useMemo(() => {
      // console.log('Calcular selected', model.pk, detalles.itemId, item)
      return (
        typeof item[model.pk] === 'number'
          ? Number(detalles.itemId)
          : detalles.itemId
      ) === item[model.pk] && detalles.itemId !== null
    }, [model.pk, detalles.itemId, item])

    const [acordeon, handleToggle] = useAcordeon(selected)

    const saveItem = useItemSaver(voRef, resetAllChanges, {
      model: props.model,
      shouldPost: props.shouldPost,
      beforeSend: props.config.beforeSend,
      afterSave
    })

    const onSave = useSaveHandlerTransitional({
      changesRef,
      saveItem,
      handleClose: handleBack
    })

    const wipeItem = useItemEraser(voRef, {
      model: props.model, dispatcher: props.config.wipeDispatcher
    })
    const [destroyLayout, confirmDestroy] = useDestroyDialog(wipeItem, handleBack)
    const [blockLayout] = useBlockDialog({
      active: selected,
      onSave,
      onDiscard: resetAllChanges,
      handleBlock
    })

    React.useEffect(() => {
      if (selected) {
        voRef.current = item

        return () => {
          // OJO: Es importante limpiarla sólo cuando es "nuestra"
          voRef.current = null
        }
      }
    }, [voRef, item, selected])

    const onClick = React.useMemo(() => {
      // console.log(selected, item)
      if (single === 'concepto') return
      return !selected ? () => pickCard(item[pk]) : undefined
    }, [selected, pickCard, item, pk, single])

    // console.info('Render TarjetaItem (HOC)', changesRef)

    return (
      <Paper
        component='article'
        data-cy={single + '-item-' + vo[pk]}
        className={
          `card ${single} ${
            selected ? 'selected' : ''
          } ${
            classes(item, props)
          }`
        }
        tabIndex={selected ? -1 : 0}
        // Nota: Puede ser necesario usar la prop "onClickCapture"
        // PD: Dejé de usar el prevent bubbling hace tanto que ni me acuerdo
        // ver https://stackoverflow.com/a/61506528/1894803
        // ver https://reactjs.org/docs/events.html
        onClick={onClick}
        onKeyPress={
          onClick
            ? event => !selected && (event.key === 'Enter') && onClick()
            : null
        }
        ref={articleRef}
        onFocus={event => {
          selected && articleRef.current.blur()
        }}
      >
        <ItemComponent
          {...props} // por convención
          {...{
            vo,
            selected,
            acordeon,
            handleToggleAcordeon: handleToggle,
            handleChangeEvent,
            handleChangeObject,
            onSave,
            // se customiza con la lógica de deshacer
            onBack: handleBack,
            onForceClose,
            resetAllChanges,
            onDestroy: () => {
              // Esto sólo es válido porque sólo se necesita el id
              voRef.current = vo
              confirmDestroy()
            }
          }}
        />
        {destroyLayout}
        {blockLayout}
      </Paper>
    )
  }

  return TarjetaItem
}
