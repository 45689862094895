import TarjetaListHOC from '../Abc/Tarjeta/TarjetaListHOC'

import { factura as model } from '../../service/empresas/model'

import FacturaItem from './FacturaItem'

import PropTypes from 'prop-types'

export function FacturaList (props) {
  return props.children
}

export default TarjetaListHOC(FacturaList, {
  model,
  shouldPost: (id) => !id || /^fra-pendiente/.test(id),
  ItemComponent: FacturaItem
})

FacturaList.propTypes = {
  confEmpresa: PropTypes.object
}
