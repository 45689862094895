import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'

import {
  DateRange as TiempoIcon,
  ShoppingBasket as ConceptoIcon
} from '@mui/icons-material'

import {
  filterType,
  CONTACTO,
  CONCEPTO,
  TIEMPO
} from '../../../../utils/hooks/useFiltersControl'

export default function CompatibleChip ({ vo, razonFlag, ...props }) {
  const razon = (vo.razon !== '' && vo.razon !== vo.alias && razonFlag) ? ' / ' + vo.razon : ''
  let label
  let avatar
  let cy
  let key

  switch (filterType(vo)) {
    case CONTACTO:
      label = vo.alias + razon
      avatar = (
        <Avatar
          style={{
            backgroundColor: vo.fk_entidad ? 'var(--done)' : 'var(--warn)',
            color: 'white'
          }}
        >
          {vo.alias.slice(0, 1)}
        </Avatar>
      )
      cy = 'filter-contacto'
      key = 0
      vo = { ...vo, idx: 0 }
      break
    case CONCEPTO:
      label = vo.nombre
      avatar = (
        <Avatar style={{ backgroundColor: 'white' }}><ConceptoIcon /></Avatar>
      )
      cy = 'filter-concepto'
      key = 1
      vo = { ...vo, idx: 1 }
      break
    case TIEMPO:
      label = vo.label
      avatar = (
        <Avatar style={{ backgroundColor: 'white' }}><TiempoIcon /></Avatar>
      )
      cy = 'filter-date'
      key = 2
      vo = { ...vo, idx: 2 }
      break
    default:
      console.error('VO irreconocible', { vo })
      throw new TypeError('VO irreconocible')
  }

  return (
    <Chip
      // size='small'
      // variant='outlined'
      label={label}
      avatar={avatar}
      data-cy={cy}
      key={key}
      {...props}
    />
  )
}
