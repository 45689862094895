import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { StateKeys } from '../../../store/const'

// import MainMenu from './MainMenu'
import TitleBar from './TitleBar'

import './Header.scss'

const { localStorage } = global

const authRegex = /^\/auth/i

export default function Header (props) {
  const location = useLocation()

  const { user, authLoading } = useSelector(state => ({
    user: state.user,
    authLoading: state[StateKeys.authLoading]
  }))

  /* React.useEffect(() => {
    if (!user || authLoading) return // console.warn('auth is loading')

    const pathname = localStorage.getItem('abrela:path')
    if (!pathname || history.location.pathname === pathname) return

    // console.info('Header did Mount, going to', pathname)
    history.push(pathname)
  }, [user, authLoading, history]) */

  React.useEffect(() => {
    if (!user || authLoading) return console.warn('auth is loading')

    const { pathname } = location
    if (authRegex.test(pathname)) return

    // console.info('Header will register change', pathname)
    localStorage.setItem('abrela:path', pathname)
  }, [user, authLoading, location])

  // console.info('Render Header', { authLoading, actions })

  // if (authRegex.test(location.pathname)) return <></>
  if (!user) return <></>

  return <TitleBar destinos={props.destinos} />

  /*
  return (
    <header>
      <MainMenu history={props.history} destinos={props.destinos} />
      <TitleBar history={props.history} destinos={props.destinos} />
    </header>
  )
  */
}
