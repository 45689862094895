import IbanField from '../FormUtils/IbanField'
import {
  ComboIva,
  ComboProfesional
} from '../FieldSet/Opciones'

import { GridContainer, Field, NumberField } from '../FormUtils'
import InputAdornment from '@mui/material/InputAdornment'

export default function ConfigFacturas (props) {
  const vo = props.vo || {}
  const { handleChange, handleChangeObject /* , errored */ } = props

  // console.info('Render ConfigFacturas', { props, vo })

  return (
    <GridContainer
      spacing={2}
      component='form'
      data-cy='form-facturas'
      className='settings'
    >
      <Field
        sm={4}
        label='Serie Facturas'
        name='serie_facturas'
        value={vo?.serie_facturas || ''}
        // error={!!errored.serie_facturas}
        onChange={handleChange}
      />
      <NumberField
        sm={4}
        label='Margen de Cobro Factura Vencida'
        unit=''
        inputProps={{ style: { textAlign: 'left' } }}
        name='margen_cobro'
        value={vo.margen_cobro}
        // error={!!errored.serie_facturas}
        onChange={handleChange}
      />
      <ComboIva
        sm={4}
        name='fk_iva_defecto'
        value={vo?.fk_iva_defecto || 1}
        // error={!!errored.fk_iva_defecto}
        onChange={handleChange}
      />

      <ComboProfesional
        sm={4}
        value={vo?.es_profesional || 0}
        // error={!!errored.es_profesional}
        required
        onChange={handleChange}
      />

      <Field
        sm={4}
        name='irpf'
        label='Tipo Retención IRPF'
        value={vo?.irpf === '' ? '' : (vo.irpf || 0)}
        // error={!!errored.irpf}
        onChange={handleChange}
        InputProps={{
          endAdornment: <InputAdornment position='end'>%</InputAdornment>
        }}
      />

      <Field
        sm={4}
        name='unidad_medida_defecto'
        label='Unidad'
        value={vo?.unidad_medida_defecto || ''}
        // error={!!errored.unidad_medida_defecto}
        onChange={handleChange}
      />
      {/* Es necesario crear el campo email gestor en empresa base */}
      <Field
        sm={8}
        name='email_gestor'
        label='Email para envío interno de las facturas'
        value={vo?.email_gestor || ''}
        // error={!!errored.unidad_medida_defecto}
        onChange={handleChange}
      />

      <IbanField
        sm={12}
        name='iban'
        label='IBAN'
        value={vo?.iban || ''}
        // error={!!errored.iban}
        onChange={
          (e) => handleChangeObject({
            iban: e.target.value.toUpperCase()
          })
        }
      />

      <Field
        multiline
        // rows={2}
        name='pie_factura'
        label='Pie de Factura'
        value={vo.pie_factura || ''}
        error={!!vo.pie_factura && vo.pie_factura.length > 750}
        helperText={
          (!!vo.pie_factura && vo.pie_factura.length > 750)
            ? 'Máximo 750 caracteres'
            : undefined
        }
        sx={{ textAlign: 'justify' }}
        onChange={handleChange}
      />
    </GridContainer>
  )
}
