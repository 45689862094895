import TarjetaListHOC from '../Abc/Tarjeta/TarjetaListHOC'

import { Borrador as model } from '../../service/empresas/model'

import FacturaItem from './FacturaItem'

import PropTypes from 'prop-types'

export function BorradorList (props) {
  return props.children
}

export default TarjetaListHOC(BorradorList, {
  model,
  ItemComponent: FacturaItem
})

BorradorList.propTypes = {
  confEmpresa: PropTypes.object
}
