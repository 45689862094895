import { formatFloat } from '@grupomarea/abrela-tesoreria'

import { InputAdornment } from '@mui/material'

import Field from '../FormUtils/Field'
import NumberField from '../FormUtils/NumberField'

export function Cantidad ({ unidad, ...props }) {
  return (
    <NumberField
      unit={unidad || 'Ud.'}
      name='cantidad'
      label='Cantidad'
      defaults={1}
      notZero
      {...props}
    />
  )
}

export function Precio ({ required, ...props }) {
  return (
    <NumberField
      unit='€'
      name='precio'
      label='Precio'
      required={required}
      defaults=''
      minimum={0}
      notZero={required}
      format={formatFloat}
      {...props}
    />
  )
}

export function Descuento ({ ...props }) {
  return (
    <NumberField
      unit='%'
      name='descuento'
      label='Descuento'
      defaults={0}
      minimum={0}
      maximum={100}
      // hideZero
      {...props}
    />
  )
}

export function Importe (props) {
  return (
    <Field
      disabled
      // OJO: https://material-ui.com/api/text-field/#textfield-api
      inputProps={{ tabIndex: -1 }}
      InputProps={{
        endAdornment: <InputAdornment position='end'>€</InputAdornment>
      }}
      {...props}
    />
  )
}

/**
 * Esta es la versión "focuseable" del widget Importe anterior
 */
export function ImporteFocusable (props) {
  return (
    <Field
      readOnly
      // OJO: https://material-ui.com/api/text-field/#textfield-api
      // inputProps={{ tabIndex: -1 }}
      InputProps={{
        endAdornment: <InputAdornment position='end'>€</InputAdornment>
      }}
      {...props}
    />
  )
}
