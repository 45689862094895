import React from 'react'
import { Buffer } from 'buffer'

import { useSelector, useDispatch } from 'react-redux'
import { selectorEmpresaBase, selectorListado } from '../../../store/selector'
import { empresa as model } from '../../../service/empresas/model'

import AuthService from '../../../service/auth'

import { useNavigate } from 'react-router-dom'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Divider from '@mui/material/Divider'
import Avatar from '@mui/material/Avatar'
import AvatarGroup from '@mui/material/AvatarGroup'
import Button from '@mui/material/Button'
// import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'

import {
  AssignmentTurnedIn as EmpresaSeleccionadaIcon,
  Add
} from '@mui/icons-material'

import './DialogAjustes.scss'
import { pickerEmpresa } from '../../../store/dispatcher'

const selectEmpresas = selectorListado(model)
const selectBase = selectorEmpresaBase(model)
// const selectEmpresa = useItemSaver()

function TabPanel (props) {
  const { children, value, index, ...other } = props
  // console.log(props)
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {
         value === index && children
      }
    </div>
  )
}

export default function DialogAjustes (props) {
  const { open, setOpen } = props
  const [page, setPage] = React.useState(0)
  const containerRef = React.useRef(null)

  const empresas = useSelector(selectEmpresas)
  const empresaBase = useSelector(selectBase)

  const dispatch = useDispatch()
  const pickEmpresa = pickerEmpresa(dispatch)
  const selectEmpresa = (id) => {
    pickEmpresa(id)
  }

  const navigate = useNavigate()

  const handleClose = () => {
    setOpen(false)
    setPage(0)
  }

  const handleLogout = event => {
    AuthService.dispatchLogout()
    navigate('/')
  }

  return (
    <Dialog
      data-cy='dialog-ajustes'
      name='dialogAjustes'
      className='dialogAjustes'
      open={open}
      fullWidth
      maxWidth='sm'
      scroll='paper'
    >
      <DialogTitle>
        {empresaBase?.alias && page === 0 ? empresaBase.alias : 'Lista de Empresas'}
      </DialogTitle>
      <DialogContent>
        <List
          dense
          disablePadding
          ref={containerRef}
        >
          <Divider />
          <TabPanel value={page} index={0}>
            <ListItemButton
              data-cy='ajustes-lista-empresas'
              onClick={() => {
                navigate('/usuario/empresas')
                handleClose()
              }}
            >
              <ListItemText
                primary='Lista de empresas'
              />
            </ListItemButton>
            <ListItemButton
              data-cy='ajustes-empresas'
              onClick={() => {
                navigate(`/${empresaBase.fk_empresa}/configuracion`)
                handleClose()
              }}
              disabled={!empresaBase?.fk_empresa}
            >
              <ListItemText
                primary='Configuracion empresa'
              />
            </ListItemButton>
            <ListItemButton
              data-cy='ajustes-conceptos'
              onClick={() => {
                empresaBase
                  ? navigate(`/${empresaBase.fk_empresa}/configuracion/conceptos`)
                  : navigate('/conceptos')
                handleClose()
              }}
            >
              <ListItemText primary='Catálogo Conceptos' />
            </ListItemButton>
            <ListItemButton
              data-cy='ajustes-documentos'
              onClick={() => {
                navigate(`/${empresaBase.fk_empresa}/configuracion/conf-documentos`)
                handleClose()
              }}
            >
              <ListItemText
                primary='Plantillas y documentos'
              />
            </ListItemButton>
            <Divider />
          </TabPanel>
          <TabPanel value={page} index={1}>
            <ListSubheader>CAMBIAR EMPRESA</ListSubheader>
            {
              empresas.length > 0
                ? empresas.map((empresa, idx) =>
                  <ListItemButton
                    data-cy={`empresa-${idx}`}
                    key={idx}
                    onClick={() => {
                      // console.log('esto selecciona una empresa base')
                      selectEmpresa(empresa.fk_empresa)
                      handleClose()
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt='Avatar'
                        variant='rounded'
                        src={
                          empresa.avatar
                            ? typeof empresa.avatar === 'string'
                              ? empresa.avatar
                              : Buffer.from(empresa.avatar).toString()
                            : undefined
                        }
                      >
                        {!empresa.avatar && empresa.alias.slice(0, 1)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={empresa.alias}
                      secondary={empresa.razon}
                    />
                    {
                      empresa.fk_empresa === empresaBase?.fk_empresa &&
                        <AvatarGroup>
                          <Avatar className='empresa-activa'>
                            <EmpresaSeleccionadaIcon fontSize='small' />
                          </Avatar>
                        </AvatarGroup>
                    }
                  </ListItemButton>
                )
                : (
                  <ListItem>
                    <ListItemText
                      primary='No hay empresas'
                    />
                  </ListItem>
                  )
            }
            <Divider />
            <ListItemButton
              data-cy='nueva-empresa'
              onClick={() => {
                navigate('/usuario/empresas/crear')
                handleClose()
              }}
            >
              <ListItemAvatar>
                <Avatar>
                  <Add />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary='Añadir nueva empresa' />
            </ListItemButton>
          </TabPanel>
        </List>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item sm={9}>
            {page === 0 && (
              <Button
                variant='text'
                onClick={() => setPage(1)}
              >
                Cambiar empresa
              </Button>

            )}
            {page === 1 && (
              <Button
                variant='text'
                onClick={() => setPage(0)}
              >
                Opciones empresa
              </Button>
            )}
            <Button
              onClick={handleLogout}
              data-cy='logout'
            >
              Desconectar
            </Button>
          </Grid>
          <Grid item sm={3} sx={{ textAlign: 'end', alignSelf: 'end' }}>
            <Button onClick={handleClose}>
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
