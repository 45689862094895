import React from 'react'

import tesoreria from '@grupomarea/abrela-tesoreria'
import * as fecha from '../../utils/fecha'

import { Field, DateWidget } from '../Abc/FormUtils'

import { Cantidad, Precio, Descuento } from '../Abc/FieldSet/Valor'
import WidgetConcepto from '../Abc/FieldSet/WidgetConcepto'
import notify from '../../utils/notify'

export default function FacturaItemActividadRow (props) {
  const { editable, vo, idx, errored, open, onClose, ivaBase } = props

  const { handleError, handleChangeObject } = props

  const [openDate, setOpenDate] = React.useState(false)

  const changeEventHandler = (field) => event => handleChangeObject({
    [field]: event.target.value
  })

  const namespace = `actividad[${idx}]`
  // console.info('Render', vo)

  const content = (editable || vo.concepto === null)
    ? [
      <DateWidget
        key={0}
        name={`${namespace}[fecha_prevista]`}
        open={openDate}
        onOpen={() => setOpenDate(true)}
        value={
          vo.fecha_prevista === null || vo.fecha_prevista === ''
            ? new Date()
            : new Date(vo.fecha_prevista)
        }
        error={!!errored.fecha_prevista}
        onChange={dateObj => {
          if (
            vo.fecha_prevista !== null &&
            vo.fecha_prevista === fecha.input(dateObj)) return

          handleChangeObject({
            fecha_prevista: dateObj === null ? null : fecha.input(dateObj)
          })
          setOpenDate(false)
        }}
        onInvalid={err => handleError({
          [`${namespace}[fecha_prevista]`]: err
        })}
        onClose={() => {
          handleChangeObject({
            fecha_prevista: vo.fecha_prevista === null || vo.fecha_prevista === ''
              ? fecha.input(new Date())
              : fecha.input(new Date(vo.fecha_prevista))
          })
          setOpenDate(false)
        }}
      />,
      <React.Fragment key={1}>
        <WidgetConcepto
          name={`${namespace}[concepto]`}
          open={open}
          label={null}
          // OJO!!!! Esto va a volver a mordernos
          value={vo.concepto || null}
          error={!!errored.concepto}
          onClose={onClose}
          onChange={
            (concepto) => {
              if (concepto.fk_iva !== ivaBase && idx !== 0 && vo.concepto !== null) {
                notify({ message: 'IVA distinto', type: 'danger' })
                onClose()
                // console.log('IVA DISTINTOS')
                return
              }
              const changes = {
                concepto,
                fk_concepto: concepto.id_concepto
              }
              if (typeof concepto.precio === 'number') {
                changes.precio = concepto.precio || 0
              }
              if (!vo.fecha_prevista) {
                setOpenDate(true)
              }
              if (concepto.id_concepto &&
                (concepto.id_concepto === vo.concepto?.id_concepto)) return
              handleChangeObject(changes)
            }
          }
        />
        <Field
          name={`${namespace}[descripcion]`}
          label={null}
          placeholder='Descripción opcional'
          value={vo.descripcion || ''}
          error={!!errored.descripcion}
          onChange={changeEventHandler('descripcion')}
          multiline
        />
      </React.Fragment>,
      <Cantidad
        key={2}
        name={`${namespace}[cantidad]`}
        label={null}
        value={vo.cantidad}
        error={!!errored.cantidad}
        unidad={vo.concepto?.unidad_medida}
        onChange={changeEventHandler('cantidad')}
      />,
      <Precio
        key={3}
        name={`${namespace}[precio]`}
        label={null}
        value={vo.precio}
        error={!!errored.precio}
        required
        autoFocus={open || !vo.precio || vo.precio === 0}
        onChange={changeEventHandler('precio')}
      />,
      <Descuento
        key={4}
        name={`${namespace}[descuento]`}
        label={null}
        value={vo.descuento}
        error={!!errored.descuento}
        onChange={changeEventHandler('descuento')}
      />,
      tesoreria.formatEUR(tesoreria.importe(vo, false))
      ]
    : [
        fecha.formatSlash(vo.fecha_prevista),
        (
          <React.Fragment key={1}>
            <strong>{vo.nombre}</strong>
            <small>{vo.descripcion}</small>
          </React.Fragment>
        ),
        [vo.cantidad, ' ', vo.unidad_medida],
        tesoreria.formatEUR(vo.precio),
        // OJO Bug en Abrela-tesorería, valores multiplicados por 100
        tesoreria.format100(vo.descuento),
        tesoreria.formatEUR(vo.importe)
      ]

  return (
    <tr data-cy='actividad-row' data-actividad={vo.id_actividad}>
      <td data-cy={`${namespace}[fecha]`}>{content[0]}</td>
      <td data-cy={`${namespace}[concepto]`}>{content[1]}</td>
      <td data-cy={`${namespace}[cantidad]`}>{content[2]}</td>
      <td data-cy={`${namespace}[precio]`}>{content[3]}</td>
      <td data-cy={`${namespace}[descuento]`}>{content[4]}</td>
      <td data-cy={`${namespace}[importe]`}>{content[5]}</td>
    </tr>
  )
}
