import { Store } from 'react-notifications-component'

import 'animate.css/animate.min.css'

export function severity (status) {
  switch (Number(status)) {
    case 200:
      return 'info'
    case 201:
      return 'success'
    case 202:
      return 'danger'
    case 400:
    case 401:
    case 422:
      return 'warning'
    default:
      return 'danger'
  }
}

export function message (message, status, determiner = severity) {
  notificar({ message, type: determiner(status) })
}

export default function notificar ({ dismiss = {}, ...notification }) {
  let effectIn = 'animate__fadeIn'
  let effectOut = 'animate__fadeOut'
  /* let effectIn = 'animate__flipInY'
  let effectOut = 'animate__flipOutY' */

  switch (notification.type) {
    case 'default':
      effectIn = 'animate__fadeIn'
      effectOut = 'animate__fadeOut'
      notification.container = 'bottom-left'
      break
    case 'success':
    case 'warning':
    case 'danger':
      // effectIn = 'animate__tada'
      effectIn = 'animate__fadeIn'
      effectOut = 'animate__fadeOut'
      notification.container = 'bottom-center'
      break
    default:
  }

  if (notification.type === 'default') {
    dismiss.duration = 1500
    dismiss.onScreen = false
    dismiss.showIcon = false
  }
  if (notification.type === 'danger') {
    dismiss.duration = 5000
  }
  if (notification.type === 'info') {
    notification.container = 'bottom-left'
    dismiss.duration = 1500
  }

  setTimeout(() =>
    Store.addNotification({
      type: 'warning',
      insert: 'top',
      container: 'center',
      animationIn: ['animate__animated', effectIn],
      animationOut: ['animate__animated', effectOut],
      dismiss: {
        // duration: 5000,
        duration: 3000,
        showIcon: true,
        onScreen: true,
        pauseOnHover: true,
        waitForAnimation: true,
        ...dismiss
      },
      /*
      content: (
        <div className={`notification__cuistom--$type}
        </>
      )
      */
      ...notification
    })
  , 200)
}

window.notificar = notificar

/**
 * TODO: Mejorar con iconos
 *
setTimeout(() => {
  const type = 'danger'
  notificar({
    content: (
      <div className={`notification__content notification__custom--${type}`}>
        <div class='notification__close' />
        <div class='notification__mesage'>prueba content</div>
      </div>
    )
  })
}, 3000)
*/
