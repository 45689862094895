import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker'
import Dialog from '@mui/material/Dialog'

import { FieldDateTime } from './Field'
import { GridItemHOC } from './Grid'

import { commonProps, DatePickerHOC } from './DateWidget'

import * as fecha from '../../../utils/fecha'
import { Grid } from '@mui/material'

export const DateTimeWidget = DatePickerHOC(
  function DateTimeField (props) {
    const {
      open,
      onChange,
      onTimeChange,
      name,
      value,
      hideField,
      ...other
    } = props

    const handleChange = (dateObj) => {
      if (!dateObj || isNaN(dateObj.getTime())) {
        return onChange(dateObj, null, null)
      }

      // TODO esta forma de generar los strings es muy ugly
      const date = fecha.input(dateObj)
      const time = dateObj.toTimeString().split(' ').shift().slice(0, 5)

      // console.warn('handle (datetime)', { value, date, time, dateObj, getTime: dateObj })

      onChange(dateObj, date, time)
    }

    return (
      <Dialog
        open={open}
      >
        <Grid container spacing={2} sx={{ padding: '1rem 1rem 0rem 2rem' }}>
          <FieldDateTime
            sm={8}
            autoFocus
            inputProps={{
              sx: {
                fontSize: '1.5rem'
              }
            }}
            InputProps={{
              disableUnderline: true
            }}
            value={value}
            onChange={handleChange}
          />
          <FieldDateTime
            sm={4}
            inputProps={{
              sx: {
                fontSize: '1.5rem'
              }
            }}
            InputProps={{
              disableUnderline: true
            }}
            showtime
            value={value}
            onChange={handleChange}
          />
        </Grid>
        <StaticDateTimePicker
          // Este picker pide a gritos una manera en la que las vistas no
          // cambien cuando les da la gana y dar la opción
          value={value}
          label='Fecha y hora'
          openTo='day'
          onChange={handleChange}
          onAccept={handleChange}
          orientation='portrait'
          views={['day'/* , 'year' */]}
          slots={{
            toolbar: undefined,
            tabs: undefined
          }}
          slotProps={{
            actionBar: {
              actions: ['cancel', 'today', 'accept']
            }
          }}
          {...commonProps}
          {...other}
        />
      </Dialog>
    )
  }
)

export default GridItemHOC(DateTimeWidget)
