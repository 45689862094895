import { createAxiosClient } from './core'

import store from '../store'
import { MainAction, AuthAction } from '../store/const'

import notify, { message as notifyMsg } from '../utils/notify'

const { localStorage, sessionStorage } = global

const _clients = []

/**
 * Privado e inaccesible fuera del scope del módulo
 */
const _axios = createAxiosClient('/api/auth')

/**
 * La propia clase AuthService actúa como singleton
 */
export default class AuthService {
  /**
   * Registra un cliente axios y lo configura a efectos de autenticación
   */
  static register (client) {
    console.info('Register', client.defaults.baseURL)

    _clients.push(client)

    const token = AuthStorage.getToken()
    if (token) {
      this.setAuthorizationHeader(client, token)
    }

    client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!error.response) {
          notify({
            message: 'No se obtuvo respuesta del servidor',
            type: 'danger'
          })
          this.dispatchOffline()
          return Promise.reject(error)
        }

        const { response } = error
        let message = 'Se ha producido un error en la comunicación'

        switch (response.status) {
          case 403:
            message = 'Se ha prohibido el acceso'
            this.dispatchLogout()
            break
          case 401:
            message = 'Debes identificarte'
            this.dispatchLogout()
            break
          case 502:
            message = 'No se pudo conectar con el servicio API'
            break
          default:
            // console.error(error)
        }

        if (response.data.message) {
          notifyMsg(response.data.message, response.status)
        } else {
          notifyMsg(message, response.status)
        }

        return Promise.reject(error)
      }
    )

    return client
  }

  /**
   * Establece la cabecera Authorization del cliente especificado
   */
  static setAuthorizationHeader (client, token = '') {
    client.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  /**
   * ELimina la cabecera Authorization del cliente especificado
   */
  static removeAuthorizationHeader (client) {
    delete client.defaults.headers.common.Authorization
  }

  /**
   * Autoriza todos los clientes registrados solicitando previamente un
   * nuevo token de acceso al servicio de backend
   */
  static async requestLogin (credentials = {}, follow = null) {
    let next = follow || '/usuario'

    return _axios.post('/login', credentials)
      .then((response) => {
        const { data } = response

        switch (response.status) {
          case 201:
            this.dispatchLogin(credentials.username, data)
            break
          case 202:
            next = `/auth/passwd?${new URLSearchParams({
              mode: 'force',
              code: data.session,
              email: credentials.username
            })}`
            break
          default:
            console.warn('Problema de login', { response })
            throw new Error('Login Fallido')
        }

        notify({ message: data.message, type: 'success' })
        return next
      })
      .catch(error => {
        if (!error.response) {
          console.error('No existe response', error)
        }
        if (error.response.status === 401) {
          // AuthService.dispatchLogout()
        } else {
          console.error('Problema al refrescar sesión', error)
        }
      })
  }

  /**
   * Refresca los tokens de acceso de todos los clientes registrados previa
   * solicitud al backend de nuevos tokens de acceso
   */
  static async requestRefresh () {
    // store.dispatch({ type: AuthAction.LOADING })

    const user = AuthStorage.getUser()
    if (!user) return console.warn('No se puede refrescar la sesión')

    return _axios.put('/login')
      .then(response => {
        const { data } = response

        switch (response.status) {
          case 201:
            // Por alguna razon el test de login peta en el tercer refresh
            // sin esta linea pero no tiene sentido
            AuthStorage.setToken(data.accessToken, data.expires)
            this.dispatchLogin(user.email, data)
            // store.dispatch({ type: AuthAction.LOADING, payload: false })
            break
          default:
            console.warn('Problema de refresh', { response })
            throw new Error('Login Fallido')
        }

        notify({ message: data.message, type: 'default' })
        return null
      })
      .catch(error => {
        console.error('Problema al refrescar sesión', error)
      })
  }

  /**
   * Comunica al almacén de redux que el usuario se ha autenticado con éxito
   */
  static dispatchLogin (username, data) {
    AuthStorage.setToken(data.accessToken, data.expires)

    store.dispatch({
      type: AuthAction.SIGN_IN,
      user: {
        email: username,
        relaciones: data.relaciones
      }
    })
  }

  /**
   * Desautoriza todos los clientes registrados y comunica al backend la
   * desconexión del usuario.
   * @todo De momento no se comunica con backend
   */
  static async requestLogout () {
    AuthService.dispatchLogout()
  }

  /**
   * Comunica al almacén de redux que el usuario se ha desconectado
   */
  static dispatchLogout () {
    AuthStorage.removeToken()
    AuthStorage.removeUser()
    store.dispatch({
      type: MainAction.MULTIACTION,
      payload: [
        { type: AuthAction.LOG_OUT },
        { type: AuthAction.LOADING, payload: false }
      ]
    })
  }

  static async requestPasswdReset (body = {}, follow = null) {
    const next = follow || '/'

    return _axios.put('/reset', body)
      .then(response => {
        const { data } = response
        notify({ message: data.message, type: 'success' })
        this.dispatchLogin(body.username, data)
        return next
      })
  }

  /**
   * Recarga los datos de la cuenta del usuario autenticado
   */
  static reloadAccount () {
    console.warn('Pidiendo los detalles de la cuenta al servidor')
    store.dispatch({ type: AuthAction.LOADING })

    _axios.get('/cuenta')
      .then(response => {
        const { data } = response
        store.dispatch({
          type: MainAction.MULTIACTION,
          payload: [
            {
              type: AuthAction.SIGN_IN,
              user: {
                email: data.email,
                relaciones: data.relaciones
              }
            },
            {
              type: AuthAction.LOADING,
              payload: false
            }
          ]
        })

        // OJO! NO SABEMOS SI ES NECESARIO
        // AuthService.requestRefresh()
      })
      .catch(error => console.error(error))
  }

  /**
   * Comunica al almacén de redux que no se puede comunicar con el servidor
   */
  static dispatchOffline () {
    store.dispatch({
      type: MainAction.MULTIACTION,
      payload: [
        { type: AuthAction.OFFLINE },
        { type: AuthAction.LOADING, payload: false }
      ]
    })
  }
}

/**
 * clase privada e inaccesible desde fuera del scope del módulo
 */

class AuthStorage {
  /**
   * Token de acceso
   */
  static getToken () {
    return localStorage.getItem('token')
  }

  static hasToken () {
    return localStorage.getItem('token') !== null
  }

  static setToken (token, expires = null) {
    localStorage.setItem('token', token)

    for (const client of _clients) {
      AuthService.setAuthorizationHeader(client, token)
    }

    if (typeof expires === 'number') {
      console.warn(`Refresco automático en ${expires} segundos`)

      setTimeout(() => {
        console.warn('El token de autenticación ha expirado')
        AuthService.requestRefresh()
      }, expires * 999)
    }
  }

  static removeToken (token) {
    localStorage.removeItem('token')
    for (const client of _clients) {
      AuthService.removeAuthorizationHeader(client, token)
    }
  }

  /*
   * User conectado
   */
  static getUser () {
    return JSON.parse(sessionStorage.getItem('abrela:user'))
  }

  static hasUser () {
    return this.getUser() !== null
  }

  static removeUser () {
    return sessionStorage.removeItem('abrela:user')
  }
}

AuthService.register(_axios)

if (AuthStorage.hasToken() && !AuthStorage.hasUser()) {
  AuthService.reloadAccount()
}
