import React from 'react'
import TarjetaItemHOC from '../Abc/Tarjeta/TarjetaItemHOC'

import { Field } from '../Abc/FormUtils'
import { Precio } from '../Abc/FieldSet/Valor'
import { ComboIva } from '../Abc/FieldSet/Opciones'

import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'

import IconButton from '@mui/material/IconButton'

import DeleteIcon from '@mui/icons-material/Delete'
// import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/Check'

export function ConceptoItem (props) {
  const { vo, errored } = props

  const { handleChangeEvent: handleChange } = props

  const { onSave, onDestroy, editable } = props

  return (
    <Grid container spacing={1}>
      <Field
        sm={12}
        md={3}
        name='nombre'
        label='Nombre'
        value={vo.nombre || ''}
        error={!!errored.nombre}
        required
        InputProps={{
          disableUnderline: !editable && vo.id_concepto !== 'SHOW_CREATE',
          readOnly: !editable && vo.id_concepto !== 'SHOW_CREATE'
        }}
        onChange={handleChange}
      />
      <Field
        className='valores'
        sm={3}
        md={2}
        name='unidad_medida'
        label='Unidad de medida'
        value={vo.unidad_medida || ''}
        error={!!errored.unidad_medida}
        InputProps={{
          disableUnderline: !editable && vo.id_concepto !== 'SHOW_CREATE',
          readOnly: !editable && vo.id_concepto !== 'SHOW_CREATE'
        }}
        onChange={handleChange}
      />
      <ComboIva
        className='valores'
        sm={3}
        md={2}
        name='fk_iva'
        // className={editable ? 'editable' : 'readOnly'}
        value={vo?.fk_iva || 1}
        error={!!errored.fk_iva}
        InputProps={{
          disableUnderline: !editable && vo.id_concepto !== 'SHOW_CREATE',
          readOnly: !editable && vo.id_concepto !== 'SHOW_CREATE'
        }}
        onChange={handleChange}
      />
      <Precio
        className='valores precio'
        sm={4}
        md={3}
        value={vo.precio}
        error={!!errored.precio}
        onChange={handleChange}
        InputProps={{
          disableUnderline: !editable && vo.id_concepto !== 'SHOW_CREATE',
          readOnly: !editable && vo.id_concepto !== 'SHOW_CREATE'
        }}
      />
      <Grid
        item
        sm={2}
        className='concepto-buttonWrapper'
      >
        <Collapse
          in={editable || vo.id_concepto === 'SHOW_CREATE'}
          mountOnEnter
          unmountOnExit
          className='concepto-icons'
        >
          <IconButton
            onClick={onSave}
            data-cy='save-button'
          >
            <CheckIcon fontSize='small' />
          </IconButton>
          <IconButton
            onClick={onDestroy}
            data-cy='destroy-button'
          >
            <DeleteIcon fontSize='small' />
          </IconButton>
        </Collapse>
      </Grid>
    </Grid>
  )
}

export default TarjetaItemHOC(ConceptoItem)
