import React from 'react'
// import PropTypes from 'prop-types'

import TarjetaItemHOC from '../Abc/Tarjeta/TarjetaItemHOC'

import ActividadItemParalela from './ActividadItemParalela'
import ActividadItemSimple from './ActividadItemSimple'

export function ActividadItemAdapter (props) {
  const { vo } = props

  // Parece mejor:
  // if (vo && vo.isParalela) {
  if (vo.actividades && vo.actividades.length > 1) {
    return <ActividadItemParalela {...props} />
  } else {
    return <ActividadItemSimple {...props} />
  }
}

export default TarjetaItemHOC(ActividadItemAdapter, {
  // Se podría connectar directamente usando un array de parámetros
  // connect: [state => ({}), dispatch => ({})]
  // Pero parece un anti-pattern.
  // ActividadList puede injectar mediante las opciones de TarjetaListHOC
  // (ver "itemConnector")
})
