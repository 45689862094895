import { utils, writeFile } from 'xlsx'

export default function goldenSoftExport (data) {
  const newWB = utils.book_new()
  const newWS = utils.json_to_sheet(data)
  utils.sheet_add_aoa(newWS, [[
    'Emitida (E), Recibida (R), Ingreso (I) o Gasto (G)',
    'Número de la factura',
    'Documento',
    'Fecha de la factura',
    'CIF',
    'Fecha de contabilización',
    'Concepto',
    'Tipo Operación',
    'CP',
    'Domicilio de la factura',
    'Municipio',
    'Cliente ó proveedor',
    'Nombre del cliente/prov.',
    'Código de Actividad',
    'Base 1ª Linea Factura',
    'IVA 1ª Linea Factura',
    'Cuota Iva 1ª Linea Factura',
    'Cuenta de Apoyo 1 ª Linea Factura',
    'Forma de pago',
    'Importe IRPF factura',
    'Generar asientos'
  ]], { origin: 'A1' })

  utils.book_append_sheet(newWB, newWS, 'hoja1')

  writeFile(newWB, 'goldenSoft_Export.xls')
}
