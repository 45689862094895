import React from 'react'
import { useLocation } from 'react-router-dom'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import Add from '@mui/icons-material/Add'

import {
  empresa, contacto, ActividadParalela, factura, concepto, Evento, Borrador
} from '../../service/empresas/model'

import { MainAction } from '../../store/const'
import { Typography } from '@mui/material'
const { SHOW_CREATE } = MainAction

export function useLocationDetails () {
  const location = useLocation()
  const breadcumbs = React.useMemo(() => {
    return location.pathname.split('/').map(decodeURIComponent)
  }, [location.pathname])

  switch (breadcumbs[2]) {
    case 'contactos':
      return {
        index: 0,
        order: 1,
        name: 'Contactos',
        links: {},
        model: contacto,
        itemId: breadcumbs[3] === 'crear' ? SHOW_CREATE : (breadcumbs[3] || null),
        icon: (
          <div className='newCardContent'>
            <AccountCircleOutlinedIcon color='disabled' sx={{ fontSize: '3em' }} />
            <Typography paragraph={false} component='span'>Crear contacto nuevo</Typography>
          </div>),
        drawer: true,
        searchParams: {
          contactos: true,
          conceptos: false,
          tiempo: false
        }
      }
    case 'agenda':
    case 'agenda-full':
      return {
        index: 1,
        order: 2,
        name: 'Agenda',
        links: {},
        model: Evento,
        itemId: breadcumbs[3] === 'crear' ? SHOW_CREATE : (breadcumbs[3] || null),
        drawer: true,
        searchParams: {
          contactos: true,
          conceptos: true,
          tiempo: true
        }
      }
    case 'actividades':
      return {
        index: 2,
        order: 3,
        name: 'Actividades',
        links: {},
        model: ActividadParalela,
        itemId: breadcumbs[3] === 'crear' ? SHOW_CREATE : (breadcumbs[3] || null),
        icon: (
          <div className='newCardContent'>
            <Add color='disabled' sx={{ fontSize: '3em' }} />
            <Typography paragraph={false} component='span'>Crear actividad nueva</Typography>
          </div>),
        drawer: true,
        searchParams: {
          contactos: true,
          conceptos: true,
          tiempo: true
        }
      }
    case 'facturacion':
      return {
        index: 3,
        order: 4,
        name: breadcumbs[3] === 'emitidas'
          ? 'Facturas'
          : breadcumbs[3] === 'borradores'
            ? 'Borradores'
            : null,
        links: {},
        model: breadcumbs[3] === 'emitidas'
          ? factura
          : breadcumbs[3] === 'borradores'
            ? Borrador
            : null,
        subModel: breadcumbs[3],
        itemId: breadcumbs[4] === 'crear'
          ? SHOW_CREATE
          : breadcumbs[4] || null,
        icon: (
          <div className='newCardContent'>
            <Add color='disabled' sx={{ fontSize: '3em' }} />
            <Typography paragraph={false} component='span'>
              Crear nueva factura desde cero
            </Typography>
          </div>),
        drawer: true,
        searchParams: {
          contactos: true,
          conceptos: true,
          tiempo: true
        }
      }
    case 'empresas':
      return {
        index: 4,
        order: 5,
        name: 'Mis empresas',
        settingsDisabled: true,
        searchDisabled: true,
        links: {
          'Logout usuario': '/auth/logout',
          'Mis empresas': '/usuario/empresas'
        },
        model: empresa,
        itemId: breadcumbs[3] === 'crear' ? SHOW_CREATE : (breadcumbs[3] || null),
        icon: (
          <div className='newCardContent'>
            <Add color='disabled' sx={{ fontSize: '3em' }} />
            <Typography paragraph={false} component='span'>Crear nueva empresa</Typography>
          </div>),
        drawer: false
      }
    case 'configuracion':
      return {
        index: 4,
        order: 6,
        name: 'Configuración',
        settingsDisabled: true,
        searchDisabled: true,
        links: {},
        model: concepto,
        itemId: breadcumbs[4] === 'crear' ? SHOW_CREATE : (breadcumbs[3] || null),
        drawer: false,
        searchParams: {
          contactos: false,
          conceptos: true,
          tiempo: false
        }
      }
    default:
      return {
        index: -1,
        order: 0,
        name: 'Menu',
        links: {
          Inicio: '/',
          'Recuperar Contraseña': '/auth/reset',
          Registro: '/auth/registro'
        },
        searchDisabled: true,
        model: null
      }
  }
}
