
import React from 'react'
import { useSelector } from 'react-redux'

import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import IconButton from '@mui/material/IconButton'
import Fade from '@mui/material/Fade'

import {
  MoreVert as MenuIcon,
  Search as SearchIcon
} from '@mui/icons-material'
import BackspaceIcon from '@mui/icons-material/Backspace'
import CheckIcon from '@mui/icons-material/Check'

import {
  selectorIsLoading,
  selectorEmpresa
} from '../../../../store/selector'

import {
  useLocationDetails,
  useFiltersControl
} from '../../../../utils/hooks'

import { MainAction } from '../../../../store/const'
import { searchData } from '../../../../utils/SearchInput'

import { Field } from '../../FormUtils/Field'

import CompatibleChip from './CompatibleChip'

const { SHOW_CREATE } = MainAction

const selectIsLoading = selectorIsLoading()
const selectEmpresa = selectorEmpresa()

export default function SearchBar (props) {
  const { toggleExpanded, expanded } = props
  const [anchor, setAnchor] = React.useState(null)
  const [search, setSearch] = React.useState('')
  const [options, setOptions] = React.useState([])
  const inputRef = React.useRef(null)
  const containerRef = React.useRef()

  const appLoading = useSelector(selectIsLoading)

  const empresa = useSelector(selectEmpresa)

  const detalles = useLocationDetails()

  const links = detalles.links ? Object.entries(detalles.links) : []

  const { filters, addFilter, popFilter, mayPopFilter } = useFiltersControl()

  const toRef = React.useRef(null)

  const popFilterHandler = (event) => {
    popFilter(1)
    setSearch('')
  }

  const focusSearch = () => inputRef.current.focus()
  const rightActionProps = {
    onClick: (
      mayPopFilter
        ? () => popFilterHandler()
        : links.length
          ? props.leftAction
          : focusSearch
    ),
    title: (
      mayPopFilter
        ? 'Borrar filtro'
        : 'Buscar'
    ),
    'aria-label': (
      mayPopFilter
        ? 'borrar filtro'
        : 'buscar'
    )
  }

  const leftActionProps = {
    onClick: toggleExpanded(),
    disabled:
      appLoading ||
      (detalles.itemId === SHOW_CREATE && expanded === false) ||
      detalles.settingsDisabled ||
      !empresa,
    title: 'Ajustes',
    'aria-label': 'ajustes'
  }

  const addFilterHandler = (vo) => (event) => {
    addFilter(vo)
    setSearch('')
    setAnchor(null)
  }

  const handleBlur = (event) => {
    const { relatedTarget } = event
    if (relatedTarget &&
      relatedTarget.classList.contains('MuiChip-clickable')
    ) {
      return
    }
    !search && setAnchor(null)
  }

  const handleSearch = React.useCallback((event) => {
    if (toRef.current) {
      clearTimeout(toRef.current)
    }

    const text = event.target.value.trimStart()
    setSearch(text)

    if (text) {
      toRef.current = setTimeout(() => {
        searchData(
          text,
          detalles.searchParams,
          options,
          filters
        ).then(opts => {
          setOptions(opts)
        })
      }, 200)
    } else {
      setSearch('')
      setOptions([])
    }
  }, [setOptions, options, filters, detalles])

  if (appLoading) {
    return <div className='searchBar'>Cargando!</div>
  }

  return (
    <div className='searchBar' ref={containerRef}>
      <IconButton
        edge='start'
        data-cy='left-action'
        {...leftActionProps}
      >
        {expanded ? <CheckIcon /> : <MenuIcon />}
      </IconButton>
      <div
        className='chipList'
        data-cy='chip-list'
      >
        {
          filters.map((vo, idx) =>
            <CompatibleChip
              key={idx}
              vo={vo}
              avatar={null}
              razonFlag={false}
              // onDelete={() => delFilter(vo)}
            />
          )
        }
      </div>
      <Field
        type='search'
        name='search'
        value={search}
        data-cy='search-field'
        placeholder={detalles.name}
        disabled={detalles.searchDisabled}
        onBlur={handleBlur}
        onChange={handleSearch}
        onFocus={event => setAnchor(containerRef.current)}
        inputRef={inputRef}
        inputProps={{
          autoComplete: 'off',
          color: 'inherit',
          'data-cy': 'search-input'
        }}
        InputProps={{
          // startAdornment: (),
          // endAdornment: ()
        }}
      />

      <Popper
        className='searchPopper'
        open={!!anchor}
        disablePortal
        anchorEl={anchor}
        placement='bottom-start'
        style={containerRef.current &&
          {
            width: containerRef.current.scrollWidth,
            zIndex: 0
          }}
      >
        <Fade in={!!anchor}>
          <Paper
            className='options-container'
            data-cy='filter-list'
            square
          >
            {options.map((vo, idx) =>
              <CompatibleChip
                key={idx}
                vo={vo}
                onClick={addFilterHandler(vo)}
                razonFlag
                variant='outlined'
              />
            )}
            {!options.length && (search
              ? <span>No hay coincidencias</span>
              : <span>Escribe para filtrar...</span>
            )}
          </Paper>
        </Fade>
      </Popper>
      <IconButton
        edge='end'
        data-cy='right-action'
        {...rightActionProps}
      >
        {
          mayPopFilter
            ? <BackspaceIcon />
            : <SearchIcon />
        }
      </IconButton>
    </div>
  )
}
