import React from 'react'

/**
 * Devuelve una función que permite forzar el renderizado de un componente
 * OJO: Necesitar esto indica con probabilidad una estrategia inadecuada
 */
// https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
export function useForceRender () {
  return React.useReducer(x => x + 1, 0)[1]
}

export { useDispatcher } from './useDispatcher'

export { useAcordeon } from '../../componentes/Abc/Acordeon'
export { useListLoader } from './useListLoader'
export { useChangeHandlers } from './useChangeHandlers'
export { useCardPicker } from './useCardPicker'
export { useItemSaver } from './useItemSaver'
export { useItemEraser } from './useItemEraser'
export { useDestroyDialog } from '../../componentes/Abc/Tarjeta/Dialogs'

export { useConfigureEB } from './useConfigureEB'
export { useCalendarFilters } from './useCalendarFilters'

export { useHistoryBlocker } from './useHistoryBlocker'
export { useSaveHandlerTransitional } from './useSaveHandler'
export { useLocationDetails } from './useLocationDetails'
export { useFiltersControl } from './useFiltersControl'

export { useMountDetector } from './useMountDetector'
