import React from 'react'

import { Virtuoso } from 'react-virtuoso'

import InputAdornment from '@mui/material/InputAdornment'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import IconButton from '@mui/material/IconButton'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'

import Add from '@mui/icons-material/Add'
import Close from '@mui/icons-material/Close'

import { contacto as model } from '../../../service/empresas/model'

import { searchData } from '../../../utils/SearchInput'

import { useListLoader } from '../../../utils/hooks'

import Field from '../FormUtils/Field'
import { GridContainer } from '../FormUtils/Grid'

export default function DialogContacto (props) {
  const {
    id = 'dialog-contacto-' + Math.random(),
    open,
    value: vo,
    razon,
    locked,
    onChange,
    onClose,
    onCancel,
    ComponentClass = 'WidgetContacto',
    defaults
  } = props

  const [showForm, setShowForm] = React.useState(!!locked)
  const [selected, setSelected] = React.useState(-1)
  const [errored, setErrored] = React.useState({ alias: false, telefono: false })
  const [stringValue, setStringValue] = React.useState('')
  const [state, setState] = React.useState(vo)
  const toRef = React.useRef(null)
  const detalles = {
    searchParams: {
      q: stringValue,
      contactos: true,
      conceptos: false,
      tiempo: false,
      actividades: false,
      facturas: false
    }
  }

  const { listado, loadNext, total, hasMore } = useListLoader({
    model,
    shouldLoad: (empresa) => !!empresa,
    noFilters: true
  })

  const [options, setOptions] = React.useState(listado)

  React.useEffect(() => {
    if (!stringValue && (options !== listado)) {
      setOptions(listado)
    }
  }, [stringValue, options, listado])

  const updateError = React.useCallback((obj = {}) => {
    setErrored(prev => ({ ...prev, ...obj }))
  }, [setErrored])

  // select(null) idica que se desea crear uno nuevo
  const select = React.useCallback((vo) => {
    if (vo === null || !vo || (!stringValue && !vo)) {
      // console.warn('vo=null?', vo, { ...defaults, alias: stringValue })
      setState(prev => ({
        ...defaults,
        alias: stringValue,
        // [razon ? 'razon' : 'alias']: stringValue,
        telefono: prev.telefono ? prev.telefono : ''
      }))
      if (!showForm) {
        setShowForm(true) // showForm => !showForm)
      } else {
        !state.alias ? updateError({ alias: true }) : updateError({ alias: false })
        !state.telefono ? updateError({ telefono: true }) : updateError({ telefono: false })
        if (!errored.alias && !errored.telefono) {
          onChange({ ...state }, false) // handleClose()
        }
      }
    } else {
      // console.warn('select', { vo, state })
      const hasEntidad = !!vo.fk_entidad
      const closeDialog = hasEntidad || (!!vo.telefono && !!vo.alias)
      // setShowForm(showForm => razon && !showForm && !hasEntidad)
      if (closeDialog) {
        onChange({ ...vo })
        setState(prev => ({ ...prev, open: false }))
      } else {
        setShowForm(!vo.alias || !vo.telefono)
      }
    }
  }, [
    setState,
    setShowForm,
    onChange,
    stringValue,
    showForm,
    defaults,
    state,
    errored.alias,
    errored.telefono,
    updateError
  ])

  const handleFormChange = (event) => {
    setState(prev => ({
      ...prev,
      [event.target.name]: event.target.value === ''
        ? null
        : event.target.value
    }))
  }

  const handleSearch = React.useCallback((event) => {
    updateError({ aliasError: false })
    if (toRef.current) {
      clearTimeout(toRef.current)
    }

    const text = event.target.value
    setStringValue(text)
    handleFormChange(event)

    if (showForm) return
    if (text) {
      toRef.current = setTimeout(() => {
        searchData(
          text,
          detalles.searchParams,
          options
        ).then(opts => {
          setOptions(opts)
        })
      }, 200)
    } else {
      setOptions(listado)
    }
  }, [setOptions, listado, options, setStringValue, updateError, detalles.searchParams, showForm])

  const memoizedList = React.useMemo(() => {
    return options.map((contacto, idx) =>
      <ListItemButton
        key={idx}
        // disableGutters
        selected={selected === idx}
        onClick={() => select(contacto)}
      >
        <ListItemAvatar>
          <Avatar
            sx={
              contacto.entidad
                ? { bgcolor: '#008000' }
                : { bgcolor: '#ffa500' }
            }
            className={
              'contacto' + (contacto.fk_entidad ? ' entidad' : '')
            }
          >
            {contacto.alias.slice(0, 1).toUpperCase()}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={contacto.alias}
          secondary={
            contacto.fk_entidad
              ? contacto.razon
              : 'Sin datos de facturación'
          }
        />
      </ListItemButton>
    )
  }, [options, selected, select])

  /**
   * HOOKS END HERE
   */

  const handleClose = (event) => {
    switch (event.target.textContent) {
      case 'Cerrar':
        onCancel()
        break
      case 'OK':
        !state.alias ? updateError({ alias: true }) : updateError({ alias: false })
        !state.telefono ? updateError({ telefono: true }) : updateError({ telefono: false })
        if (state.alias && state.telefono) {
          // setState(prev => ({ ...prev, open: false }))
          onChange({ ...vo, ...state }, false)
          onClose()
        } else {
          setShowForm(true)
        }
        break
      default:
        break
    }
  }

  const handleKeyboard = (event) => {
    if (showForm) return
    switch (event.key) {
      case 'ArrowDown':
        return setSelected(
          prev => prev >= (options.length - 1) ? -1 : ++prev
        )
      case 'ArrowUp':
        return setSelected(
          prev => prev <= -1 ? (options.length - 1) : --prev
        )
      case 'Enter':
        if (selected < 0) {
          setShowForm(true)
          return onChange({ ...defaults, alias: stringValue }, false)
        }
        return select(options[selected])
      default:
        // console.info('Keyboard', event.key)
    }
  }

  const handleCancel = (event) => {
    setStringValue('')
    setOptions(listado)
  }

  return (
    <Dialog
      data-cy={ComponentClass}
      className={`${ComponentClass}-dialog`}
      open={open || false}
      onClose={handleClose}
      aria-labelledby={id + '-title'}
    >
      <DialogTitle id={id + '-title'}>
        {`Selector ${model.single}`}
      </DialogTitle>
      <DialogContent>
        {showForm && (
          <DialogContentText>
            {
              !state.id_contacto
                ? 'Para añadir un contacto se necesitan los siguientes datos'
                : !state.fk_entidad
                    ? 'Este contacto necesita datos de facturación'
                    : 'Datos esenciales del contacto'
            }
          </DialogContentText>
        )}
        <GridContainer sx={{ marginBottom: '1rem' }}>
          <Field
            name='alias'
            fullWidth
            xs={12}
            label={showForm ? 'Alias' : undefined}
            value={stringValue}
            error={showForm && errored.aliasError}
            required={showForm}
            onChange={handleSearch}
            onKeyDown={handleKeyboard}
            autoFocus={!showForm || (razon && locked)}
            inputProps={{ autoComplete: 'off' }}
            InputProps={
              showForm
                ? undefined
                : {
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='Limpiar la selección'
                          onClick={handleCancel}
                          onMouseDown={event => event.preventDefault()}
                        >
                          <Close fontSize='small' />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
            }
          />
          {(showForm || locked) && (
            <Field
              fullWidth
              xs={12}
              type='tel'
              name='telefono'
              label='Telefono'
              value={state.telefono || ''}
              error={errored.telError}
              required
              onChange={handleFormChange}
              autoFocus={showForm && state.alias !== ''}
            />
          )}
        </GridContainer>
        {!showForm && (
          <>
            <GridContainer className='listFooter'>
              <ListItemButton
                // disableGutters
                data-cy={`${model.single}-nuevo`}
                selected={selected === -1}
                onClick={() => select(
                  Object.entries(vo).length === 0
                    ? null
                    : vo
                )}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: '#2196f3' }}>
                    <Add />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    stringValue
                      ? `Añadir "${stringValue}"`
                      : `Añadir un ${model.single} nuevo`
                  }
                />
              </ListItemButton>
              <ListItem>
                <Divider variant='middle' sx={{ width: '95%' }} />
              </ListItem>
            </GridContainer>

            <List dense disablePadding>
              {total > 0 && (
                <Collapse in={memoizedList.length > 0} mountOnEnter unmountOnExit>
                  <Virtuoso
                    style={{
                      height: (itemHeight => {
                        const calc = itemHeight * memoizedList.length
                        return calc > 400 ? 400 : calc
                      })(70) + 'px'
                    }}
                    data={memoizedList}
                    endReached={hasMore ? loadNext : undefined}
                    totalCount={total || 0}
                    overscan={{ main: 15, reverse: 15 }}
                    itemContent={(index, thing) => thing || <div>Placeholder</div>}
                  />
                </Collapse>
              )}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {(showForm && !locked) && (
          <Button onClick={() => setShowForm(false)}>Atrás</Button>
        )}
        <Button onClick={handleClose}>
          {showForm ? 'OK' : 'Cerrar'}
        </Button>
        {/* <Button type='submit' color='primary'>Añadir</Button> */}
      </DialogActions>
    </Dialog>
  )
}
