import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { MonthCalendar } from '@mui/x-date-pickers/MonthCalendar'
import { YearCalendar } from '@mui/x-date-pickers/YearCalendar'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import {
  addYears,
  subYears,
  isWithinInterval,
  isSameDay,
  addDays,
  subDays,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  getMonth
  // getDate,
  // closestTo
} from 'date-fns'

import {
  TIEMPO,
  filterType
} from '../../../../store/filters/const'
import * as fecha from '../../../../utils/fecha'
import { useCalendarFilters, useFiltersControl } from '../../../../utils/hooks'

import './SearchCalendar.scss'

export default function SearchCalendar (props) {
  const { location } = props
  const { addFilter, hasTiempo, filters } = useFiltersControl()
  const filtroTiempo = filters.find(vo => filterType(vo) === TIEMPO)

  const desde = React.useMemo(() => {
    return filtroTiempo !== null && filtroTiempo !== undefined
      ? filtroTiempo.desde
      : new Date()
  }, [filtroTiempo])

  const hasta = filtroTiempo !== null && filtroTiempo !== undefined
    ? filtroTiempo.hasta
    : new Date()

  const [date, setDate] = React.useState(desde)
  const [tab, setTab] = React.useState('1')
  const [start, setStart] = React.useState(desde)
  const [end, setEnd] = React.useState(hasta)
  const [range, setRange] = React.useState(false)
  const [firstDate, setFirstDate] = React.useState(false)
  const filtered = React.useRef(null)
  // const [monthArr, setMonthArr] = React.useState([])
  // console.log(filters, hasTiempo, filtroTiempo)

  React.useEffect(() => {
    if (!filtroTiempo) return
    if (filtered.current === null) {
      filtered.current = filtroTiempo
    }

    if (
      filtered.current !== filtroTiempo &&
      filtroTiempo !== null &&
      filtroTiempo !== undefined &&
      hasTiempo && !range
    ) {
      // setDate(filtroTiempo.desde)
      setStart(filtroTiempo.desde)
      setEnd(filtroTiempo.hasta)
      if (filtroTiempo.hoy) {
        setDate(filtroTiempo.hoy)
      } else if (filtroTiempo.origen) {
        setDate(filtroTiempo.origen)
      }
    }
  }, [filtroTiempo, hasTiempo, start, range])

  const {
    yearView,
    monthView,
    weekView,
    threeDayView,
    dayView,
    view
  } = useCalendarFilters()

  const minDate = subYears(new Date(), 10)
  const maxDate = addYears(new Date(), 20)

  const handleRange = () => {
    if (range) {
      setRange(false)
      setDate(date)
      setStart(date)
      setEnd(date)
    } else {
      setRange(true)
      setFirstDate(true)
    }
  }

  const handleReset = () => {
    setDate(date)
    setStart(date)
    setEnd(date)
    setFirstDate(true)
  }

  const dateRange = (newDate) => {
    if (firstDate) {
      setStart(newDate)
      setEnd(newDate)
      setFirstDate(false)
    } else {
      setEnd(newDate)
      setFirstDate(true)
      const isInverted = fecha.compare(start, newDate) === 1
      addFilter({
        date: true,
        view: '',
        label: isInverted
          ? fecha.formatSlash(newDate) + ' - ' + fecha.formatSlash(start)
          : fecha.formatSlash(start) + ' - ' + fecha.formatSlash(newDate),
        desde: isInverted ? newDate : start,
        hasta: isInverted ? endOfDay(start) : endOfDay(newDate)
      })
    }

    setDate(newDate)
  }

  const monthRange = (newDate) => {
    if (firstDate) {
      setStart(startOfMonth(newDate))
      setEnd(startOfMonth(newDate))
      setFirstDate(false)
      setDate(newDate)
    } else {
      setEnd(endOfMonth(newDate))
      setFirstDate(true)
      const isInverted = fecha.compare(start, endOfMonth(newDate)) === 1
      /* Proyecto de subrayar los meses en rango
        console.log(
        fecha.formatSlash(new Date(newDate)),
        fecha.formatSlash(new Date(start)),
        fecha.formatSlash(new Date(start)).split('/')[1]
      )
      const firstMonth = Number(fecha.formatSlash(new Date(start)).split('/')[1]) - 1
      const lastMonth = Number(fecha.formatSlash(new Date(newDate)).split('/')[1]) - 1
      const elements = document.getElementsByClassName('PrivatePickersMonth-root')
      elements[firstMonth].setAttribute('class', 'Mui-Selected')
      const rangeStart = elements[firstMonth]
      const rangeEnd = elements[lastMonth]
      console.log({ start: rangeStart.innerText, end: rangeEnd.innerText, firstMonth, lastMonth, rango: MONTH.slice(firstMonth, lastMonth + 1) })
      */
      addFilter({
        date: true,
        view: '',
        label: isInverted
          ? fecha.formatTextMonth(newDate) + ' - ' + fecha.formatTextMonth(start)
          : fecha.formatTextMonth(start) + ' - ' + fecha.formatTextMonth(newDate),
        desde: isInverted ? newDate : start,
        hasta: isInverted ? endOfDay(endOfMonth(start)) : endOfDay(endOfMonth(newDate))
      })
    }
    // setDate(newDate)
  }

  const handleTab = (event, tab) => {
    handleReset()
    setTab(tab)
  }

  const changeDate = (date) => {
    switch (view) {
      case 'tresDias':
        setStart(startOfDay(subDays(date, 1)))
        setEnd(endOfDay(addDays(date, 1)))
        threeDayView(date)
        break
      case 'semana':
        setStart(fecha.startOfWeek(date))
        setEnd(fecha.endOfWeek(date))
        weekView(date)
        break
      default:
        setStart(startOfDay(date, 1))
        setEnd(endOfDay(date))
        dayView(date)
        break
    }
    setDate(date)
  }

  // console.log(getDate(start), getDate(desde), getDate(hasta), getDate(end), range)
  /*
    Si nueva fecha > principio pero < fin setEnd
    Si nueva > fin setEnd
    Si nueva fecha < principio setStart

  Potencial casuistica en caso de que el metodo de parejas moleste

  const isStart = compareAsc(start, end) === 0 && compareAsc(start, date) === 0
  const sameDate = compareAsc(newDate, start) === 0 && compareAsc(newDate, start) === 0
  const middleDate = compareAsc(newDate, start) === 1 && compareAsc(end, newDate) === 1
  const endDate = compareAsc(newDate, end) === 1
  const startDate = compareAsc(start, newDate) === 1
  const closerDate = closestTo(newDate, [start, end])
  const startIsCloser = isSameDay(start, closerDate)
  const endIsCloser = isSameDay(end, closerDate)

  */

  /* if (startDate) setStart(newDate)
  if (endDate) setEnd(newDate)
  if (middleDate && endIsCloser) setEnd(newDate)
  if (middleDate && startIsCloser) setStart(newDate) */

  // console.warn({ firstDate, isStart })
  // console.warn({ startIsCloser, endIsCloser })

  // console.log(getDate(start), getDate(end), range)

  /* const monthRange = (newDate) => {
    if (!range) {
      setDate(newDate)
      monthView(newDate)
    } else {
      let arr = monthArr
      const month = startOfMonth(newDate)
      if (arr.includes(month)) {
        arr = arr.filter((item) => item !== month)
      } else {
        arr.push(month) // .sort(compareAsc)
        arr.sort(compareAsc)
      }
      console.warn(arr)
      setMonthArr(arr)

      console.log(monthArr[0], arr[arr.length - 1])
      if (arr.length > 1) {
        addFilter({
          date: true,
          view: '',
          label: fecha.formatTextMonth(monthArr[0]) +
                 ' - ' + fecha.formatTextMonth(month),
          desde: monthArr[0],
          hasta: endOfMonth(arr[arr.length - 1])
        })
      }
    }
  } */

  // Funcion que renderiza el calendario mensual día a día para asignarles los
  // nombres de clase que luego va a represantar los rangos
  const RenderDays = (props) => {
    const { day } = props
    if (!day) return
    const newDate = day
    const isInverted = fecha.compare(start, end) === 1

    const intervalObj = isInverted
      ? { start: end, end: start }
      : { start, end }

    const dayIsBetween = isWithinInterval(newDate, intervalObj)
    const isFirstDay = isInverted ? isSameDay(newDate, end) : isSameDay(newDate, start)
    const isLastDay = isInverted ? isSameDay(newDate, start) : isSameDay(newDate, end)
    const isSingleDay = isFirstDay && isLastDay
    const outsideMonth = getMonth(newDate) !== getMonth(date)

    let estilo = `${view} selected`
    if (isSingleDay) {
      estilo = estilo + ' dia-unico'
    } else if (isFirstDay) {
      estilo = estilo + ' dia-izquierdo'
    } else if (isLastDay) {
      estilo = estilo + ' dia-derecho'
    } else if (dayIsBetween) {
      estilo = estilo + ' dia-central'
    } else {
      estilo = 'dia-normal'
    }

    return (
      <span className={estilo} key={newDate}>
        <PickersDay
          isFirstVisibleCell={false}
          isLastVisibleCell={false}
          disableMargin
          today={isSameDay(newDate, new Date())}
          disabled={outsideMonth}
          showDaysOutsideCurrentMonth
          day={newDate}
          outsideCurrentMonth={outsideMonth}
          onDaySelect={(newDate) => {
            range && (location === 2 || location === 3)
              ? dateRange(newDate)
              : changeDate(newDate)
          }}
          className='dias'
        />
      </span>
    )
  }

  const DiaPicker = () => {
    return (
      <DateCalendar
        className={view}
        value={date}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(newDate) => {
          changeDate(newDate)
        }}
        showDaysOutsideCurrentMonth={false}
        onMonthChange={(newDate) => {
          changeDate(newDate)
        }}
        slots={{ day: RenderDays }}
      />
    )
  }

  const MesPicker = () => {
    return (
      <MonthCalendar
        value={date}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(newDate) => {
          range
            ? monthRange(newDate)
            : monthView(newDate)
          setDate(newDate)
        }}
      />
    )
  }

  const AnoPicker = () => {
    return (
      <YearCalendar
        value={date}
        minDate={minDate}
        maxDate={maxDate}
        yearsPerRow={4}
        onChange={(newDate) => {
          setDate(newDate)
          yearView(newDate)
        }}
      />
    )
  }

  if (location === 1) {
    if (range) handleRange()
    switch (view) {
      case 'mes':
        return (
          <Stack className='quick-date'>
            <MesPicker />
          </Stack>
        )
      case 'semana':
      case 'tresDias':
      case 'dia':
        return (
          <Stack className='quick-date'>
            <DiaPicker />
          </Stack>
      )
      default:
        console.error('Falta vista')
    }
  } else {
    return (
      <Stack className='quick-date'>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTab}>
              <Tab label='Día' value='1' />
              <Tab label='Mes' value='2' />
              <Tab label='Año' value='3' />
            </TabList>
          </Box>
          <TabPanel value='1'>
            <DiaPicker />
          </TabPanel>
          <TabPanel value='2'>
            <MesPicker />
          </TabPanel>
          <TabPanel sx={{ maxWidth: '378px' }} value='3'>
            <AnoPicker />
          </TabPanel>
        </TabContext>
        <Stack direction='row' justifyContent='center'>
          <FormGroup>
            <FormControlLabel
              control={<Switch onChange={handleRange} />}
              label='Rango'
            />
          </FormGroup>
          <Button onClick={() => handleReset()}>
            Reiniciar
          </Button>
        </Stack>
      </Stack>
    )
  }
}
