import React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import ContactsIcon from '@mui/icons-material/Contacts'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Paper } from '@mui/material'

export default function LoginPage () {
  const handlerLogin = (ev) => {
    ev.preventDefault()
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Paper
        sx={{
          marginTop: '8rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar>
          <ContactsIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Registro
        </Typography>
        <form onSubmit={handlerLogin}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='name'
            label='Nombre Usuario'
            name='name'
            autoFocus
          />

          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
          />

          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='password'
            label='Contraseña'
            name='password'
          />

          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href='/' variant='body2'>
                Volver
              </Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  )
}
