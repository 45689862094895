import React from 'react'
import { empresa as empresaModel } from '../../service/empresas/model'
import { useSelector } from 'react-redux'
import { selectorEmpresaBase } from '../../store/selector'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Avatar from '@mui/material/Avatar'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckIcon from '@mui/icons-material/Check'
import SaveIcon from '@mui/icons-material/Save'
import UndoIcon from '@mui/icons-material/Undo'

import TarjetaItemHOC from '../Abc/Tarjeta/TarjetaItemHOC'
import TarjetaItemMenu from '../Abc/Tarjeta/TarjetaItemMenu'

import { GridContainer, Field, DateWidget } from '../Abc/FormUtils'

import IbanField from '../Abc/FormUtils/IbanField'

import { MainAction } from '../../store/const'

import WidgetPostal, { Domicilio } from '../Abc/FieldSet/WidgetPostal'

import { Documentos } from '../Abc/SettingsMenu'

import {
  ComboMetodoCobro,
  ComboEmisionFactura,
  ComboParticular,
  ComboPlazoCobro,
  ComboEnvioFactura
} from '../Abc/FieldSet/Opciones'

import * as fecha from '../../utils/fecha'

import './ContactoItem.scss'

const { SHOW_CREATE } = MainAction

const selectBase = selectorEmpresaBase(empresaModel)

export function ContactoItem (props) {
  const { vo, selected, errored, changesRef } = props

  const { handleChangeEvent, handleChangeObject } = props

  const { onBack, onSave, onDestroy } = props

  const empresa = useSelector(selectBase)

  const [toggle, setToggle] = React.useState(null)

  // ver https://stackoverflow.com/q/4782068/1894803
  const mailFactory = (event) => ({
    subject: ['Hola', vo.alias].join(' '),
    body: [`Hola ${vo.alias},`, '', ''].join('\n')
  })

  const idConfFacturas = `conf-facturas-${vo.id_contacto}:${vo.fk_empresa}`
  // const idOtrosDatos = `otros-datos-${vo.id_contacto}:${vo.fk_empresa}`
  const idNotas = `notas-${vo.id_contacto}:${vo.fk_empresa}`
  const idDocumentos = `documentos-${vo.id_contacto}:${vo.fk_empresa}`

  const handleToggle = (panel) => (event, isExpanded) => {
    setToggle(isExpanded ? panel : false)
  }

  const extendEntidad = (obj) =>
    ({
      ...obj,
      entidad: { ...(vo.entidad || {}), ...obj }
    })

  const handleChangeEntidad = (event) => {
    const { name, value } = event.target
    handleChangeObject(extendEntidad(
      (name !== 'nif' && name !== 'iban')
        ? { [name]: value }
        : { [name]: value.toUpperCase() }))
  }

  const AccordionBar = (props) => {
    const { id, text } = props
    return (
      <AccordionSummary
        aria-controls='panel1a-content'
        id={id}
      >
        <Typography>{text}</Typography>
        <Grid
          className='accordionControl'
          item
        >
          <Collapse in={
            toggle === id &&
            !!changesRef.current.length
          }
          >
            <IconButton onClick={(e) => {
              e.stopPropagation()
              onBack()
            }}
            >
              <UndoIcon />
            </IconButton>
          </Collapse>
          <Button
            variant='text'
            onClick={() => {
              handleToggle(id)
              changesRef.current.length && onSave({ pickNext: true })
            }}
            endIcon={
              <>
                <Collapse
                  in={toggle !== id}
                  orientation='horizontal'
                  mountOnEnter
                  unmountOnExit
                >
                  <ExpandMoreIcon />
                </Collapse>
                <Collapse
                  in={toggle === id}
                  orientation='horizontal'
                  mountOnEnter
                  unmountOnExit
                >
                  {
                    changesRef.current.length
                      ? <SaveIcon color='primary' />
                      : <CheckIcon />
                  }
                </Collapse>
              </>
            }
          />
        </Grid>
      </AccordionSummary>
    )
  }

  return (
    <>
      <Collapse in={!selected}>
        <ListItem disableGutters component='form'>
          <ListItemAvatar>
            <Avatar
              style={{
                backgroundColor: vo.fk_entidad ? 'var(--done)' : 'var(--warn)'
              }}
            >
              {vo.alias && vo.alias.slice(0, 1)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={vo.alias}
            secondary={
              vo.fk_entidad
                ? vo.razon
                : 'Sin datos de facturación'
            }
            primaryTypographyProps={{ 'data-cy': 'alias-reposo' }}
            secondaryTypographyProps={{ 'data-cy': 'datos-reposo' }}
          />
        </ListItem>
      </Collapse>
      <Collapse in={selected} mountOnEnter unmountOnExit>
        <TarjetaItemMenu
          back={onBack}
          save={onSave}
          changed={changesRef.current}
          destroy={vo.id_contacto !== SHOW_CREATE ? onDestroy : undefined}
          call={vo.telefono || ''}
          mail={vo.email_general || ''}
          mailFactory={mailFactory}
        />
      </Collapse>
      <Collapse in={selected} mountOnEnter unmountOnExit>
        <form>
          <GridContainer sx={{ marginBottom: '1.5rem' }}>
            <Field
              sm={12}
              md={6}
              name='alias'
              label='Alias'
              className='alias'
              value={vo?.alias || ''}
              error={!!errored.alias}
              required
              onChange={handleChangeEvent}
            />
            <Field
              sm={6}
              md={6}
              type='tel'
              name='telefono'
              label='Teléfono'
              value={vo?.telefono || ''}
              error={!!errored.telefono}
              required
              onChange={handleChangeEvent}
            />
            <Field
              sm={8}
              label='Email'
              name='email_general'
              value={vo?.email_general || ''}
              error={!!errored.email_general}
              onChange={handleChangeEvent}
            />
            <Field
              sm={6}
              name='razon'
              label='Nombre completo o Razón Social'
              value={vo.entidad?.razon || ''}
              error={!!errored.razon}
              onChange={handleChangeEntidad}
            />
            <Field
              sm={6}
              name='nif'
              label='NIF/CIF'
              value={vo.entidad?.nif || ''}
              error={!!errored.nif}
              onChange={handleChangeEntidad}
            />
            <DateWidget
              sm={6}
              name='fecha_nacimiento'
              label='Fecha de nacimiento o constitución'
              value={new Date(vo.fecha_nacimiento)}
              error={!!errored.fecha_nacimiento}
              disableFuture
              maxDate={new Date()}
              onChange={
                (date) => {
                  date && vo.fecha_nacimiento !== fecha.input(date) &&
                  handleChangeObject(extendEntidad({
                    fecha_nacimiento: fecha.input(date)
                  }))
                }
              }
              onInvalid={() => console.warn('InvalidDate')}
            />
            <Domicilio
              value={(vo.fk_entidad ? vo : vo.entidad)?.domicilio || ''}
              error={!!errored.domicilio}
              required={false}
              onChange={handleChangeEntidad}
            />
            <WidgetPostal
              sm={8}
              md={6}
              vo={vo.fk_entidad ? vo : (vo.entidad || {})}
              error={
                errored.fk_codigo_postal ||
                errored.fk_ayuntamiento ||
                errored.fk_provincia
              }
              onChange={obj => handleChangeObject(extendEntidad(obj))}
            />
          </GridContainer>
          <Accordion
            disableGutters
            data-cy='toggleConfig'
            className='acordeon-contacto'
            expanded={toggle === idConfFacturas}
            onChange={handleToggle(idConfFacturas)}
          >
            <AccordionBar
              id={idConfFacturas}
              text='Configuración Facturas por defecto'
            />
            <AccordionDetails>
              <GridContainer>
                <ComboParticular
                  sm={6}
                  value={vo?.es_particular || 0}
                  error={!!errored.es_particular}
                  required
                  onChange={handleChangeEvent}
                  sx={{ width: '50%' }}
                />
                <ComboEmisionFactura
                  sm={6}
                  name='fk_periodo_facturacion'
                  value={vo?.fk_periodo_facturacion || 1}
                  error={!!errored.fk_periodo_facturacion}
                  required
                  onChange={handleChangeEvent}
                  sx={{ width: '50%' }}
                />
                <ComboEnvioFactura
                  sm={6}
                  name='fk_envio_factura'
                  value={vo?.fk_envio_factura || 1}
                  error={!!errored.fk_envio_factura}
                  required
                  onChange={handleChangeEvent}
                  sx={{ width: '50%' }}
                />
                <ComboMetodoCobro
                  sm={6}
                  name='fk_metodo_cobro'
                  value={vo?.fk_metodo_cobro || 1}
                  error={!!errored.fk_metodo_cobro}
                  required
                  onChange={handleChangeEvent}
                  sx={{ width: '50%' }}
                />
                <ComboPlazoCobro
                  sm={6}
                  name='fk_plazo_cobro'
                  value={vo?.fk_plazo_cobro || 1}
                  error={!!errored.fk_plazo_cobro}
                  required
                  onChange={handleChangeEvent}
                  sx={{ width: '50%' }}
                />
                <Field
                  label='Email para el envío de las Facturas'
                  name='email_contable'
                  value={vo?.email_contable || ''}
                  error={!!errored.email_contable}
                  onChange={handleChangeEvent}
                />
                <IbanField
                  sm={12}
                  name='iban'
                  label='IBAN'
                  value={vo.entidad?.iban || ''}
                  error={!!errored.iban}
                  onChange={handleChangeEntidad}
                />
              </GridContainer>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            data-cy='toggleDocumentos'
            className='acordeon-contacto'
            expanded={toggle === idDocumentos}
            onChange={handleToggle(idDocumentos)}
            id={idDocumentos}
            name='Documentos'
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id={idDocumentos}
            >
              Documentos
            </AccordionSummary>
            <AccordionDetails>
              <Documentos
                empresa={empresa}
                contacto={vo}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            data-cy='toggleNotas'
            className='acordeon-contacto'
            expanded={toggle === idNotas}
            onChange={handleToggle(idNotas)}
            id={idNotas}
            name='Notas'
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id={idNotas}
            >
              Notas
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                spacing={1}
                container
                className='notas-outer-wrap'
              >
                {vo.actividades?.length > 0 && vo.actividades.map(
                  (notas, idx) => {
                    if (notas.nota !== '') {
                      return (
                        <Grid
                          item
                          sm={12}
                          md={12}
                          key={idx}
                          className='notas-inner-wrap'
                          columnGap={2}
                        >
                          <Typography>{fecha.formatSlash(notas.fechaActividad)}</Typography>
                          <Typography>{notas.nota}</Typography>
                        </Grid>
                      )
                    } else {
                      return (null)
                    }
                  }
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </form>
      </Collapse>
      {false && (
        <pre style={{ overflowX: 'scroll', scrollbarWidth: 'thin' }}>
          {JSON.stringify(vo, null, 4)}
        </pre>
      )}
    </>
  )
}

export default TarjetaItemHOC(ContactoItem)
