import React from 'react'
import { Buffer } from 'buffer'
// import { renderToString } from 'react-dom/server'
import { addDays } from 'date-fns'
import { useNavigate } from 'react-router-dom'

import { ActividadParalela as actividad } from '../../service/empresas/model'
import { MainAction } from '../../store/const'

import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import InputAdornment from '@mui/material/InputAdornment'
import Collapse from '@mui/material/Collapse'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'

import {
  MeetingRoom as MeetingRoomIcon,
  Euro as EuroIcon,
  PlaylistAdd as AddIcon,
  // PlaylistAddCheck as MoreIcon,
  DeleteSweep as MoreIcon,
  PictureAsPdf as PdfIcon,
  Send as EmitirIcon
  // Contacts as ContactIcon
} from '@mui/icons-material'

import tesoreria from '@grupomarea/abrela-tesoreria'
import * as fecha from '../../utils/fecha'

import { Field, DateWidget } from '../Abc/FormUtils'

import IbanField from '../Abc/FormUtils/IbanField'

import TarjetaItemHOC from '../Abc/Tarjeta/TarjetaItemHOC'
import TarjetaItemMenu, { IButton as MenuButton } from '../Abc/Tarjeta/TarjetaItemMenu'

// import WidgetContacto from '../Abc/FieldSet/WidgetContacto'
import DialogContacto from '../Abc/FieldSet/DialogContacto'
import WidgetPostal, { Domicilio } from '../Abc/FieldSet/WidgetPostal'

import {
  listOpIva,
  ComboIva,
  IVA_EXENTO,
  IVA_PASIVO,
  IVA_REBU,
  ComboMetodoCobro,
  PAGO_EFECTIVO,
  TRANSFERENCIA,
  DOMICILIACION
} from '../Abc/FieldSet/Opciones'

import { useDialogEmitir } from './DialogEmitir'
import ActividadRow from './FacturaItemActividadRow'
import DialogMore from './FacturaItemDialogMore'

import { useMountDetector } from '../../utils/hooks'

import './FacturaItem.scss'

const address1 = (vo) => `${vo.domicilio}`
const address2 = (vo) =>
  `${vo.codigo_postal} ${vo.ayuntamiento} (${vo.provincia})`

const sortActividades = (a, b) =>
  a.fecha_prevista < b.fecha_prevista
    ? -1
    : a.fecha_prevista > b.fecha_prevista
      ? 1
      : 0

export function FacturaItem (props) {
  const { vo, voRef, selected, errored, changesRef, resetAllChanges, model } = props
  const { onForceClose, onBack, onSave, onDestroy } = props
  const { handleChangeEvent: handleChange, handleChangeObject } = props

  /* React.useEffect(() => {
    selected && handleChangeObject({})
  }, [selected, handleChangeObject]) */

  const { SHOW_CREATE } = MainAction
  const emitida = model.single === 'factura'
  const editable = !emitida
  const empresa = { ...props.confEmpresa, ...vo.empresa }
  const cliente = vo.pagador || vo.contacto
  const actividades = (vo.actividades && vo.actividades.sort(sortActividades)) || []

  const mountedRef = useMountDetector()
  const navigate = useNavigate()

  const [dialogMore, setDialogMore] = React.useState(null)
  const [contactoOpen, setContactoOpen] = React.useState({ open: false, opened: false, locked: false })
  const [dateOpen, setDateOpen] = React.useState(false)
  const [pagadaOpen, setPagadaOpen] = React.useState(false)
  const [openActividad, setOpenActividad] = React.useState(-1)
  const [hayConcepto, setHayConcepto] = React.useState(vo.actividades?.length > 0 && vo.actividades[0].concepto?.id_concepto !== null)

  React.useEffect(() => {
    if (emitida && vo.id_factura === SHOW_CREATE) {
      navigate(`/${empresa.fk_empresa}/facturacion/borradores/crear`, { replace: true })
    }
  }, [emitida, vo, SHOW_CREATE, empresa.fk_empresa, navigate])

  const handleChangeCliente = React.useCallback(
    (obj, reset = false) => {
      const nuevo = reset ? obj : { ...cliente, ...obj }
      // console.warn('CAMBIO CLIENTE?', { obj, reset, cliente, vo, cambios: changesRef.current, nuevo })
      const emisionPrevista = model.periodoFacturacion(
        nuevo.fk_periodo_facturacion,
        empresa.work_spec.daysOfWeek
      )
      const vence = model.vencimiento(
        (nuevo?.fk_plazo_cobro || empresa?.fk_plazo_cobro_defecto),
        emisionPrevista
      )

      handleChangeObject({
        pagador: nuevo,
        contacto: nuevo,
        emision_prevista: emisionPrevista,
        vence,
        actividades: vo.actividades.map(
          item => ({ ...item, contacto: nuevo })
        )
      })
      setContactoOpen({ opened: true, open: false })
    },
    [vo, cliente, handleChangeObject]
  )

  const tasaIVA = vo.fk_iva || empresa.fk_iva_defecto
  const tasaIRPF = isNaN(vo.irpf * 1) ? empresa.irpf : Number(vo.irpf)
  const importe = editable || vo.id_factura === SHOW_CREATE
    ? tesoreria.factura(actividades, {
      iva: listOpIva.find(item => item.value === tasaIVA).tasa,
      irpf: tasaIRPF
    })
    : vo.importe

  const showIRPF = importe?.tasa_irpf > 0 || (editable && empresa.irpf > 0)
  const formaPago = editable
    ? (vo.fk_metodo_cobro || empresa.fk_metodo_cobro_defecto)
    : vo.pago === 'Efectivo'
      ? PAGO_EFECTIVO
      : vo.pago === 'Transferencia' ? TRANSFERENCIA : DOMICILIACION

  const fechaFactura = vo.fecha || vo.emision_prevista
  const ivaBase = (selected && vo.actividades[0]?.concepto?.fk_iva) || null

  // TODO cargar esto de conf
  const fkPlazoCobro = vo.contacto?.fk_plazo_cobro || empresa.fk_plazo_cobro_defecto
  const showIBAN = formaPago !== PAGO_EFECTIVO || (vo.pago && vo.pago !== 'Efectivo')

  // TODO esto pertenece a tarjeta item?
  const handleError = (obj) => {
    console.warn('Field error', obj)
  }

  const handleDialogMoreClose = (state) => {
    // Deberiamos modificar todo este dialog para que no afecte directamente a la base de datos
    // si no a un array temporal y que no se comunique nada a la base de datos hasta pinchar en "GUARDAR"
    if (!state) return setDialogMore(null)

    // console.warn('handleDialogMoreClose', state)
    setDialogMore({ ...state, view: 'LOADING' })

    // console.log('DIALOG MORE', state, empresa)

    // Se tuvo que modificar la manera de mandar las fechas de "cerrada" y "anulada", en back
    // está pendiente de ver como se hace esto
    Promise.all(state.checked.map(
      dto => (!dto.id_actividad
        ? state.action !== 'DESTROY'
          ? actividad.create({
            ...dto,
            fk_empresa: empresa.fk_empresa,
            [actividad.pk]: SHOW_CREATE,
            id_actividad_paralela: SHOW_CREATE,
            contacto: dto.contacto || cliente,
            fecha_anulada: (state.action === 'ANULADA' ? fecha.input(new Date()) : null),
            estado: (state.action === 'ABIERTA'
              ? 'abierta'
              : state.action === 'ANULADA'
                ? 'anulada'
                : 'cerrada'),
            precio: state.action === 'PRECIO0' ? 0 : dto.precio
          })
          : Promise.resolve(dto)
        : state.action !== 'DESTROY'
          ? actividad.update({
            ...dto,
            [actividad.pk]: dto.id_actividad,
            fk_empresa: empresa.fk_empresa,
            id_actividad_paralela: dto.id_actividad.toString(),
            contacto: dto.contacto || cliente,
            fecha_anulada: (state.action === 'ANULADA'
              ? fecha.input(new Date())
              : null),
            fecha_cerrada: (state.action === 'ABIERTA' || state.action === 'ANULADA'
              ? null
              : (dto.fecha_cerrada || fecha.input(new Date()))),
            estado: (state.action === 'ABIERTA'
              ? 'abierta'
              : state.action === 'ANULADA'
                ? 'anulada'
                : 'cerrada'),
            precio: state.action === 'PRECIO0' ? 0 : (dto.precio || 0)
          })
          : actividad.destroy({
            ...dto,
            id_actividad_paralela: dto.id_actividad.toString()
          })
      ).then(() => dto)
    ))
      .then(results => {
        // OJO: Resulta esencial que las promesas resuelvan el vo original
        //      para poder realizar el filtrado por referencia
        mountedRef.current && handleChangeObject({
          actividades: actividades
            .filter(actividad => results.indexOf(actividad) === -1
              // !results.find(vo => vo.id_actividad === actividad.id_actividad)
            )
            .sort(sortActividades)
        })
      })
      .catch(console.error)
      .finally(() => {
        mountedRef.current && setDialogMore(null)
      })
  }

  const handleActividadNueva = () => {
    setOpenActividad(actividades.length)
    handleChangeObject({
      actividades: [...actividades, {
        id_actividad: null,
        fecha_prevista: null,
        contacto: cliente,
        concepto: null,
        fecha_cerrada: null,
        precio: '',
        cantidad: 1,
        descuento: 0
      }].sort(sortActividades)
    })
  }

  const handleChangeActividad = (index, changes) => handleChangeObject({
    actividades: actividades
      .map((vo, idx) =>
        (idx === index)
          ? { ...vo, ...changes }
          : vo
      )
      .sort(sortActividades)
  })

  const fkEmpresa = props.confEmpresa.fk_empresa

  const handleVerPdf = () => {
    model.printPdf(fkEmpresa, vo)
  }

  const saveHandler = (params) => () => {
    const {
      next = null,
      emitir = false,
      force = false
    } = params

    if (emitir) {
      const fechaPagada = vo.fk_metodo_cobro === 3 &&
      vo.emision_prevista === vo.vence
        ? vo.emision_prevista
        : null

      voRef.current = {
        ...voRef.current,
        emitir,
        fecha_pagada: fechaPagada,
        fecha: fecha.input(vo.fecha)
      }
    }

    voRef.current = { ...voRef.current, emitir, iban: vo.iban || '' }
    return onSave({ pickNext: next, forceSave: force })
  }

  const handlePagada = (fechaPagada) => {
    model.updatePago({
      id: vo.id_factura,
      fecha_pagada: fechaPagada || null
    }).then(res => {
      switch (res.response.status) {
        case 200:
        case 201:
          resetAllChanges({ ...vo, fecha_pagada: res.data.fecha_pagada })
          onForceClose()
          break
        default:
          break
      }
    })
  }

  const handleOpenPagada = (open) => {
    setPagadaOpen(open)
  }

  const [emitLayout, handleEmit] = useDialogEmitir(
    saveHandler({
      next: null,
      emitir: true,
      force: true
    }),
    onBack,
    vo,
    tesoreria.formatEUR(importe.total),
    fkEmpresa,
    handleChangeObject,
    selected,
    editable
  )

  const logotipo = !empresa.logo
    ? <MeetingRoomIcon className='imagotipo' />
    : (
      <img
        className='imagotipo'
        alt={`Logotipo ${empresa.razon}`}
        src={
          typeof empresa.logo === 'string'
            ? empresa.logo
            : Buffer.from(empresa.logo).toString()
        }
      />
      )

  const eurAvatar = (
    <Avatar
      onClick={(e) => {
        e.stopPropagation()
        handleOpenPagada(true)
      }}
      className={
        'sm-avatar comercial ' + (
          (emitida && vo.fecha_pagada !== '' && vo.fecha_pagada !== null)
            ? 'facturada'
            : emitida && (
              fecha.compare(
                new Date(), addDays(new Date(vo.vence), empresa.margen_cobro)
              ) === -1)
              ? 'emitida'
              : emitida &&
              (fecha.compare(
                new Date(), addDays(new Date(vo.vence), empresa.margen_cobro)
              ) === 1) &&
              (vo.fecha_pagada === null || vo.fecha_pagada === '')
                ? 'atrasada'
                : 'pendiente'
        )
      }
    >
      <EuroIcon
        fontSize='small'
        sx={{ color: 'white' }}
      />
    </Avatar>
  )

  // selected && console.info('Render FacturaItem selected', { editable, vo })

  return (
    <>
      <Grid
        container
        sx={{ display: 'flex', flexDirection: 'row-reverse' }}
      >
        <Grid
          item
          xs={selected ? 12 : 1}
          sm={emitida && selected
            ? 3
            : emitida && !selected
              ? 1
              : 1}
          md={emitida && selected
            ? 2
            : emitida && !selected
              ? 1
              : 1}
          className='eurContainer'
        >
          <DateWidget
            sm={1}
            md={1}
            name='fechaPagada'
            clearable
            open={pagadaOpen}
            value={(vo?.fecha_pagada && new Date(vo.fecha_pagada)) || null}
            // error={!!errored.fecha_pagada}
            fieldProps={{ sx: { display: 'none' } }}
            onChange={(fechaPagada) => {
              // e.stopPropagation()
              if (
                (fechaPagada === vo.fecha_pagada) ||
                (fechaPagada === null && vo.fecha_pagada === '')
              ) return
              handlePagada(fecha.input(fechaPagada))
              handleOpenPagada(false)
            }}
            onClose={() => {
              handleOpenPagada(false)
            }}
            onInvalid={
              () => handleError({ fecha_pagada: 'Fecha inválida' })
            }
          />
          {emitida && (
            <Button
              data-cy='fecha-pagada'
              endIcon={eurAvatar}
              onClick={() => handleOpenPagada(true)}
              className='euroButton'
            >
              {selected && (fecha.formatSlash(vo.fecha_pagada) || 'Fecha Pago')}
            </Button>
          )}
        </Grid>
        <Grid
          item
          className='factura-wrap'
          xs={selected ? 12 : 11}
          sm={emitida && selected
            ? 9
            : emitida && !selected
              ? 11
              : 12}
          md={emitida && selected
            ? 10
            : emitida && !selected
              ? 11
              : 11}
        >
          <Collapse
            className='facturaClosed'
            in={!selected}
            mountOnEnter
            unmountOnExit
          >
            <Grid
              container
              spacing={1}
              className='datos-closed'
              data-cy='datos-closed'
            >
              <Grid
                item
                name='numero_fra'
                xs={5}
                sm={5}
                md={2}
              >
                <div className='label'>Número factura</div>
                <div className='valor'>{emitida ? vo.id_factura : 'BORRADOR'}</div>
              </Grid>
              <Grid
                item
                name='fecha_fra'
                xs={6}
                sm={6}
                md={2}
              >
                <div className='label'>
                  {emitida ? 'Fecha' : 'Fecha Prevista'}
                </div>
                <div className='valor'>
                  {fechaFactura ? fecha.formatSlash(fechaFactura) : '-'}
                </div>
              </Grid>
              <Grid
                item
                name='contacto[alias]'
                xs={4}
                sm={4}
                md={4}
              >
                <div className='label'>Cliente (Alias)</div>
                <div className='valor'>{cliente.alias || ''}</div>
              </Grid>
              <Grid
                item
                name='base_fra'
                xs={4}
                sm={4}
                md={2}
                sx={{ textAlign: 'right' }}
              >
                <div className='label'>Base Imponible</div>
                <div className='valor'>
                  {tesoreria.formatEUR(importe?.base || 0)}
                </div>
              </Grid>
              <Grid
                item
                name='total_fra'
                xs={4}
                sm={4}
                md={2}
                sx={{ textAlign: 'right' }}
              >
                <div className='label'>Importe total</div>
                <div className='valor'>
                  {tesoreria.formatEUR(importe?.total || 0)}
                </div>
              </Grid>
            </Grid>
          </Collapse>
          <Collapse
            in={selected}
            mountOnEnter
            unmountOnExit
            className='menuContainer'
            component={Grid}
            container
          >
            <Grid item xs={4} sm={4} md={3}>
              <TarjetaItemMenu
                className='contacto-menu'
                contacto={cliente}
                openWidget={() => setContactoOpen({ open: true, opened: true })}
                facturada={!editable}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={9}>
              <TarjetaItemMenu
                back={editable ? onBack : onForceClose}
                save={editable && (
                  cliente?.alias && cliente?.telefono
                    ? saveHandler({ emitir: false })
                    : () => setContactoOpen({
                        ...contactoOpen,
                        locked: true,
                        open: true
                      })
                )}
                destroy={!editable ? onDestroy : undefined}
                changed={changesRef.current}
                mail={!editable ? undefined : cliente?.email}
              >
                {
                  !editable
                    ? <MenuButton
                        data-cy='pdf'
                        title='Ver la factura en pdf'
                        onClick={() => handleVerPdf()}
                        Icon={PdfIcon}
                        iconProps={{ color: 'primary', fontSize: 'small' }}
                      />
                    : <MenuButton
                        data-cy='emit'
                        title='Emitir la factura'
                        onClick={() => {
                          cliente?.alias && cliente?.telefono
                            ? handleEmit()
                            : setContactoOpen({
                              ...contactoOpen,
                              locked: true,
                              open: true
                            })
                        }}
                        Icon={EmitirIcon}
                        iconProps={{ color: 'primary', fontSize: 'small' }}
                      />
                }
              </TarjetaItemMenu>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
      <Collapse
        in={selected}
        mountOnEnter
        unmountOnExit
      >
        <Grid container className='header'>
          <Grid item sm>
            <figure className='logo'>
              {logotipo}
            </figure>
          </Grid>
          <Grid item sm={6} className='cajetin'>
            <Fade
              className='bgText'
              in={editable && selected}
            >
              <p>BORRADOR</p>
            </Fade>
            <Grid
              container
              component='figure'
              className='datos factura'
              data-title='Factura'
            >
              <table>
                <tbody>
                  <tr>
                    <th>Número</th>
                    <td data-cy='numero_fra'>
                      {
                        editable
                          ? <Field
                              name='numero_fra'
                              label={null}
                              value='BORRADOR'
                              // placeholder='Se generará al facturar'
                              disabled
                            />
                          : vo.id_factura
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>{emitida ? 'Fecha' : 'Fecha Prevista'}</th>
                    <td data-cy='fecha_fra'>
                      {
                        editable
                          ? <DateWidget
                              name='fecha_fra'
                              data-cy='fecha_fra'
                              value={new Date(fechaFactura) || new Date()}
                              onClick={() => setDateOpen(true)}
                              open={dateOpen}
                              error={!!errored.emision_prevista}
                              onOpen={() => setDateOpen(true)}
                              onChange={
                                date => {
                                  // setDateOpen(false)
                                  vo.emision_prevista !== fecha.input(date) &&
                                  handleChangeObject({
                                    emision_prevista: fecha.input(date),
                                    fecha: fecha.input(date),
                                    vence: model.vencimiento(fkPlazoCobro, date)
                                  })
                                }
                              }
                              onInvalid={
                                () => handleError({ emision_prevista: 'Fecha inválida' })
                              }
                              onClose={() => setDateOpen(false)}
                            />
                          : fecha.formatSlash(vo.fecha)
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid
              component='figure'
              className={'datos cliente' + (editable ? ' editable' : '')}
              data-title='Cliente'
              container
            >
              <table>
                <tbody>
                  <tr>
                    <td data-cy='contacto[razon]'>
                      {
                        editable &&
                        (
                          <DialogContacto
                            open={
                              (selected &&
                                !cliente.alias &&
                                !contactoOpen.opened
                              ) || contactoOpen.open
                            }
                            name='contacto[razon]'
                            className='widgetContacto'
                            // razon // Activa el modo "Razón social"
                            locked={contactoOpen.locked} // pendiente} // Impide cambiar el contacto
                            label={null}
                            value={cliente}
                            // TODO
                            error={!!errored.razon}
                            onChange={
                              contacto => {
                                const hayContacto = !!(
                                  contacto.id_contacto ||
                                  (contacto.alias && contacto.telefono)
                                )
                                if (!!contacto.id_contacto && contacto.id_contacto === cliente.id_contacto) return
                                handleChangeCliente(contacto, true)
                                if (hayContacto && !hayConcepto) {
                                  setOpenActividad(0)
                                }
                              }
                            }
                            onCancel={() =>
                              setContactoOpen({ open: false, opened: true })}
                            onClose={() =>
                              setContactoOpen({ open: false, opened: true })}
                          />)
                      }
                      {
                        editable
                          ? <Field
                              name='contacto[razon]'
                              // label='NIF/CIF'
                              placeholder='Empresa de ejemplo, SL'
                              value={cliente.razon || ''}
                              error={!!errored.razon}
                              required
                              onChange={(event) => handleChangeCliente({
                                razon: event.target.value
                              })}
                            />
                          : cliente.razon
                      }
                    </td>
                  </tr>
                  <tr>
                    <td data-cy='contacto[nif]'>
                      {
                        editable
                          ? <Field
                              name='contacto[nif]'
                              // label='NIF/CIF'
                              placeholder='A00000000'
                              value={cliente.nif || ''}
                              error={!!errored.nif}
                              required
                              onChange={(event) => handleChangeCliente({
                                nif: event.target.value.toUpperCase()
                              })}
                            />
                          : cliente.nif
                      }
                    </td>
                  </tr>
                  <tr>
                    <td data-cy='contacto[domicilio]'>
                      {
                        editable
                          ? <Domicilio
                              name='contacto[domicilio]'
                              label={null}
                              placeholder='Calle de ejemplo, número X, Puerta A'
                              value={cliente.domicilio || ''}
                              error={!!errored.domicilio}
                              required
                              onChange={(event) => handleChangeCliente({
                                domicilio: event.target.value
                              })}
                              multiline
                            />
                          : address1(cliente)
                      }
                    </td>
                  </tr>
                  <tr>
                    <td
                      data-cy='contacto[direccion2]'
                    >
                      {
                        editable
                          ? <WidgetPostal
                              vo={cliente}
                              label={null}
                              namespace='contacto'
                              onChange={handleChangeCliente}
                              error={!!errored.fk_codigo_postal}
                            />
                          : address2(cliente)
                    }
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            {/*
            <p class='descripcion'>
              ¿Texto libre para describir la factura?
            </p>
            */}
          </Grid>
        </Grid>
        <section className='datos operaciones'>
          <table>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Concepto</th>
                <th>Uds.</th>
                <th>Precio</th>
                <th>Descuento</th>
                <th>Importe</th>
              </tr>
            </thead>
            <tbody>
              {actividades.map((actividad, idx) => {
                return (
                  <ActividadRow
                    key={idx}
                    idx={idx}
                    vo={actividad}
                    open={openActividad === idx || (
                      selected &&
                      !!vo.contacto?.id_contacto &&
                      !hayConcepto
                    )}
                    ivaBase={ivaBase}
                    onClose={() => {
                      setOpenActividad(-1)
                      setHayConcepto(true)
                    }}
                    errored={
                      Object.keys(errored)
                        .filter(key => {
                          return key.split('.')[0] === `actividades[${idx}]`
                        })
                        .reduce((obj, key) => ({
                          ...obj,
                          [key.replace(`actividades[${idx}].`, '')]: errored[key]
                        }), {})
                    }
                    editable={editable}
                    handleError={handleError}
                    handleChangeObject={obj => handleChangeActividad(idx, obj)}
                  />
                )
              })}
              <tr><td colSpan={6} /></tr>
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={6}>
                  {
                    editable &&
                      <menu className='actividades'>
                        <MenuButton
                          data-cy='add'
                          title='Añadir actividad'
                          onClick={handleActividadNueva}
                          Icon={AddIcon}
                          iconProps={{ color: 'primary' }}
                        />
                        <MenuButton
                          data-cy='more'
                          title='Acciones sobre actividades'
                          disabled={!actividades.length}
                          onClick={() => setDialogMore({ actividades })}
                          Icon={MoreIcon}
                          // iconProps={{ color: 'primary' }}
                        />
                        <DialogMore
                          state={dialogMore}
                          setState={setDialogMore}
                          onClose={handleDialogMoreClose}
                        />
                      </menu>
                  }
                  {
                    vo.fk_iva === IVA_EXENTO &&
                      <p>I.V.A. exento</p>
                  }
                  {
                    vo.fk_iva === IVA_REBU &&
                      <p>I.V.A. exento por R.E.B.U.</p>
                  }
                  {
                    vo.fk_iva === IVA_PASIVO &&
                      <p>I.V.A. exento por Inversión del Sujeto Pasivo</p>
                  }
                </td>
              </tr>
            </tfoot>
          </table>
        </section>
        <section className='datos totales'>
          <table className='cajetinTotal'>
            <tbody>
              <tr>
                <th colSpan={2}>Base imponible</th>
                <td data-cy='importe-base'>
                  {tesoreria.formatEUR(importe.base)}
                </td>
              </tr>
              <tr>
                <th>I.V.A.</th>
                <td data-cy='iva'>
                  {
                    editable
                      ? <ComboIva
                          name='fk_iva'
                          data-cy='fk_iva'
                          label={null}
                          value={vo.fk_iva}
                          error={!!errored.fk_iva}
                          onChange={handleChange}
                        />
                      : `${importe.tasa_iva} %`
                  }
                </td>
                <td data-cy='importe-iva'>
                  {tesoreria.formatEUR(importe.iva)}
                </td>
              </tr>
              {
                showIRPF &&
                  <tr>
                    <th>Retención I.R.P.F.</th>
                    <td data-cy='irpf'>
                      {
                        editable
                          ? <Field
                              name='irpf'
                              value={tasaIRPF || null}
                              error={!!errored.irpf}
                              // OJO: Aquí es "InputProps" con mayúscula
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    %
                                  </InputAdornment>
                                )
                              }}
                              onChange={handleChange}
                            />
                          : `${importe.tasa_irpf} %`
                      }
                    </td>
                    <td data-cy='importe-irpf'>
                      {tesoreria.formatEUR(importe.irpf)}
                    </td>
                  </tr>
              }
              <tr>
                <th colSpan={2}>Total Factura</th>
                <td data-cy='importe-total'>
                  {tesoreria.formatEUR(importe.total)}
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className='datos pago' data-title='Información de pago'>
          <Grid
            container
            spacing={0}
          >
            <Grid item sm={12} md={showIBAN ? 4 : 6}>
              <Grid item sm={6}>Forma de Pago</Grid>
              {
                editable
                  ? <ComboMetodoCobro
                      sm={6}
                      name='fk_metodo_cobro'
                      data-cy='fk_metodo_cobro'
                      className='metodo-cobro'
                      label={null}
                      value={formaPago}
                      error={!!errored.fk_metodo_cobro}
                      onChange={
                        event => {
                          const { name, value } = event.target
                          handleChangeObject({
                            [name]: value,
                            iban: value === PAGO_EFECTIVO
                              ? ''
                              : value === TRANSFERENCIA
                                ? empresa.iban
                                : cliente.iban
                          })
                        }
                      }
                    />
                  : (
                    <Grid
                      data-cy='metodo_cobro'
                      item
                      sm={6}
                    >
                      {vo.pago}
                    </Grid>
                    )
              }
            </Grid>
            {showIBAN && (
              <Grid item sm={12} md={5}>
                <Grid item sm={4} md={3}>
                  {
                    formaPago === DOMICILIACION
                      ? 'IBAN Cliente'
                      : 'IBAN'
                  }
                </Grid>
                {
                  editable
                    ? <IbanField
                        sm={9}
                        md={9}
                        name='iban'
                        sx={{ textAlign: 'center' }}
                        placeholder='ESXX XXXX XXXX XXXX XXXX XXXX'
                        value={vo.iban || ''}
                        error={!!errored.iban}
                        onChange={(e) => handleChangeObject({
                          iban: e.target.value.toUpperCase()
                        })}
                      />
                    : (
                      <Grid
                        item
                        sm={9}
                        md={9}
                        sx={{ fontSize: '0.8rem' }}
                        data-cy='iban'
                      >
                        {vo.iban}
                      </Grid>
                      )
                }
              </Grid>
            )}
            <Grid item sm={12} md={showIBAN ? 3 : 6}>
              <Grid item sm={6}>Vencimiento</Grid>
              {
                editable
                  ? <DateWidget
                      sm={5}
                      name='vence'
                      className='vence'
                      value={new Date(vo.vence)}
                      // error={!!errored.fecha}
                      minDate={new Date(fechaFactura)}
                      onChange={vence => handleChangeObject({
                        vence: fecha.input(vence)
                      })}
                      onInvalid={
                        () => handleError({ vence: 'Fecha inválida' })
                      }
                    />
                  : (
                    <Grid item sm={5}>
                      {fecha.formatSlash(vo.vence)}
                    </Grid>
                    )
                }
            </Grid>
          </Grid>
        </section>
        <footer>
          <address>
            {empresa.razon}{' · '}{empresa.nif}
            {' · '}{address1(empresa)}{', '}{address2(empresa)}{' · '}
            {empresa.email && <>{empresa.email}</>}
          </address>
          {empresa.pie_factura &&
            <address
              style={{
                textAlign: 'justify',
                paddingRight: '2em',
                paddingLeft: '2em',
                fontSize: 10
              }}
            >
              {empresa.pie_factura}
            </address>}
        </footer>
      </Collapse>
      {selected && emitLayout}
    </>
  )
}

export default TarjetaItemHOC(FacturaItem, {
  afterSave (sent, response) {
    // console.log({ afterSave: response, sent })
    return response.item || { ...sent, actividades: response.body.actividades }
  }
})
