import React from 'react'

import { InputAdornment } from '@mui/material'

import { Field } from './Field'
import { GridItemHOC } from './Grid'

export function NumberField (props) {
  const {
    unit = 'U',
    value = '',
    error = false,
    onChange,
    defaults = 0,
    // Mínimo y máximo no exclusivos
    maximum = false,
    minimum = false,
    notZero = false,
    // Opciones de display
    hideZero = false,
    format = num => num.toString().replace(/\./g, ','),
    onFocus = () => null,
    onBlur = () => null,
    autoFocus = false,
    readOnly = false,
    ...other
  } = props

  const inputRef = React.useRef(null)
  const [hasFocus, setFocus] = React.useState(autoFocus)

  React.useEffect(() => {
    hasFocus && inputRef.current.select()
  }, [hasFocus])

  const handleChange = event => {
    const str = event.target.value.replace(',', '.')
    const num = Number(str)

    if (isNaN(num)) {
      // Permitir existencia de un guión como valor
      if (str === '-') return onChange(event)
      // Impedir introducción de números inválidos
      return
    }

    event.target.value = str
    onChange(event)
  }

  // console.info('Render NumberField', unit, value, hasFocus, readOnly)

  const safeVal = value === 0 ? value : value || defaults
  const numeric = value === '-' ? 0 : Number(safeVal)
  const display = (value === '' || value === '-')
    ? value
    : hasFocus
      ? safeVal.toString().replace(/\./, ',')
      : (
          (hideZero && numeric === 0)
            ? ''
            : format(numeric)
        )

  const errNotZero = (notZero && numeric === 0 && display !== '')
  const errMinimum = (minimum !== false && numeric < minimum)
  const errMaximum = (maximum !== false && numeric > maximum)

  return (
    <Field
      type='tel' // Hack para mostrar teclado numérico en móviles
      value={display}
      error={
        error || errNotZero || errMinimum || errMaximum || display === '-'
      }
      helperText={
        display === '-'
          ? props.helperText
          : (
              (errNotZero && '"0" no vale') ||
              (errMinimum && `Mínimo "${minimum}"`) ||
              (errMaximum && `Máximo "${maximum}"`) ||
              props.helperText
            )
      }
      onBlur={(event) => { setFocus(false); onBlur(event) }}
      onFocus={(event) => { onFocus(event); setFocus(true) }}
      onChange={handleChange}
      InputProps={{
        endAdornment: <InputAdornment position='end'>{unit}</InputAdornment>,
        readOnly
      }}
      inputProps={{ style: { textAlign: 'right' } }}
      inputRef={inputRef}
      {...other}
    />
  )
}

export default GridItemHOC(NumberField)
