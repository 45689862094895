import React from 'react'

import { MainAction } from '../../store/const'
const { SHOW_CREATE } = MainAction

export function useHandlerActividadNueva (vo, handleChangeObject, onDone) {
  return React.useCallback(function handleActividadNueva () {
    // console.log('Handle Actividad Nueva', vo)
    const cambios = {
      ids_actividades: [...vo.ids_actividades, SHOW_CREATE],
      actividades: [
        ...vo.actividades,
        {
          concepto: { id_concepto: null },
          descuento: 0,
          precio: 0,
          cantidad: 1,
          estado: 'abierta'
        }
      ]
    }
    if (vo.actividades.length === 1 && vo.id_actividad_paralela === SHOW_CREATE) {
      cambios.actividades[0] = {
        ...cambios.actividades[0],
        ...vo
      }
      delete cambios.actividades[0].actividades
      delete cambios.actividades[0].fk_contacto
      delete cambios.actividades[0].id_actividad
      delete cambios.actividades[0].nota
      delete cambios.actividades[0].contacto
      delete cambios.actividades[0].ids_actividades
      delete cambios.actividades[0].id_actividad_paralela
      delete cambios.actividades[0].descripcion
      delete cambios.actividades[0].fecha_prevista
      delete cambios.actividades[0].hora_prevista
    }
    handleChangeObject(cambios)
    onDone(vo.actividades.length)
  }, [vo, handleChangeObject, onDone])
}
