import axios from 'axios'

export const baseURL = process.env.REACT_APP_ENV === 'staging'
  ? 'https://stage.agendayfacturacion.es'
  : (
      process.env.REACT_APP_API_URL ||
      window.location.origin
    )

export function createAxiosClient (pathname = '', otherConf = {}) {
  return axios.create({
    baseURL: `${baseURL}${pathname}`,
    ...otherConf
  })
}
