import React from 'react'

import Grid from '@mui/material/Grid'

import { importe, formatFloat, formatEUR, format100 } from '@grupomarea/abrela-tesoreria'

import { Field } from '../Abc/FormUtils'

import { Cantidad, Precio, Descuento, Importe } from '../Abc/FieldSet/Valor'
import WidgetConcepto from '../Abc/FieldSet/WidgetConcepto'

export default function ActividadRow (props) {
  const { editable, vo, errored, idx, open, onClose } = props

  const { handleChangeObject } = props
  const changeEventHandler = (field) => event => {
    handleChangeObject({ [field]: event.target.value })
  }

  const namespace = `actividad[${idx}]`
  // console.info('Render', open)

  const content = (editable || vo === null)
    ? [
      <React.Fragment key={0}>
        <WidgetConcepto
          name={`${namespace}[concepto]`}
          open={open}
          label='Concepto'
          value={vo.concepto}
          error={!!errored.concepto}
          onClose={onClose}
          onChange={
            (concepto) => {
              const changes = {
                concepto,
                fk_concepto: concepto.id_concepto
              }
              if (typeof concepto.precio === 'number') {
                changes.precio = concepto.precio || 0
              }
              if (concepto.id_concepto && (vo.concepto.id_concepto === concepto.id_concepto)) {
                return
              }
              handleChangeObject(changes)
            }
          }
        />
      </React.Fragment>,
      <Cantidad
        key={1}
        name={`${namespace}[cantidad]`}
        label='Cantidad'
        value={vo.cantidad}
        error={!!errored.cantidad}
        unidad={vo.concepto.unidad_medida}
        onChange={changeEventHandler('cantidad')}
        readOnly={!!vo.fk_factura}
      />,
      <Precio
        key={2}
        name={`${namespace}[precio]`}
        label='Precio'
        value={vo.precio || 0}
        error={!!errored.precio}
        autoFocus={(open || !vo.precio || vo.precio === 0) && !vo.factura}
        onChange={changeEventHandler('precio')}
        readOnly={!!vo.fk_factura}
      />,
      <Descuento
        key={3}
        name={`${namespace}[descuento]`}
        label='Descuento'
        value={vo.descuento}
        error={!!errored.descuento}
        onChange={changeEventHandler('descuento')}
        readOnly={!!vo.fk_factura}
      />,
      <Importe
        key={4}
        name='importe'
        label='Importe'
        value={vo.precio ? formatFloat(importe(vo)) : 0}
        inputProps={{ style: { textAlign: 'right' } }}
      />
      ]
    : [
      <Field
        disabled
        label='Concepto'
        key={0}
        value={vo.concepto.nombre}
      />,
      <Field
        disabled
        label='Cantidad'
        key={0}
        value={vo.cantidad + ' ' + vo.concepto.unidad_medida}
        inputProps={{ style: { textAlign: 'right' } }}
      />,
      <Field
        disabled
        label='Precio'
        key={0}
        value={formatEUR(vo.precio)}
        inputProps={{ style: { textAlign: 'right' } }}
      />,
      <Field
        disabled
        label='Descuento'
        key={0}
        value={format100(vo.descuento)}
        inputProps={{ style: { textAlign: 'right' } }}
      />,
      <Field
        disabled
        label='Importe'
        key={0}
        value={formatEUR(importe(vo))}
        inputProps={{ style: { textAlign: 'right' } }}
      />
      ]

  return (
    <Grid item sm={10} md={11} data-cy='actividad-row' data-actividad={vo.id} className='actividadRow'>
      <Grid item sm={12} md={3} data-cy={`${namespace}[concepto]`}>{content[0]}</Grid>
      <Grid item sm={12} md={9} className='valores'>
        <Grid item sm={6} data-cy={`${namespace}[cantidad]`}>{content[1]}</Grid>
        <Grid item sm={6} data-cy={`${namespace}[precio]`}>{content[2]}</Grid>
        <Grid item sm={6} data-cy={`${namespace}[descuento]`}>{content[3]}</Grid>
        <Grid item sm={6} data-cy={`${namespace}[importe]`}>{content[4]}</Grid>
      </Grid>
    </Grid>
  )
}
