import { Navigate } from 'react-router-dom'
import AuthService from '../../service/auth'

export default function Logout (props) {
  const handleLogout = event => {
    AuthService.dispatchLogout()
    Navigate(-1)
  }

  return (
    <section>
      <h1>Desconectar</h1>
      <p>Esta vista es provisional, obviamente</p>
      <button onClick={handleLogout}>Salir</button>
    </section>
  )
}
