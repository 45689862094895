import { createStore } from 'redux'

import {
  AuthAction as Auth,
  MainAction as Main,
  ListAction as List,
  StateKeys as State,
  ResetKeys as Reset,
  ResetOnFilter
} from './const'

import {
  init as filtersInit,
  reducer as filtersReducer
} from './filters'

const { sessionStorage, localStorage } = global

/*
 * Estado inicial de la aplicación
 */
const user = JSON.parse(sessionStorage.getItem('abrela:user'))
const empresa = JSON.parse(localStorage.getItem('abrela:empresa'))

const reduceEmpresa = (vo) => ({
  id: vo.fk_empresa,
  alias: vo.alias || vo.empresa
})

export const init = {
  [State.user]: user,
  [State.empresa]: empresa || (
    user && user.relaciones.length
      ? reduceEmpresa(user.relaciones[0])
      : null
  ),
  [State.isLoading]: null,
  [State.card]: { id: null, errored: {} },
  [State.filters]: filtersInit,
  [State.contactos]: [],
  [State.conceptos]: [],
  [State.eventos]: [],
  [State.actividades]: [],
  [State.actividades_paralelas]: [],
  [State.facturas]: [],
  [State.borradores]: [],
  [State.todos]: [],
  [Main.PICK_CREATE]: false,
  [State.lockHistory]: null
}

/*
 * Reducer principal
 */
export function reducer (state = init, action) {
  console.info(action.type, { action })
  let thing, other
  switch (action.type) {
    case Main.MULTIACTION:
      // Esta gema se me ocurrió para evitar múltiples renders cuando
      // necesito despachar varias acciones seguidas y es just awesome
      return action.payload.reduce(
        (state, action) => reducer(state, action), state
      )
    case Main.INIT_LOADING:
      return {
        ...state,
        [State.isLoading]: state[State.isLoading]
          ? state[State.isLoading].concat(...action.payload)
          : [...action.payload]
      }
    case Main.DONE_LOADING:
      return {
        ...state,
        [State.isLoading]: (state[State.isLoading] || []).filter(
          str => !action.payload.includes(str)
        )
      }
    case Auth.LOADING:
      return {
        ...state,
        [State.authLoading]: action.payload !== false
      }
    case Auth.SIGN_IN:
      return {
        ...state,
        [State.user]: action.user,
        [State.empresa]: state[State.empresa] || (
          action.user.relaciones.length
            ? reduceEmpresa(action.user.relaciones[0])
            : null
        )
      }
    case Auth.LOG_OUT:
      return {
        ...state,
        [State.user]: null,
        [State.empresa]: null,
        [State.empresas]: null,
        ...Reset.reduce((obj, key) => ({ ...obj, [key]: init[key] }), {})
      }
    case Auth.OFFLINE:
      console.warn('No hay conexión')
      return {
        ...state
      }
    case Main.SET_EMPRESA:
      return {
        ...state,
        ...Reset.reduce(
          // TODO esta acción es impura y depende del objecto inicial
          (obj, key) => ({ ...obj, [key]: init[key] }),
          {}
        ),
        [State.empresa]: state.user.relaciones.reduce(
          (NULL, vo) => vo.fk_empresa === action.payload
            ? reduceEmpresa(vo)
            : NULL,
          null
        )
      }
    case Main.PUT_EMPRESA:
      if (state[State.empresa].id !== action.payload.fk_empresa) {
        return state
      }
      return {
        ...Reset.reduce(
          // TODO esta acción es impura y depende del objecto inicial
          (obj, key) => ({ ...obj, [key]: init[key] }),
          {}
        ),
        ...state,
        [State.empresa]: reduceEmpresa(action.payload),
        [State.confEmpresa]: { ...action.payload }
      }
    case 'LOAD_DATALISTS':
      return {
        ...state,
        contactos: action.contactos,
        conceptos: action.conceptos,
        empresas: action.empresas,
        [State.confEmpresa]: action.empresa
      }
    /**
     * "List" actions son las relacionadas con el "listado de tarjetas"
     * → Las que modifican la lista (Array) de Value Objects
     */
    case List.LOAD_INITIAL:
      thing = action.list.slice(0)
      thing.total = action.total
      thing.totales = action?.totales
      return {
        ...state,
        [action.plural]: thing
      }
    case List.NEXT_PAGE:
      thing = [...state[action.plural], ...action.list]
      thing.total = state[action.plural].total
      thing.totales = state[action.plural].totales
      return {
        ...state,
        [action.plural]: thing
      }
    case List.LOAD_REFRESH:
      return {
        ...state,
        [List.LOAD_REFRESH]: (
          action.payload !== false ? action.plural : false
        ),
        [action.plural]: (
          action.payload !== false ? init[action.plural] : state[action.plural]
        )
      }
    case List.ITEM_UNSHIFT:
      if (!state[action.plural]) {
        thing = [action.item]
        thing.total = 1
      } else {
        thing = [action.item, ...state[action.plural]].sort(action.sorter || (() => 0))
        thing.total = state[action.plural].total + 1
      }
      return { ...state, [action.plural]: thing }
    case List.ITEM_REPLACE:
      // Mapeo la lista entera y reemplazo cualquier elemento coincidente
      // Si se especifica "matcher", se usa para identificar las coincidencias
      // En caso contrario hay que especificar "pk"
      other = action.matcher || (vo => vo[action.pk] === action.item[action.pk])
      thing = state[action.plural].map(vo => other(vo) ? action.item : vo)
      thing.total = state[action.plural].total
      return { ...state, [action.plural]: thing }
    case List.ITEM_DESTROY:
      // Filtro la lista entera y descarto cualquier elemento coincidente
      // Si se especifica "matcher", tiene prioridad sobre la dupla { pk, id }
      // En caso contrario hay que especificar "pk" y "id"
      thing = state[action.plural].filter(
        action.matcher || (vo => vo[action.pk] !== action.id)
      )
      // console.warn('Se quedan', thing.length, 'cosas', 'se van', state[action.plural].length - thing.length)
      thing.total = state[action.plural].total - (state[action.plural].length - thing.length)
      return { ...state, [action.plural]: thing }
    case List.ADD_FILTER:
    case List.POP_FILTER:
      return {
        ...state,
        [State.filters]: filtersReducer(state[State.filters], action),
        ...ResetOnFilter.reduce((obj, key) => ({ ...obj, [key]: init[key] }), {})
      }
    /**
     * "Main" actions son las relacionadas con la "tarjeta actual"
     * TODO Los "PICK" siempre envían una señal?
     */
    case Main.PICK_CREATE:
      return {
        ...state,
        [Main.PICK_CREATE]: action.payload === false
          ? false
          : action.payload || true
      }
    case Main.CARD_SELECT:
      return {
        ...state,
        card: {
          model: action.model,
          ...action.card
        },
        [Main.PICK_CREATE]: (
          // Se establece a false cuando:
          // - se va a mostrar/ocultar una tarjeta (esta acción)
          // - existe la señal
          // - la tarjeta que se va a mostrar NO es la de crear un item
          //   * Esto incluye cuando se oculta una tarjeta (id === null)
          state[Main.PICK_CREATE] &&
          (action.card.id !== Main.SHOW_CREATE)
            ? false
            : state[Main.PICK_CREATE]
        )
      }
    case Main.CARD_ERRORS:
      return {
        ...state,
        card: {
          ...state.card,
          errored: action.payload
        }
      }
    case Main.LOCK_HISTORY:
      return {
        ...state,
        [State.lockHistory]: action.payload
      }
    default:
      if (!IGNORE.test(action.type)) {
        console.warn('Unknown action type:', action.type)
      }
      return state
  }
}

const IGNORE = /^@@redux*/

const store = createStore(reducer)

global.reduxStore = store

export default store
