import BaseModel from './ActividadParalela'

export default class Evento extends BaseModel {
  static get single () { return 'agenda' }
  static get plural () { return 'eventos' }

  // Delegamos en el modelo Base (Actividad), pero en su propio contexto
  static getItemUrl (...args) { return BaseModel.getItemUrl(...args) }
  static getListUrl (...args) { return BaseModel.getListUrl(...args) }

  static getFrontItemUrl (fkEmpresa, id) { return `/${fkEmpresa}/agenda/${id}` }
  static getFrontListUrl (fkEmpresa) { return `/${fkEmpresa}/agenda` }

  static async findAll (filters, params) {
    return super.findAll(
      filters,
      { ...params, limit: 100, offset: 0 }
    )
  }
}
