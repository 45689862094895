import BaseModel from './Actividad'
import { MainAction } from '../../../store/const'

const { SHOW_CREATE } = MainAction

export default class ActividadParalela extends BaseModel {
  static get pk () { return 'id_actividad_paralela' }
  static get single () { return 'actividad_paralela' }
  static get plural () { return 'actividades_paralelas' }

  static getListUrl (fkEmpresa) {
    return BaseModel.getListUrl(fkEmpresa) // o `/${fkEmpresa}/${super.plural}`
  }

  static getItemUrl (fkEmpresa, id) {
    return BaseModel.getItemUrl(fkEmpresa, id)
    // o `/${fkEmpresa}/${super.plural}/${id}`
  }

  static defaults (confEmpresa, filters) {
    const actividadNueva = BaseModel.defaults(confEmpresa, filters)
    // console.log('inicializar actividad paralela', actividadNueva)
    return {
      id_actividad_paralela: SHOW_CREATE,
      ...actividadNueva,
      actividades: [actividadNueva],
      ids_actividades: [actividadNueva.id_actividad]
    }
  }
}
