import React from 'react'

import { Virtuoso } from 'react-virtuoso'

import InputAdornment from '@mui/material/InputAdornment'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import IconButton from '@mui/material/IconButton'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
// import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'

import Add from '@mui/icons-material/Add'
import Close from '@mui/icons-material/Close'

import { concepto as model } from '../../../service/empresas/model'

import { searchData } from '../../../utils/SearchInput'

import { useListLoader } from '../../../utils/hooks'

import Field from '../FormUtils/Field'
import { GridContainer } from '../FormUtils/Grid'

export default function DialogConcepto (props) {
  const {
    id = 'dialog-concepto-' + Math.random(),
    // name,
    state,
    open,
    onChange,
    onClose,
    field,
    ComponentClass,
    defaults
  } = props

  const [selected, setSelected] = React.useState(0)
  const [error, setError] = React.useState(false) // Error handler en caso de campo vacío
  const [stringValue, setStringValue] = React.useState('')
  const toRef = React.useRef(null)
  const detalles = {
    searchParams: {
      q: stringValue,
      contactos: false,
      conceptos: true,
      tiempo: false,
      actividades: false,
      facturas: false
    }
  }

  const { listado, loadNext, total, hasMore } = useListLoader({
    model,
    shouldLoad: (empresa) => !!empresa
  })

  const [options, setOptions] = React.useState(listado)

  if (!stringValue && (options !== listado)) {
    setOptions(listado)
  }

  // select(null) idica que se desea crear uno nuevo
  const select = React.useCallback((vo, idx = -1) => {
    if (!state) return
    if (stringValue === '' && !vo) {
      setError(true)
    } else {
      const shouldClose = vo || (!vo && stringValue !== '')
      // console.warn('select', shouldClose, { idx, vo })
      setError(false)
      setSelected(idx)
      onChange(
        !vo
          ? { ...defaults, [field]: stringValue }
          : { ...vo },
        shouldClose
      )
      onClose && onClose()
    }
  }, [stringValue, onClose, onChange, state, defaults, field])

  const handleSearch = React.useCallback((event) => {
    setError(false)
    if (toRef.current) {
      clearTimeout(toRef.current)
    }

    const text = event.target.value
    setStringValue(text)

    if (text) {
      toRef.current = setTimeout(() => {
        searchData(
          text,
          detalles.searchParams,
          options
        ).then(opts => {
          setOptions(opts)
        })
      }, 200)
    } else {
      setOptions(listado)
    }
  }, [setOptions, listado, options, detalles.searchParams])

  const memoizedList = React.useMemo(() => {
    // handleSearch(stringValue)
    return options.map((vo, idx) =>
      <ListItemButton
        key={idx}
        // disableGutters
        selected={selected === idx}
        onClick={() => select(vo, idx)}
      >
        <ListItemAvatar>
          <Avatar
            className={model.single}
          >
            {vo[field].slice(0, 1).toUpperCase()}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={vo[field]}
          secondary={
            // TODO ?
            undefined
          }
        />
      </ListItemButton>
    )
  }, [options, selected, select, field])

  /*
   * HOOKS END HERE
   */

  const handleCancel = (event) => {
    setStringValue('')
    setError(false)
    setOptions(listado)
  }

  if (!state) {
    // console.warn('Omitir render dialog', state)
    return props.children
  }

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return false
    }
    onChange(
      state.nombre
        ? { ...state, conceptoFinish: true }
        : { ...state, conceptoFinish: false }, true
    )
    onClose && onClose()
  }

  // console.log(options)
  const handleKeyboard = (event) => {
    switch (event.key) {
      case 'ArrowDown':
        return setSelected(
          prev => prev >= (memoizedList.length - 1) ? -1 : ++prev
        )
      case 'ArrowUp':
        return setSelected(
          prev => prev <= -1 ? (memoizedList.length - 1) : --prev
        )
      case 'Enter':
        return selected < 0
          ? onChange(
            { ...defaults, [field]: stringValue },
            !!stringValue
          )
          : select(memoizedList[selected])
      default:
        // console.info('Keyboard', event.key)
    }
  }

  return (
    <Dialog
      data-cy={ComponentClass}
      className={`${ComponentClass}-dialog`}
      fullWidth
      open={open || false}
      onClose={handleClose}
      aria-labelledby={id + '-title'}
    >
      <DialogTitle id={id + '-title'}>
        {`Selector ${model.single}`}
      </DialogTitle>
      <DialogContent>
        <GridContainer>
          <Field
            name={`${ComponentClass}[${field}]`}
            label='Nombre'
            value={stringValue}
            error={error}
            fullWidth
            required
            onChange={handleSearch}
            onKeyDown={handleKeyboard}
            autoFocus
            inputProps={{ autoComplete: 'off' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='Limpiar la selección'
                    onClick={handleCancel}
                  >
                    <Close fontSize='small' />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </GridContainer>
        <GridContainer className='listFooter'>
          <ListItemButton
            // disableGutters
            data-cy={`${model.single}-nuevo`}
            selected={selected === -1}
            onClick={() => select(null)}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: '#2196f3' }}>
                <Add />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                stringValue
                  ? `Añadir "${stringValue}"`
                  : `Añadir un ${model.single} nuevo`
              }
            />
          </ListItemButton>
          <ListItem>
            <Divider variant='middle' sx={{ width: '95%' }} />
          </ListItem>
        </GridContainer>
        <List dense disablePadding>
          {total > 0 && (
            <Collapse in={memoizedList.length > 0} mountOnEnter unmountOnExit>
              <Virtuoso
                style={{
                  height: (itemHeight => {
                    const calc = itemHeight * memoizedList.length
                    return calc > 400 ? 400 : calc
                  })(48) + 'px'
                }}
                data={memoizedList}
                endReached={hasMore ? loadNext : undefined}
                totalCount={total || 0}
                overscan={{ main: 15, reverse: 15 }}
                itemContent={(index, thing) => thing || <div>Placeholder</div>}
              />
            </Collapse>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
        {/* <Button type='submit' color='primary'>Añadir</Button> */}
      </DialogActions>
    </Dialog>
  )
}
