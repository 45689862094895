import React from 'react'

import { useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'

import Close from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import CheckIcon from '@mui/icons-material/Check'
import SaveIcon from '@mui/icons-material/Save'
import UndoIcon from '@mui/icons-material/Undo'

import { concepto } from '../../service/empresas/model'
import { useSelector } from 'react-redux'
import {
  useConfigureEB,
  useCardPicker,
  useLocationDetails
} from '../../utils/hooks'

import ConceptoList from '../Conceptos/ConceptoList'

import { searchData } from '../../utils/SearchInput'

import UploadButton from '../Abc/FormUtils/UploadButton'
import WidgetPostal, { Domicilio } from '../Abc/FieldSet/WidgetPostal'

import Loading from '../Abc/Loading'
import * as fecha from '../../utils/fecha'

import { Field, DateWidget } from '../Abc/FormUtils'
import {
  ConfigFacturas,
  ConfigContactos,
  ConfigActividades,
  Documentos
} from '../Abc/SettingsMenu'
import WidgetContacto from '../Abc/FieldSet/WidgetContacto'
import './Empresa.scss'

export default function EmpresaConfiguracion (props) {
  const detalles = useLocationDetails()
  const { errored } = props

  const initialOptions = useSelector(state => state.conceptos || [])
  const [options, setOptions] = React.useState(initialOptions)
  const [stringValue, setStringValue] = React.useState('')
  const [editable, setEditable] = React.useState(false)
  const [contactoOpen, setContactoOpen] = React.useState(false)
  const [contacto, setContacto] = React.useState(null)
  const toRef = React.useRef(null)

  const navigate = useNavigate()

  const pickCard = useCardPicker(concepto)

  if (!stringValue && (options !== initialOptions)) {
    setOptions(initialOptions)
  }

  const handleSearch = React.useCallback((event) => {
    if (toRef.current) {
      clearTimeout(toRef.current)
    }
    const text = event.target.value
    setStringValue(text)

    if (text) {
      toRef.current = setTimeout(() => {
        searchData(
          text,
          detalles.searchParams,
          options
        ).then(opts => {
          setOptions(opts)
        })
      }, 200)
    } else {
      setOptions(initialOptions)
    }
  }, [setOptions, initialOptions, options, detalles.searchParams])

  const {
    vo,
    changesRef,
    blockLayout,
    onSave,
    handleBack,
    handleChange,
    handleChangeObject
  } = useConfigureEB()

  // HOOKS END HERE

  if (!vo) return <Loading />

  const handleToggle = (panel) => (event) => {
    const isExpanded = detalles.itemId === panel
    changesRef.current.length > 0
      ? isExpanded && onSave({ pickNext: null })
      : navigate(!isExpanded ? panel : '', { replace: true })
  }

  const AccordionBar = (props) => {
    const { id, text } = props
    return (
      <AccordionSummary
        id={id}
      >
        <Typography>{text}</Typography>
        <Grid
          className='accordionControl'
          item
        >
          <Collapse in={
            detalles.itemId === id &&
            !!changesRef.current.length
          }
          >
            <IconButton onClick={(e) => {
              e.stopPropagation()
              handleBack()
            }}
            >
              <UndoIcon />
            </IconButton>
          </Collapse>
          <Button
            variant='text'
            onClick={() => handleToggle(id)}
            endIcon={
              <>
                <Collapse
                  in={detalles.itemId !== id}
                  orientation='horizontal'
                  mountOnEnter
                  unmountOnExit
                >
                  <ExpandMoreIcon />
                </Collapse>
                <Collapse
                  in={detalles.itemId === id}
                  orientation='horizontal'
                  mountOnEnter
                  unmountOnExit
                >
                  {
                    changesRef.current.length
                      ? <SaveIcon data-cy='save-button' color='primary' />
                      : <CheckIcon />
                  }
                </Collapse>
              </>
            }
          />
        </Grid>
      </AccordionSummary>
    )
  }

  const handleEdit = () => {
    detalles.itemId === 'SHOW_CREATE' && navigate('conceptos')
    setEditable(!editable)
  }

  const handleCreate = () => {
    setEditable(false)
    detalles.itemId === 'SHOW_CREATE'
      ? navigate('conceptos')
      : pickCard('crear')
  }

  // console.warn(initialOptions, options, memoizedList)
  const handleCancel = (event) => {
    setStringValue('')
    setOptions(initialOptions)
  }

  // console.log(changesRef.current)

  return (
    <Grid container className='empresaFormRoot'>
      <Accordion
        expanded={detalles.itemId === 'conf-empresa'}
        data-cy='conf-empresa'
        onChange={handleToggle('conf-empresa')}
        sx={{ width: '100%' }}
      >
        <AccordionBar id='conf-empresa' text='Datos de mi empresa' />
        <AccordionDetails>
          <Grid
            container
            component='form'
            className='datos-empresa'
            data-cy='form-empresa'
            spacing={2}
          >
            <UploadButton
              item
              sm={12}
              md={6}
              name='logo'
              image={vo.logo}
              onChange={
                (dataURL) => {
                  console.warn('logo')
                  handleChangeObject({ logo: dataURL })
                }
              }
              titulo='Logotipo de la empresa'
              texto='*Imagen en documentos'
            />
            <UploadButton
              item
              sm={12}
              md={6}
              name='avatar'
              image={vo.avatar}
              onChange={
                (dataURL) => {
                  console.warn('avatar')
                  handleChangeObject({ avatar: dataURL })
                }
              }
              titulo='Avatar de la empresa'
              texto='*Imagen de personalización'
            />
            <Field
              sm={12}
              md={5}
              name='alias'
              label='Alias'
              value={vo?.alias || ''}
              // error={!!errored.alias}
              required
              onChange={handleChange}
            />
            <Field
              sm={12}
              md={5}
              name='razon'
              label='Nombre completo o razon social'
              value={vo?.razon || ''}
              // error={!!errored.razon}
              required
              onChange={handleChange}
            />
            <Field
              sm={12}
              md={5}
              name='nif'
              label='NIF/CIF'
              value={vo?.nif || ''}
              // error={!!errored.nif}
              required
              onChange={(e) => handleChangeObject({
                nif: e.target.value.toUpperCase()
              })}
            />
            <Field
              sm={12}
              md={5}
              required
              label='Email'
              name='email'
              value={vo?.email || ''}
              // error={!!errored.email}
              onChange={handleChange}
            />
            <Domicilio
              sm={12}
              value={vo?.domicilio || ''}
              // error={!!errored.domicilio}
              required
              onChange={handleChange}
            />
            <WidgetPostal
              sm={12}
              md={12}
              vo={vo}
              // errored={errored}
              required
              onChange={handleChangeObject}
            />
            <DateWidget
              sm={6}
              md={3}
              name='fecha_nacimiento'
              label='Fecha de nacimiento o constitución'
              value={(vo?.fecha_nacimiento && new Date(vo.fecha_nacimiento)) || null}
              // error={!!errored.fecha_nacimiento}
              onChange={
                (date) => handleChangeObject({
                  fecha_nacimiento: fecha.input(date)
                })
              }
              onInvalid={() => console.warn('InvalidDate')}
            />

            <Field
              sm={6}
              md={3}
              name='iae'
              label='Código IAE'
              value={vo?.iae || ''}
              // error={!!errored.iae}
              onChange={handleChange}
            />

            <Field
              sm={6}
              md={3}
              name='cnae'
              label='Código CNAE'
              value={vo?.cnae || ''}
              // error={!!errored.cnae}
              onChange={handleChange}
            />
            <Field
              sm={6}
              md={3}
              name='cuenta_contable_empresa'
              label='Cuenta Contable'
              value={vo?.cuenta_contable_empresa || ''}
              // error={!!errored.cnae}
              onChange={handleChange}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={detalles.itemId === 'conf-contactos'}
        onChange={handleToggle('conf-contactos')}
        data-cy='conf-contactos'
        sx={{ width: '100%' }}
      >
        <AccordionBar
          id='conf-contactos'
          text='Configuración por defecto de los datos
            de facturación de los contactos'
        />
        <AccordionDetails>
          <ConfigContactos
            {...{ vo, handleChange, handleChangeObject, errored }}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={detalles.itemId === 'conf-actividades'}
        onChange={handleToggle('conf-actividades')}
        data-cy='conf-actividades'
        sx={{ width: '100%' }}
      >
        <AccordionBar
          id='conf-actividades'
          text='Configuración por defecto de agenda y actividades'
        />
        <AccordionDetails>
          <ConfigActividades
            {...{ vo, handleChange, handleChangeObject, errored }}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={detalles.itemId === 'conf-facturas'}
        onChange={handleToggle('conf-facturas')}
        data-cy='conf-facturas'
        sx={{ width: '100%' }}
      >
        <AccordionBar
          id='conf-facturas'
          text='Configuración por defecto de mis facturas'
        />
        <AccordionDetails>
          <ConfigFacturas
            {...{ vo, handleChange, handleChangeObject, errored }}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={
          detalles.itemId === 'conceptos' ||
          detalles.itemId === 'SHOW_CREATE'
        }
        onChange={handleToggle('conceptos')}
        data-cy='conf-conceptos'
        sx={{ width: '100%' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id='listConceptos'
        >
          <Typography>Lista de conceptos</Typography>
        </AccordionSummary>
        <AccordionDetails
          className='listConceptos'
        >
          <Grid
            container
            component='form'
          >
            <Grid
              item
              md={6}
              sm={12}
              noValidate
              onSubmit={event => event.preventDefault()}
            >
              <Field
                name='concepto[buscar]'
                label='Buscar'
                value={stringValue}
                // error={error}
                required
                onChange={handleSearch}
                autoComplete='off'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='Limpiar la selección'
                        onClick={handleCancel}
                        onMouseDown={event => event.preventDefault()}
                      >
                        <Close fontSize='small' />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                onClick={() => handleEdit()}
                data-cy='editar-concepto'
              >
                Editar
              </Button>
              <Button
                onClick={() => handleCreate()}
                data-cy='nuevo-concepto'
              >
                Nuevo
              </Button>
            </Grid>
          </Grid>
          <ConceptoList
            editable={editable}
            list={options}
            stringValue={stringValue}
            windowScroll={false}
            {...props}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={detalles.itemId === 'conf-documentos'}
        onChange={handleToggle('conf-documentos')}
        sx={{ width: '100%' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='documentos'
        >
          <Typography>Documentos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={6} sx={{ marginBottom: '32px' }}>
            <WidgetContacto
              open={contactoOpen}
              name='contacto[razon]'
              label={null}
              placeholder='Seleccionar Destinatario'
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => setContactoOpen(true)}
                  >
                    <AccountCircleOutlinedIcon />
                  </IconButton>
                )
              }}
              value={contacto || ''}
              // TODO
              // error={!!errored.razon}
              onChange={
                contacto => {
                  setContacto(contacto)
                  setContactoOpen(false)
                }
              }
              onCancel={() => setContactoOpen(false)}
            />
          </Grid>
          <Documentos
            empresa={vo}
            contacto={contacto}
          />
        </AccordionDetails>
      </Accordion>

      {blockLayout}
    </Grid>
  )
}
