import TarjetaListHOC from '../Abc/Tarjeta/TarjetaListHOC'

import { contacto as model } from '../../service/empresas/model'

import ContactoItem from './ContactoItem'

export function ContactoList (props) {
  return (
    props.children
  )
}

export default TarjetaListHOC(ContactoList, {
  model,
  ItemComponent: ContactoItem,
  search: model.search
})
