import React from 'react'

import Toolbar from '@mui/material/Toolbar'
// import Badge from '@mui/material/Badge'

import Drawer from './Drawer'
import SearchBar from './SearchBar'

export default function ToolMenu (props) {
  const [expanded, setExpanded] = React.useState(false)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  return (
    <Toolbar variant='dense'>
      {!props.offset &&
        <>
          <SearchBar toggleExpanded={() => toggleExpanded} expanded={expanded} />
          <Drawer expanded={expanded} {...props} />
        </>}
    </Toolbar>
  )
}
