import React from 'react'

import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Typography
} from '@mui/material'

import {
  DeleteForever as DestroyIcon,
  MoneyOff as NoComercialIcon
} from '@mui/icons-material'

import * as tesoreria from '@grupomarea/abrela-tesoreria'

import Loading from '../Abc/Loading'

import {
  CaraNormal as CaraNormalIcon,
  CaraTriste as CaraTristeIcon
} from '../Actividad/ActividadItemBadge'

export const VIEW_CHECKLIST = 'VIEW_CHECKLIST'
export const VIEW_OPS_TRASH = 'VIEW_OPS_TRASH'
export const VIEW_LOADING = 'LOADING'

export default function DialogMore (props) {
  const { state, setState, onClose, ...other } = props

  const [checked, setChecked] = React.useState(state?.checked || [])

  React.useEffect(() => {
    if (!state && checked.length) setChecked([])
  }, [state, checked])

  // HOOKS END HERE
  if (!state) return state

  const toggleHandler = (vo) => () => {
    const index = checked.indexOf(vo)
    const result = checked.slice(0)

    if (index === -1) {
      result.push(vo)
    } else {
      result.splice(index, 1)
    }

    setChecked(result)
  }

  const setView = (view) => setState({ ...state, checked, view })

  const id = props.id || Math.random()
  const idTitle = 'dialog-title-' + id
  const idContent = 'dialog-content-' + id

  const actionHandler = (action) => (event) => {
    onClose({ ...state, action, checked })
  }

  let title, textContent, listContent

  const isLoading = state.view === VIEW_LOADING

  switch (state.view) {
    case VIEW_LOADING:
      textContent = 'Trabajando...'
      textContent = 'Se están realizando las acciones solicitadas'
      listContent = []
      break
    case VIEW_OPS_TRASH:
      title = 'Eliminar actividades'
      textContent = 'Qué quieres hacer con las actividades?'
      listContent = [
        {
          color: 'var(--info)',
          avatar: <CaraNormalIcon fontSize='default' />,
          action: 'ABIERTA',
          primary: 'No facturar porque no están hechas',
          secondary: 'Se actualizarán con el estado establecido a pendiente'
        },
        {
          color: 'var(--warn)',
          avatar: <NoComercialIcon fontSize='default' />,
          action: 'PRECIO0',
          primary: 'No facturar porque no son comerciales',
          secondary: 'Se actualizarán con el precio establecido a 0'
        },
        {
          color: 'var(--fail)',
          avatar: <CaraTristeIcon fontSize='default' />,
          action: 'ANULADA',
          primary: 'No facturar porque no se van a hacer',
          secondary: 'Se actualizarán con el estado establecido a cancelada'
        },
        {
          color: 'var(--fail)',
          avatar: <DestroyIcon fontSize='default' />,
          action: 'DESTROY',
          primary: 'Eliminarlas definitivamente',
          secondary: 'Serán destruídas sin posibilidad de recuperarlas'
        }
      ].map((item, idx) =>
        <ListItem
          key={idx}
          dense
          button
          // disableGutters
          onClick={actionHandler(item.action)}
        >
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: item.color }}>
              {item.avatar}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={item.primary} secondary={item.secondary} />
        </ListItem>
      )
      break
    case VIEW_CHECKLIST:
    default:
      title = 'Seleccionar actividades'
      textContent = 'Selecciona una o varias actividades'
      listContent = state.actividades.map((vo, idx) => {
        const labelId = `check-${idx}-label`

        return (
          <ListItem
            key={idx}
            role={undefined}
            dense
            button
            alignItems='flex-start'
            onClick={toggleHandler(vo)}
          >
            <ListItemIcon>
              <Checkbox
                edge='start'
                checked={checked.indexOf(vo) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText
              id={labelId}
              primary={vo.concepto?.nombre || '(Sin concepto)'}
              secondary={
                <>
                  {vo.descripcion}
                  {vo.descripcion && ' - '}
                  <Typography
                    component='span'
                    variant='body2'
                    style={{ display: 'inline' }}
                    color='textPrimary'
                  >
                    {tesoreria.formatEUR(tesoreria.importe(vo))}
                  </Typography>
                </>
              }
            />
            <ListItemAvatar>
              <Avatar>
                {vo.cantidad}
              </Avatar>
            </ListItemAvatar>
          </ListItem>
        )
      })
  }

  return (
    <Dialog
      data-cy='dialog-actividades-factura'
      open
      fullWidth
      maxWidth='md'
      onBackdropClick={() => onClose()}
      aria-labelledby={idTitle}
      aria-describedby={idContent}
      onClose={() => onClose()}
      {...other}
    >
      <DialogTitle id={idTitle}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id={idContent}>{textContent}</DialogContentText>
        {isLoading ? <Loading /> : <List>{listContent}</List>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose()}
          color='secondary'
          disabled={isLoading}
        >
          Cancelar
        </Button>
        {
          (!state.view || state.view === VIEW_CHECKLIST) &&
            <Button
              color='secondary'
              disabled={checked.length < 1 || isLoading}
              onClick={() => setView(VIEW_OPS_TRASH)}
            >
              Eliminar
            </Button>
        }
      </DialogActions>
    </Dialog>
  )
}
