import React from 'react'

import { contacto as model } from '../../../service/empresas/model'

import DialogContacto from './DialogContacto'
import Field from '../FormUtils/Field'
import { GridItemHOC } from '../FormUtils/Grid'

import './WidgetModales.scss'

const defaults = model.defaults

/**
 * La intención es un widget reusable en cualquier formulario
 * El datalist necesario se obtine directamente a la store de redux
 *
 * El segundo intento es crear un control completamente customizado,
 * que para seleccionar se basa en un diálogo modal.
 *
 * Para la estructura me basé en el código original del <TextField>,
 * y emplearé <FormControl> y relacionados.
 *
 * - https://github.com/mui-org/material-ui/blob/v4.x/packages/material-ui/src/TextField/TextField.js#L155
 */
function WidgetContacto (props) {
  const {
    className,
    margin = 'none',
    fullWidth = true,
    // Controla si se muestra abierto por defecto
    open = false,
    // Activa/Desactiva el modo Razón Social
    razon = false,
    // Impide cambiar de contacto si se establece a true
    locked = false,
    // Propiedades vía state mapper
    options,
    // Propiedades comunes
    id = `widget-${model.single}-` + Math.random(),
    name = 'contacto',
    label = props.label || model.single,
    ComponentClass = 'WidgetContacto',
    value = { ...defaults },
    error = false,
    required = true,
    helperText, //  = 'Texto ayuda ejemplo',
    placeholder,
    // Eventos e interacción
    onChange,
    onCancel,
    tabIndex,
    // Extensión
    inputProps = {},
    // El resto de props se le pasan al <Field>
    ...other
  } = props

  // console.error(options)
  // Estado del diálogo
  const [dialog, setDialog] = React.useState(null)
  const resetDialogState = (extra = { open: false }) =>
    setDialog({ ...(value || {}), ...extra })

  React.useEffect(() => {
    // console.warn('Efecto detector open', open, dialog)
    setDialog(open && setDialog(value || { ...defaults }))
  }, [setDialog, open, value])

  // console.log('Render WidgetContacto', { open, dialog })

  const handleOpen = (open || razon)
    ? undefined
    : event => setDialog(prev => ({ ...prev, open: true }))

  const handleChange = (event) => {
    setDialog(prev => ({ ...prev, alias: event.target.value }))
  }
  const handleChangeDialog = (contacto) => {
    // setDialog(close ? null : contacto)
    onChange(contacto)
  }
  const handleChangeField = razon
    ? (event) => onChange({ ...value, razon: event.target.value })
    : handleChange

  return (
    <>
      <Field
        id={id}
        name={name}
        label={label}
        margin={margin}
        fullWidth={fullWidth}
        value={
          value ? (razon ? value.razon : value.alias) || '' : ''
        }
        className={ComponentClass + (className ? ` ${className}` : '')}
        required={required}
        error={error}
        onChange={handleChangeField}
        onClick={handleOpen}
        placeholder={placeholder}
        inputProps={{ tabIndex, autoComplete: 'off', ...inputProps }}
        helperText={helperText}
        {...other}
      />
      <DialogContacto
        open={open}
        state={dialog}
        name={`dialog-${name}`}
        options={options}
        ComponentClass={ComponentClass}
        onInput={handleChange} // escucha en el input de fuzzy search
        onChange={handleChangeDialog} // escucha la selección de un contacto
        onCancel={() => { onCancel(); resetDialogState() }}
        razon={razon}
        locked={locked}
        setState={setDialog}
        defaults={defaults}
      />
    </>
  )
}

export default (GridItemHOC(WidgetContacto))
