import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Alert from '../../utils/notify'

export default function ResetPasswd (props) {
  const navigate = useNavigate()
  const [inputValue, setinputValue] = useState({})

  const handlerInput = (ev) => {
    setinputValue({ ...inputValue, [ev.target.name]: ev.target.value })
  }

  const handlerLogin = async (ev) => {
    ev.preventDefault()
    try {
      throw new Error('This components needs refactor')
      // const { data: { message } } = await upData('/auth/reset', inputValue)
      /*
      Alert({
        message: message,
        type: 'success'
      })
      */
    } catch (error) {
      if (error.code >= 400) {
        Alert({
          message: error.status,
          type: 'warning'
        })
        return
      }
      Alert({
        message: error.message,
        type: 'danger'
      })
    }
  }

  const handleBack = () => navigate(-1)

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Paper
        sx={{
          marginTop: '8rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <form onSubmit={handlerLogin}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='username'
            autoComplete='email'
            onChange={handlerInput}
            autoFocus
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item>
              <Button onClick={handleBack}>
                Volver
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  )
}
