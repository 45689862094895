import {
  startOfToday
} from 'date-fns'

import {
  startOfWeek,
  endOfWeek
} from '../../utils/fecha'

import { ListAction } from '../const'

export function initAgendaFilter (empresa) {
  const hoy = startOfToday()

  // console.warn('initFiltro Agenda', empresa)

  return {
    type: ListAction.ADD_FILTER,
    payload: {
      order: 2,
      filter: {
        label: 'Hoy',
        date: true,
        view: empresa.default_view,
        show: true,
        desde: startOfWeek(hoy),
        hasta: endOfWeek(hoy)
      }
    }
  }
}
