function assert (probe, message) {
  if (!probe) throw new TypeError(message || 'Expected probe to be true')
}

/**
 * Este módulo contiene diversas utilidades, que fundamentalmente se clasifican
 * en los siguientes tipos:
 *
 * - Utilidades de cálculo
 * - Utilidades de formateo numérico
 *
 * Las utilidades de formateo numérico se declaran teniendo en cuenta el
 * contexto runtime de javascript, para que funcionen en cualquier condición,
 * tanto _server-side_ como _browser-side_, independientemente de si el entorno
 * de ejecución tiene o no instalados los _locales_ `es-ES`
 * @module tesoreria
 */
exports = module.exports = {}

/**
 * Formatea un número dado de acuerdo a las normas habituales en España.
 * Se emplea el punto como carácter separador de grupos, y la coma como
 * carácter separador de la parte decimal.
 *
 * El formato elegido es el clásico, empleando el punto como separador, y no
 * aquel que [especifica la RAE](https://www.rae.es/dpd/n%C3%BAmeros)
 *
 * A estos efectos, en los entornos con soporte `Intl`, se emplea el formato
 * Alemán, que encaja con el efecto que se persigue.
 *
 * - https://stackoverflow.com/a/57628229/1894803
 * - https://stackoverflow.com/a/58430717/1894803
 *
 * Si se desea omitir por completo el número prefijado de dígitos decimales,
 * usar `null` como segundo argumento.
 * @function formatFloat
 * @param {Number} number - Número a formatear
 * @param {Number|null} [places=2] - Número fijo de dígitos decimales a emplear
 * @returns {String}
 * @static
 * @example
 * formatFloat(0) // => "0,00"
 * formatFloat(29.99) // => "29,99"
 * formatFloat(29.99, 0) // => "30"
 * formatFloat(1000) // => "1000,00"
 * formatFloat(12345) // => "12.345,00"
 * formatFloat(0.12345, null) // => "0,12345"
 */

/**
 * Formatea un número dado como importe en moneda
 * @function formatEUR
 * @param {Number} number - Número a formatear
 * @returns {String}
 * @static
 * @example
 * formatEUR(0) // => "0,00 €"
 * formatEUR(29.99) // => "29,99 €"
 * formatEUR(1000) // => "1.000,00 €"
 */

/**
 * Formatea un número dado como porcentaje con decimales
 * @function format100
 * @param {Number} number - Número a formatear
 * @param {Number|null} [places=2] - Número fijo de dígitos decimales a emplear
 * @returns {String}
 * @static
 * @example
 * format100(0) // => "0,00 %"
 * format100(2 / 3) // => "0,67 %"
 * format100(90.234) // => "90,23 %"
 * @todo comprobar cómo se comporta el redondeo
 */

/**
 * Formatea un número dado como porcentaje sin decimales
 * @function formatIVA
 * @param {Number} number - Número a formatear
 * @returns {String}
 * @static
 * @example
 * format100(0) // => "0 %"
 * format100(21) // => "21 %"
 * @todo comprobar cómo se comporta el redondeo
 */

/**
 * Espacio _non-breaking_ en UTF-16
 * `'\xa0'`
 */
const WS = '\xa0'

/**
 * Separador de grupos de dígitos para números grandes
 * `'.'`
 */
const GS = '.'

/**
 * Separador de fracción (decimales)
 * `'.'`
 */
const DS = ','

const DISABLE_ICU = Boolean(
  typeof process !== 'undefined' && process.env && process.env.DISABLE_ICU
)

// <https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat>
if (Intl.NumberFormat.supportedLocalesOf('de-DE').length && !DISABLE_ICU) {
  // O intérprete ten soporte
  const esES = {
    [null]: new Intl.NumberFormat('de-DE', {
      maximumFractionDigits: 20 // no existe soporte para más de 20
    }),
    2: new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }
  const EURO = new Intl.NumberFormat('de-DE', {
    style: 'currency', currency: 'EUR', useGrouping: true
  })

  exports.formatFloat = (number, places = 2) => {
    if (!esES[places]) {
      esES[places] = new Intl.NumberFormat('es-ES', {
        minimumFractionDigits: places,
        maximumFractionDigits: places
      })
    }
    return esES[places].format(
      places !== null ? number.toFixed(places) : number
    )
  }
  exports.formatEUR = (number) => EURO.format(number)
  exports.format100 = (...args) => exports.formatFloat(...args) + `${WS}%`
  exports.formatIVA = (number) => exports.format100(number, 0)
} else {
  // O intérprete non ten soporte
  // ver https://stackoverflow.com/a/16233919/1894803 para fixes (node)
  exports.formatFloat = (number, places = 2) => {
    const [left, right] = `${
      places !== null ? number.toFixed(places) : number
    }`.split('.')
    return (
      left.length > 3
        ? [...left].reduceRight(
            (acc, curr, idx, all) =>
              `${(all.length - idx) % 3 ? '' : GS}${curr}${acc}`,
            ''
          )
        : left
    ) + (right ? `${DS}${right}` : '')
  }
  exports.formatEUR = (number) => `${exports.formatFloat(number)}${WS}€`
  exports.format100 = (...arg) => `${exports.formatFloat(...arg)}${WS}%`
  exports.formatIVA = (number) => `${exports.formatFloat(number, 0)}${WS}%`
}

/**
 * Formatea un número dado mediante formatFloat con la unidad especificada
 * @function formatUds
 * @param {Number} number - Número a formatear
 * @param {String} unit - Unidad a emplear
 * @param {Number|null} [places=null] - Número de decimales a emplear
 * @returns {String}
 * @static
 * @example
 * formatUds(0) // => "0 U"
 * formatEUR(29.99) // => "29,99 U"
 * formatEUR(1000, 'm', 2) // => "1.000,00 m"
 */
exports.formatUds = (number, unit = 'U', places = null) => {
  return exports.formatFloat(number, places) + WS + `${unit}`
}

/**
 * Objeto que contiene propiedades relativas al valor de una transacción.
 *
 * El descuento siempre se indica en porcentaje. Para calcular el descuento en
 * moneda se puede usar {@link module:tesoreria.descuento}
 *
 * Se pueden generar manualmente usando {@link module:tesoreria.Valor},
 * pero la mayoría de utilidades de cálculo ya lo hacen internamente.
 *
 * @typedef {Object} Valor
 * @property {Number} precio - Precio unitario
 * @property {Number} cantidad - Cantidad de unidades
 * @property {Number} descuento - Descuento (%) sobre el precio unitario
 * @example
 *     const vo = { cantidad: 1, precio: 29.99, descuento: 0 }
 * @inner
 */

/**
 * Devuelve un objeto {@link module:tesoreria~Valor} construído con los `datos`
 * de entrada dados, asegurándose de la validez de los mismos.
 *
 * Habitualmente se utiliza un _Value Object (vo)_ de Actividad u Operación
 * como argumento.
 * @param {Object} datos
 * @param datos.precio - Precio unitario
 * @param datos.cantidad - Cantidad de unidades
 * @param datos.descuento - Descuento (%) sobre el precio unitario
 * @returns {module:tesoreria~Valor}
 */
exports.Valor = (datos) => {
  if (typeof datos !== 'object' || datos === null) {
    throw new TypeError('Los datos de entrada deben ser un objeto no nulo')
  }
  const pure = {}
  for (const param of ['precio', 'cantidad', 'descuento']) {
    const n = datos[param]

    pure[param] = (n === '-') ? 0 : Number(n)

    if (isNaN(pure[param])) {
      throw new TypeError(`datos.${param} no puede convertirse a un número`)
    }
  }
  return pure
}

/**
 * Calcula el importe para un precio, cantidad, y descuento en porcentaje dados.
 * @param {Object} valor - valor a pasar a {@link module:tesoreria.Valor}
 * @param {boolean} percent - Si es true se calcula el descuento en procentaje, si no en moneda
 * @returns {Number}
 * @example
 *     importe({ cantidad: 1.5, precio: 12, descuento: 50 })
 *     // => 9
 */
exports.importe = (valor = {}, percent = true) => {
  // desestructuramos y validamos las variables que necesitamos
  const { precio, cantidad, descuento } = exports.Valor(valor)

  // devolvemos el cálculo
  return percent
    ? cantidad * (precio - precio * descuento / 100)
    : (cantidad * precio) - descuento
}

/**
 * Calcula el descuento en moneda para un precio, cantidad y descuento (en porcentaje o moneda) dados
 * @param {Object} valor - valor a pasar a {@link module:tesoreria.Valor}
 * @param {boolean} percent - Si es true se utiliza el descuento en procentaje, si no en moneda
 * @returns {Number}
 * @example
 *     descuento({ cantidad: 1, precio: 15, descuento: 10 })
 *     // => 5
 *
 *     descuento({ cantidad: 1, precio: 10, descuento: 15 }, true)
 *     // => 1.5
 */
exports.descuento = (valor = {}, percent = false) => {
  // desestructuramos y validamos las variables que necesitamos
  const { precio, cantidad, descuento } = exports.Valor(valor)

  // devolvemos el cálculo
  return percent
    ? cantidad * precio * descuento / 100
    : cantidad * descuento
}

/**
 * Calcula el descuento en moneda para un precio, cantidad, y descuento en porcentaje dados.
 * @param {Object} valor - valor a pasar a {@link module:tesoreria.Valor}
 * @returns {Number}
 * @example
 *     importe({ cantidad: 1.5, precio: 12, descuento: 50 })
 *     // => 9
 */

exports.descuentoMoneda = (valor = {}) => {
  return exports.descuento(valor, true)
}

/**
 * Calcula los totales de una factura.
 *
 * Cada elemento del `Array` `lista` será convertido en un objeto valor
 * ({@link module:tesoreria~Valor}) usando {@link module:tesoreria.Valor} al
 * calcular la Base Imponible de la factura
 *
 * @param {Array<Object>} lista - Listado de transacciones
 * @param {Object} tasas - Tasas de impuestos a aplicar
 * @param tasas.iva - Porcentaje de IVA a repercutir
 * @param tasas.irpf - Porcentaje de IRPF a retener
 * @returns {module:tesoreria~TotalFactura}
 * @example
 * const vo = { cantidad: 1, precio: 100, descuento: 0 }
 *
 * factura([vo], { iva: 21, irpf: 0 })
 * // => { base: 100, iva: 21, irpf: 0, total: 121 }
 *
 * factura([vo, vo], { iva: 21, irpf: 15 })
 * // => { base: 200, iva: 42, irpf: 30, total: 212 }
 *
 * factura([vo, vo, vo], { iva: 0, irpf: 0 })
 * // => { base: 300, iva: 0, irpf: 0, total: 300 }
 */
exports.factura = (datos = [], tasas = {}) => {
  // comprobamos que los datos son un Array
  assert(Array.isArray(datos), 'datos debe ser Array')

  // comprobamos que las tasas son un Objeto con las propiedades iva e irpf
  assert(typeof tasas === 'object' && tasas !== null, 'tasas debe ser Object')
  assert(typeof tasas.iva === 'number', 'tasas.iva debe ser Number')
  assert(typeof tasas.irpf === 'number', 'tasas.irpf debe ser Number')

  // calculamos la base imponible reduciendo el listado a un número
  const base = datos.reduce(
    (base, objeto) => base + exports.importe(objeto),
    0
  )

  // calculamos los impuestos
  const iva = base * tasas.iva / 100
  const irpf = base * tasas.irpf / 100

  // calculamos el total y devolvemos el resultado
  return {
    base,
    tasa_iva: tasas.iva,
    iva,
    tasa_irpf: tasas.irpf,
    irpf,
    total: base + iva - irpf
  }
}

/**
 * Objeto que contiene los importes totales calculados de una Factura
 * @typedef {Object} TotalFactura
 * @property {Number} base - Base imponible (€)
 * @property {Number} tasa_iva - Tasa de IVA (%)
 * @property {Number} iva - Importe IVA (€)
 * @property {Number} tasa_irpf - Retención de IRPF (%)
 * @property {Number} irpf - Importe Retención (€)
 * @property {Number} total - Importe total de la factura (€)
 * @inner
 */

/**
 * Utilidad de extración de datos (EntidadFacturacion)
 * @ignore
 */
exports.entidad = (vo = null, opts = {}) => {
  opts = {
    prefix: '',
    ...opts
  }

  return [
    'razon_social',
    'nif',
    'domicilio',
    'codigo_postal',
    'ayuntamiento',
    'provincia'
  ].reduce((result, field) => {
    let value = null
    switch (field) {
      case 'ayuntamiento':
      case 'provincia':
        value = vo[`${field}$nombre`]
        break
      default:
        value = vo[field]
    }
    // TODO comprobar que existen los datos de vo[field]?
    return {
      ...result,
      [`${opts.prefix}${field}`]: value
    }
  }, {})
}

/* vim: set expandtab: */
/* vim: set filetype=javascript ts=2 shiftwidth=2: */
