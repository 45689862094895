import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import Avatar from '@mui/material/Avatar'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import {
  Call as CallIcon,
  MailOutline as EmailIcon
} from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import GroupIcon from '@mui/icons-material/Group'
import { Divider } from '@mui/material'

import { useFiltersControl } from '../../../utils/hooks'
import { selectorEmpresaBase } from '../../../store/selector'

const selectEmpresa = selectorEmpresaBase()

export default function DialogContacto (props) {
  const {
    contacto,
    facturada,
    open,
    setOpenContacto,
    mailFactory,
    openWidget
  } = props

  const { addFilter } = useFiltersControl()
  const navigate = useNavigate()
  const empresa = useSelector(selectEmpresa)

  const handleClose = () => {
    setOpenContacto(prev => ({ ...prev, open: false }))
  }

  const handleCall = (event) => window.open(`tel:+34${contacto.telefono}`, '_self')
  const handleWhatsApp = (event) => window.open(`https://wa.me/34${contacto.telefono}`, '_blank')
  const handleMail = (event) => {
    if (typeof mailFactory !== 'function') {
      return window.open(`mailto:${contacto.email_general}`, '_self')
    }
    window.open(`mailto:${contacto.email_general}?${
      Object.entries(mailFactory(event))
        .map(([key, val]) => `${key}=` + encodeURIComponent(val)).join('&')
    }`, '_self')
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className='dialog-contacto-container'
      data-cy='dialog-contacto'
    >
      <DialogTitle className='dialog-title'>
        <Avatar
          style={{
            backgroundColor: contacto.fk_entidad ? 'var(--done)' : 'var(--warn)'
          }}
        >
          {contacto.alias && contacto.alias.slice(0, 1)}
        </Avatar>
        {contacto.alias}
      </DialogTitle>
      <DialogContent
        className='DialogContacto'
        data-cy='dialog-contacto'
      >
        <List>
          <ListItem className='contact-group'>
            <Button onClick={(e) => {
              e.stopPropagation()
              handleCall(contacto.telefono)
              handleClose()
            }}
            >
              <Avatar
                style={{
                  backgroundColor: 'var(--info-light)'
                }}
              >
                <CallIcon />
              </Avatar>
            </Button>
            <Button onClick={(e) => {
              e.stopPropagation()
              handleWhatsApp(contacto.telefono)
              handleClose()
            }}
            >
              <Avatar
                style={{
                  backgroundColor: '#25D366'
                }}
              >
                <WhatsAppIcon />
              </Avatar>
            </Button>
            <Button onClick={(e) => {
              e.stopPropagation()
              handleMail(contacto.email_general)
              handleClose()
            }}
            >
              <Avatar
                style={{
                  backgroundColor: 'hsl(7, 57%, 46%)'
                }}
              >
                <EmailIcon />
              </Avatar>
            </Button>
          </ListItem>
          <Divider />
          <ListItemButton
            onClick={(e) => {
              e.stopPropagation()
              navigate(
                `/${empresa.fk_empresa}/contactos/${contacto.id_contacto}`,
                { replace: true }
              )
              addFilter(contacto, 1)
            }}
            data-cy='navigate-contacto'
          >
            <ListItemAvatar>
              <Avatar>
                <EditIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={'Editar los datos de ' + contacto.alias} />
          </ListItemButton>
          {!facturada && (
            <ListItemButton
              onClick={() => {
                openWidget()
                handleClose()
              }}
              data-cy='change-contacto'
            >
              <ListItemAvatar>
                <Avatar>
                  <GroupIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary='Cambiar contacto' />
            </ListItemButton>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => {
          e.stopPropagation()
          handleClose()
        }}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
