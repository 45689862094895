import Empresa from '../service/empresas/model/Empresa'
import store from '../store'
import { selectorEmpresa } from '../store/selector'
// solucion temporal hasta modificar la base de datos y la API
import { createTimeFilters } from '../utils/hooks/useFiltersControl'

const selectEmpresa = selectorEmpresa()

const TextSearchRe = (text) => new RegExp(
  'aáÁ:eéÉ:iíÍ:oóÓ:uúÚ'.split(':').reduce(
    (acc, cur) => {
      const replace = `[${cur}]`
      return acc.replace(new RegExp(replace, 'g'), replace)
    },
    text.replace('.', '\\.')
  ),
  'i' // case-insensitive
)

export async function searchData (text, searchParams, options = [], filters = []) {
  const timeFilters = createTimeFilters()
  const opts = []

  const empresa = selectEmpresa(store.getState())

  const searchRe = TextSearchRe(text.trim())

  const result = await Empresa.search(
    { [Empresa.pk]: empresa.id },
    text,
    searchParams
  )

  if (searchParams.contactos) {
    // console.log(response.contactos)
    opts.push(...(
      result.contactos.filter(vo =>
        (searchRe.test(vo.alias) || searchRe.test(vo.razon)) &&
        !filters.includes(vo) &&
        !options.includes(vo)
      )
    ))
  }
  if (searchParams.conceptos) {
    opts.push(...(
      result.conceptos.filter(vo =>
        searchRe.test(vo.nombre) &&
        !filters.includes(vo) &&
        !options.includes(vo)
      )
    ))
  }
  if (searchParams.tiempo) {
    opts.push(...timeFilters.filter(vo => searchRe.test(vo.label)))
  }

  return opts
}
