import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

// ver https://material-ui.com/es/customization/theming/
import { createTheme, ThemeProvider } from '@mui/material/styles'

// ver https://material-ui-pickers.dev/getting-started/installation
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { esES } from '@mui/x-date-pickers/locales'
import esLocale from 'date-fns/locale/es'

import App from './App'
import store from './store'
// import reportWebVitals from './reportWebVitals'

import './index.css'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 340,
      md: 650,
      lg: 980,
      xl: 1280
    }
  }
})

const container = document.getElementById('root')
const root = createRoot(container)
// Dada la cantidad de providers, queda más ordenado emplear una función

root.render(<Root />)

function Root () {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
          localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
        >
          <App />
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  )
}

// ReactDOM.render(<Root />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
