import React from 'react'

import { useNavigate } from 'react-router-dom'

import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'

import {
  useCalendarFilters,
  useLocationDetails
} from '../../../../utils/hooks'

export default function QuickFilters (props) {
  const { fkEmpresa } = props
  const detalles = useLocationDetails()
  const [actividad, setActividad] = React.useState([])
  const navigate = useNavigate()
  // const [factura, setFactura] = React.useState([])
  const {
    monthView,
    weekView,
    threeDayView,
    dayView,
    hoy,
    view
  } = useCalendarFilters()

  const handleChangeActividades = (string) => {
    setActividad(string)
    switch (string) {
      case 'abiertas':
        break
      case 'finalizadas':
        break
      case 'atrasadas':
        break
      default:
        break
    }
  }

  // console.log(actividad)

  switch (detalles.order) {
    case 1:
      return <span />
    case 2:
      return (
        <Stack direction='row' spacing={1} data-cy='quick-filters' className='chip-filter'>
          <Chip
            label='Mes'
            onClick={() => monthView(new Date())}
            color={view === 'mes' ? 'primary' : 'default'}
          />
          <Chip
            label='Semana'
            onClick={() => weekView(new Date())}
            color={view === 'semana' ? 'primary' : 'default'}
          />
          <Chip
            label='Tres Días'
            onClick={() => threeDayView(new Date())}
            color={view === 'tresDias' ? 'primary' : 'default'}
          />
          <Chip
            label='Día'
            onClick={() => dayView(new Date())}
            color={view === 'dia' ? 'primary' : 'default'}
          />
          <Chip label='Hoy' onClick={hoy} />
        </Stack>
      )
    case 3:
      return (
        <Stack direction='row' spacing={1} data-cy='quick-filters' className='chip-filter'>
          <Chip
            label='Abiertas'
            value='abiertas'
            onClick={() => {
              handleChangeActividades('abiertas')
            }}
            color={actividad === 'abiertas' ? 'primary' : 'default'}
          />
          <Chip
            label='Finalizadas'
            onClick={() => {
              handleChangeActividades('finalizadas')
            }}
            color={actividad === 'finalizadas' ? 'success' : 'default'}
          />
          <Chip
            label='Atrasadas'
            onClick={() => {
              handleChangeActividades('atrasadas')
            }}
            color={actividad === 'atrasadas' ? 'warning' : 'default'}
          />
        </Stack>
      )
    case 4:
      return (
        <Stack direction='row' spacing={1} data-cy='quick-filters' className='chip-filter'>
          <Chip
            label='Borradores'
            onClick={() =>
              navigate(
                `${fkEmpresa}/facturacion/borradores`,
                { replace: true }
              )}
            color={detalles.subModel === 'borradores' ? 'primary' : 'default'}
          />
          <Chip
            label='Emitidas'
            onClick={() =>
              navigate(
                `${fkEmpresa}/facturacion/emitidas`,
                { replace: true }
              )}
            color={detalles.subModel === 'emitidas' ? 'primary' : 'default'}
          />
          <Chip
            label='Presupuestos'
            onClick={() => console.log('esto mostraría presupuestos')}
            // color={view === 'tresDias' ? 'success' : 'default'}
          />
        </Stack>
      )
    case 5:
      return null
    default:
      break
  }
}
