import React from 'react'

import { useSelector } from 'react-redux'
import { selectorLockHistory } from '../../../store/selector'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { useMountDetector } from '../../../utils/hooks/useMountDetector'

const selectLockHistory = selectorLockHistory()

export const DIALOG_ACCEPT = true
export const DIALOG_DOSAVE = true
export const DIALOG_FORGET = false
export const DIALOG_CANCEL = null

export function DryDialog (props) {
  const { open, onClose, title, content, children, ...other } = props

  const id = props.id || Math.random()
  const idTitle = 'dialog-title-' + id
  const idContent = 'dialog-content-' + id

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={idTitle}
      aria-describedby={idContent}
      {...other}
    >
      <DialogTitle id={idTitle}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id={idContent}>{content}</DialogContentText>
        {other.list || ''}
      </DialogContent>
      <DialogActions>
        {children}
      </DialogActions>
    </Dialog>
  )
}

export function DialogSelectItem ({ onClose, ...props }) {
  return (
    <DryDialog
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onClose(DIALOG_CANCEL)
        }
      }}
      title='Aviso'
      content={[
        'Estabas modificando una tarjeta. Para seleccionar otra debes',
        'guardar o descartar los cambios pendientes.'
      ].join(' ')}
      {...props}
    >
      <Button autoFocus onClick={() => onClose(DIALOG_DOSAVE)} color='primary'>
        Guardar
      </Button>
      <Button onClick={() => onClose(DIALOG_FORGET)} color='secondary'>
        Descartar
      </Button>
      <Button onClick={() => onClose(DIALOG_CANCEL)}>Cancelar</Button>
    </DryDialog>
  )
}

export function DialogDestroyItem ({ onClose, ...props }) {
  return (
    <DryDialog
      onClose={() => onClose(DIALOG_CANCEL)}
      title='Aviso'
      content={[
        'Destruír este elemento eliminará la información permanentemente,',
        'y esta acción no se puede deshacer.'
      ].join(' ')}
      {...props}
    >
      <Button onClick={() => onClose(DIALOG_ACCEPT)} color='secondary'>
        Destruír
      </Button>
      <Button onClick={() => onClose(DIALOG_CANCEL)}>Cancelar</Button>
    </DryDialog>
  )
}

export function DialogCantDestroy ({ onClose, ...props }) {
  return (
    <DryDialog
      onClose={() => onClose(DIALOG_ACCEPT)}
      title='Acción imposible'
      content={[
        'No se puede destruír este elemento porque existe información',
        'relacionada que debe ser destruída primero.'
      ].join(' ')}
      {...props}
    >
      <Button onClick={() => onClose(DIALOG_ACCEPT)}>Aceptar</Button>
    </DryDialog>
  )
}

export function useDestroyDialog (destroyItem, destroyDone) {
  const [onConfirm, setOnConfirm] = React.useState(null)
  const [onCantMsg, setOnCantMsg] = React.useState(null)

  const layout = (
    <>
      <DialogDestroyItem
        open={typeof onConfirm === 'function'}
        onClose={onConfirm}
      />
      <DialogCantDestroy
        open={typeof onCantMsg === 'function'}
        onClose={onCantMsg}
      />
    </>
  )

  const mountRef = useMountDetector()

  const handleDestroy = React.useCallback(
    // Para usar una función como estado es necesario el flow funcional
    (id) => setOnConfirm(() => (decision) => {
      switch (decision) {
        case DIALOG_CANCEL:
          return setOnConfirm(null)
        case DIALOG_ACCEPT:
          return destroyItem()
            .then(response => {
              switch (response.status) {
                case 200:
                  destroyDone(response)
                  break
                case 422:
                  setOnCantMsg(prev => () => setOnCantMsg(null))
                  break
                default:
                  console.warn('Item was not destroyed', response.status)
              }
              return response
            })
            .catch((err) => console.error(err))
            .finally(() => mountRef.current && setOnConfirm(null))
        default:
          console.warn('dialog closed', { decision })
          console.error(new Error('Decisión no reconocible'))
      }
    }),
    [destroyItem, destroyDone, mountRef]
  )

  return [layout, handleDestroy]
}

export function useBlockDialog (conf = {}) {
  const { active, onSave, onDiscard, handleBlock } = conf

  const [blockDialog, setBlockDialog] = React.useState(null)

  const lockHistory = useSelector(selectLockHistory)

  // active && console.warn('HOOK blocker', { blockDialog, lockHistory })

  const layout = (
    <DialogSelectItem
      open={typeof blockDialog === 'function'}
      onClose={blockDialog}
    />
  )

  const mountRef = useMountDetector()

  // Quizá necesitamos desbloquer sólo cuando se desmonta, sin depender de otros cambios
  // React.useEffect(() => unblock, [unblock])

  React.useEffect(() => {
    if (!active || !lockHistory) {
      // active && console.warn('No aplicar blockDialog', { active, lockHistory })
      return setBlockDialog(null)
    }

    setBlockDialog(prevState => {
      if (typeof prevState === 'function') return prevState

      return (decision) => {
        switch (decision) {
          case DIALOG_CANCEL:
            // console.warn('Cerrar dialogo y bloquear de nuevo')
            handleBlock(true)
            setBlockDialog(null)
            break
          case DIALOG_FORGET:
            setBlockDialog(null)
            onDiscard()
            handleBlock(false)
            break
          case DIALOG_DOSAVE:
            onSave({ pickNext: false })
              .then(response => {
                switch (response.status) {
                  case 200:
                  case 201:
                    handleBlock(false)
                    break
                  default:
                    handleBlock(true)
                }
              })
              .finally(() => mountRef.current && setBlockDialog(null))
            return
          default:
            console.warn('dialog closed', { decision })
            console.error(new Error('Decisión no reconocible'))
        }
      }
    })
  }, [active, lockHistory, setBlockDialog, handleBlock, onSave, onDiscard, mountRef])

  return [layout]
}
