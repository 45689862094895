import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import AddIcon from '@mui/icons-material/Add'

import { importe, formatFloat } from '@grupomarea/abrela-tesoreria'

// import Acordeon from '../Abc/Acordeon'
import { Field, FieldDateTime, DateTimeWidget, GridContainer } from '../Abc/FormUtils'

import WidgetConcepto from '../Abc/FieldSet/WidgetConcepto'
import DialogContacto from '../Abc/FieldSet/DialogContacto'
// import WidgetValor from '../Abc/FieldSet/WidgetValor'
import { Cantidad, Precio, Descuento, Importe } from '../Abc/FieldSet/Valor'

import TarjetaItemMenu from '../Abc/Tarjeta/TarjetaItemMenu'

import * as fecha from '../../utils/fecha'

import { BadgeEstado, BadgePrecio, Notas } from './ActividadItemBadge'

import {
  useHandlerActividadNueva
} from './ActividadItemUtils'

import { MainAction } from '../../store/const'
import { Avatar, AvatarGroup } from '@mui/material'

export default function ActividadItem (props) {
  const { vo, voRef, selected, errored, notOpenDate, changesRef } = props

  const { onBack, onForceClose, onSave, onDestroy } = props

  const { acordeon, handleToggleAcordeon } = props

  const { handleChangeEvent: handleChange, handleChangeObject } = props

  const { SHOW_CREATE } = MainAction

  const id = `actividad-${vo.id_actividad || 'nueva'}`

  const idTexto = 'texto-' + id
  const idValor = 'valor-' + id

  // ver https://stackoverflow.com/q/4782068/1894803
  const mailFactory = (event) => ({
    subject: [
      vo.concepto ? vo.concepto.nombre : 'Actividad',
      'del',
      vo.fecha_prevista
    ].join(' '),
    body: [`Hola ${vo.contacto.alias},`, '', ''].join('\n')
  })

  const [showEstado, setShowEstado] = React.useState(false)
  const [dateOpen, setDateOpen] = React.useState(false)
  const [contactoOpen, setContactoOpen] = React.useState({ open: false, opened: false })
  const [conceptoOpen, setConceptoOpen] = React.useState({ open: false, opened: false })

  const trampaConcepto = React.useCallback(() => {
    setConceptoOpen({ open: true, opened: false })
  }, [setConceptoOpen])

  const handleActividadNueva = useHandlerActividadNueva(
    vo,
    handleChangeObject,
    trampaConcepto
  )

  const handleShowEstado = (event) => {
    event.stopPropagation()
    return setShowEstado(true)
  }

  const handleAction = (action) => {
    switch (action) {
      case 'pass':
        handleChangeObject({ fecha_cerrada: fecha.input(new Date()), fecha_anulada: null })
        break
      case 'fail':
        handleChangeObject({ fecha_cerrada: null, fecha_anulada: fecha.input(new Date()) })
        break
      case 'open':
        handleChangeObject({ fecha_cerrada: null, fecha_anulada: null })
        break
      default:
        throw new TypeError(`Acción irreconocible: ${action}`)
    }
    // Guardar y hacer la acción a la vez
    onSave({ pickNext: true, force: true })
      .then((response) => {
        // onAction(action) // Ya no se necesita porque el onSave guarda automáticamente
        setShowEstado(false)
        if (vo.id_actividad === SHOW_CREATE) {
          voRef.current = response.item
        }
        // Cerrar dialogo estado y dialogo actividad
        // OJO: Quizá nos de problemas si provoca unmount del componente
        onForceClose()
      })
  }

  const handleChangeArr = (event) => {
    const { name, value } = event.target
    // console.warn(name, value)
    handleChangeObject({
      [name]: value,
      actividades: [{
        ...vo.actividades[0], [name]: value
      }]
    })
  }

  // selected && console.log('Render ActividadItemSimple', { vo, date: new Date().getTime() })

  return (
    <Grid container className='actividad-wrap'>
      <Grid item sm={11} md={11}>
        <Grid
          item
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            marginBottom: '1rem'
          }}
        >
          <Grid item sm={6} md={4}>
            <TarjetaItemMenu
              className='contacto-menu'
              contacto={vo.contacto}
              openWidget={() => setContactoOpen({ open: true, opened: true })}
              facturada={!!vo.fk_factura}
            />
          </Grid>
          <Collapse
            // timeout={{ appear: 500, exit: 100 }}
            in={selected}
            mountOnEnter
            unmountOnExit
            component={Grid}
            item
            sm={6}
            md={8}
          >
            <TarjetaItemMenu
              back={onBack}
              save={vo.fk_factura ? onBack : onSave}
              changed={changesRef.current}
              mailFactory={mailFactory}
            />
          </Collapse>
        </Grid>
        <form>
          <GridContainer sx={{ marginBottom: '8px' }}>
            <Grid
              item
              xs={selected ? 3 : 0}
              zeroMinWidth
              sx={{
                paddingLeft: selected ? '8px' : '0px !important',
                paddingTop: '8px'
              }}
            >
              <Collapse
                in={selected}
                orientation='horizontal'
                mountOnEnter
                unmountOnExit
              >
                <FieldDateTime
                  disabled={selected && !!vo.fk_factura}
                  className='fecha'
                  name='fecha_prevista'
                  label={(!selected && (vo.fecha_prevista !== null)) ? ' ' : 'Fecha prevista'}
                  onClick={() => !vo.fk_factura && setDateOpen(true)}
                  value={vo.fecha_prevista !== null && vo.fecha_prevista !== ''
                    ? fecha.toIso(vo.fecha_prevista)
                    : new Date()}
                  inputProps={{
                    readOnly: true
                  }}
                />
                <DateTimeWidget
                  open={dateOpen}
                  value={vo.fecha_prevista !== null && vo.fecha_prevista !== ''
                    ? fecha.combine(
                      vo.fecha_prevista,
                      vo.hora_prevista || fecha.getTime(new Date())
                    )
                    : new Date()}
                  onChange={(dateObj, date, time) => {
                    if (date === vo.fecha_prevista && time === vo.hora_prevista) return
                    handleChangeObject({
                      fecha_prevista: date,
                      hora_prevista: time
                    })
                  }}
                  onClose={() => {
                    setDateOpen(false)
                  }}
                />
              </Collapse>
            </Grid>
            {selected && (
              <DialogContacto
                label='Contacto'
                required
                name='contacto'
                open={
                  (selected &&
                  (!vo.contacto ||
                  !vo.contacto.alias ||
                  !vo.contacto.telefono) &&
                  !contactoOpen.opened &&
                  vo.id_actividad === SHOW_CREATE
                  ) || contactoOpen.open
                }
                value={vo.contacto}
                error={!!errored.contacto}
                onChange={
                  contacto => {
                    const changes = {
                      contacto,
                      fk_contacto: contacto ? contacto.id_contacto : null
                    }
                    if (!vo.concepto.id_concepto) {
                      changes.concepto = {} // TODO usar model.defaults
                      setConceptoOpen({ open: true, opened: false })
                    }
                    setContactoOpen({ open: false, opened: true })
                    handleChangeObject(changes)
                  }
                }
                onClose={() => setContactoOpen({ open: false, opened: true })}
                onCancel={() => {
                  vo.id_actividad === SHOW_CREATE && !vo.concepto.nombre
                    ? onForceClose()
                    : setContactoOpen({ open: false, opened: true })
                }}
                tabIndex={selected ? 0 : -1}
              />
            )}
            {
              !selected || vo.fk_factura
                ? (
                  <Field
                    disabled={selected && !!vo.fk_factura}
                    sm={6}
                    md={4}
                    label='Concepto'
                    name='concepto'
                    value={vo.concepto?.nombre}
                    className='WidgetConcepto'
                    InputProps={{
                      readOnly: (!!vo.fk_factura)
                    }}
                  />
                  )
                : (
                  <WidgetConcepto
                    label='Concepto'
                    required
                    sm={6}
                    md={4}
                    // name='concepto'
                    open={conceptoOpen.open || (
                      Object.hasOwn(vo.contacto, 'id_contacto') &&
                      vo.concepto.id_concepto === null &&
                      !conceptoOpen.opened
                    )}
                    value={vo.concepto}
                    error={!!errored.concepto}
                    onChange={
                      concepto => {
                        const changes = {
                          concepto,
                          fk_concepto: concepto ? concepto.id_concepto : null,
                          actividades: [{ ...vo.actividades[0], concepto }]
                        }
                        if (typeof concepto.precio === 'number') {
                          changes.precio = concepto.precio || 0
                          changes.actividades = [{
                            ...changes.actividades[0],
                            precio: changes.precio
                          }]
                        }
                        if (!notOpenDate &&
                          vo.id_actividad === SHOW_CREATE) {
                          setConceptoOpen({ open: false, opened: true })
                          if (!vo.fecha_prevista) {
                            handleChangeObject({
                              fecha_prevista: fecha.input(new Date())
                            })
                          }
                          setDateOpen(true)
                        }
                        if (concepto.id_concepto &&
                           (concepto.id_concepto === vo.concepto?.id_concepto)) return
                        handleChangeObject(changes)
                      }
                    }
                    onCancel={() => setConceptoOpen({ open: false, opened: true })}
                    onClose={() => setConceptoOpen({ open: false, opened: true })}
                    tabIndex={selected ? 0 : -1}
                  />
                  )
              }

            <Field
              disabled={selected && !!vo.fk_factura}
              sm={6}
              md={5}
              className='descripcion'
              name='descripcion'
              label={!selected && !vo.descripcion ? ' ' : 'Descripción'}
              value={vo.descripcion || ''}
              error={!!errored.descripcion}
              onChange={handleChange}
              InputProps={{
                readOnly: (!!vo.fk_factura)
              }}
            />

          </GridContainer>
          <>
            <Collapse
              in={
                selected && (
                  (acordeon === idValor || vo.id_actividad === SHOW_CREATE) ||
                  vo.precio > 0 ||
                  vo.precio === '')
              }
              data-cy='tab-content'
              mountOnEnter
              unmountOnExit
            >
              <Grid item sm={12} md={12} className='tabContent valores'>
                <Cantidad
                  // Todos se reparten el espacio disponible (siempre)
                  disabled={!!vo.fk_factura}
                  value={vo.cantidad}
                  error={!!errored.cantidad}
                  unidad={vo.concepto?.unidad_medida}
                  onChange={e => handleChangeArr(e)}
                  readOnly={!!vo.fk_factura}
                />
                <Precio
                  disabled={!!vo.fk_factura}
                  value={vo.precio}
                  error={!!errored.precio}
                  onChange={e => handleChangeArr(e)}
                  autoFocus={(selected || !vo.precio || vo.precio === 0) && !vo.fk_factura}
                  readOnly={!!vo.fk_factura}
                />
                <Descuento
                  disabled={!!vo.fk_factura}
                  placeholder='0'
                  value={vo.descuento}
                  error={!!errored.descuento}
                  onChange={e => handleChangeArr(e)}
                  readOnly={!!vo.fk_factura}
                />
                <Importe
                  // type='text'
                  name='importe'
                  label='Importe'
                  value={vo.precio ? formatFloat(importe(vo)) : '0,00'}
                  inputProps={{ style: { textAlign: 'right' } }}
                />

              </Grid>
            </Collapse>
            <Collapse
              in={selected && acordeon === idTexto}
              mountOnEnter
              unmountOnExit
              data-cy='tab-content'
            >
              <GridContainer>
                <Field
                  multiline
                  // rows={2}
                  autoFocus
                  name='nota'
                  label='Notas'
                  value={vo.nota || ''}
                  error={!!errored.nota}
                  onChange={handleChange}
                />
              </GridContainer>
            </Collapse>
          </>
        </form>
      </Grid>
      <Grid item sm={1} md={1}>
        <AvatarGroup>
          <Collapse in={selected} mountOnEnter unmountOnExit className='toggle-buttons'>
            {
              !vo.fk_factura &&
                <IconButton onClick={handleActividadNueva} data-cy='add'>
                  <Avatar className='sm-avatar'>
                    <AddIcon />
                  </Avatar>
                </IconButton>
            }
            <IconButton
              aria-label='mostrar campos descripcion'
              data-cy='notas'
              color={acordeon !== idTexto ? 'default' : 'primary'}
              onClick={() => handleToggleAcordeon(idTexto)}
            >
              <Avatar className='sm-avatar'>
                <Notas />
              </Avatar>
            </IconButton>
          </Collapse>
          <BadgePrecio
            vo={vo}
            selected={selected}
            handleToggleAcordeon={handleToggleAcordeon}
            idValor={idValor}
          />
          <BadgeEstado
            vo={vo}
            contacto={vo.contacto}
            handleAction={handleAction}
            handleShowEstado={handleShowEstado}
            showEstado={showEstado}
            onClose={() => setShowEstado(false)}
            onDestroy={() => {
              onDestroy(vo.id_actividad)
            }}
          />
        </AvatarGroup>
      </Grid>
    </Grid>
  )
}

// see https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
ActividadItem.propTypes = {
  // acciones del menú
  onBack: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  onDestroy: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  // Manejo de acordeones
  // TODO handleToggleAcordeon => onToggleAcordeon
  acordeon: PropTypes.string,
  handleToggleAcordeon: PropTypes.func.isRequired,
  // Registro de cambios en el formulario
  // TODO handleChange* => onChange*
  handleChangeEvent: PropTypes.func.isRequired,
  handleChangeObject: PropTypes.func.isRequired
}
