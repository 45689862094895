import { utils, writeFile } from 'xlsx'

export default function glasofExport (data) {
  const newWB = utils.book_new()
  const ventasWS = utils.json_to_sheet(data)
  utils.sheet_add_aoa(ventasWS, [[
    'Factura', 'Fecha', 'Operación', 'Pais', 'Cif ', 'Apellido1/Razón social ',
    'Apellido2', 'Nombre', 'Contrapartida', 'Base', '%IVA', 'Cuota Factura',
    '% Recargo', 'Recargo', 'Suplidos', '% Retención', 'Base IRPF', 'Cuota IRPF',
    'Cta IRPF', 'Clave IRPF', 'No usado', 'Incluir 347', 'Tipo Venta', 'NºFact.Rectificada',
    'F.expedición', 'F.Operación', 'Signatura', 'Domicilio', 'Numero', 'Piso',
    'Puerta', 'Escalera', 'CPostal', 'Población', 'Provincia', 'Teléfono',
    'Móvil', 'Fax', 'Email', 'FT Inicial', 'FT Final', 'TotalFT', 'Referencia Catastral',
    'Cta cliente', 'Anotar cobros', 'Fecha cobro', 'Cuenta cobro', 'TipoFactura',
    'ClaveRegEspecial', 'TipoFactRectificada', 'FechaFactRectificada', 'PrecioCompra',
    'Emitida 3ºs', 'DesgloseEntrega/Prestación', 'ConcursoAcreedores', 'ClaveRegEspecial1',
    'ClaveRegEspecial2', 'Descripción', 'Art72_61', 'Número Documento', 'Ruta Fichero',
    'Nombre Fichero', 'No usado', 'Centro/Actividad', 'No usado', 'No usado',
    'Cuota Devengada', 'País Destino', 'Rég. OSS'
  ]], { origin: 'A1' })

  const comprasWS = utils.json_to_sheet([{}])
  utils.sheet_add_aoa(comprasWS, [[
    'Factura', 'Fecha', 'Operación', 'Pais', 'Cif', 'Apellido1/Razón social',
    'Apellido2', 'Nombre', 'Contrapartida', 'Base', '%IVA', 'CuotaFac',
    '% Recargo', 'Recargo', 'Suplidos', '% Retención', 'Base IRPF', 'Cuota IRPF',
    'Cta IRPF', 'Clave IRPF', 'Subcla IRPF', 'Incluir 347', 'Clave349/ISP',
    'NºFact.Rectificada', 'F.expedición', 'F.Operación', 'Signatura', 'Domicilio',
    'Numero', 'Piso', 'Puerta', 'Escalera', 'CPostal', 'Población', 'Provincia',
    'Teléfono', 'Móvil', 'Fax', 'Email', 'FT Inicial', 'FT Final', 'TotalFT',
    'Referencia Catastral', 'Cta proveedor', 'Anotar pagos', 'Fecha pago',
    'Cuenta pago', 'TipoFactura', 'ClaveRegEspecial', 'TipoFactRectificada',
    'FechaFactRectificada', 'BaseaCoste', 'BienInversión', 'DesgloseExenta/NoSujeta',
    'FechaRegistroContable', 'ClaveRegEspecial1', 'ClaveRegEspecial2', 'Descripción',
    'Art72_61', 'Número Documento', 'Ruta Fichero', 'Nombre Fichero', 'No usado',
    'Centro/Actividad', 'Prorrata', 'ClProrrata', 'No usado']],
  { origin: 'A1' })

  const simplesWS = utils.json_to_sheet([{}])
  utils.sheet_add_aoa(simplesWS, [[
    'Asiento', 'Fecha', 'Subcuenta', 'Descripción (No usado)', 'Concepto',
    'Debe', 'Haber', 'Tipo Asiento', 'Centro', 'Ejercicio', 'Empresa'
  ]], { origin: 'A1' })

  utils.book_append_sheet(newWB, ventasWS, 'ventas')
  utils.book_append_sheet(newWB, comprasWS, 'compras')
  utils.book_append_sheet(newWB, simplesWS, 'Simples-1')

  writeFile(newWB, 'glasof_export.xls')
}
