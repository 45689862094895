import TarjetaListHOC from '../Abc/Tarjeta/TarjetaListHOC'

import { ActividadParalela as model } from '../../service/empresas/model'

import ActividadItem from './ActividadItem'

import './ActividadItemList.scss'

export function ActividadList (props) {
  return (
    props.children
  )
}

export default TarjetaListHOC(ActividadList, {
  model,
  ItemComponent: ActividadItem,
  grouped: true
})
