import {
  factura as model
} from '../../service/empresas/model'

import { ListAction as List, StateKeys } from '../const'

export function wipeDispatcher (dispatch, sent, response, action) {
  const actions = []
  actions.push(
    {
      type: List.LOAD_REFRESH,
      plural: StateKeys.actividades
    },
    {
      type: List.LOAD_REFRESH,
      plural: StateKeys.actividades_paralelas
    },
    {
      type: List.ITEM_DESTROY,
      plural: model.plural,
      pk: model.pk,
      id: response.data.idFactura
    },
    {
      type: List.LOAD_REFRESH,
      plural: StateKeys.borradores
    }
  )
  dispatch(...actions)
}
