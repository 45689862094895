import React from 'react'

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckIcon from '@mui/icons-material/Check'

import {
  useFiltersControl
} from '../../../../utils/hooks'

import { factura } from '../../../../service/empresas/model'

export default function ExportList (props) {
  const { fkEmpresa } = props
  const [expandExport, setEpandExport] = React.useState(false)

  const { filters } = useFiltersControl()

  const hanldleExpandExport = () => {
    setEpandExport(prev => !prev)
  }

  return (
    <Stack
      className='quick-settings'
      sx={{ width: '100%', padding: '0 1rem 0 1rem' }}
    >
      <Button
        onClick={() => hanldleExpandExport()}
        sx={{
          width: '100%',
          justifyContent: 'space-between'
        }}
        endIcon={
          <>
            <Collapse
              in={!expandExport}
              orientation='horizontal'
              mountOnEnter
              unmountOnExit
            >
              <ExpandMoreIcon />
            </Collapse>
            <Collapse
              in={expandExport}
              orientation='horizontal'
              mountOnEnter
              unmountOnExit
            >
              <CheckIcon />
            </Collapse>
          </>
        }
      >
        Exportación de Facturas
      </Button>
      <Collapse
        in={expandExport}
        component={Grid}
        item
        sm={12}
        mountOnEnter
        unmountOnExit
      >
        <List dense sx={{ margin: 0 }}>
          <ListItemButton
            onClick={() => {
              factura.exportXls(filters, {
                fk_empresa: fkEmpresa,
                software: 'goldenSoft',
                limit: 1000,
                offset: 0
              })
            }}
          >
            <ListItemText primary='Golden Soft' />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              factura.exportXls(filters, {
                fk_empresa: fkEmpresa,
                software: 'glasof',
                limit: 1000,
                offset: 0
              })
            }}
          >
            <ListItemText primary='Glasof' />
          </ListItemButton>
        </List>
      </Collapse>
    </Stack>
  )
}
