import React from 'react'

import { concepto as model } from '../../../service/empresas/model'

import DialogConcepto from './DialogConcepto'
import Field from '../FormUtils/Field'
import { GridItemHOC } from '../FormUtils/Grid'

import './WidgetModales.scss'

/**
 * La intención es un widget reusable en cualquier formulario
 * El datalist necesario se obtine directamente a la store de redux
 *
 * Este segundo intento trata de imitar al <WidgetContacto> que emplea un
 * diálogo modal para búsqueda y selección, y por lo tanto su estructura
 * se basa en el código original del <TextField>, empleando <FormControl>
 * y componentes relacionados.
 *
 * > Ver https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/TextField/TextField.js#L155
 *
 * Voy a construírlo con la mente puesta en un futuro HOC para poder reusar
 * el concepto de "selector vía modal"
 *
 * NOTA: Lo cierto es que cuesta separar la parte reusable, quizás deba ser
 * simplemente una factoría a la que se le indica un [string] "ComponentName"
 */

function WidgetConcepto (props) {
  const defaults = model.defaults
  const {
    className,
    margin = 'none',
    fullWidth = true,
    // Controla si se muestra abierto por defecto
    open = false,
    // Propiedades vía state mapper
    options,
    // Propiedades comunes
    id = `widget-${model.single}-` + Math.random(),
    name = 'concepto',
    field = 'nombre',
    ComponentClass = 'WidgetConcepto',
    label = props.label || model.single,
    value = { ...defaults },
    error = false,
    required = true,
    helperText, //  = 'Texto ayuda ejemplo',
    placeholder,
    // Eventos e interacción
    onChange,
    onClose,
    tabIndex,
    // El resto de props se le pasan al <FormControl>
    ...other
  } = props

  // Estado del diálogo
  const [dialog, setDialog] = React.useState(null)

  React.useEffect(() => {
    open && setDialog(value || { ...defaults })
  }, [open, value, setDialog, defaults])

  // console.log('Render WidgetConcepto', { open, value, dialog })

  const handleOpen = (event) => setDialog(value || { ...defaults })
  const handleChange = (event) => (dialog ? setDialog : onChange)({
    ...value,
    [field]: event.target.value
  })
  const handleChangeDialog = (vo, close = true) => {
    setDialog(close ? null : vo)
    onChange(vo)
  }

  return (
    <>
      <DialogConcepto
        open
        state={dialog}
        name={`dialog-${name}`}
        options={options}
        ComponentClass={ComponentClass}
        onInput={handleChange}
        onChange={handleChangeDialog}
        onClose={onClose}
        defaults={defaults}
        field={field}
      >
        <Field
          id={id}
          name={name}
          label={label}
          margin={margin}
          fullWidth={fullWidth}
          value={value ? value[field] || '' : ''}
          className={ComponentClass + (className ? ` ${className}` : '')}
          required={required}
          error={error}
          onChange={handleChange}
          onClick={handleOpen}
          placeholder={
            placeholder || (label === null ? label : undefined)
          }
          {...other}
        />
      </DialogConcepto>
    </>
  )
}

export default (GridItemHOC(WidgetConcepto))
