import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import AvatarGroup from '@mui/material/AvatarGroup'
import AddIcon from '@mui/icons-material/Add'

import { Field, FieldDateTime, DateTimeWidget, GridContainer } from '../Abc/FormUtils'

import DialogContacto from '../Abc/FieldSet/DialogContacto'

import TarjetaItemMenu from '../Abc/Tarjeta/TarjetaItemMenu'
import ActividadRow from './ActividadRow'

import * as fecha from '../../utils/fecha'

import { BadgeEstado, BadgePrecio, Notas, BadgeMultiple } from './ActividadItemBadge'

import {
  useHandlerActividadNueva
} from './ActividadItemUtils'

import { MainAction } from '../../store/const'

const { SHOW_CREATE } = MainAction

export default function ActividadItemParalela (props) {
  const { vo, selected, errored, changesRef } = props

  const { onBack, onForceClose, onSave, onDestroy /*, onAction */ } = props

  const { acordeon, handleToggleAcordeon } = props

  const { handleChangeEvent: handleChange, handleChangeObject } = props

  const id = `actividad-${vo.id_actividad_paralela || vo.id_actividad || 'nueva'}`

  const idTexto = 'texto-' + id
  // const idValor = 'valor-' + id

  const avisoFactura = vo.actividades.some(item => item.fk_factura)

  // ver https://stackoverflow.com/q/4782068/1894803
  const mailFactory = (event) => ({
    subject: [
      vo.concepto ? vo.concepto.nombre : 'Actividad',
      'del',
      vo.fecha_prevista
    ].join(' '),
    body: [`Hola ${vo.contacto.alias},`, '', ''].join('\n')
  })

  const [showMultiple, setShowMultiple] = React.useState(false)
  const [showEstado, setShowEstado] = React.useState(-1)
  const [dateOpen, setDateOpen] = React.useState(false)
  const [contactoOpen, setContactoOpen] = React.useState({ open: false, opened: false })
  const [conceptoOpen, setConceptoOpen] = React.useState(
    vo.actividades[vo.actividades.length - 1].concepto.id_concepto ? -1 : vo.actividades.length - 1
  )
  const [dialog, setDialog] = React.useState({ ...(vo.contacto || {}), open: contactoOpen.open })

  const handlerShowEstado = (index) => (event) => {
    // console.log(index)
    setShowEstado(index)
  }

  const handleShowMultiple = (e) => {
    e.stopPropagation()
    return vo.descripcion && setShowMultiple(true)
  }

  const handleChangeActividad = (index, changes) => {
    handleChangeObject({
      actividades: vo.actividades
        .map((vo, idx) =>
          (idx === index) ? { ...vo, ...changes } : vo
        )
    })
  }

  const handleDeleteActividad = (index) => {
    setShowEstado(-1)
    const actividades = vo.actividades.filter((item, idx) => idx !== index)
    const changes = { actividades }
    if (actividades.length < 2) {
      changes.concepto = actividades[0].concepto
      changes.cantidad = actividades[0].cantidad
      changes.precio = actividades[0].precio
      changes.descuento = actividades[0].descuento
      changes.id_actividad = actividades[0].id_actividad
      /* changes.id_actividad_paralela = actividades[0].id_actividad
      changes.isParalela = false */
    }
    handleChangeObject(changes)
  }

  const actionHandler = (index) => (action) => {
    switch (action) {
      case 'fail':
        handleChangeActividad(index, {
          estado: 'anulada',
          fecha_anulada: fecha.input(new Date()),
          fecha_cerrada: null
        })
        break
      case 'pass':
        handleChangeActividad(index, {
          estado: 'cerrada',
          fecha_anulada: null,
          fecha_cerrada: fecha.input(new Date())
        })
        break
      case 'open':
        handleChangeActividad(index, {
          estado: 'abierta',
          fecha_anulada: null,
          fecha_cerrada: null
        })
        break
      default:
        console.error('Acción irreconocible', { action })
    }
    setShowEstado(-1)
  }

  const handleActividadNueva = useHandlerActividadNueva(
    vo,
    handleChangeObject,
    setConceptoOpen
  )

  // console.warn('Render ActividadItemParalela', { vo, contactoOpen })

  return (
    <Grid container className='actividad-wrap'>
      <Grid item sm={11} md={11}>
        <Grid
          container
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            marginBottom: '1rem'
          }}
        >
          <Grid item sm={6} md={4}>
            <TarjetaItemMenu
              className='contacto-menu'
              contacto={vo.contacto}
              facturada={avisoFactura}
              openWidget={() => setContactoOpen({ open: true, opened: true })}
              style={{ justifyContent: 'flex-start' }}
            />
          </Grid>
          <Collapse
            in={selected}
            mountOnEnter
            unmountOnExit
            component={Grid}
            item
            sm={6}
            md={8}
          >
            <TarjetaItemMenu
              back={onBack}
              save={vo.fk_factura ? onBack : onSave}
              changed={changesRef.current}
              mailFactory={mailFactory}
            />
          </Collapse>
        </Grid>
        <form>
          <GridContainer sx={{ marginBottom: '8px' }}>
            <Grid
              item
              sm={6}
              md={4}
              sx={{
                paddingLeft: '8px',
                paddingTop: '8px'
              }}
            >
              <Collapse
                in={selected}
                // orientation='horizontal'
                mountOnEnter
                unmountOnExit
              >
                <FieldDateTime
                  disabled={selected && avisoFactura}
                  className='fecha'
                  name='fecha_prevista'
                  label={(!selected && (vo.fecha_prevista !== null)) ? ' ' : 'Fecha prevista'}
                  onClick={() => !avisoFactura && setDateOpen(true)}
                  value={vo.fecha_prevista !== null && vo.fecha_prevista !== ''
                    ? fecha.toIso(vo.fecha_prevista)
                    : new Date()}
                  inputProps={{
                    readOnly: true
                  }}
                />
                <DateTimeWidget
                  open={dateOpen}
                  value={vo.fecha_prevista !== null && vo.fecha_prevista !== ''
                    ? fecha.combine(vo.fecha_prevista, vo.hora_prevista)
                    : new Date()}
                  onChange={(dateObj, date, time) => {
                    if (date === vo.fecha_prevista && time === vo.hora_prevista) return
                    handleChangeObject({
                      fecha_prevista: date,
                      hora_prevista: time
                    })
                  }}
                  onClose={() => {
                    setDateOpen(false)
                  }}
                />
              </Collapse>
              {selected && (
                <DialogContacto
                  disabled={selected && avisoFactura}
                  label='Contacto'
                  required
                  // name='contacto'
                  state={dialog}
                  setState={setDialog}
                  open={
                    ((selected && !avisoFactura &&
                    (vo.contacto.id_contacto === null &&
                    !contactoOpen.opened &&
                    vo.id_actividad_paralela === SHOW_CREATE
                    )) || contactoOpen.open)
                  }
                  value={vo.contacto}
                  error={!!errored.contacto}
                  onChange={
                    contacto => {
                      const changes = {
                        contacto,
                        fk_contacto: contacto ? contacto.id_contacto : null
                      }
                      if (contactoOpen.opened && !vo.concepto) {
                        changes.concepto = {} // TODO usar model.defaults
                        setConceptoOpen({ open: true, opened: false })
                      }
                      setContactoOpen({ open: false, opened: true })
                      handleChangeObject(changes)
                    }
                  }
                  onClose={() => setContactoOpen({ open: false, opened: true })}
                  onCancel={() => {
                    vo.id_actividad === SHOW_CREATE && !vo.concepto.nombre
                      ? onForceClose()
                      : setContactoOpen({ open: false, opened: true })
                  }}
                  tabIndex={selected ? 0 : -1}
                />
              )}
              <Collapse
                in={!selected}
                mountOnEnter
                unmountOnExit
              >
                <Field
                  label='Concepto'
                  value='Varios Conceptos'
                />
              </Collapse>
            </Grid>
            <Field
              sm={5}
              md={5}
              disabled={selected && avisoFactura}
              className='descripcion'
              name='descripcion'
              label={!selected && !vo.descripcion ? ' ' : 'Descripción'}
              value={vo.descripcion || ''}
              error={!!errored.descripcion || !vo.descripcion}
              required={selected}
              onChange={handleChange}
              InputProps={{
                readOnly: (!!vo.fk_factura)
              }}
            />

          </GridContainer>
          <Collapse in={selected} mountOnEnter unmountOnExit>
            {
              vo.actividades.map((actividad, idx) => {
                return (
                  <Grid
                    container
                    columnSpacing={0}
                    // className='actividadRow'
                    key={actividad.id_actividad || `nueva-${idx}`}
                  >
                    <ActividadRow
                      idx={idx}
                      vo={actividad}
                      open={conceptoOpen === idx}
                      onClose={() => setConceptoOpen(-1)}
                      editable={selected && !actividad.fk_factura}
                      handleChangeObject={obj => handleChangeActividad(idx, obj)}
                      errored={
                        Object.keys(errored)
                          .filter(key => {
                            return key.split('.')[0] === `actividades[${idx}]`
                          })
                          .reduce((obj, key) => ({
                            ...obj,
                            [key.replace(`actividades[${idx}].`, '')]: errored[key]
                          }), {})
                      }
                    />
                    <BadgeEstado
                      vo={actividad}
                      contacto={vo.contacto}
                      handleAction={actionHandler(idx)}
                      handleShowEstado={handlerShowEstado(idx)}
                      showEstado={showEstado === idx}
                      onClose={() => setShowEstado(-1)}
                      onDestroy={() => handleDeleteActividad(idx)}
                    />
                  </Grid>
                )
              })
            }
          </Collapse>
          <Collapse
            data-cy='tab-content'
            in={selected && (acordeon === idTexto)}
            mountOnEnter
            unmountOnExit
          >
            <GridContainer>
              <Field
                multiline
                autoFocus
                name='nota'
                label='Notas'
                value={vo.nota || ''}
                error={!!errored.nota}
                onChange={handleChange}
              />
            </GridContainer>
          </Collapse>
        </form>
      </Grid>
      <Grid item sm={1} className='sideControl'>
        <BadgeMultiple
          vo={vo}
          contacto={vo.contacto}
          handleChangeObject={handleChangeObject}
          handleShowMultiple={handleShowMultiple}
          handleAction={actionHandler(vo.ids_actividades)}
          showMultiple={showMultiple}
          onClose={() => setShowMultiple(false)}
          onDestroy={() => onDestroy(vo)}
          onSave={onSave}
          onForceClose={onForceClose}
        />
        <BadgePrecio
          vo={vo}
          selected={selected}
          facturada={avisoFactura}
        />
        <Collapse in={selected} className='toggle-buttons' mountOnEnter unmountOnExit>
          <AvatarGroup>
            <IconButton
              disabled={avisoFactura}
              onClick={handleActividadNueva}
            >
              <Avatar className='sm-avatar'>
                <AddIcon />
              </Avatar>
            </IconButton>
            <IconButton
              aria-label='mostrar campos descripcion'
              data-cy='notas'
              color={acordeon !== idTexto ? 'default' : 'primary'}
              onClick={() => handleToggleAcordeon(idTexto)}
            >
              <Avatar className='sm-avatar'>
                <Notas />
              </Avatar>
            </IconButton>
          </AvatarGroup>
        </Collapse>
      </Grid>
    </Grid>
  )
}

// see https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
ActividadItemParalela.propTypes = {
  // acciones del menú
  onBack: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  onDestroy: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  // Manejo de acordeones
  // TODO handleToggleAcordeon => onToggleAcordeon
  acordeon: PropTypes.string,
  handleToggleAcordeon: PropTypes.func.isRequired,
  // Registro de cambios en el formulario
  // TODO handleChange* => onChange*
  handleChangeEvent: PropTypes.func.isRequired,
  handleChangeObject: PropTypes.func.isRequired
}
