import {
  CONTACTO,
  filterType
} from '../../../store/filters/const'

import BaseModel from './AbstractModel'

export default class Contacto extends BaseModel {
  static get pk () { return 'id_contacto' }
  static get single () { return 'contacto' }
  static get plural () { return 'contactos' }

  static defaults (confEmpresa, filters) {
    return {
      id_contacto: null,
      alias: '',
      email: '',
      telefono: '',
      email_general: '',
      email_contable: '',
      razon: '',
      nif: '',
      provincia: '',
      fk_provincia: null,
      ayuntamiento: '',
      fk_ayuntamiento: null,
      codigo_postal: '',
      fk_codigo_postal: null,
      iban: '',
      fecha_nacimiento: '',
      fk_empresa: confEmpresa?.fk_empresa,
      es_particular: confEmpresa?.es_particular,
      fk_periodo_facturacion: confEmpresa?.fk_periodo_facturacion_defecto,
      fk_plazo_cobro: confEmpresa?.fk_plazo_cobro_defecto,
      fk_envio_factura: confEmpresa?.fk_envio_factura_defecto,
      fk_metodo_cobro: confEmpresa?.fk_metodo_cobro_defecto
    }
  }

  static breforeSend = (vo) => {
    if (vo.entidad && vo.entidad !== null) {
      if (!Object.values(vo.entidad).some(item => item !== '')) {
        return { ...vo, entidad: null }
      }
    }
    return vo
  }

  static async findAll (filters, params) {
    const filtroContacto = filters.find(vo => filterType(vo) === CONTACTO)
    if (filtroContacto) {
      const results = await super.findAll({
        [this.pk]: filtroContacto[this.pk]
      }, params)

      return results
    }
    return super.findAll({}, params)
  }

  static async create (data = {}, opts = {}) {
    const sent = this.breforeSend(data)
    return super.create(sent, opts)
  }

  static async update (data = {}, opts = {}) {
    const sent = this.breforeSend(data)
    return super.update(sent, opts)
  }
}
