import React from 'react'

import { useMountDetector } from './useMountDetector'

/**
 * Este hook contiene la lógica de registro de cambios y de deshacer.
 * Es probable que en el futuro debamos adoptar una estrategia mejor, quizás
 * similar a react-hook-form.
 *
 * Este hook devuelve {
 *   vo             // Estado actual (cambios incluídos)
 *   changed        // Array con los cambios registrados
 *   resetChanges   // Resetea todos los cambios
 *   onChangeObject // registra cambios en múltiples propiedades a la vez
 *   onChangeEvent  // registra UN cambio en base a UN evento
 *   onUndo         // deshace el último cambio registrado
 * }
 */
const initVOItem = item => item // ? { ...item } : item

export function useChangeHandlers (voRef, item, changesRef, cancel, handleBlock) {
  // El vo almacena una copia del item con el último estado de él
  // Extraer el VO de cualquier formulario a redux no es viable debido a
  // la pérdida de responsividad/rendimiento al escribir en los campos
  const [vo, setVO] = React.useState(initVOItem(item))

  // TODO eliminar esto
  if (typeof handleBlock !== 'function') {
    throw new TypeError('Esta API ha cambiado (falta handleBlock)')
  }

  const mountedRef = useMountDetector()

  const resetAllChanges = React.useCallback((newItem) => {
    changesRef.current = []
    voRef.current = initVOItem(newItem || item)
    // console.warn('RESET ALL CHANGES', mountedRef, voRef)
    // fuerza un nuevo renderizado
    mountedRef.current && setVO(voRef.current)
    handleBlock(false)
  }, [voRef, changesRef, setVO, item, handleBlock, mountedRef])

  const onChangeObject = React.useCallback(
    (object) => {
      // Esta lógica de registro de cambios vino de TarjetaItemHOC "tal cual"
      // para no perder tiempo (aún no está claro cómo actúa "deshacer")
      /*
      * Aquí muto el array porque fué la forma que encontré de subir el
      * estado al padre (https://reactjs.org/docs/lifting-state-up.html)
      * sin provocar un re-render del padre cada vez que se efectúa un cambio.
      * Es probable que exista otro método mejor (quizás una Ref?)
      * TODO lógica del deshacer.
      * Tiene que explicar qué quiere y esto está muy sucio por culpa de los
      * cambios múltiples (campos dirección)
      */
      // TODO esto hay que revisarlo
      const changed = changesRef.current
      const keys = Object.keys(object)

      // console.warn('CHANGE', object)

      changed.forEach((key, idx) => {
        if (!Array.isArray(key)) return
        changed[idx] = changed[idx].filter(
          key => keys.indexOf(key) < 0
        )
      })

      let idx
      while ((
        idx = changed.findIndex(thing => Array.isArray(thing) && !thing.length)
      ) > -1) {
        // console.log('remove empty array at', idx)
        changed.splice(idx, 1)
      }

      if (keys.length === 1) {
        const key = keys.pop()
        const idx = changed.indexOf(key)

        if (item[key] !== object[key]) {
          // Quíza debo splice() si existe, y push siempre
          if (idx < 0) changed.push(key)
        } else {
          if (idx > -1) changed.splice(idx, 1)
        }
      } else if (keys.length) {
        const add = keys.filter(key => item[key] !== object[key])

        // console.log('should add', add)
        changed.push(add)
      }

      handleBlock(!!changed.length)
      // console.warn('Cambios registrados', changed)

      // Actualizar el estado y la referencia al VO
      // console.info('update VO', changed, object)
      voRef.current = { ...item, ...voRef.current, ...object }
      // console.warn('SET VO', voRef.current, 'CHANGES: ', changed, changesRef.current.slice(0))
      setVO(voRef.current)
    },
    [voRef, item, setVO, changesRef, handleBlock]
  )

  // Maneja el registro de UN cambio en base a UN evento
  const onChangeEvent = React.useCallback(
    event => onChangeObject({ [event.target.name]: event.target.value }),
    [onChangeObject]
  )

  const onUndo = React.useCallback(
    () => {
      if (!changesRef.current.length) {
        resetAllChanges()
        voRef.current = null
        return cancel()
      }
      // la mutación del array se justifica aquí también
      // TODO tiene que haber un método mejor
      const key = changesRef.current.pop()
      const obj = Array.isArray(key)
        ? key.reduce((obj, key) => ({ ...obj, [key]: item[key] }), {})
        : { [key]: item[key] }

      // console.log('deshacer', { key, obj })

      if (!changesRef.current.length) { handleBlock(false) }

      voRef.current = { ...voRef.current, ...obj }
      setVO(voRef.current)
    },
    [voRef, item, setVO, cancel, changesRef, resetAllChanges, handleBlock]
  )

  return {
    vo,
    resetVO: () => {
      // console.error('Resetear el VO a', { vo, item, test: vo === item })
      vo !== item && setVO(initVOItem(item))
    },
    setVO: (item) => {
      console.warn([
        'Usar esta función indica una mala estrategia.',
        'Es probable que necesites usar resetVO en su lugar'
      ].join('\n'))
      voRef.current = item
      setVO(voRef.current)
    },
    get changes () { return changesRef.current },
    hasChanges: () => changesRef.current.length > 0,
    resetAllChanges,
    onChangeObject,
    onChangeEvent,
    onUndo
  }
}
