import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import Input from '@mui/material/Input'
import Slider from '@mui/material/Slider'
import Popover from '@mui/material/Popover'
import { GridContainer, Field } from '../FormUtils'
import { FormControl, InputLabel } from '@mui/material'

export default function ConfigActividades (props) {
  const vo = props.vo || {}
  const { handleChange, handleChangeObject/*, errored */ } = props

  const [openPopper, setOpenPopper] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [workDays, setWorkDays] = React.useState(vo.work_spec.daysOfWeek)
  const [workHours, setWorkHours] = React.useState(
    [
      parseInt(vo.work_spec.startTime.split(':')[0]),
      parseInt(vo.work_spec.endTime.split(':')[0])
    ] || []
  )

  const handleChangeWorkDays = (event) => {
    const {
      target: { value }
    } = event
    setWorkDays(
      typeof value === 'string' ? value.split(',') : value
    )
  }

  const toHourString = (value) => {
    return value < 10 ? `0${value}:00` : `${value}:00`
  }

  const handleChangeWorkHours = (event, value) => {
    setWorkHours(value)
  }

  const handlePopper = (event) => {
    setAnchorEl(event.currentTarget)
    setOpenPopper(prev => !prev)
  }

  const marks = [
    {
      value: 0,
      label: '00:00'
    },
    {
      value: 23,
      label: '23:00'
    },
    {
      value: 6,
      label: '06:00'
    },
    {
      value: 18,
      label: '18:00'
    },
    {
      value: 12,
      label: '12:00'
    }
  ]

  return (
    <GridContainer
      spacing={2}
      component='form'
      data-cy='form-actividades'
      className='settings'
    >
      <Field
        sm={6}
        select
        label='Duración Actividades'
        unit='min'
        inputProps={{ style: { textAlign: 'left' } }}
        name='duracion'
        value={vo.duracion}
        // error={!!errored.duracion}
        onChange={(e) => handleChangeObject({ duracion: e.target.value })}
      >
        <MenuItem value={15}>15 minutos</MenuItem>
        <MenuItem value={30}>30 minutos</MenuItem>
        <MenuItem value={45}>45 minutos</MenuItem>
        <MenuItem value={60}>60 minutos</MenuItem>
        <MenuItem value={75}>75 minutos</MenuItem>
        <MenuItem value={90}>90 minutos</MenuItem>
        <MenuItem value={105}>105 minutos</MenuItem>
        <MenuItem value={120}>120 minutos</MenuItem>
      </Field>
      <Field
        sm={6}
        select
        label='Vista Inicial'
        value={vo.default_view || ''}
        InputProps={{ style: { fontSize: '0.8rem' } }}
        onChange={(e) => {
          handleChangeObject({ default_view: e.target.value })
        }}
      >
        <MenuItem value='mes'>Mes</MenuItem>
        <MenuItem value='semana'>Semana</MenuItem>
        <MenuItem value='tresDias'>Tres Días</MenuItem>
        <MenuItem value='dia'>Día</MenuItem>
      </Field>
      <Grid
        item
        sm={6}
        sx={{ alignSelf: 'self-end' }}
      >
        <FormControl variant='standard' fullWidth>
          <InputLabel id='workDays'>Días a mostrar en la agenda</InputLabel>
          <Select
            fullWidth
            multiple
            id='workDays'
            className='workDays'
            input={<Input />}
            value={workDays}
            onChange={handleChangeWorkDays}
            onClose={() => {
              workDays.length > 0 && workDays !== vo.work_spec.daysOfWeek
                ? handleChangeObject({
                  work_spec: {
                    daysOfWeek: workDays,
                    startTime: toHourString(workHours[0]),
                    endTime: toHourString(workHours[1])
                  }
                })
                : console.log('Array workDays vacío')
            }}
          >
            <MenuItem value={1}>Lunes</MenuItem>
            <MenuItem value={2}>Martes</MenuItem>
            <MenuItem value={3}>Miercoles</MenuItem>
            <MenuItem value={4}>Jueves</MenuItem>
            <MenuItem value={5}>Viernes</MenuItem>
            <MenuItem value={6}>Sábado</MenuItem>
            <MenuItem value={0}>Domingo</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Popover
        open={openPopper}
        anchorEl={anchorEl}
        className='slide-popper'
        onClose={() => setOpenPopper(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Slider
          value={workHours}
          onChangeCommitted={() => handleChangeObject({
            work_spec: {
              daysOfWeek: workDays,
              startTime: toHourString(workHours[0]),
              endTime: toHourString(workHours[1])
            }
          })}
          onChange={handleChangeWorkHours}
          valueLabelDisplay='auto'
          valueLabelFormat={toHourString}
          marks={marks}
          size='small'
          min={0}
          max={23}
        />
      </Popover>
      <Field
        sm={6}
        onClick={handlePopper}
        label='Horas a mostrar'
        value={toHourString(workHours[0]) + ' - ' + toHourString(workHours[1])}
        onChange={handleChange}
      />

    </GridContainer>
  )
}
