import React from 'react'
import PropTypes from 'prop-types'

import {
  InputAdornment,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  Button
} from '@mui/material'

import { ArrowDropDown as ArrowDown } from '@mui/icons-material'

import { Field } from './Field'

import { GridContainer, GridItemHOC } from './Grid'

export function ComboDialog ({
  name,
  title,
  label,
  options,
  avatar,
  describe,
  value = null,
  onChange,
  dialogProps = {},
  ...props
}) {
  // console.info('Render ComboDialog', { props })

  const [dialog, setDialog] = React.useState(null)
  // const inputRef = React.useRef(null)

  const selected = options.find(option => option.value === value)

  const handleOpen = () => !dialog && setDialog(true)
  const handleClose = () => dialog && setDialog(false)
  const handleKeyboard = (event) => {
    switch (event.key) {
      case 'Escape':
      case 'Shift':
      case 'Tab':
        return
      default:
        console.log('keyboard open', event.key)
        handleOpen()
    }
  }

  return (
    <SelectorDialog
      label={label}
      state={dialog ? selected : null}
      options={options}
      avatar={avatar}
      describe={describe}
      /*
      onInput={
        (...args) => {
          console.warn('onInput', args)
        }
      } // */
      onChange={
        (vo) => {
          // console.warn('onChange', vo)
          // inputRef.current.blur()
          handleClose()
          onChange({ target: { name, value: vo.value } }, vo)
        }
      }
      {...dialogProps}
    >
      <Field
        name={name}
        label={label}
        value={selected.title}
        {...props}
        className='selector-dialog-field'
        // inputRef={inputRef}
        readOnly
        onClick={handleOpen}
        onKeyDown={handleKeyboard}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end' disablePointerEvents>
              <ArrowDown />
            </InputAdornment>
          )
        }}
      />
    </SelectorDialog>
  )
}

const valueShape = PropTypes.number.isRequired
const optionShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  value: valueShape
})

ComboDialog.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  value: valueShape,
  error: PropTypes.bool,
  options: PropTypes.arrayOf(optionShape),
  onChange: PropTypes.func.isRequired
}

export default GridItemHOC(ComboDialog)

SelectorDialog.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  state: PropTypes.any,
  options: PropTypes.arrayOf(optionShape),
  avatar: PropTypes.func,
  describe: PropTypes.func,
  // onInput: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

export function SelectorDialog (props) {
  const {
    id = 'selector-dialog-' + Math.random(),
    label = 'Title',
    field = 'title',
    state,
    options,
    avatar = (vo) => vo[field].slice(0, 1),
    describe,
    // onInput,
    onChange,
    ...other
  } = props

  const [selected, setSelected] = React.useState(0)

  /*
   * HOOKS END HERE
   */

  if (!state) {
    return props.children
  }

  // select(null) idica que se desea crear uno nuevo

  const select = (vo, idx = -1) => {
    // const shouldClose = vo || (!vo && stringValue !== '')
    // console.warn('select', { idx, vo })
    setSelected(idx)
    onChange(vo)
  }

  const handleClose = (event) => onChange(state)
  // const handleFuzzy = (event) => onInput(event)

  const stringValue = state[field] || ''
  /*
  const fuzzyRegExp = new RegExp(stringValue.replace('.', '\\.'), 'i')
  const fuzzyOption = options.filter(vo => stringValue === '' || (
    Object.values(vo).some(str => fuzzyRegExp.test(str))
    // TODO config opcional ??
  )) */
  const limit = 0
  const handleKeyboard = (event) => {
    // console.log('keyboard', event.key)
    switch (event.key) {
      case 'ArrowDown':
        return setSelected(
          prev => prev >= (options.length - 1) ? limit : ++prev
        )
      case 'ArrowUp':
        return setSelected(
          prev => prev <= limit ? (options.length - 1) : --prev
        )
      case 'Enter':
        return selected < limit
          ? console.warn('Enter key (out of bounds)')
          : onChange(options[selected])
      default:
        // console.info('Keyboard', event.key)
    }
  }

  // console.warn('Render Dialog', { model, state, options })

  return (
    <>
      {props.children}
      <Dialog
        className='selector-dialog'
        fullWidth
        open
        onClose={handleClose}
        aria-labelledby={id + '-title'}
        {...other}
      >
        <DialogTitle id={id + '-title'}>
          Selector opción
          {/* `Selector ${model.single}` */}
        </DialogTitle>
        <DialogContent>
          <form
            noValidate
            onSubmit={event => event.preventDefault()}
          >
            {/* <DialogContentText>Seleccione una opción</DialogContentText> */}
            <GridContainer>
              <Field
                sx={{ marginTop: '10px' }}
                name={field}
                label={label}
                value={stringValue}
                error={
                  // TODO opcion para determinar error?
                  !state[field]
                }
                required
                // onChange={handleFuzzy}
                onKeyDown={handleKeyboard}
                autoFocus
              />
            </GridContainer>
          </form>
          <List dense disablePadding className='muiLista'>
            {options.map((vo, idx) =>
              <ListItem
                key={idx}
                button
                // disableGutters
                selected={selected === idx}
                onClick={() => select(vo, idx)}
              >
                <ListItemAvatar>
                  <Avatar className='avatar-opcion'>{avatar(vo)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={vo.title}
                  secondary={
                    typeof describe === 'function' ? describe(vo) : undefined
                  }
                />
                {
                  // <pre>{JSON.stringify(option, null, 4)}</pre>
                }
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
          {/* <Button type='submit' color='primary'>Añadir</Button> */}
        </DialogActions>
      </Dialog>
    </>
  )
}
