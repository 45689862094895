import TarjetaListHOC from '../Abc/Tarjeta/TarjetaListHOC'

import { empresa as model } from '../../service/empresas/model'

import EmpresaItem from './EmpresaItem'

export function EmpresaList (props) {
  // console.warn('Render empresa list', props)
  return (
    props.children
  )
}

export default TarjetaListHOC(EmpresaList, {
  model,
  shouldLoad: (empresa) => true,
  // OJO: fkEmpresa es la empresa seleccionada!
  ItemComponent: EmpresaItem
})
