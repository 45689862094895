import * as fecha from '../../../utils/fecha'
import { MainAction } from '../../../store/const'
import {
  dispatcherRelacionados,
  wipeDispatcher
} from '../../../store/modelDispatcher/ActividadDispatcher'

import {
  CONCEPTO, CONTACTO, TIEMPO, filterType
} from '../../../store/filters/const'

import Concepto from './Concepto'
import Contacto from './Contacto'
// import Borrador from './Borrador'

import BaseModel from './AbstractModel'

const { SHOW_CREATE } = MainAction

export default class Actividad extends BaseModel {
  static get pk () { return 'id_actividad' }
  static get single () { return 'actividad' }
  static get plural () { return 'actividades' }

  static defaults (confEmpresa, filters) {
    const _contacto = filters.find(filter => filterType(filter) === CONTACTO) || (
      { ...Contacto.defaults }
    )
    const _concepto = filters.find(filter => filterType(filter) === CONCEPTO) || (
      Concepto.defaults(confEmpresa, filters)
    )

    return {
      id_actividad: SHOW_CREATE,
      fk_empresa: confEmpresa?.fk_empresa,
      fecha_prevista: '',
      fecha_cerrada: '',
      hora_prevista: '',
      nota: '',
      descripcion: '',
      estado: 'abierta',
      cantidad: 1,
      precio: 0,
      descuento: 0,
      descuento_eur: 0,
      duracion: confEmpresa?.duracion || 30,
      concepto: _concepto,
      contacto: _contacto
    }
  }

  static defineFilters (filters) {
    const filtroConcepto = filters.find(vo => filterType(vo) === CONCEPTO)
    const filtroContacto = filters.find(vo => filterType(vo) === CONTACTO)
    const filtroTiempo = filters.find(vo => filterType(vo) === TIEMPO)

    const _filters = {
      fk_contacto: filtroContacto ? filtroContacto[Contacto.pk] : null,
      fk_concepto: filtroConcepto ? filtroConcepto[Concepto.pk] : null,
      desde: filtroTiempo ? fecha.input(filtroTiempo.desde) : null,
      hasta: filtroTiempo ? fecha.input(filtroTiempo.hasta) : null
    }
    return (_filters)
  }

  static beforeSend = (vo) => {
    return {
      ...vo,
      precio: vo.precio === '' ? 0 : vo.precio,
      cantidad: vo.cantidad <= 0 ? 1 : vo.cantidad
    }
  }

  static async findAll (filters, params) {
    const _filters = this.defineFilters(filters)

    const results = await super.findAll(_filters, params)
    return results
  }

  static async create (data = {}, opts = {}) {
    const sent = this.beforeSend(data)

    return super.create(sent, opts, dispatcherRelacionados)
  }

  static async update (data = {}, opts = {}) {
    const sent = this.beforeSend(data)

    return super.update(sent, opts, dispatcherRelacionados)
  }

  static async destroy (data = {}, opts = {}) {
    return super.destroy(data, opts, wipeDispatcher)
  }
}
