import React from 'react'
import { Buffer } from 'buffer'

import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import PictureAsPdf from '@mui/icons-material/PictureAsPdf'
import Collapse from '@mui/material/Collapse'

import { MeetingRoom } from '@mui/icons-material'

import Empresa from '../../../service/empresas/model/Empresa'
import Lopd from './Lopd'
import * as fecha from '../../../utils/fecha'

import './Documentos.scss'

export default function Documentos (props) {
  const { empresa, contacto } = props
  const [option, setOption] = React.useState(null)

  const handleVerPdf = (documento) => {
    Empresa.printPdf(empresa.fk_empresa, documento, contacto.id_contacto)
  }

  const logotipo = !empresa.logo
    ? <MeetingRoom className='imagotipo' />
    : (
      <img
        className='imagotipo'
        alt={`Logotipo ${empresa.razon}`}
        src={
          typeof empresa.logo === 'string'
            ? empresa.logo
            : Buffer.from(empresa.logo).toString()
        }
      />
      )

  const date = fecha.formatTextDate(new Date())

  return (
    <Grid
      container
      rowSpacing={4}
      data-cy='documentos'
    >
      <Grid item xs={12}>
        <ButtonGroup
          variant='contained'
          aria-label='outlined primary button group'
        >
          <Button
            onClick={() => setOption('lopd')}
          >
            LOPD
          </Button>
          <Button
            onClick={() => setOption('otro')}
          >
            Otro Documento
          </Button>
          <Button
            onClick={() => setOption('otroMas')}
          >
            otro Distinto
          </Button>
        </ButtonGroup>
        <IconButton
          onClick={() => handleVerPdf('lopd')}
        >
          <PictureAsPdf />
        </IconButton>
      </Grid>
      <Grid item xs={12} className='documento'>
        <Collapse in={contacto !== null && option === 'lopd'} mountOnEnter unmountOnExit>
          <Lopd
            empresa={empresa}
            contacto={contacto}
            logotipo={logotipo}
            date={date}
          />
        </Collapse>
      </Grid>
    </Grid>
  )
}
