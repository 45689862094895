import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import { MainAction } from '../../store/const'

// import { useDialogEmitir } from '../Facturas/DialogEmitir'

import {
  DIALOG_CANCEL,
  DIALOG_ACCEPT
} from '../Abc/Tarjeta/Dialogs'

import { CaraFeliz, CaraNormal, CaraTriste, Euro } from './ActividadItemBadge'

const { SHOW_CREATE } = MainAction

export default function DialogEstadoActividad (props) {
  const {
    vo,
    contacto,
    showEmit = true,
    onClose,
    onAction,
    children,
    onDestroy,
    ...other
  } = props

  const id = props.id || Math.random()
  const idTitle = `dialog-title-${id}`

  const handleAction = (action) => {
    onClose(DIALOG_ACCEPT)
    onAction(action)
  }

  // console.log({ contacto, vo })

  /* const [emitLayout, handleEmit] = useDialogEmitir(
    saveHandler({
      next: null,
      emitir: true,
      force: true
    }),
    onBack,
    vo,
    tesoreria.formatEUR(importe.total),
    fkEmpresa,
    handleChangeObject,
    selected,
    editable
  ) */

  const handleEmit = (action) => {
    onAction(action)
    // window.location.href = `local.agendayfacturacion.es/${vo.fk_empresa}/facturacion/${}`
  }

  const isFacturada = !!vo.fk_factura

  return (
    <Dialog
      onClose={() => onClose(DIALOG_CANCEL)}
      title='Hacer actividad'
      fullWidth
      maxWidth='sm'
      aria-labelledby={idTitle}
      {...other}
    >
      <DialogTitle id={idTitle}>
        {
          isFacturada
            ? 'Ya está facturada!'
            : 'Qué estado quieres asignar?'
        }
      </DialogTitle>
      {isFacturada && (
        <DialogContent>
          <DialogContentText>
            No se puede cambiar de estado porque esta actividad pertenece
            a la factura <i>{vo.fk_factura}</i>.
          </DialogContentText>
        </DialogContent>
      )}
      {!isFacturada && (
        <List disablePadding>
          <ListItemButton
            data-cy='resolver-abierta'
            selected={vo.estado === 'abierta' || vo.estado === 'atrasada'}
            disabled={vo.estado === 'abierta' || vo.estado === 'atrasada'}
            onClick={() => handleAction('open')}
          >
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: 'var(--info)' }}>
                <CaraNormal fontSize='default' />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary='Pendiente'
              secondary='Aún no se ha hecho'
            />
          </ListItemButton>
          <ListItemButton
            data-cy='resolver-cerrada'
            selected={vo.estado === 'cerrada'}
            disabled={vo.estado === 'cerrada'}
            onClick={() => handleAction('pass')}
          >
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: 'var(--done)' }}>
                <CaraFeliz fontSize='default' />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary='Finalizada'
              secondary='Ya se ha hecho'
            />
          </ListItemButton>
          {showEmit && (
            <ListItemButton
              data-cy='resolver-emitir'
              disabled={contacto?.fk_entidad === null}
              onClick={() => handleEmit('pass')}
            >
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: 'var(--warn)' }}>
                  <Euro fontSize='default' />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary='Finalizar y emitir'
                secondary='Emitir factura'
              />
            </ListItemButton>
          )}
          <ListItemButton
            data-cy='resolver-anulada'
            selected={vo.estado === 'anulada'}
            disabled={vo.estado === 'anulada'}
            onClick={() => handleAction('fail')}
          >
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: 'var(--fail)' }}>
                <CaraTriste fontSize='default' />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary='Cancelada'
              secondary='No se va a hacer'
            />
          </ListItemButton>
          {vo.id_actividad !== SHOW_CREATE && (
            <ListItemButton
              data-cy='eliminar'
              onClick={() => onDestroy()}
            >
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: 'var(--fail)' }}>
                  <DeleteForeverIcon fontSize='default' />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary='Eliminar'
                secondary='Eliminar actividad'
              />
            </ListItemButton>
          )}
        </List>
      )}
      <DialogActions>
        <Button onClick={(e) => {
          e.stopPropagation()
          onClose(DIALOG_CANCEL)
        }}
        >
          Atrás
        </Button>
      </DialogActions>
    </Dialog>
  )
}
