import axios from '../'
import ExportService from '../../export'
import { message as notify } from '../../../utils/notify'
import store from '../../../store'
import { ListAction } from '../../../store/const'
import { multipleDispatcher } from '../../../store/dispatcher'

const multiple = multipleDispatcher(store.dispatch.bind(store))

const {
  ITEM_UNSHIFT,
  ITEM_REPLACE,
  ITEM_DESTROY
} = ListAction

const { Blob } = global

export default class AbstractModel {
  static get pk () { throw new Error('Debe implementarse') }
  static get single () { throw new Error('Debe implementarse') }
  static get plural () { throw new Error('Debe implementarse') }

  static get axios () { return axios }

  static getItemUrl (fkEmpresa, id) {
    return `/${fkEmpresa}/${this.plural}/${id}`
  }

  static getListUrl (fkEmpresa) {
    return `/${fkEmpresa}/${this.plural}`
  }

  static getFrontListUrl (fkEmpresa) {
    return this.getListUrl(fkEmpresa)
  }

  static getFrontItemUrl (fkEmpresa, id) {
    return this.getItemUrl(fkEmpresa, id)
  }

  static getPrintUrl (fkEmpresa, documento, id) {
    return `/${fkEmpresa}/${documento}/${id}.pdf`
  }

  static searchUrl (fkEmpresa) {
    return `/${fkEmpresa}/search`
  }

  static defaults (confEmpresa, filters) {
    throw new Error('Debe implementarse')
  }

  /**
   * Comunicación con backend
   */
  static async loadList (qs, ctx = null) {
    return await this.axios.get(this.getListUrl(ctx), {
      params: qs
    })
  }

  static async postList (vo, ctx = null) {
    // console.log(vo, ctx)
    /* if (!(vo instanceof this)) {
      throw new TypeError(`El ValueObject debe ser instanceof ${this}`)
    } */
    return await this.axios.post(this.getListUrl(ctx), vo)
  }

  static async loadItem (vo, ctx = null) {
    return await this.axios.get(this.getItemUrl(ctx, vo[this.pk]))
  }

  static async saveItem (vo, ctx = null) {
    // console.log('update?', vo, ctx)
    /* if (!(vo instanceof this)) {
      throw new TypeError(`El ValueObject debe ser instanceof ${this}`)
    } */
    return await this.axios.put(this.getItemUrl(ctx, vo[this.pk]), vo)
  }

  static async wipeItem (vo, ctx = null) {
    /* if (!(vo instanceof this)) {
      throw new TypeError(`El ValueObject debe ser instanceof ${this}`)
    } */
    return await this.axios.delete(this.getItemUrl(ctx, vo))
  }

  static async printPdf (url, label) {
    return ExportService.request({
      method: 'put',
      url,
      responseType: 'blob'
    })
      .then(response => {
        notify('Se abrirá el PDF en una nueva ventana', 200)
        const blob = new Blob([response.data])

        // window.open(window.URL.createObjectURL(blob), '_blank')

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', `${label}.pdf`)
        link.click()
        link.remove()
      })
      .catch(error => {
        notify('Se ha producido un error al cargar el PDF', 500)
        console.error({ error })
      })
  }

  /**
   * INTERFAZ CRUD (aka "DAO")
   */

  static async findAll (
    filters = {},
    opts = {},
    loadDispatcher = (dispatch, sent, response, action) => dispatch(action)
  ) {
    return this.loadList({
      ...opts,
      ...filters
    }, opts.fk_empresa)
      .then(response => {
        const { data: { list, message, ...etc }, status } = response
        let total = response.data.total

        // console.warn({ list, message, total })
        if (typeof total !== 'number') {
          console.error('OJO: La respuesta de la API no incluye total', response.data)
          total = list.length || 0
        }

        const action = {
          type: opts.offset ? ListAction.NEXT_PAGE : ListAction.LOAD_INITIAL,
          plural: this.plural,
          list,
          total,
          ...etc
        }

        loadDispatcher(multiple, null, list, action)

        return {
          ...etc,
          total,
          list,
          status,
          message
        }
      })
  }

  static async findOne (filters = {}, opts = {}) {
    if (!filters.fk_empresa) {
      throw Error('falta el fk de empresa')
    }
    if (!filters[this.pk]) {
      throw Error('falta el id')
    }
    // TODO fkEmpresa
    const results = await this.loadItem({
      [this.pk]: filters[this.pk]
    }, filters.fk_empresa)

    if (results.length !== 1) {
      throw new Error('Se han encontrado ' + results.length + ' resultados')
    }

    return results
  }

  static async create (
    data = {},
    opts = {},
    postDispatcher = (dispatch, sent, response, action) => dispatch(action)
  ) {
    if (!data.fk_empresa) {
      return Promise
        .reject(new Error('No hay empresa seleccionada'))
        .catch(err => {
          throw err
        })
    }

    return await this.postList(data, data.fk_empresa)
      .then(response => {
        const { data: { item, message }, status } = response

        const action = {
          type: ITEM_UNSHIFT,
          plural: this.plural,
          item
        }

        // console.log('postDispatcher:', { data, response, action })
        postDispatcher(multiple, data, response, action)

        // console.log({ response })
        notify(message, status)

        return {
          notify: {
            severity: 'success',
            message
          },
          status,
          item
        }
      })
      .catch(err => {
        throw err
      })
  }

  static async update (
    data = {},
    opts = {},
    saveDispatcher = (dispatch, sent, response, action) => dispatch(action)
  ) {
    if (!data.fk_empresa) {
      return Promise
        .reject(new Error('No hay empresa seleccionada'))
        .catch(err => {
          throw err
        })
    }

    return await this.saveItem(data, data.fk_empresa)
      .then(response => {
        const { data: { item, message }, status } = response
        notify(message, status)

        const action = {
          type: ITEM_REPLACE,
          plural: this.plural,
          pk: this.pk,
          item
        }

        // console.log(data, response, action)

        saveDispatcher(multiple, data, response, action)

        return {
          notify: {
            severity: 'success',
            message
          },
          status,
          item,
          body: response.data
        }
      }).catch(err => {
        throw err
      })
  }

  static async destroy (
    data = {},
    opts = {},
    wipeDispatcher = (dispatch, sent, response, action) => dispatch(action)
  ) {
    if (!data.fk_empresa) {
      return Promise
        .reject(new Error('No hay empresa seleccionada'))
        .catch(err => {
          throw err
        })
    }

    return await this.wipeItem(data[this.pk], data.fk_empresa)
      .then(response => {
        const { data: { message }, status } = response
        notify(message, status)

        const action = {
          type: ITEM_DESTROY,
          plural: this.plural,
          pk: this.pk,
          id: data[this.pk]
        }

        wipeDispatcher(multiple, null, response, action)

        return response
      })
      .catch(err => {
        throw err
      })
  }
}
