import { ListAction, MainAction, StateKeys } from '../const'
import { ActividadParalela as model } from '../../service/empresas/model'

const { SHOW_CREATE } = MainAction

export function dispatcherRelacionados (dispatch, sent, response, action) {
  const actions = []
  const { contacto, conceptos, concepto, item } = response.data

  if (sent[model.pk] !== SHOW_CREATE) {
    if (action.type !== ListAction.ITEM_REPLACE) {
      throw new Error('Qué carajo?')
    }
    action.matcher = vo => vo.ids_actividades.some(id => item.ids_actividades.includes(id))
  } else {
    // console.warn('Cambios de "create" (no se hace nada)')
  }
  actions.push(action)

  // console.warn({ contacto, conceptos })

  concepto && actions.push({
    type: ListAction.ITEM_UNSHIFT,
    plural: StateKeys.conceptos,
    item: concepto,
    pk: 'id_concepto'
  })

  conceptos && actions.push({
    type: ListAction.LOAD_REFRESH,
    plural: StateKeys.conceptos
  })

  contacto && actions.push({
    type: ListAction.ITEM_UNSHIFT,
    plural: StateKeys.contactos,
    item: contacto,
    pk: 'id_contacto'
  })

  if (item.actividades.some(
    // Se necesita refrescar según la siguiente condición
    dto => dto.precio
  )) {
    actions.push({
      type: ListAction.LOAD_REFRESH,
      plural: StateKeys.borradores
    })
  } else {
    // console.warn('No es necesario refrescar borradores')
  }

  if (item.nota) {
    actions.push({
      type: ListAction.LOAD_REFRESH,
      plural: StateKeys.contactos
    })
  } else {
    // console.warn('No es necesario refrescar contactos')
  }

  dispatch(...actions)
}

export function wipeDispatcher (dispatch, sent, response, action) {
  dispatch({
    type: ListAction.LOAD_REFRESH,
    plural: StateKeys.borradores
  })
  response.status === 200 && dispatch(action)
}
