import React from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'

import { useDestroyDialog, useBlockDialog } from '../Abc/Tarjeta/Dialogs'

import {
  useAcordeon,
  useHistoryBlocker,
  useChangeHandlers,
  useItemEraser,
  useItemSaver
} from '../../utils/hooks'

import { MainAction } from '../../store/const'

import { Evento as model } from '../../service/empresas/model'

import { Dialog, /* DialogTitle, */ DialogContent } from '@mui/material'

import { ActividadItemAdapter } from '../Actividad/ActividadItem'
import { dispatcherRelacionados } from '../../store/modelDispatcher/ActividadDispatcher'
import { useSaveHandlerTransitional } from '../../utils/hooks/useSaveHandler'

export default function DialogActividad (props) {
  const { voRef, changesRef, item, onClose } = props
  const { SHOW_CREATE } = MainAction
  // const id = props.id || Math.random()
  // const idTitle = 'dialog-title-' + id

  const errored = useSelector(
    state => (state.card && state.card.errored) || {}
  )
  const [acordeon, toggleAcordeon] = useAcordeon()

  // Cierra todos los acordeones cuando no existe actividad seleccionada
  React.useEffect(() => {
    if (!item) toggleAcordeon(null)
  }, [item, toggleAcordeon])

  const handleClose = React.useCallback((event, reason) => {
    // Muy probablemente haya que volver a activar esto
    /* if (reason === 'backdropClick') {
      return false
    } */
    // console.warn('Manejando el close')
    onClose()
  }, [onClose])

  const handleBlock = useHistoryBlocker()

  const {
    onChangeObject,
    onChangeEvent,
    onUndo,
    resetAllChanges
  } = useChangeHandlers(voRef, item, changesRef, handleClose, handleBlock)

  const wipeItem = useItemEraser(voRef, { model })

  const saveItem = useItemSaver(voRef, resetAllChanges, {
    model,
    shouldPost: (id) => !id || id === SHOW_CREATE,
    beforeSend: vo => vo,
    dispatcher: dispatcherRelacionados
  })

  const onSave = useSaveHandlerTransitional({ changesRef, saveItem, handleClose })

  const [destroyLayout, confirmDestroy] = useDestroyDialog(wipeItem, handleClose)
  const [blockLayout] = useBlockDialog({
    active: !!item,
    handleBlock,
    onSave,
    onDiscard: resetAllChanges
  })

  const onForceClose = () => {
    resetAllChanges()
    handleClose()
  }

  const handleDestroy = item &&
    item.id_actividad &&
    item.id_actividad !== SHOW_CREATE
    // por el momento no me preocupa que no re-renderice, sólo cierro diálogo
    ? confirmDestroy
    : undefined

  // console.info('Render DialogActividad', { item, voRef, changesRef })

  return (
    <>
      <Dialog
        className='dialog actividad'
        fullWidth
        maxWidth='md'
        open={!!item}
        onClose={handleClose}
        // aria-labelledby={idTitle}
      >
        {/* <DialogTitle id={idTitle}>{title}</DialogTitle> */}
        <DialogContent
          className={`card actividad selected actividadAgenda ${item?.estado}`}
          data-cy={item?.id_actividad}
        >
          {
            voRef.current &&
              <ActividadItemAdapter
                // En un modal, siempre está seleccionada
                selected
                notOpenDate
                // acordeones
                acordeon={acordeon}
                handleToggleAcordeon={toggleAcordeon}
                // formulario
                vo={voRef.current}
                voRef={voRef}
                changesRef={changesRef}
                errored={errored}
                handleChangeEvent={onChangeEvent}
                handleChangeObject={onChangeObject}
                // menú
                onBack={onUndo}
                onSave={onSave}
                onDestroy={handleDestroy}
                onForceClose={onForceClose}
              />
          }
        </DialogContent>
      </Dialog>
      {destroyLayout}
      {blockLayout}
    </>
  )
}

const nullable = (wrappedPropTypes) => (props, propName, ...etc) =>
  props[propName] === null
    ? null
    : wrappedPropTypes(props, propName, ...etc)

DialogActividad.propTypes = {
  voRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: nullable(PropTypes.object.isRequired)
    })
  ]).isRequired,
  changesRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: nullable(PropTypes.array.isRequired)
    })
  ]).isRequired,
  item: nullable(PropTypes.object.isRequired),
  // TODO actividad shape
  onClose: PropTypes.func.isRequired
}
