import { concepto as model } from '../../service/empresas/model'

import TarjetaListHOC from '../Abc/Tarjeta/TarjetaListHOC'

import ConceptoItem from './ConceptoItem'

export function ConceptoList (props) {
  return (
    props.children
  )
}

export default TarjetaListHOC(ConceptoList, {
  model,
  ItemComponent: ConceptoItem,
  windowScroll: false
})

// Proyecto a corto plazo -> limpiar los HOC

/* export function ConceptoListBien (props) {
  return (
    <TarjetaList
      model={model}
      ItemComponent={ConceptoItem}
      windowScroll={false}
    />
  )
} */
