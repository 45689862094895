import React from 'react'
import { useSelector } from 'react-redux'
import {
  useChangeHandlers,
  useItemSaver,
  useHistoryBlocker,
  useSaveHandlerTransitional
} from '../../utils/hooks'

import { useBlockDialog } from '../../componentes/Abc/Tarjeta/Dialogs'
import AuthService from '../../service/auth'

import { empresa as model } from '../../service/empresas/model'

import { selectorIsLoading, selectorEmpresaBase } from '../../store/selector'

import { ListAction, MainAction } from '../../store/const'

const { ITEM_UNSHIFT } = ListAction
const { SHOW_CREATE } = MainAction

const selectIsLoading = selectorIsLoading()
const selectBase = selectorEmpresaBase(model)

export function useConfigureEB () {
  const item = useSelector(selectBase)
  const voRef = React.useRef(null)
  const changesRef = React.useRef([])

  // console.warn('HOOK EMPRESA BASE', { item, voRef, changesRef })

  // const item = useSelector(selectorEmpresaBase(model))
  const handleBlock = useHistoryBlocker()

  const appLoading = useSelector(selectIsLoading)

  const onBack = React.useCallback(() => {
    // TODO esto pinta que debería ser una llamada a resetAllChanges
    changesRef.current = []
    voRef.current = null
  }, [voRef, changesRef])

  const {
    vo,
    onChangeObject: handleChangeObject,
    onChangeEvent: handleChange,
    onUndo: handleBack,
    resetAllChanges
  } = useChangeHandlers(voRef, item, changesRef, onBack, handleBlock)

  // Esto es una guarrada. By lolo
  React.useEffect(() => {
    if (appLoading || !item || vo) return
    // console.warn('configurar el Vo', { appLoading, item, vo })
    resetAllChanges()
  }, [appLoading, vo, item, resetAllChanges])

  const saveItem = useItemSaver(voRef, resetAllChanges, {
    model,
    shouldPost: (id) => (id === SHOW_CREATE),
    dispatcher (dispatch, sent, response, action) {
      (
        action.type === ITEM_UNSHIFT
          ? (dispatch, sent, response, action) => {
              dispatch(action)
              AuthService.reloadAccount()
            }
          : (dispatch, sent, response, action) => {
              dispatch(action,
                {
                  type: MainAction.PUT_EMPRESA,
                  payload: response.item
                })
            }
      )(dispatch, sent, response, action)
    }
  })

  const saveTuneado = React.useCallback((...args) => {
    // console.warn('saveTuneado')
    return saveItem({ pickNext: false })
  }, [saveItem])

  const onSave = useSaveHandlerTransitional({
    changesRef,
    saveItem: saveTuneado,
    handleClose: handleBack
  })

  const [blockLayout] = useBlockDialog({
    active: !!changesRef?.current,
    onSave,
    onDiscard: resetAllChanges,
    handleBlock
  })

  return {
    vo,
    blockLayout,
    changesRef,
    onSave,
    handleBack,
    handleChange,
    handleChangeObject,
    resetAllChanges
  }
}
