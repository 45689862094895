import React from 'react'

import { useSelector } from 'react-redux'

import { useDispatcher } from './useDispatcher'
import { useLocationDetails } from './useLocationDetails'

import { selectorFilters } from '../../store/selector'
import { filterController } from '../../store/dispatcher'

import {
  intervalToDuration
} from 'date-fns'

import {
  CONTACTO,
  CONCEPTO,
  TIEMPO,
  filterType
} from '../../store/filters/const'

export { createTimeFilters } from '../../store/filters/const'

export { CONTACTO, CONCEPTO, TIEMPO, filterType }

export function useFiltersControl () {
  const detalles = useLocationDetails()
  const filters = useSelector(
    React.useMemo(() => selectorFilters(detalles.order), [detalles])
  )

  // console.warn('useFiltersControl', { detalles, filters })
  const tiempo = filters.find(vo => filterType(vo) === TIEMPO)

  const { addFilter, popFilter } = useDispatcher(filterController)

  const mayPopFilter = filters.length > (detalles.order === 2 ? 1 : 0)

  return {
    ...(tiempo
      ? {
          view: tiempo.view,
          desde: tiempo.desde,
          hasta: tiempo.hasta,
          cuanto: intervalToDuration({
            start: tiempo.desde,
            end: tiempo.hasta
          })
        }
      : {}
    ),
    filters, // : filters[detalles.order] || [],
    addFilter: (filter, order = detalles.order) => addFilter(filter, order),
    popFilter: (quantity) => {
      return mayPopFilter && popFilter(detalles.order, quantity)
    },
    mayPopFilter,
    hasContacto: filters.some(vo => filterType(vo) === CONTACTO),
    hasConcepto: filters.some(vo => filterType(vo) === CONCEPTO),
    hasTiempo: filters.some(vo => filterType(vo) === TIEMPO)
  }
}
