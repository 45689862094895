import React from 'react'

import { MenuItem } from '@mui/material'

import Field from './Field'

export function AutoSelect ({ list, value, onChange, ...props }) {
  // TODO label

  const handleChange = (event, menuItem) => onChange(
    list.find(item => menuItem.props.value === item.id)
  )

  const v = (value || value === '')
    ? value
      ? (
          list.length > 1
            ? value
            : (list.find(item => Number(item.id) === value)?.name || value)
        )
      : value
    : list.length ? list[0][list.length > 1 ? 'id' : 'name'] : ''

  // console.info('Render AutoSelect', { value, list, v })

  return (
    <Field
      select={list.length > 1}
      value={v}
      disabled={list.length < 2}
      onChange={handleChange}
      {...props}
    >
      {list.map(option =>
        <MenuItem key={Number(option.id)} value={Number(option.id)}>
          {option.name}
        </MenuItem>
      )}
    </Field>
  )
}
