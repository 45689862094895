import React from 'react'

import TextField from '@mui/material/TextField'
import Skeleton from '@mui/material/Skeleton'
import { DateField } from '@mui/x-date-pickers/DateField'
import { GridItem } from './Grid'
import { TimeField } from '@mui/x-date-pickers'

/**
 * Este componente centraliza las opciones de estilo en un lugar
 */

export function FieldAlone ({ skeleton, ...props }) {
  const {
    fullWidth = true,
    inputProps,
    InputLabelProps,
    InputProps,
    FormHelperTextProps,
    variant = 'standard',
    margin = 'none'
  } = props
  if (skeleton) {
    return (
      <Skeleton width='100%'>
        <FieldAlone {...props} />
      </Skeleton>
    )
  }
  return (
    <TextField
      size='small'
      margin={margin}
      variant={variant}
      fullWidth={fullWidth}
      InputProps={{ ...InputProps }}
      inputProps={{ ...inputProps }}
      InputLabelProps={{ ...InputLabelProps }}
      FormHelperTextProps={{ ...FormHelperTextProps }}
      {...props}
    />
  )
}

export function FieldDateTimeAlone (props) {
  const { showtime = false, ...other } = props

  return (
    showtime
      ? (
        <TimeField
          placeholder='hh:mm'
          {...other}
        />
        )
      : (
        <DateField
          placeholder='dd/mm/yy'
          {...other}
        />
        )
  )
}

export function FieldDateTime (props) {
  const {
    className,
    xs = true,
    sm,
    md,
    lg,
    xl,
    ...other
  } = props

  return (
    <GridItem
      className={className}
      {...{ xs, sm, md, lg, xl }}
    >
      <FieldDateTimeAlone
        className={className}
        variant='standard'
        margin='none'
        size='small'
        {...other}
      />
    </GridItem>
  )
}

export function Field (props) {
  const {
    className,
    xs = true,
    sm,
    md,
    lg,
    xl,
    ...other
  } = props

  return (
    <GridItem
      className={className}
      {...{ xs, sm, md, lg, xl }}
    >
      <FieldAlone className={className} {...other} />
    </GridItem>
  )
}

export default Field
