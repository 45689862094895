import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import { MainAction } from '../../store/const'

import {
  DIALOG_CANCEL
} from '../Abc/Tarjeta/Dialogs'

import * as fecha from '../../utils/fecha'

import { CaraFeliz, CaraNormal, CaraTriste, Euro } from './ActividadItemBadge'

const { SHOW_CREATE } = MainAction

export default function DialogEstadoActividadMultiple (props) {
  const {
    vo,
    contacto,
    onClose,
    handleShowEstado,
    handleChangeObject,
    onSave,
    onDestroy,
    onForceClose,
    ...other
  } = props

  const id = props.id || Math.random()
  const idTitle = `dialog-title-${id}`

  const noFacturadas = vo.actividades.filter(actividad =>
    !actividad.fk_factura || actividad.fk_factura === '')

  const todasAbiertas = noFacturadas.length > 0 &&
    noFacturadas.every(actividad => actividad.estado === 'abierta' ||
    actividad.estado === 'atrasada')

  const todasCerradas = noFacturadas.length > 0 &&
    noFacturadas.every(actividad => actividad.estado === 'cerrada')

  const todasAnuladas = noFacturadas.length > 0 &&
    noFacturadas.every(actividad => actividad.estado === 'anulada')

  const ningunaFacturada = noFacturadas.length > 0 &&
    (vo.actividades.length - noFacturadas.length) === 0

  const todasFacturadas = (vo.actividades.length - noFacturadas.length) === vo.actividades.length

  /* const handleEmit = (action) => {
    onAction(action)
    // window.location.href = `local.agendayfacturacion.es/${vo.fk_empresa}/facturacion/${}`
  } */

  // console.log(vo)

  const handleGroupChange = (changes) => {
    console.warn(vo)
    handleChangeObject({
      actividades: vo.actividades.map((actividad) => (
        actividad.fk_factura
          ? actividad
          : { ...actividad, ...changes }
      ))
    })
  }

  const handleDestroy = () => {
    if (ningunaFacturada) {
      onDestroy()
    } else {
      const actividades = vo.actividades.filter(actividad =>
        actividad.fk_factura)
      const changes = { actividades }
      if (actividades.length < 2) {
        changes.concepto = actividades[0].concepto
        changes.cantidad = actividades[0].cantidad
        changes.precio = actividades[0].precio
        changes.descuento = actividades[0].descuento
        changes.id_actividad = actividades[0].id_actividad
        changes.fk_factura = actividades[0].fk_factura || ''
        /* changes.id_actividad_paralela = actividades[0].id_actividad
        changes.isParalela = false */
      }
      handleChangeObject(changes)
      // onClose()
    }
  }

  /* console.log({
    vo,
    todasAnuladas,
    noFacturadas,
    ningunaFacturada,
    ninguna: vo.actividades.length - noFacturadas.length,
    actividades: vo.actividades.length,
    noFact: noFacturadas.length
  }) */

  const handleActionMultiple = (action) => {
    switch (action) {
      case 'pass':
        handleGroupChange({
          estado: 'cerrada',
          fecha_cerrada: fecha.input(new Date()),
          fecha_anulada: null
        })
        break
      case 'fail':
        handleGroupChange({
          estado: 'anulada',
          fecha_cerrada: null,
          fecha_anulada: fecha.input(new Date())
        })
        break
      case 'open':
        handleGroupChange({
          estado: 'abierta',
          fecha_cerrada: null,
          fecha_anulada: null
        })
        break
      default:
        throw new TypeError(`Acción irreconocible: ${action}`)
    }
    onClose()
    // Guardar y hacer la acción a la vez
    noFacturadas.length > 0 && (
      onSave({ pickNext: true, force: true })
        .then((response) => {
          onForceClose()
          // Cerrar dialogo estado y dialogo actividad
          // OJO: Quizá nos de problemas si provoca unmount del componente
          // onForceClose()
        }))
  }

  return (
    <Dialog
      onClose={() => onClose(DIALOG_CANCEL)}
      title='Hacer actividad'
      fullWidth
      maxWidth='sm'
      aria-labelledby={idTitle}
      {...other}
    >
      <DialogTitle id={idTitle}>
        {
          todasFacturadas
            ? 'Ya está facturada!'
            : 'Qué estado quieres asignar?'
        }
      </DialogTitle>
      {todasFacturadas && (
        <DialogContent>
          <DialogContentText>
            No se puede cambiar de estado porque todas
            las actividades están facturadas.
          </DialogContentText>
        </DialogContent>
      )}
      {!todasFacturadas &&
        <List disablePadding>
          <ListItemButton
            data-cy='resolver-abierta'
            selected={todasAbiertas}
            disabled={todasAbiertas}
            onClick={() => handleActionMultiple('open')}
          >
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: 'var(--info)' }}>
                <CaraNormal fontSize='default' />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary='Todas Pendientes'
              secondary='Aún no se han hecho'
            />
          </ListItemButton>
          <ListItemButton
            data-cy='resolver-cerrada'
            selected={todasCerradas}
            disabled={todasCerradas}
            onClick={() => handleActionMultiple('pass')}
          >
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: 'var(--done)' }}>
                <CaraFeliz fontSize='default' />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary='Finalizadar todas'
              secondary='Todas finalizadas'
            />
          </ListItemButton>
          <ListItemButton
            data-cy='resolver-emitir'
            disabled={contacto?.fk_entidad === null}
            // onClick={() => handleEmit('pass')}
          >
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: 'var(--warn)' }}>
                <Euro fontSize='default' />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary='Finalizar y emitir'
              secondary='Emitir factura'
            />
          </ListItemButton>
          <ListItemButton
            data-cy='resolver-anulada'
            selected={todasAnuladas}
            disabled={todasAnuladas}
            onClick={() => handleActionMultiple('fail')}
          >
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: 'var(--fail)' }}>
                <CaraTriste fontSize='default' />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary='Todas canceladas'
              secondary='No se van a hacer'
            />
          </ListItemButton>
          {vo.id_actividad !== SHOW_CREATE && (
            <ListItemButton
              data-cy='eliminar'
              onClick={handleDestroy}
            >
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: 'var(--fail)' }}>
                  <DeleteForeverIcon fontSize='default' />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary='Eliminar'
                secondary='Eliminar actividad'
              />
            </ListItemButton>
          )}
        </List>}
      <DialogActions>
        <Button onClick={(e) => {
          e.stopPropagation()
          onClose(DIALOG_CANCEL)
        }}
        >
          Atrás
        </Button>
      </DialogActions>
    </Dialog>
  )
}
