import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Paper } from '@mui/material'

export default function ForgotPass (props) {
  const [values, setValues] = useState({
    email: '',
    password: '',
    code: ''
  })

  const navigate = useNavigate() // este es el bueno aun que se deberia ver paramas
  const params = useParams()

  useEffect(() => {
    setValues({ ...values, email: params.email, code: params.code })
  }, [
    values,
    params.email,
    params.code
  ])

  console.log(navigate, 'history')
  console.log(params, 'params')

  /*  const checkPasswd = (pass, newPass) => {
    // TODO verificar password
  } */

  const handlerLogin = (ev) => {
    ev.preventDefault()
    console.log(values) // TODO mandar parametros
    navigate('/')
  }

  const handlerChange = (ev) => {
    ev.preventDefault()
    setValues({ ...values, [ev.target.name]: ev.target.value })

    console.log(ev)
  }

  const handleBack = () => navigate(-1)

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Paper
        sx={{
          marginTop: '8rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar>
          <LockOpenIcon />
        </Avatar>
        <Typography component='h1' variant='h4'>
          Cambiar Contraseña
        </Typography>
        <form onSubmit={handlerLogin}>
          <TextField
            error={values.error}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            value={values.email}
            onChange={handlerChange}
            autoFocus
          />
          <TextField
            error={values.error}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='code'
            label='Codigo de verificación'
            name='code'
            value={values.code}
            onChange={handlerChange}
          />
          <TextField
            error={values.error}
            variant='outlined'
            margin='normal'
            type='password'
            required
            fullWidth
            id='newpass'
            label='Nueva Contraseña'
            name='password'
            value={values.password}
            onChange={handlerChange}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
          >
            Enviar
          </Button>
          <Grid container>
            <Grid item xs>
              <Button onClick={handleBack}>
                Volver
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  )
}
