import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

import axios from './service/empresas'

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom'

import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import CssBaseline from '@mui/material/CssBaseline'

import Header from './componentes/Abc/Header/Header.jsx'
import Footer from './componentes/Abc/Footer'
import Loading from './componentes/Abc/Loading'

import ErrorPage from './componentes/shared/ErrorPage'

import { secure, nobody } from './componentes/Auth/AccessControl'

// Vistas de autenticación
import AuthLogout from './componentes/Auth/Logout'
import AuthPasswd from './componentes/Auth/Passwd'
// import AuthSignIn from './componentes/Auth/SignIn'
import LoginPage from './componentes/auth/LoginPage'
import ForgotPass from './componentes/auth/ForgotPass'
import Registro from './componentes/auth/Registro'
import ResetPasswd from './componentes/auth/ResetPasswd'
// import PrivateRoute from './componentes/auth/PrivateRoute'

// Vistas de Actividad
import ActividadList from './componentes/Actividad/ActividadList'

// Vistas de Usuario
import EmpresaList from './componentes/Usuario/EmpresaList'

// Vistas provisionales
import Agenda from './componentes/Agenda/Agenda'
import ContactoList from './componentes/Contactos/ContactoList'
import FacturaList from './componentes/Facturas/FacturaList'
import BorradorList from './componentes/Facturas/BorradorList'
import ConceptoList from './componentes/Conceptos/ConceptoList'
import EmpresaConfiguracion from './componentes/Usuario/EmpresaConfiguracion'

import './App.scss'

import { initAgendaFilter } from './store/filters/action'
import { selectorUser, selectorEmpresa, selectorIsLoading } from './store/selector'

const { sessionStorage, localStorage } = global

const selectUser = selectorUser()
const selectEmpresa = selectorEmpresa()

export default function App (props) {
  const user = useSelector(selectUser)
  const empresa = useSelector(selectEmpresa)

  // TODO creo que esto estaría mejor en service/auth.jsx mediante subscribe
  React.useEffect(() => {
    console.info('Almacenando user y empresa en sesión', user, empresa)
    sessionStorage.setItem('abrela:user', JSON.stringify(user))
    localStorage.setItem('abrela:empresa', JSON.stringify(empresa))
  }, [user, empresa])

  const dispatch = useDispatch()
  const getDatalists = useGetDatalists(dispatch)

  React.useEffect(() => {
    if ((!empresa || !empresa.id) || !user) {
      console.warn('Creo que no hay nada que cargar')
      dispatch({ type: 'DONE_LOADING', payload: [] })
      return
    }

    console.info('Cargando listados de datos', empresa)
    getDatalists(empresa.id)

    return () => {
      console.warn('Se desmonta App, o la empresa ha cambiado')
    }
  }, [empresa, getDatalists, dispatch, user])

  const prefix = empresa ? `/${empresa.id}` : ''

  // console.info('Render App', { user, empresa }, nobody(AuthPasswd))

  return (
    <BrowserRouter>
      <CssBaseline />
      <ReactNotifications />
      <Header
        destinos={{
          // Se especifican aquí para que estén al lado del Router
          1: `${prefix}/contactos`,
          2: `${prefix}/agenda`,
          3: `${prefix}/actividades`,
          4: `${prefix}/facturacion/borradores`,
          5: '/usuario'
        }}
      />
      <main id='main'>
        <Rutas user={user} />
      </main>
      <Footer />
    </BrowserRouter>
  )
}

function Rutas (props) {
  const { user } = props

  const isLoadingDatalists = useSelector(React.useMemo(selectorIsLoading, []))

  if (user && isLoadingDatalists) {
    return <Loading />
  }

  return (
    <Routes>
      <Route path='/'>
        <Route end path='' element={<Navigate to='/usuario/empresas' replace />} />

        <Route path='auth'>
          <Route path='passwd' element={nobody(AuthPasswd)}>
            <Route path=':code&:email' element={ForgotPass} />
          </Route>
          <Route path='logout' element={secure(AuthLogout)} />
          <Route path='login' element={nobody(LoginPage)} />
          <Route path='reset' element={nobody(ResetPasswd)} />
          <Route path='registro' element={nobody(Registro)} />
        </Route>

        <Route path='usuario'>
          <Route path='empresas' element={secure(EmpresaList)}>
            <Route path=':id' element={secure(EmpresaList)} />
          </Route>
          <Route path='' element={<Navigate to='/usuario/empresas' replace />} />
        </Route>

        <Route path=':idEmpresa'>
          <Route path='contactos' element={secure(ContactoList)}>
            <Route path=':id' element={secure(ContactoList)} />
          </Route>

          <Route path='agenda' element={secure(Agenda)}>
            <Route path=':id' element={secure(Agenda)} />
          </Route>

          <Route path='actividades' element={secure(ActividadList)}>
            <Route path=':id' element={secure(ActividadList)} />
          </Route>

          <Route path='facturacion'>
            <Route path='borradores' element={secure(BorradorList)}>
              <Route path=':id' element={secure(BorradorList)} />
            </Route>
            <Route path='emitidas' element={secure(FacturaList)}>
              <Route path=':id' element={secure(FacturaList)} />
            </Route>
          </Route>

          <Route path='conceptos' element={secure(ConceptoList)} />
          <Route path='configuracion' element={secure(EmpresaConfiguracion)}>
            <Route path='conf-empresa' element={secure(EmpresaConfiguracion)} />
            <Route path='conf-contactos' element={secure(EmpresaConfiguracion)} />
            <Route path='conf-actividades' element={secure(EmpresaConfiguracion)} />
            <Route path='conf-facturas' element={secure(EmpresaConfiguracion)} />
            <Route path='conceptos' element={secure(EmpresaConfiguracion)}>
              <Route path=':id' element={secure(EmpresaConfiguracion)} />
            </Route>
            <Route path='conf-documentos' element={secure(EmpresaConfiguracion)} />
          </Route>

          <Route path='*' element={<ErrorPage />} />
        </Route>

        <Route path='contactos' element={secure(ContactoList)} />
        <Route path='agenda' element={secure(Agenda)} />
        <Route path='actividades' element={secure(ActividadList)} />
        <Route path='facturacion/borradores' element={secure(FacturaList)} />
        <Route path='conceptos' element={secure(ConceptoList)} />
        <Route path='configuracion' element={secure(EmpresaConfiguracion)} />
      </Route>
      <Route path='*' element={<ErrorPage />} />
    </Routes>
  )
}

function useGetDatalists (dispatch) {
  const listTrap = (data) => {
    const list = data.list.slice(0)
    list.total = data.total
    return list
  }

  return function getDatalists (id) {
    const urls = [
      '/',
      `/${id}`,
      `/${id}/contactos`,
      `/${id}/conceptos`
    ]
    dispatch({ type: 'INIT_LOADING', payload: urls })
    return Promise.all(urls.map(uri => axios.get(uri)))
      .then(([empresas, empresa, contactos, conceptos]) => {
        dispatch({
          type: 'MULTIACTION',
          payload: [
            {
              type: 'LOAD_DATALISTS',
              empresa: empresa.data.item,
              empresas: listTrap(empresas.data),
              contactos: listTrap(contactos.data),
              conceptos: listTrap(conceptos.data)
            },
            initAgendaFilter(empresa.data.item),
            {
              type: 'DONE_LOADING',
              payload: urls
            }
          ]
        })
      })
      .catch(error => {
        console.error(error)
        dispatch({ type: 'FAIL_DATALISTS', error })
      })
  }
}

/*
En el ruteo todos las vistas que necesiten ir sin header como todas las de auth deben seguir
este patron /auth/loquesa
El resto de rutas que si llevan el componente header deben de seguir este patron
/nombre_de_la_vista por ejemplo /agenda
*/
/* vim: set expandtab: */
/* vim: set filetype=javascript ts=2 shiftwidth=2: */
