import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { pickerEmpresa } from '../../store/dispatcher'

import { MainAction } from '../../store/const'

import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import AvatarGroup from '@mui/material/AvatarGroup'
import Grid from '@mui/material/Grid'
import Collapse from '@mui/material/Collapse'
import Fade from '@mui/material/Fade'
import EmpresaSeleccionadaIcon from '@mui/icons-material/AssignmentTurnedIn'
import AjustesEmpresa from '@mui/icons-material/Build'

import { GridContainer, Field } from '../Abc/FormUtils'
import WidgetPostal, { Domicilio } from '../Abc/FieldSet/WidgetPostal'

import TarjetaItemHOC from '../Abc/Tarjeta/TarjetaItemHOC'
import TarjetaItemMenu from '../Abc/Tarjeta/TarjetaItemMenu'

export function EmpresaItem (props) {
  const { vo, selected, errored, changesRef } = props

  const { onBack, onSave, onDestroy } = props

  const { handleChangeEvent: handleChange, handleChangeObject } = props

  const { SHOW_CREATE } = MainAction

  // console.info(`Render EmpresaItem ${vo.alias}`, { vo, props })

  const isSeleccionada = props.empresa && (props.empresa.id === vo.fk_empresa)

  // console.error(isSeleccionada, vo.fk_empresa)

  const dispatch = useDispatch()
  const selectEmpresa = pickerEmpresa(dispatch)
  const navigate = useNavigate()

  return (
    <Grid container>
      <Grid component='form' item sm={selected ? 12 : 11}>
        <Collapse in={selected} mountOnEnter unmountOnExit>
          <TarjetaItemMenu
            back={onBack}
            save={onSave}
            destroy={onDestroy}
            changed={changesRef.current}
          >
            {isSeleccionada
              ? (
                <IconButton
                  data-cy='pick'
                  aria-label='Ajustes Empresa'
                  title='Ajustes Empresa'
                  // onDestroy es falsy en "tarjeta nueva"
                  disabled={(vo?.fk_empresa === SHOW_CREATE)}
                  onClick={() => navigate(`/${vo.fk_empresa}/configuracion`)}
                >
                  <AjustesEmpresa />
                </IconButton>
                )
              : (
                <IconButton
                  data-cy='pick'
                  aria-label='Seleccionar Empresa'
                  title='Seleccionar Empresa'
                  // onDestroy es falsy en "tarjeta nueva"
                  disabled={isSeleccionada || !onDestroy || (vo?.fk_empresa === SHOW_CREATE)}
                  onClick={() => selectEmpresa(vo.fk_empresa)}
                >
                  <EmpresaSeleccionadaIcon />
                </IconButton>
                )}
          </TarjetaItemMenu>
        </Collapse>
        <GridContainer rowSpacing={2} sx={{ marginLeft: 'auto' }}>
          <Field
            xs={6}
            name='alias'
            label='Alias'
            value={vo?.alias || ''}
            error={!!errored.alias}
            required
            onChange={handleChange}
            inputProps={{ readOnly: !selected, tabIndex: selected ? 0 : -1 }}
          />
          <Field
            xs={6}
            name='razon'
            label='Razon Social'
            value={vo?.razon || ''}
            error={!!errored.razon}
            required
            onChange={handleChange}
            inputProps={{ readOnly: !selected, tabIndex: selected ? 0 : -1 }}
          />
        </GridContainer>
        <Collapse in={selected} mountOnEnter unmountOnExit sx={{ paddigTop: '16px' }}>
          <GridContainer sx={{ marginLeft: 'auto' }} rowSpacing={2}>
            <Field
              xs={6}
              name='nif'
              label='NIF/CIF'
              value={vo?.nif || ''}
              error={!!errored.nif}
              required
              onChange={(e) => handleChangeObject({
                nif: e.target.value.toUpperCase()
              })}
            />
            <Field
              xs={6}
              required
              label='Email'
              name='email'
              value={vo?.email || ''}
              error={!!errored.email}
              onChange={handleChange}
            />
            <Domicilio
              xs={12}
              value={vo?.domicilio || ''}
              error={!!errored.domicilio}
              required
              onChange={handleChange}
            />
            <WidgetPostal
              xs={6}
              vo={vo}
              errored={errored}
              required
              onChange={handleChangeObject}
            />
          </GridContainer>
        </Collapse>
      </Grid>
      <Grid item className='empresa-activa'>
        <Fade
          in={!selected && isSeleccionada}
          orientation='horizontal'
        >
          <AvatarGroup>
            <Avatar className='sm-avatar empresa-badge'>
              <EmpresaSeleccionadaIcon fontSize='small' />
            </Avatar>
          </AvatarGroup>
        </Fade>
      </Grid>
    </Grid>
  )
}

export default TarjetaItemHOC(EmpresaItem, {
  classes: (item, props) => {
    if (props.empresa && item.fk_empresa === props.empresa.id) {
      return 'empresa-activa'
    }
    return ''
  }
})
