import axios from '../'
import ExportService from '../../export'
import goldenSoftExport from '../../../utils/xlsExport/GoldenSoftExport'
import glasofExport from '../../../utils/xlsExport/GlasofExport'
import contacto from './Contacto'
import Actividad from './Actividad'
import BaseModel from './AbstractModel'

import { TRANSFERENCIA } from '../../../componentes/Abc/FieldSet/Opciones'

import * as fecha from '../../../utils/fecha'
import { message } from '../../../utils/notify'

import store from '../../../store'
import { ListAction } from '../../../store/const'
import { multipleDispatcher } from '../../../store/dispatcher'
import {
  wipeDispatcher
} from '../../../store/modelDispatcher/FacturaDispatcher'

import {
  /* CONCEPTO, */CONTACTO, TIEMPO, filterType
} from '../../../store/filters/const'

const { LOAD_REFRESH } = ListAction

const multiple = multipleDispatcher(store.dispatch.bind(store))

export default class Factura extends BaseModel {
  static get pk () { return 'id_factura' }
  static get single () { return 'factura' }
  static get plural () { return 'facturas' }
  static get axios () { return axios }

  static getItemUrl (fkEmpresa, id) {
    return `/${fkEmpresa}/facturacion/emitidas/${id}`
  }

  static getListUrl (fkEmpresa) {
    return `/${fkEmpresa}/facturacion/emitidas`
  }

  static defaults (confEmpresa, filters) {
    // TODO deducirlo de confEmpresa.fk_plazo_cobro_defecto
    const plazoCobroDefecto = { dias: 0 }
    const fechaFra = fecha.input(new Date())
    const _contacto = filters.find(filter => filterType(filter) === CONTACTO) || { ...contacto.defaults }
    /* const _concepto = filters.find(filter => filterType(filter) === CONCEPTO) || (
      _contacto.id_contacto ? { ...concepto.defaults } : null
    ) */
    return ({
      id_factura: null,
      fecha: fechaFra,
      vence: fecha.input(fecha.fromPlazo(fechaFra, plazoCobroDefecto)),
      fecha_pagada: null,
      contacto: _contacto || {},
      fk_empresa: confEmpresa?.fk_empresa,
      actividades: [{
        ...Actividad.defaults(confEmpresa, filters),
        id_actividad: null,
        contacto: {},
        precio: ''
      }],
      fk_iva: confEmpresa ? confEmpresa.fk_iva_defecto : 1,
      fk_metodo_cobro:
        confEmpresa ? confEmpresa.fk_metodo_cobro_defecto : 1,
      iban: confEmpresa
        ? confEmpresa.fk_metodo_cobro_defecto === TRANSFERENCIA
          ? confEmpresa.iban
          : ''
        : ''
    })
  }

  static defineFilters (filters) {
    const filtroContacto = filters.find(vo => filterType(vo) === CONTACTO)
    const filtroTiempo = filters.find(vo => filterType(vo) === TIEMPO)
    const _filters = {
      fk_pagador: filtroContacto ? filtroContacto[contacto.pk] : null,
      desde: filtroTiempo ? fecha.input(filtroTiempo.desde) : null,
      hasta: filtroTiempo ? fecha.input(filtroTiempo.hasta) : null
    }
    return (_filters)
  }

  static beforeSend (data) {
    if (!data.emitir) {
      data.emitir = false
    }
    data.empresa = { ...data.empresa }
    delete data.empresa.logo

    const sent = {
      ...data,
      emision_prevista: data.fecha,
      id_borrador: null
    }
    return sent
  }

  static async findAll (filters, params) {
    const _filters = this.defineFilters(filters)

    return await super.findAll(_filters, params)
  }

  static async create (data = {}, opts = {}) {
    const sent = this.beforeSend(data)
    return await super.create(sent, opts)
  }

  static async update (data = {}, opts = {}) {
    const sent = this.beforeSend(data)
    return await super.update(sent, opts)
  }

  static async destroy (data = {}, opts = {}) {
    return await super.destroy(data, opts, wipeDispatcher)
  }

  static getNextId (fkEmpresa, fecha) {
    return (
      axios
        .get(`/${fkEmpresa}/facturacion/emitidas/next`, { params: { fecha } })
        .then(response => {
          return response.data.nextIdFactura
        })
        .catch(err => {
          if (err.response.status === 422) {
            // errores de validacion
            console.warn(err)
            return err
          }
          console.warn(err)
          return (err)
        })
    )
  }

  static updatePago (data = {}, opts = {}) {
    const {
      dispatcher = (dispatch, sent, response, action) => dispatch(action),
      notifier = (data, status) => message(data.message, status),
      catcher = (error) => { throw error }
    } = opts

    const empresa = store.getState().empresa

    if (!empresa || !empresa.id) {
      return Promise
        .reject(new Error('No hay empresa seleccionada'))
        .catch(catcher)
    }

    const sent = this.beforeSend(data)

    return axios
      .patch(`/${empresa.id}/facturacion/emitidas/${data.id}/pagada`, sent)
      .then(response => {
        // const { data: { item } } = response

        notifier && notifier(response.data, response.status)

        const action = {
          type: LOAD_REFRESH,
          plural: this.plural
        }
        dispatcher(multiple, sent, response, action)
        return { response, data }
      })
      .catch(catcher)
  }

  static printPdf (fkEmpresa, vo) {
    super.printPdf(super.getPrintUrl(fkEmpresa, 'facturas', vo.id_factura), vo.id_factura)
  }

  static exportXls (filters, params) {
    const _filters = this.defineFilters(filters)

    return ExportService.get(`${params.fk_empresa}/facturacion/export`, {
      params: { ...params, ..._filters }
    })
      .then(response => {
        switch (params.software) {
          case 'goldenSoft':
            goldenSoftExport(response.data)
            break
          case 'glasof':
            glasofExport(response.data)
            break
        }
      })
      .catch(error => {
        console.error({ error })
      })
  }
}
