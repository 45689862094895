import React from 'react'
import { useSelector } from 'react-redux'
import tesoreria from '@grupomarea/abrela-tesoreria'

import Stack from '@mui/material/Stack'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Portal from '@mui/material/Portal'

import {
  selectorIsLoading,
  selectorEmpresa
} from '../../../../store/selector'

import { useLocationDetails } from '../../../../utils/hooks'

import SearchCalendar from './SearchCalendar'

import QuickFilters from './QuickFilters'
import SwitchSettigns from './SwitchSettings'
import ExportList from './ExportList'
import { GridContainer, GridItem } from '../../FormUtils/Grid'

const selectIsLoading = selectorIsLoading()
const selectEmpresa = selectorEmpresa()

export default function Drawer (props) {
  const { expanded } = props

  const appLoading = useSelector(selectIsLoading)

  const empresa = useSelector(selectEmpresa)

  const totalFacturas = useSelector(state => state.facturas.totales || null)

  const detalles = useLocationDetails()

  const container = document.getElementById('main')

  if (appLoading) {
    return <div className='searchBar'>Cargando!</div>
  }

  return (
    detalles.drawer && (
      <Portal container={props.drawerContainerRef.current}>
        <SwipeableDrawer
          onOpen={() => null}
          onClose={() => null}
          className='settings-container'
          variant='persistent'
          container={container}
          anchor='left'
          open={expanded}
          disableSwipeToOpen={false}
        >
          <Stack
            data-cy='settings-drawer'
            alignItems='center'
            spacing={3}
            sx={{ paddingTop: '120px' }}
          >
            <QuickFilters fkEmpresa={empresa?.id} />
            {
              detalles.index !== 0 &&
                <SearchCalendar
                  location={detalles.index}
                />
            }
            {
              detalles.index === 3 &&
              detalles.name === 'Facturas' && (
                <>
                  <GridContainer
                    spacing={2}
                    sx={{
                      padding: '1rem',
                      fontSize: '1rem'
                    }}
                  >
                    <GridItem
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #00000024'
                      }}
                    >
                      <span>Total facturado en el período: </span>
                      <span>{tesoreria.formatEUR(totalFacturas?.base || 0)}</span>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #00000024'
                      }}
                    >
                      <span>IVA repercutido en el período: </span>
                      <span>{tesoreria.formatEUR(totalFacturas?.iva || 0)}</span>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #00000024'
                      }}
                    >
                      <span>Total: </span>
                      <span>{tesoreria.formatEUR(totalFacturas?.total || 0)}</span>
                    </GridItem>
                  </GridContainer>
                </>
              )
            }
            <SwitchSettigns
              detalles={detalles}
              order={detalles.order}
            />
            {detalles.index === 3 && (
              <ExportList fkEmpresa={empresa?.id} />
            )}
          </Stack>
        </SwipeableDrawer>
      </Portal>
    )
  )
}
