import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

import { FieldDateTime } from './Field'
import { GridItemHOC } from './Grid'

import './MuiTunes.scss'

export const commonProps = {
  invalidDateMessage: 'Fecha inválida',
  maxDateMessage: 'La fecha está por encima del máximo',
  minDateMessage: 'La fecha está por debajo del mínimo',
  format: 'dd/MM/yy'
}

export function DatePickerHOC (Component) {
  return ({ value, error, onChange, onInvalid, ...props }) => {
    // console.warn('HOC corrector', value, props)
    const handleChange = typeof onInvalid === 'function'
      ? (date, ...etc) => {
          ;(
            date !== null && isNaN(date.getTime()) ? onInvalid : onChange
          )(date, ...etc)
        }
      : onChange
    return (
      <Component
        value={value}
        error={error || (
          value !== null && value.getTime && isNaN(value.getTime())
        )}
        onChange={handleChange}
        {...props}
      />
    )
  }
}

export const DateWidget = DatePickerHOC(function DateWidget (props) {
  const {
    name,
    value = null,
    fieldProps = {},
    clearable = false,
    clearText,
    ...other
  } = props
  return (
    <MobileDatePicker
      DialogProps={{ 'data-cy': 'selector-fecha' }}
      value={value}
      openTo='day'
      slots={{ field: FieldDateTime }}
      slotProps={{
        field: { ...fieldProps, name },
        actionBar: {
          actions: [clearable ? 'clear' : 'cancel', 'today', 'accept']
        }
      }}
      {...commonProps}
      {...other}
    />
  )
})

export default GridItemHOC(DateWidget)
