import React from 'react'
import DialogContacto from './DialogContacto'
import { IconButton } from '@mui/material'
import Button from '@mui/material/Button'

import {
  Undo as ArrowBackIcon,
  Delete as DeleteIcon,
  Clear as CancelIcon,
  Check as CheckIcon,
  Call as CallIcon,
  MailOutline as EmailIcon,
  HelpOutline as UnknownIcon,
  AccountCircleOutlined as UserIcon
} from '@mui/icons-material'

import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import SaveIcon from '@mui/icons-material/Save'

export default function TarjetaItemMenu ({
  back: handleBack,
  save: handleSave,
  contacto,
  facturada,
  openWidget,
  cancel: handleCancel,
  call: phone,
  mail: email,
  mailFactory,
  destroy: handleDestroy,
  changed = [],
  className = '',
  ...props
}) {
  // console.log('Render TarjetaItemMenu', { props })
  const [openContacto, setOpenContacto] = React.useState({ contacto: null, open: false })

  const handleWhatsApp = (event) => window.open(`https://wa.me/34${phone}`, '_self')
  const handleCall = (event) => window.open(`tel:+34${phone}`, '_self')
  const handleMail = (event) => {
    if (typeof mailFactory !== 'function') {
      return window.open(`mailto:${email}`, '_self')
    }
    window.open(`mailto:${email}?${
      Object.entries(mailFactory(event))
        .map(([key, val]) => `${key}=` + encodeURIComponent(val)).join('&')
    }`, '_self')
  }

  const handleContacto = (contacto) => {
    setOpenContacto({ contacto, open: true })
  }

  return (
    <menu className={'card-menu ' + className}>
      {openContacto.open &&
        <DialogContacto
          contacto={openContacto.contacto}
          open={openContacto.open}
          setOpenContacto={setOpenContacto}
          mailFactory={mailFactory}
          openWidget={openWidget}
          facturada={facturada}
        />}
      {
        handleBack &&
          <IButton
            data-cy='back'
            title='Atrás / deshacer'
            onClick={handleBack}
            Icon={ArrowBackIcon}
            iconProps={{ fontSize: 'small' }}
          />
      }
      {
        (phone || phone === '') &&
          <IButton
            data-cy='call'
            title='Llamar por teléfono'
            disabled={!phone}
            onClick={handleCall}
            Icon={CallIcon}
            iconProps={{ fontSize: 'small' }}
          />
      }
      {
        (phone || phone === '') &&
          <IButton
            data-cy='whatsapp'
            title='Mensaje de whatsapp'
            disabled={!phone}
            onClick={handleWhatsApp}
            Icon={WhatsAppIcon}
            iconProps={{ fontSize: 'small' }}
          />
      }
      {
        (email || email === '') &&
          <IButton
            data-cy='mail'
            title='Enviar un email'
            disabled={!email}
            onClick={handleMail}
            Icon={EmailIcon}
            iconProps={{ fontSize: 'small' }}
          />
      }
      {
        (contacto || contacto === '') &&
          <Button
            onClick={(e) => {
              e.stopPropagation()
              handleContacto(contacto)
            }}
            className='info-contacto'
            data-cy='info-contacto'
            title='Informacion Contacto'
            disabled={!contacto}
            startIcon={<UserIcon />}
          >
            {contacto.alias ? contacto.alias : 'Contacto'}
          </Button>
      }
      {
        handleDestroy &&
          <IButton
            data-cy='wipe'
            title='Destruír'
            onClick={handleDestroy}
            Icon={DeleteIcon}
            iconProps={{ fontSize: 'small', color: 'error' }}
          />
      }
      {
        handleSave &&
          (changed.length
            ? <IButton
                data-cy='save'
                title='Guardar'
                onClick={() => handleSave({ pickNext: null })}
                Icon={SaveIcon}
                iconProps={{ fontSize: 'small', color: 'primary' }}
              />
            : <IButton
                data-cy='save'
                title='Guardar'
                onClick={handleBack}
                Icon={CheckIcon}
                iconProps={{ fontSize: 'small', color: 'primary' }}
              />)
      }
      {props.children}
      {
        handleCancel &&
          <IButton
            data-cy='cancel'
            title='Cancelar'
            onClick={handleCancel}
            Icon={CancelIcon}
            iconProps={{ fontSize: 'small', color: 'error' }}
          />
      }
    </menu>
  )
}

export function IButton ({ Icon, iconProps, ...props }) {
  if (!Icon) Icon = UnknownIcon
  return (
    <IconButton
      aria-label={props.title}
      {...props}
    >
      {
        // TODO fontSize='small'
        // ver https://material-ui.com/es/api/icon/
      }
      <Icon {...iconProps} />
    </IconButton>
  )
}
