import React from 'react'

/**
 * Este hook facilita la creación de un callback que realiza acciones sobre
 * un elemento, entendidas como peticiones POST sobre su URL
 */
export function useItemEraser (voRef, config = {}) {
  const { model } = config

  return React.useCallback(
    (action) => {
      if (!voRef.current) {
        return Promise.reject(new Error('No hay referencia al VO!'))
      }
      return model.destroy(voRef.current)
        .then(response => {
          switch (response.status) {
            case 200:
            case 201:
              voRef.current = null
              break
            default:
              break
          }
          return response
        })
        .catch(err => {
          if (!err.response) throw err
          switch (err.response.status) {
            case 422: // Errores de validación
              break
            default:
              console.error(err)
          }
          return err.response
        })
    },
    [voRef, model]
  )
}
