import React from 'react'

import { useCardPicker } from './useCardPicker'

/**
 * Este hook facilita el setup de un callback que crea o actualiza un elemento
 */
export function useItemSaver (voRef, resetChanges, config = {}) {
  const { model, shouldPost, afterSave } = config

  if (
    typeof resetChanges !== 'function'
  ) {
    throw new Error('Esta API ha cambiado')
  }

  const pickCard = useCardPicker(model)

  // console.warn('USEITEMSAVER, SIGUIENDO EL VOREF', voRef.current)

  return React.useCallback(
    /**
     * La interfaz (argumentos) de esta función debe ser estándar.
     * Existen múltiples casos de uso y es necesario poder especificar
     * qué debe ocurrir una vez se ha guardado el elemento
     */
    // Signature antiguo: (pickNextId = null, force = false, catcher = true)
    // pickNext:
    //   - *: "Enfoca el item indicado una vez realizado el guardado"
    //   - null: "Desenfoca el item que se encuentre enfocado"
    //   - true: "Enfoca el item con el id de la respuesta"
    //   - false: "No hace nada una vez realizado el guardado"
    // force: Determina si se debe guardar aunque no haya cambios
    // TODO: Si quiero mantener la lógica de cambios aparte, no tiene sentido
    // catcher: Determina si se debe permitir que la promesa se rechace
    //   - true: No permitir que se rechace aunque falle la acción
    //   - false: Rechazar la promesa si falla la acción
    (opts = {}, ...etc) => {
      if (opts === null || typeof opts !== 'object' || etc.length) {
        console.error('Uso incorrecto', { opts, etc })
        throw new Error('Interfaz deprecada')
      }

      const {
        pickNext = null,
        forceSave = false,
        catchError = true
      } = opts

      if (forceSave) throw new Error('siempre se fuerza el save!')

      if (!voRef.current) throw new Error('La referencia al VO no existe')

      const sent = voRef.current

      return model[
        // shouldPost es una opcion funcional que determina
        // cuando se debe hacer un POST a la lista o un PUT al ítem
        shouldPost(voRef.current[model.pk]) ? 'create' : 'update'
      ](sent)
        .then(response => {
          // console.log({ response })
          switch (response.status) {
            case 200:
            case 201:
              // let received = afterSave ? afterSave(sent, response) : response.data.item
              // console.log('RESET CHANGES', received)
              resetChanges(afterSave ? afterSave(sent, response) : response.item)
              // console.log('RESET VOREF')
              voRef.current = null
              pickNext !== false && pickCard(
                pickNext === true ? response.item[model.pk] : pickNext
              )
              break
            case 422: // Errores de validación
            case 413: // El logo es demasiado grande
            default:
          }
          return response
        })
        .catch(catchError
          ? err => {
            if (!err.response) {
              throw err
            }
            return err.response
          }
          : err => Promise.reject(err)
        )
    },
    [voRef, resetChanges, model, shouldPost, pickCard, afterSave]
  )
}
