import BaseModel from './AbstractModel'

import { Buffer } from 'buffer'

import {
  postDispatcher,
  saveDispatcher,
  wipeDispatcher
} from '../../../store/modelDispatcher/EmpresaDispatcher'

export default class Empresa extends BaseModel {
  static get pk () { return 'fk_empresa' }
  static get single () { return 'empresa' }
  static get plural () { return 'empresas' }

  static getItemUrl (fkEmpresa, id) {
    return `/${id}`
  }

  static getFrontItemUrl (fkEmpresa, id) {
    return `/usuario/empresas/${id}`
  }

  static getListUrl (fkEmpresa, id) {
    return '/'
  }

  static defaults (confEmpresa, filters) {
    return {
      fk_iva_defecto: 1,
      es_profesional: 0,
      es_particular: 0,
      fk_periodo_facturacion_defecto: 1,
      fk_envio_factura_defecto: 1,
      fk_metodo_cobro_defecto: 1,
      fk_plazo_cobro_defecto: 1,
      duracion: 30,
      margen_cobro: 0,
      default_view: 'semana',
      work_spec: {
        daysOfWeek: [1, 2, 3, 4, 5, 6],
        startTime: '08:00',
        endTime: '20:00'
      },
      desfase: 0,
      fk_empresa: confEmpresa?.fk_empresa
    }
  }

  static beforeSend (data) {
    // console.log('Before send EmpresaList', vo)
    return (
      (!data.logo || typeof data.logo === 'string') &&
      (!data.avatar || typeof data.avatar === 'string')
    )
      ? data
      : {
          ...data,
          logo: data.logo ? Buffer.from(data.logo).toString() : null,
          avatar: data.avatar ? Buffer.from(data.avatar).toString() : null
        }
  }

  static create (data = {}, opts = {}) {
    const sent = this.beforeSend(data)

    return super.create(sent, opts, postDispatcher)
  }

  static update (data = {}, opts = {}) {
    const sent = this.beforeSend(data)

    return super.update(sent, opts, saveDispatcher)
  }

  static destroy (data = {}, opts = {}) {
    return super.destroy(data, opts, wipeDispatcher)
  }

  static async search (dto, text, opts = {}) {
    const idEmpresa = dto[this.pk]
    const { contactos, conceptos, tiempo, actividades, facturas } = opts

    return await this.axios
      .get(`/${idEmpresa}/search`, {
        params: {
          q: text,
          contactos,
          conceptos,
          tiempo,
          actividades,
          facturas
        }
      })
      .then(response => {
        const { data } = response

        return {
          contactos: data.contactos,
          conceptos: data.conceptos
        }
      })
  }

  static printPdf (fkEmpresa, documento, id) {
    super.printPdf(super.getPrintUrl(fkEmpresa, documento, id), id)
  }
}
