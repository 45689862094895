import { useSelector } from 'react-redux'

import {
  Navigate,
  // useNavigate,
  useLocation
} from 'react-router-dom'

import { StateKeys } from '../../store/const'
import { selectorUser } from '../../store/selector'

import Loading from '../Abc/Loading'

const selectUser = selectorUser()
const selectAuthLoading = state => state[StateKeys.authLoading]

function AccessControl (props) {
  const {
    redirect = '/auth/login',
    isAuthorized = user => !!user
  } = props

  const user = useSelector(selectUser)
  const authLoading = useSelector(selectAuthLoading)

  const location = useLocation()

  if (authLoading) {
    return <Loading />
  }

  if (isAuthorized(user)) {
    return props.children
  }

  const follow = location.pathname + location.search
  return (
    <Navigate
      to={redirect + '?follow=' + encodeURIComponent(follow)}
      replace
    />
  )
}

export function secure (Componente) {
  return (
    <AccessControl isAuthorized={user => !!user} redirect='/auth/login'>
      <Componente />
    </AccessControl>
  )
}

export function nobody (Componente) {
  return (
    <AccessControl isAuthorized={user => !user} redirect='/auth/logout'>
      <Componente />
    </AccessControl>
  )
}
