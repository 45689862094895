import React from 'react'

import {
  useNavigate,
  useLocation
} from 'react-router-dom'

import { useSelector } from 'react-redux'
import { StateKeys as State } from '../../store/const'

import AuthService from '../../service/auth'

import Loading from '../Abc/Loading'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

function getFollow (location) {
  return new URLSearchParams(location).get('follow')
}

export default function LoginPage (props) {
  const [inputValue, setinputValue] = React.useState({})

  const navigate = useNavigate()
  const location = useLocation()

  const { user, authLoading } = useSelector(state => ({
    user: state[State.user],
    authLoading: state[State.authLoading]
  }))
  const follow = getFollow(location)

  React.useEffect(() => {
    if (authLoading) return
    if (user && follow) navigate(follow)
  }, [authLoading, user, navigate, follow])

  const handlerInput = (ev) => {
    setinputValue({ ...inputValue, [ev.target.name]: ev.target.value })
  }

  const handlerLogin = (event) => {
    event.preventDefault()
    AuthService.requestLogin(inputValue, follow)
      // la promesa resuelve con la url a la que debe dirigirse el usuario
      .then(next => navigate(next))
      .catch(error => console.error(error))
  }

  if (authLoading) {
    return <Loading />
  }

  return (
    <Grid
      container
      component='section'
      className='login'
    >
      <Grid className='login-container'>
        <Avatar className='' src='/static/logo.svg' />
        <Typography component='h1' variant='h5'>
          Acceder
        </Typography>
        <form className='' onSubmit={handlerLogin}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            name='username'
            autoComplete='email'
            onChange={handlerInput}
            autoFocus
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Contraseña'
            type='password'
            id='password'
            onChange={handlerInput}
            autoComplete='current-password'
          />

          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className=''
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href='/auth/reset' variant='body2'>
                Olvidaste la contraseña
              </Link>
            </Grid>
            <Grid item>
              <Link href='/auth/registro' variant='body2'>
                Crear cuenta
              </Link>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}
