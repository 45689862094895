import { ListAction as List } from '../const'

import { filterType, weekInit } from './const'

export const init = {
  0: [],
  1: [],
  2: [weekInit()],
  3: [],
  4: [],
  5: [],
  6: []
}

export function reducer (state = init, action) {
  // console.warn('filters reducer', { action })

  const { payload: { order, ...payload } } = action

  switch (action.type) {
    case List.ADD_FILTER:
      // console.log({ Estado: state, payload: payload, orden: order })
      return {
        ...state,
        [order]: addFilter(state[order], payload.filter)
      }
    case List.POP_FILTER:
      return {
        ...state,
        [order]: state[order].slice(0, -1 * (payload.quantity || 1))
      }
    default:
      return state
  }
}

function addFilter (state, filter) {
  const tipo = filterType(filter)

  if (state.some(vo => filterType(vo) === tipo)) {
    return state.map(vo => filterType(vo) !== tipo ? vo : filter)
  }

  return state.concat(filter)
}
