import contacto from './Contacto'
import Actividad from './Actividad'
import BaseModel from './AbstractModel'
import * as fecha from '../../../utils/fecha'
import { endOfWeek, endOfMonth, endOfQuarter, subDays } from 'date-fns'
import { TRANSFERENCIA } from '../../../componentes/Abc/FieldSet/Opciones'

import {
  /* CONCEPTO, */
  CONTACTO,
  TIEMPO,
  filterType
} from '../../../store/filters/const'

import {
  multipleDispatcher
  // wipeDispatcher
} from '../../../store/modelDispatcher/BorradorDispatcher'

export default class Borrador extends BaseModel {
  static get pk () { return 'id_borrador' }
  static get single () { return 'borrador' }
  static get plural () { return 'borradores' }

  static getItemUrl (fkEmpresa, id) {
    return `/${fkEmpresa}/facturacion/borradores/${id}`
  }

  static getListUrl (fkEmpresa) {
    return `/${fkEmpresa}/facturacion/borradores`
  }

  static defaults (confEmpresa, filters) {
    const plazoCobroDefecto = { dias: 0 }
    const _contacto = filters.find(filter => filterType(filter) === CONTACTO) ||
      { ...contacto.defaults }
    /* const _concepto = filters.find(filter => filterType(filter) === CONCEPTO) || (
      _contacto.id_contacto ? { ...concepto.defaults } : null
    ) */

    // TODO emision_prevista en confEmpresa o filters contacto
    const fkPeriodoFacturacion = _contacto?.fk_periodo_facturacion ||
    confEmpresa?.fk_periodo_facturacion_defecto || 1

    const fechaBorrador = confEmpresa
      ? this.periodoFacturacion(
        fkPeriodoFacturacion,
        confEmpresa?.work_spec.daysOfWeek
      )
      : new Date()

    return ({
      id_borrador: null,
      emision_prevista: fechaBorrador,
      vence: fecha.input(fecha.fromPlazo(fechaBorrador, plazoCobroDefecto)),
      contacto: _contacto || {},
      fk_empresa: confEmpresa?.fk_empresa,
      actividades: [{
        ...Actividad.defaults(confEmpresa, filters),
        id_actividad: null,
        precio: ''
      }],
      fk_metodo_cobro: confEmpresa?.fk_metodo_cobro_defecto,
      fk_iva: confEmpresa?.fk_iva_defecto,
      iban: confEmpresa
        ? confEmpresa.fk_metodo_cobro_defecto === TRANSFERENCIA
          ? confEmpresa.iban
          : ''
        : ''
    })
  }

  static defineFilters (filters) {
    const filtroContacto = filters.find(vo => filterType(vo) === CONTACTO)
    const filtroTiempo = filters.find(vo => filterType(vo) === TIEMPO)
    const _filters = {
      fk_contacto: filtroContacto ? filtroContacto[contacto.pk] : null,
      desde: filtroTiempo ? fecha.input(filtroTiempo.desde) : null,
      hasta: filtroTiempo ? fecha.input(filtroTiempo.hasta) : null
    }

    return (_filters)
  }

  static beforeSend (data) {
    data.empresa = { ...data.empresa }
    if (!data.emitir) {
      data.emitir = false
    }
    delete data.empresa.logo
    return data
  }

  static async findAll (filters, params) {
    const _filters = this.defineFilters(filters)

    return await super.findAll(_filters, params)
  }

  static async create (data = {}, opts = {}) {
    const sent = this.beforeSend(data)
    return await super.create(sent, opts, multipleDispatcher)
  }

  static async update (data = {}, opts = {}) {
    const sent = this.beforeSend(data)
    return await super.update(sent, opts, multipleDispatcher)
  }

  static async destroy (data = {}, opts = {}) {
    return await super.destroy(data, opts/*, wipeDispatcher */)
  }

  static periodoFacturacion (fkPeriodoFacturacion, daysOfWeek) {
    const weekEnds = 7 - Math.max(...daysOfWeek)
    let fechaPrevista

    switch (fkPeriodoFacturacion) {
      case 2:
        fechaPrevista = fecha.input(endOfWeek(subDays(new Date(), weekEnds)))
        break
      case 3:
        fechaPrevista = fecha.input(endOfMonth(new Date()))
        break
      case 4:
        fechaPrevista = fecha.input(endOfQuarter(new Date()))
        break
      default:
        fechaPrevista = fecha.input(new Date())
        break
    }

    return fechaPrevista
  }

  static vencimiento (fkPlazoCobro, date) {
    let plazoVence
    switch (fkPlazoCobro) {
      case 1:
        plazoVence = { dias: 0 }
        break
      case 2:
        plazoVence = { dias: 30 }
        break
      case 3:
        plazoVence = { dias: 60 }
        break
      case 4:
        plazoVence = { dias: 90 }
        break
      default:
        throw new ReferenceError(`Id plazo cobro inválido: ${fkPlazoCobro}`)
    }

    return fecha.input(fecha.fromPlazo(date, plazoVence))
  }
}
