import React from 'react'
import * as fecha from '../fecha'
import { useFiltersControl } from '.'
import {
  format,
  startOfDay,
  subDays,
  addDays,
  endOfDay,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear
} from 'date-fns'
import { es } from 'date-fns/locale'

export function useCalendarFilters (date) {
  const { view, addFilter } = useFiltersControl()

  // const origen = date ? startOfDay(date) : startOfToday()

  const yearView = React.useCallback((origen) => {
    addFilter({
      date: true,
      view: 'year',
      label: format(origen, 'yyyy', { locale: es }),
      desde: startOfYear(origen),
      hasta: endOfYear(origen),
      origen
    })
  }, [addFilter])

  const monthView = React.useCallback((origen) => {
    addFilter({
      date: true,
      view: 'mes',
      label: fecha.formatTextMonth(origen),
      desde: startOfMonth(origen),
      hasta: endOfMonth(origen),
      origen
    })
  }, [addFilter])

  const weekView = React.useCallback((origen) => {
    addFilter({
      date: true,
      view: 'semana',
      label: 'Semana',
      desde: fecha.startOfWeek(origen),
      hasta: fecha.endOfWeek(origen),
      origen
    })
  }, [addFilter])

  const threeDayView = React.useCallback((origen) => {
    addFilter({
      date: true,
      view: 'tresDias',
      label: 'Vista de tres Días',
      desde: startOfDay(subDays(origen, 1)),
      hasta: endOfDay(addDays(origen, 1)),
      origen
    })
  }, [addFilter])

  const dayView = React.useCallback((origen) => {
    addFilter({
      date: true,
      view: 'dia',
      label: fecha.formatTextDate(new Date(origen)),
      desde: startOfDay(origen),
      hasta: endOfDay(origen),
      origen
    })
  }, [addFilter])
  const hoy = React.useCallback(() => {
    switch (view) {
      case 'dia':
        addFilter({
          date: true,
          view,
          label: fecha.formatTextDate(new Date()),
          desde: startOfDay(new Date()),
          hasta: endOfDay(new Date()),
          hoy: new Date()
        })
        break
      case 'tresDias':
        addFilter({
          date: true,
          view,
          label: fecha.formatTextDate(new Date()),
          desde: startOfDay(subDays(new Date(), 1)),
          hasta: endOfDay(addDays(new Date(), 1)),
          hoy: new Date()
        })
        break
      case 'semana':
        addFilter({
          date: true,
          view,
          label: 'Esta Semana',
          desde: fecha.startOfWeek(new Date()),
          hasta: fecha.endOfWeek(new Date()),
          hoy: new Date()
        })
        break
      case 'mes':
        addFilter({
          date: true,
          view,
          label: 'Este Mes',
          desde: startOfMonth(new Date()),
          hasta: endOfMonth(new Date()),
          hoy: new Date()
        })
        break
      default:
        console.error('Vista no reconocida')
        break
    }
  }, [addFilter, view])

  return {
    yearView,
    monthView,
    weekView,
    threeDayView,
    dayView,
    hoy,
    view
  }
}
