import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { /* listLoader, */listReloader } from '../../store/dispatcher'
import {
  selectorEmpresa,
  selectorListado,
  selectorRefresh
} from '../../store/selector'

import { useFiltersControl } from './useFiltersControl'

/**
 * Este hook permite cargar un listado de elementos de cualquier modelo
 * desde el componente que lo utiliza. El listado de datos se almacena en
 * el store de redux, y se refresca cuando resulta necesario.
 */
const DEBUG = false // true

export function useListLoader (config = {}) {
  const { model, shouldLoad, noFilters } = config

  const empresa = useSelector(React.useMemo(selectorEmpresa, []))
  const { filters } = useFiltersControl()
  const listado = useSelector(
    React.useMemo(() => selectorListado(model), [model])
  )
  const loadRefresh = useSelector(
    React.useMemo(() => selectorRefresh(model), [model])
  )

  const [params, setParams] = React.useState({
    limit: 100,
    offset: 0,
    fk_empresa: empresa ? empresa.id : null
  })

  const dispatch = useDispatch()
  const [reloadList] = React.useMemo(() => [
    listReloader(dispatch, model)
  ], [dispatch, model]
  )

  // La condición impide que se cargue si ya tenemos un listado en redux
  const loaded = React.useRef(typeof listado.total !== 'undefined')
  const filtered = React.useRef(null)

  const debug = React.useCallback(
    (...args) => console.warn('useListLoader', ...args, { model }),
    [model]
  )

  DEBUG && debug('hook running', { loadRefresh, listado, loaded: loaded.current })

  React.useEffect(() => {
    if (filtered.current === null) {
      filtered.current = filters
    }

    // Si hay cambios en los filtros se carga la lista nueva
    if (filtered.current !== filters) {
      DEBUG && debug('Los Filtros han cambiado', { filters, filtered: filtered.current })
      loaded.current = false
      filtered.current = filters
      return setParams(prev => ({ ...prev, offset: 0 }))
    }

    DEBUG && debug('LOADREFRESH: ', loadRefresh)
    if (loadRefresh) {
      loaded.current = null
      reloadList(false)
      DEBUG && debug('will reload soon')
      return
    }

    if (shouldLoad(empresa) &&
    (((listado.length !== 0 && listado.length >= listado.total) &&
    loaded.current === true) || loaded.current === true)) {
      DEBUG && debug('has already loaded', {
        loaded: loaded.current,
        total: listado.total,
        actual: listado.length
      })
      return
    }

    // Si no hay filtros ni listado no debería cargar
    if (!shouldLoad(empresa) ||
      (typeof listado.total !== 'undefined' &&
      listado.length === 0 &&
      loaded.current !== false &&
      filtered.current.length < 1)) {
      DEBUG && debug('should not load', { empresa })
      return
    }

    DEBUG && debug('will load list now')
    // console.error('useListLoader loaded.current: ', loaded.current)
    // Si no se cumplen ninguna de las anteriores condiciones, se carga el listado

    loaded.current = true
    setTimeout(() => {
      model.findAll(noFilters ? [] : filters, params)
        .catch(
          error => console.error('useListLoader errored', { error })
        )
    }, 0)

    return () => {
      DEBUG && debug('will unmount (will not reset loaded)')
      // loaded.current = false
    }
  }, [
    model,
    shouldLoad,
    empresa,
    params,
    loaded,
    loadRefresh,
    reloadList,
    debug,
    listado,
    filters,
    filtered,
    noFilters
  ])

  return {
    empresa,
    filters,
    listado,
    get total () {
      return listado.total
    },
    get loaded () {
      return shouldLoad(empresa) ? loaded.current : false
    },
    get loading () {
      return shouldLoad(empresa) ? !loaded.current : false
    },
    get firstLoad () {
      return shouldLoad(empresa) ? loaded.current === null : false
    },
    refresh: () => {
      loaded.current = false
      reloadList(true)
    },
    loadNext: () => {
      loaded.current = false
      setParams(prev => ({
        ...prev,
        offset: prev.offset + prev.limit
      }))
      // filters
    },
    get hasMore () {
      return listado.length < listado.total
    }
  }
}
