import { ListAction, MainAction, StateKeys } from './const'

export function selectorUser () {
  return (state) => state[StateKeys.user]
}

export function selectorEmpresa () {
  return (state) => state[StateKeys.empresa]
}

export function selectorEmpresaBase () {
  return (state) => state[StateKeys.confEmpresa]
}

export function selectorIsLoading () {
  return (state) => !state[StateKeys.isLoading] ||
          state[StateKeys.isLoading].length > 0 ||
          state[StateKeys.authLoading]
}

export function selectorRefresh (model) {
  return (state) => state[ListAction.LOAD_REFRESH] === model.plural
}

export function selectorListado (model) {
  return (state) => state[model.plural] || []
}

export function selectorFilters (order = 0) {
  return (state) => state[StateKeys.filters][order]
}

export function selectorLockHistory () {
  return (state) => state[StateKeys.lockHistory]
}

export function selectorPickCreate () {
  return (state) => ({
    pick: state[MainAction.PICK_CREATE],
    card: state.card
  })
}

export function selectorShowCreate (model) {
  return (state) => {
    // console.log('selector showCreate', { card: state.card, model })
    return (
      model &&
      state.card &&
      (state.card.id === MainAction.SHOW_CREATE)
    )
  }
}
