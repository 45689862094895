import ConfigActividades from './ConfigActividades'
import ConfigContactos from './ConfigContactos'
import ConfigFacturas from './ConfigFacturas'
import Documentos from './Documentos'

export {
  ConfigActividades,
  ConfigContactos,
  ConfigFacturas,
  Documentos
}
