import React from 'react'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export function useCardPicker (model) {
  const fkEmpresa = useSelector(state => state.empresa ? state.empresa.id : '')

  const navigate = useNavigate()
  return React.useCallback((id) => {
    let url = ''
    const getItemUrl = model.getFrontItemUrl(fkEmpresa, id)
    const getListUrl = model.getFrontListUrl(fkEmpresa)
    switch (id) {
      case 'crear':
        url = getItemUrl
        break
      case null:
        url = getListUrl
        break
      default:
        url = getItemUrl
        break
    }
    // console.log(url)
    navigate(url)
  }, [model, fkEmpresa, navigate])
}
