import React from 'react'

import axios from '../../../service/listados'

import { GridContainer, Field } from '../FormUtils'
import { GridItemHOC } from '../FormUtils/Grid'
import { AutoSelect } from '../FormUtils/AutoSelect'

// import './WidgetPostal.scss'

export function Domicilio (props) {
  return (
    <Field
      name='domicilio'
      label='Dirección'
      value={null}
      // error={false}
      required
      {...props}
    />
  )
}

function defaultLists (vo) {
  // console.warn('seteando defaultLists', vo)
  return {
    cp: vo.codigo_postal,
    ayuntamientos: vo.fk_ayuntamiento
      ? [{
          id_ayuntamiento: vo.fk_ayuntamiento,
          nombre: vo.ayuntamiento,
          fk_provincia: vo.fk_provincia,
          provincia: vo.provincia
        }]
      : []
  }
}

export function WidgetPostal (props) {
  const { vo, label, namespace, onChange, error } = props

  const [lists, setLists] = React.useState(defaultLists(vo))

  const cp = React.useMemo(() => {
    return vo.codigo_postal || ''
  }, [vo.codigo_postal])

  const cpRef = React.useRef(null)

  // console.info('Render WidgetPostal', { lists, vo })

  React.useEffect(() => {
    if (cp.length < 5) return
    if (cpRef.current === cp) return

    let ignore = false

    Promise.all([
      axios
        .get('/provincias', { params: { cp: cp.toString().slice(0, 2) } })
        .then(response => response.data.list),
      axios
        .get('/ayuntamientos', { params: { cp } })
        .then(response => response.data.list)
    ])
      .then(([provincias, ayuntamientos]) => {
        if (ignore) return
        // console.warn('Loaded', { cp, provincias, ayuntamientos })

        cpRef.current = cp

        ayuntamientos = ayuntamientos.map(vo => ({
          ...vo,
          provincia: provincias.find(item => item.id_provincia === vo.fk_provincia).nombre
        }))

        setLists(prev =>
          (
            prev.cp === cp &&
            prev.ayuntamientos.length === ayuntamientos.length
          )
            ? prev
            : { cp, ayuntamientos }
        )
        const changed = {}

        if (!cp) return

        if (
          !vo.fk_ayuntamiento ||
          !ayuntamientos.some(ayto => ayto.id_ayuntamiento === Number(vo.fk_ayuntamiento))
        ) {
          changed.fk_ayuntamiento = ayuntamientos.length > 0
            ? ayuntamientos[0].id_ayuntamiento
            : null
          changed.ayuntamiento = ayuntamientos.length > 0
            ? ayuntamientos[0].nombre
            : null
          changed.fk_provincia = provincias.length > 0
            ? ayuntamientos[0].fk_provincia
            : null
          changed.provincia = ayuntamientos.length > 0
            ? ayuntamientos[0].provincia
            : null
        }

        if (Object.keys(changed).length) {
          console.warn('autoset', { changed })
          onChange(changed)
        }
      })
      .catch(err => console.error(err))
    return () => { ignore = true }
  }, [cp, setLists, onChange, vo, cpRef])

  const handleChangeCodigo = event => {
    const { value } = event.target

    if (value.length > 5 || isNaN(Number(value))) return

    onChange({
      codigo_postal: value,
      fk_codigo_postal: Number(value) || null
    })
  }

  const handleChangeAyuntamiento = (option) => onChange({
    fk_ayuntamiento: option ? option.id : null,
    ayuntamiento: option ? option.name : '',
    fk_provincia: option ? option.vo.fk_provincia : null,
    provincia: option ? option.vo.provincia : ''
  })

  const lenAyto = vo.ayuntamiento
    ? vo.ayuntamiento.length + 1
    : 'Ayuntamiento'.length + 1
  const lenProv = vo.provincia
    ? vo.provincia.length + 1
    : 'Provincia'.length

  const hasError = (
    !!error ||
    (cp.length > 0 && cp.length !== 5) ||
    (cp.length === 5 && !lists.ayuntamientos.length) ||
    undefined
  )

  const hasLabel = label !== null
  const getStyle = (style) => hasLabel ? undefined : style

  // console.info('Render Widget direccion', { vo, lists })

  return (
    <GridContainer className='WidgetPostal' justify='flex-start'>
      <Field
        sm={3}
        fullWidth={hasLabel}
        style={{ width: 'min-content', minWidth: '6ch' }}
        className='Codigo'
        data-cy='codigo-postal'
        type='tel'
        name={namespace ? `${namespace}[codigo_postal]` : 'codigo_postal'}
        label={label === null ? null : 'C. Postal'}
        placeholder={label === null ? '00001' : undefined}
        value={cp}
        error={hasError}
        onChange={handleChangeCodigo}
      />
      <AutoSelect
        sm={hasLabel ? true : 'auto'}
        fullWidth={hasLabel}
        style={getStyle({ width: 'min-content', minWidth: `${lenAyto}ch` })}
        className='Ayuntamiento'
        data-cy='ayuntamiento'
        list={lists.ayuntamientos.map(
          item => ({ name: item.nombre, id: item.id_ayuntamiento, vo: item })
        )}
        name={namespace ? `${namespace}[ayuntamiento]` : 'ayuntamiento'}
        label={label === null ? null : 'Ayuntamiento'}
        value={Number(vo.fk_ayuntamiento) || ''}
        error={hasError}
        onChange={handleChangeAyuntamiento}
        placeholder='Ayuntamiento'
      />
      <Field
        sm={hasLabel ? true : 'auto'}
        fullWidth={hasLabel}
        style={getStyle({ width: 'min-content', minWidth: `${lenProv}ch` })}
        className='Provincia'
        data-cy='provincia'
        name={namespace ? `${namespace}[provincia]` : 'provincia'}
        label={label === null ? null : 'Provincia'}
        value={vo.provincia || ''}
        error={hasError}
        disabled
        placeholder='Provincia'
      />
    </GridContainer>
  )
}

export default GridItemHOC(WidgetPostal)
