import React from 'react'
import { Buffer } from 'buffer'
import { useNavigate } from 'react-router-dom'

import { useSelector } from 'react-redux'

import {
  BottomNavigation,
  BottomNavigationAction,
  Avatar
} from '@mui/material'

import {
  Today as TodayIcon,
  AccountBox as AccountBoxIcon,
  Assignment as AssignmentIcon,
  Receipt as ReceiptIcon,
  Settings as SettingsIcon
} from '@mui/icons-material'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'

import { useLocationDetails } from '../../../utils/hooks'

import { selectorEmpresaBase } from '../../../store/selector'

import './MainMenu.css'

const selectEmpresa = selectorEmpresaBase()

export default function NavigationWrapper (props) {
  if (props.offset) {
    return <div className='MainMenuOffset' style={{ minHeight: '54px' }} />
  }

  return <Navigation {...props} />
}

export function Navigation (props) {
  const { destinos, setDialog } = props

  const detalles = useLocationDetails()
  const navigate = useNavigate()

  const empresa = useSelector(selectEmpresa)
  // console.info('Render NavigationMenu', { props, detalles })

  const imgRef = React.useRef(null)
  const [selected, setSelected] = React.useState(detalles.index)
  const handleNavigation = (event, newValue) => {
    if (newValue === 4) {
      setDialog(prev => !prev)
    } else {
      navigate(destinos[newValue + 1])
    }
  }

  React.useEffect(() => {
    setSelected(detalles.index)
  }, [detalles.index, setSelected])

  const avatar = (empresa && empresa.avatar)
    ? typeof empresa.avatar === 'string'
      ? empresa.avatar
      : Buffer.from(empresa.avatar).toString()
    : undefined

  return (
    <>
      <BottomNavigation
        component='nav'
        value={selected}
        onChange={handleNavigation}
        showLabels
      >
        <BottomNavigationAction
          label='Contactos'
          icon={<AccountBoxIcon />}
        />
        <BottomNavigationAction
          label='Agenda'
          icon={<TodayIcon />}
        />
        <BottomNavigationAction
          label='Actividad'
          icon={<AssignmentIcon />}
        />
        <BottomNavigationAction
          label='Facturas'
          icon={<ReceiptIcon />}
        />
        <BottomNavigationAction
          label={empresa ? empresa.alias : 'Ajustes'}
          icon={
            avatar
              ? (
                <Avatar
                  ref={imgRef}
                  variant='rounded'
                  src={avatar}
                  alt='Previsualización logotipo'
                  className='logo'
                >
                  <ImageOutlinedIcon />
                </Avatar>
                )
              : <SettingsIcon />
          }
        />
      </BottomNavigation>
    </>
  )
}
