import React from 'react'
import { useSelector } from 'react-redux'

import {
  formatISO9075,
  intervalToDuration,
  differenceInMinutes
} from 'date-fns'

import FullCalendar from './FullCalendar'

import {
  useCardPicker
} from '../../utils/hooks'

import { Evento as model } from '../../service/empresas/model'
import { selectorEmpresaBase } from '../../store/selector'
import { MainAction } from '../../store/const'

const selectBase = selectorEmpresaBase(model)

export default React.forwardRef(function FullCalendarConector (props, ref) {
  const { SHOW_CREATE } = MainAction
  const [more, setMore] = React.useState(2)
  const Empresa = useSelector(selectBase)
  const {
    voRef,
    listado,
    desde,
    hasta,
    view,
    cuanto,
    stopDrag,
    filters,
    moving
  } = props

  const pickCard = useCardPicker(model)

  const workSpec = Empresa
    ? [Empresa.work_spec]
    : [{
        daysOfWeek: [1, 2, 3, 4, 5, 6],
        startTime: '08:00',
        endTime: '20:00'
      }]
  /**
   * Ni tan mal, hasta me molesté en crear un answer en S.O.
   * → https://stackoverflow.com/a/67904826/1894803
   */

  const workMin = workSpec.map(item => item.startTime).sort().shift()
  const workMax = workSpec.map(item => item.endTime).sort().pop()
  const workDays = [...new Set(workSpec.flatMap(item => item.daysOfWeek))]
  const hideDays = [...Array(7).keys()].filter(day => !workDays.includes(day))

  React.useEffect(() => {
    if (!ref || !ref.current) return

    const api = ref.current.getApi()
    if (!api) return

    api.changeView(view)
    api.setOption('visibleRange', { start: desde, end: hasta })

    const cuanto = intervalToDuration({ start: desde, end: hasta })

    /* ;(cuanto.days <= 1 || cuanto.days >= 7) && */
    api.gotoDate(desde)

    api.setOption(
      'hiddenDays',
      (cuanto.days <= 1 && !cuanto.months) || view === 'tresDias'
        ? []
        : hideDays
    )

  // console.log('Calendar configurado a', { view: api.view, cuanto, hideDays, desde, hasta })
  }, [view, desde, hasta, hideDays, ref])

  const views = {
    mes: {
      type: 'dayGridMonth',
      duration: { months: 1 },
      buttonText: 'Mes'
    },
    semana: {
      type: 'timeGrid',
      duration: { days: 7 },
      buttonText: 'Semana'
    },
    tresDias: {
      type: 'timeGrid',
      duration: { days: 3 },
      buttonText: 'Tres días'
    },
    dia: {
      type: 'timeGridDay',
      duration: { days: 1 },
      buttonText: 'Día'
    }
  }

  const handleMore = () => {
    setMore(30)
    return (ref.current.getApi().view.type)
  }

  const handleSelect = ({ event: info, el, jsEvent, view }) => {
    // console.log(jsEvent)
    const { vo } = info.extendedProps
    // console.warn('select actividad', { vo })
    // TODO este flow de update provoca 2 renders
    // setVO(vo || null)
    voRef.current = vo
    pickCard(vo ? vo.id_actividad_paralela : null)
    // setActividad(vo ? { ...vo } : null)
  }

  const handleDrag = ({ event, jsEvent, view }) => {
    stopDrag.current = true
    jsEvent.stopPropagation()
  }

  const handleDrop = ({ delta, event: info, jsEvent, view, ...etc }) => {
    // https://fullcalendar.io/docs/eventDrop
    // typeof etc.revert === 'function'
    const { vo } = info.extendedProps
    stopDrag.current = false

    // console.log('drop actividad', { info, all: info.allDay })
    // console.log({ viejo: vo, nuevo: voRef.current })
    model.update({
      ...vo,
      fecha_prevista: formatISO9075(info.start, { representation: 'date' }),
      hora_prevista: info.allDay
        ? ''
        : formatISO9075(info.start, { representation: 'time' })
    })
  }

  const handleResize = ({ event: info, view, ...etc }) => {
    // https://fullcalendar.io/docs/eventResize
    const { vo } = info.extendedProps
    // console.log('resize actividad', { desde: info.start, hasta: info.end })
    // console.log({ duration: differenceInMinutes(info.end, info.start) })
    model.update({
      ...vo,
      duracion: differenceInMinutes(info.end, info.start)
    })
    stopDrag.current = false
  }

  const handleResStart = () => {
    stopDrag.current = true
  }

  const handleSelectSlot = ({ allDay, start, end, view, ...etc }) => {
    if (moving.current !== false) {
      ref.current.getApi().unselect()
      return
    }
    voRef.current = {
      id_actividad_paralela: SHOW_CREATE,
      ...model.defaults(Empresa, filters),
      fecha_prevista: formatISO9075(start, { representation: 'date' }),
      hora_prevista: allDay
        ? null
        : formatISO9075(start, { representation: 'time' })
    }
    // console.log('select slot', voRef)
    setTimeout(() => pickCard('crear'), 100)
  }

  return (
    <FullCalendar
      ref={ref}
      events={listado || []}
      // El mapper se define junto al adaptador ./FullCalendar
      //
      // handlers de Eventos
      //
      // https://fullcalendar.io/docs/eventClick
      eventClick={handleSelect}
      // https://fullcalendar.io/docs/event-dragging-resizing
      editable
      eventDragStart={handleDrag}
      eventDrop={handleDrop}
      eventResize={handleResize}
      // eventResizeStart={handleResStart}
      eventResizeStart={handleResStart}
      snapDuration='00:15'
      // https://fullcalendar.io/docs/date-clicking-selecting
      selectable={moving.current === false}
      select={handleSelectSlot}
      //
      // Otros
      // https://fullcalendar.io/docs/sizing
      height='parent'
      // contentHeight='auto'
      expandRows
      // https://fullcalendar.io/docs/now-indicator
      nowIndicator
      // https://fullcalendar.io/docs/business-hours
      businessHours={workSpec}
      // https://fullcalendar.io/docs/date-display
      hiddenDays={cuanto.days <= 1 && !cuanto.months ? [] : hideDays}
      // scrollTime={horarioMin}
      slotMinTime={workMin}
      slotMaxTime={workMax}
      // slotDuration='00:15'
      // https://fullcalendar.io/docs/showNonCurrentDates
      showNonCurrentDates={false}
      // https://fullcalendar.io/docs/event-popover
      dayMaxEventRows={1}
      dayMaxEvents={more}
      moreLinkClick={() => handleMore()}
      // eventMaxStack={more}
      // moreLinkContent
      // ---
      dayHeaderFormat={
        view !== 'mes'
          ? { weekday: 'short', day: 'numeric' }
          : { weekday: 'short' }
        }
      slotLabelInterval='01:00'
      slotLabelFormat={{
        hour: 'numeric',
        minute: '2-digit'
      }}
      longPressDelay={200}
      initialDate={desde}
      initialView={view}
      views={views}
      visibleRange={{ start: desde, end: hasta }}
    />
  )
})
