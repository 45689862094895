import React from 'react'

import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
// import Checkbox from '@mui/material/Checkbox'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
// import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Skeleton from '@mui/material/Skeleton'

import { factura } from '../../service/empresas/model'
import { DryDialog } from '../Abc/Tarjeta/Dialogs'
import { NumberField } from '../Abc/FormUtils'
import { useMountDetector } from '../../utils/hooks/useMountDetector'
import * as date from '../../utils/fecha'

export const DIALOG_ACCEPT = true
export const DIALOG_DOSAVE = true
export const DIALOG_CANCEL = null

export function calcularOffset (nextId, offset) {
  const splitId =
    Number(nextId.split(' ')[1].slice(4)) +
    (offset && offset > 0 ? -1 : 0) +
    (isNaN(offset) ? 0 : Number(offset))

  const splited = nextId.split(' ')
  const serie = splited[0]
  const splitYear = splited[1].slice(0, 4)

  return (serie + ' ' + splitYear + splitId.toString().padStart(4, '0000'))
}

export function DialogEmitir ({
  onClose,
  onCancel,
  vo,
  importe,
  fkEmpresa,
  loading,
  setLoading,
  nextId,
  ...props
}) {
  const { contacto, fecha } = vo
  const mountedRef = useMountDetector()
  // console.warn('DIALOG EMITIR', vo, nextId)

  return (
    <DryDialog
      className='dialog-emitir'
      onClose={() => {
        mountedRef.current && onClose(DIALOG_CANCEL)
      }}
      title='Aviso'
      content={[
        'La factura será emitida con los siguientes datos:'
      ]}
      list={
        <List
          key={1}
          disablePadding
          dense
        >
          <ListItem>
            <ListItemText
              primary='Nº Factura: '
              secondary={
                nextId ||
                  <Skeleton variant='text' animation='wave' />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='Fecha:'
              secondary={date.formatSlash(fecha)}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='Razón Social:'
              secondary={
                contacto?.entidad?.razon ||
                contacto?.razon ||
                'No hay Razon Social'
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='Importe Total:'
              secondary={importe}
            />
          </ListItem>
          {/* <ListItem>
            <ListItemText
              primary='Correos a los que se enviará:'
            />
          </ListItem>
           <ListItem>
            <List dense disablePadding>
              <ListItem>
                <ListItemIcon>
                  <Checkbox
                    disabled={!!contacto?.email_general}
                    edge='start'
                    checked={false}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 1 }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={1}
                  primary='Email Cliente: '
                  secondary={
                    contacto?.email_general ||
                    'No se ha indicado un email'
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Checkbox
                    disabled={!!contacto?.email_contable}
                    edge='start'
                    checked={false}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 2 }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={2}
                  primary='Email Gestoría: '
                  secondary={
                    contacto?.email_contable ||
                    'No se ha indicado un email'
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Checkbox
                    edge='start'
                    checked={false}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 3 }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={3}
                  primary='Email Empresa: '
                  secondary={
                    contacto?.email_general ||
                    'No se ha indicado un email'
                  }
                />
              </ListItem>
            </List>
          </ListItem> */}
        </List>
      }
      {...props}
    >
      <Button onClick={() => onCancel()}>Cancelar</Button>
      <LoadingButton
        loading={loading}
        autoFocus
        onClick={() => {
          setLoading(true)
          mountedRef.current &&
          onClose(DIALOG_DOSAVE)
        }}
        color='primary'
      >
        Emitir
      </LoadingButton>
    </DryDialog>
  )
}

export function DialogCantEmit ({ onClose, msg, ...props }) {
  // console.log(props)
  return (
    <DryDialog
      onClose={() => onClose(DIALOG_ACCEPT)}
      title='Acción imposible'
      content='No se puede emitir la factura'
      list={<p>{msg}</p>}
      {...props}
    >
      <Button onClick={() => onClose(DIALOG_ACCEPT)}>Aceptar</Button>
    </DryDialog>
  )
}

export function PrimeraFactura ({ ...props }) {
  const {
    onClose,
    handleChange,
    nextId,
    offset,
    open
  } = props

  return (
    <DryDialog
      open={open}
      title='Aviso'
      content={[
        'Esta es la primera factura, la numeración será ',
        (nextId && open) && (calcularOffset(nextId, offset))
      ]}
      list={
        <NumberField
          value={Math.abs(offset) || ''}
          autoFocus
          onChange={(e) => handleChange(e.target.value)}
          inputProps={{
            maxLength: 4
          }}
          InputProps={{
            endAdornment: ''
          }}
        />
      }
    >
      <Button onClick={() => onClose(calcularOffset(nextId, offset))}>Aceptar</Button>
    </DryDialog>
  )
}

export function useDialogEmitir (
  emitir,
  confirmEmit,
  vo,
  importe,
  fkEmpresa,
  handleChangeObject,
  selected,
  editable
) {
  const [onConfirm, setOnConfirm] = React.useState(null)
  const [onCantMsg, setOnCantMsg] = React.useState(null)
  const [errMsg, setErrMsg] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [nextId, setNextId] = React.useState(null)
  const [firstAlert, setFirstAlert] = React.useState(false)
  const [offset, setOffset] = React.useState(0)

  const { fecha } = vo

  React.useEffect(() => {
    typeof onConfirm === 'function' &&
    (nextId === null || firstAlert) &&
    factura.getNextId(fkEmpresa, date.input(fecha))
      .then(data => {
        setNextId(data.nextId)
        setFirstAlert(!data.emitidas)
      })
  }, [fecha, fkEmpresa, nextId, firstAlert, selected, editable, onConfirm])

  const layout = (
    <>
      <DialogEmitir
        open={typeof onConfirm === 'function'}
        onClose={onConfirm}
        onCancel={() => {
          setOnConfirm(null)
          setNextId(null)
        }}
        loading={loading}
        setLoading={setLoading}
        nextId={nextId}
        vo={vo}
        fkEmpresa={fkEmpresa}
        importe={importe}
      />
      <DialogCantEmit
        open={typeof onCantMsg === 'function'}
        onClose={onCantMsg}
        msg={errMsg}
        vo={vo}
      />
      <PrimeraFactura
        open={firstAlert && typeof onConfirm === 'function'}
        nextId={nextId}
        offset={offset}
        handleChange={(offset) => {
          setOffset(offset)
          handleChangeObject({ desfase: offset })
        }}
        onClose={(id) => {
          setNextId(id)
          setFirstAlert(false)
        }}
      />
    </>
  )

  const mountRef = useMountDetector()

  const handleEmit = React.useCallback((id) => {
    // Para usar una función como estado es necesario el flow funcional
    setOnConfirm(() => (decision) => {
      switch (decision) {
        case DIALOG_CANCEL:
          return setOnConfirm(null)
        case DIALOG_ACCEPT:
          return emitir()
            .then(response => {
              switch (response.status) {
                case 200:
                case 201:
                  confirmEmit()
                  break
                case 422:
                  setOnConfirm(null)
                  setErrMsg(response.data.message)
                  setOnCantMsg(prev => () => setOnCantMsg(null))
                  break
                default:
                  console.error('Factura no emitida', response.status)
              }
              return response
            })
            .catch((err) => console.error(err))
            .finally(() => {
              setLoading(false)
              mountRef.current && setOnConfirm(null)
            })
        default:
          console.warn('dialog closed', { decision })
          console.error(new Error('Decisión no reconocible'))
      }
    })
  }, [emitir, confirmEmit, mountRef, setOnConfirm])

  return [layout, handleEmit, offset]
}
