import { CircularProgress } from '@mui/material'

export default function Loading (props) {
  return (
    <section
      className='loading'
      style={{ marginTop: 'auto', marginBottom: 'auto' }}
    >
      <CircularProgress size={60} />
    </section>
  )
}

/*
function Progress (loading) {
  return loading
    ? <CircularProgress color='inherit' size={30} />
    : undefined
}
*/
