import React from 'react'

import { NavLink } from 'react-router-dom'

import IconButton from '@mui/material/IconButton'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { Evento as model } from '../../service/empresas/model'

import { MainAction } from '../../store/const'

import {
  useFiltersControl,
  useListLoader,
  useCardPicker,
  useLocationDetails
} from '../../utils/hooks'

// import SwipeableViews from 'react-swipeable-views-react-18-fix'
// import { virtualize } from 'react-swipeable-views-utils'
import Typography from '@mui/material/Typography'

import FullCalendarConnector from './FullCalendarConnector'
import DialogActividad from './DialogActividad'

import { subMinutes, sub, add } from 'date-fns'

import './Agenda.scss'

const shouldLoad = (empresa) => !!empresa

// const eventLogger = (...pre) => (...args) => console.log(...pre, { args })

export default function Agenda (props) {
  const detalles = useLocationDetails()

  const { filters, listado, loading, loaded } = useListLoader({
    model,
    shouldLoad
  })

  const { SHOW_CREATE } = MainAction

  const { view, desde, hasta, cuanto, addFilter } = useFiltersControl()

  const pickCard = useCardPicker(model)
  const [actividad, setActividad] = React.useState(null)
  // const [index, setIndex] = React.useState(0)
  const voRef = React.useRef(null)
  const stopDrag = React.useRef(false)
  const moving = React.useRef(false)
  const r1 = React.useRef(null)
  const r2 = React.useRef(null)
  const r3 = React.useRef(null)
  const refArray = React.useMemo(() => [r1, r2, r3], [r1, r2, r3])
  const desdeRef = React.useRef(desde)
  const hastaRef = React.useRef(hasta)
  const changesRef = React.useRef([])
  // const containerRef = React.useRef(null)
  const calProps = {
    voRef,
    listado,
    desde,
    hasta,
    view,
    cuanto,
    stopDrag,
    filters,
    moving
  }

  // const VirtualizeSwipeableViews = virtualize(SwipeableViews)

  // ver https://fullcalendar.io/docs/react

  const retroceder = React.useCallback(() => {
    const api = refArray[0].current.getApi()
    api.prev()
    addFilter({
      date: true,
      view: api.view.type,
      label: api.view.title,
      desde: api.view.currentStart,
      hasta: subMinutes(api.view.currentEnd, 1),
      origen: api.view.currentStart
    })
  }, [refArray, addFilter])

  const avanzar = React.useCallback(() => {
    const api = refArray[0].current.getApi()
    api.next()
    addFilter({
      date: true,
      view: api.view.type,
      label: api.view.title,
      desde: api.view.currentStart,
      hasta: subMinutes(api.view.currentEnd, 1),
      origen: api.view.currentStart
    })
  }, [refArray, addFilter])

  React.useEffect(() => {
    // console.info('Efecto de carga de actividad', detalles.itemId)
    if (detalles.itemId) {
      if (!voRef.current) {
        if (detalles.itemId === SHOW_CREATE) {
          voRef.current = {
            id_actividad: SHOW_CREATE,
            ...model.defaults(null, filters)
          }
        } else {
          voRef.current = (
            listado.find(vo => vo.id_actividad_paralela === detalles.itemId) || null
          )
        }
      }
      if (!voRef.current) {
        // let unmounted = false
        // console.error('Falta la referencia al VO!', listado, detalles.itemId)
        // console.warn('Feature request: cargar la actividad indicada')
        // model.findOne().then(item => {
        //  !unmounted && setActividad(item)
        // })
        // return () => { unmounted = true }
      } else {
        setActividad(prev => {
          return prev && prev.id_actividad_paralela === detalles.itemId
            ? prev
            : voRef.current
        })
      }
    } else {
      setActividad(prev => prev ? null : prev)
    }
  }, [detalles.itemId, listado, filters, SHOW_CREATE])

  // console.info('Render Agenda', { index, calendarIndex })

  if (!loading && !loaded) {
    return (
      <section
        className='agenda'
        style={{
          textAlign: 'center'
        }}
      >
        <Typography align='center'>
          {'No tienes seleccionada ninguna '}
          <NavLink to='/usuario/empresas'>empresa</NavLink>
        </Typography>
      </section>
    )
  }

  // if (loading && !total) return <Loading />

  /* const handleTest = () => {
    console.log(calendarRef.current.getApi().next())
  } */

  const computeCalcProps = (props, mode = null) => {
    switch (mode) {
      case 'current':
        desdeRef.current = desde
        hastaRef.current = hasta
        break
      case 'previous':
        props = { ...props }
        switch (props.view) {
          case 'dia':
            props.desde = sub(desdeRef.current, { days: 1 })
            props.hasta = sub(hastaRef.current, { days: 1 })
            break
          case 'tresDias':
            props.desde = sub(desdeRef.current, { days: 3 })
            props.hasta = sub(hastaRef.current, { days: 3 })
            break
          case 'semana':
            props.desde = sub(desdeRef.current, { weeks: 1 })
            props.hasta = sub(hastaRef.current, { weeks: 1 })
            break
          case 'mes':
            props.desde = sub(desdeRef.current, { months: 1 })
            props.hasta = sub(hastaRef.current, { months: 1 })
            break
          default:
            break
        }
        break
      case 'next':
        props = { ...props }
        switch (props.view) {
          case 'dia':
            props.desde = add(desdeRef.current, { days: 1 })
            props.hasta = add(hastaRef.current, { days: 1 })
            break
          case 'tresDias':
            props.desde = add(desdeRef.current, { days: 3 })
            props.hasta = add(hastaRef.current, { days: 3 })
            break
          case 'semana':
            props.desde = add(desdeRef.current, { weeks: 1 })
            props.hasta = add(hastaRef.current, { weeks: 1 })
            break
          case 'mes':
            props.desde = add(desdeRef.current, { months: 1 })
            props.hasta = add(hastaRef.current, { months: 1 })
            break
          default:
            break
        }
        break
      default:
        throw new Error('Se te fue la olla ')
    }
    return props
  }

  const arr = []
  const CalendarRender = ({ index, key }) => {
    let propsVar
    let ref
    arr.push(index)
    switch (arr.length) {
      case 1:
        ref = refArray[0]
        propsVar = 'current'
        break
      case 2:
        ref = refArray[1]
        propsVar = 'current'
        break
      case 3:
        ref = refArray[2]
        propsVar = 'current'
        break
      default:
        break
    }

    // console.log({ index, ref, refArray, arr, propsVar, props: computeCalcProps(calProps, propsVar) })
    return (
      <FullCalendarConnector
        {...computeCalcProps(calProps, propsVar)}
        ref={ref}
        key={key}
      />
    )
  }

  /* const handleChangeIndex = (_index) => {
    // console.log({ index, _index })
    moving.current = true
    if (index > _index) retroceder()
    if (index < _index) avanzar()
    setIndex(_index)
    moving.current = false
  }

  const handleSwitching = (e) => {
    moving.current = e.toString().includes('.')
  } */

  // console.info('Render Agenda (Full)', { actividad })

  return (
    <section
      className='agenda'
      style={{
        flexGrow: 1,
        height: '100%',
        display: 'flex' // importante para que funcione height=parent
      }}
    >

      {/* <button style={{ display: 'none' }} onClick={handleTest}>Test</button> */}
      <DialogActividad
        // title='Lets go maderfaquers'
        voRef={voRef}
        changesRef={changesRef}
        item={actividad}
        onClose={() => {
          if (changesRef.current.length) {
            pickCard(null)
            return console.warn('Existen cambios pendientes')
          }
          voRef.current = null
          pickCard(null)
        }}
      />
      <IconButton
        className='sideArrow'
        onClick={() => retroceder()}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <CalendarRender index={1} />
      {/* <VirtualizeSwipeableViews
        ref={containerRef}
        resistance
        className='wrapper'
        index={index}
        overscanSlideAfter={1}
        overscanSlideBefore={1}
        onChangeIndex={handleChangeIndex}
        slideRenderer={CalendarRender}
        onSwitching={handleSwitching}
        disabled={stopDrag.current}
      /> */}
      <IconButton
        className='sideArrow'
        onClick={() => avanzar()}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </section>
  )
}
