import React from 'react'

import { Field } from './Field'
import { GridItemHOC } from './Grid'

export const TRIM_RE = /[\s-]/g
export const SPLIT_RE = /.{1,4}/g

export function maskIbanValue (text) {
  if (!text) return ''
  return (
    // Técnica de enmascarado derivada de un post en Stack Overflow
    // https://stackoverflow.com/a/6259543/1894803
    text.replace(TRIM_RE, '').match(SPLIT_RE) || []
  ).join(' ')
}

export function IbanField (props) {
  const {
    value,
    ...other
  } = props

  return (
    <Field
      name='iban'
      value={maskIbanValue(value)}
      {...other}
    />
  )
}

export default GridItemHOC(IbanField)
