import {
  endOfWeek as _endOfWeek,
  startOfWeek as _startOfWeek,
  addMonths,
  format,
  getDate,
  getMonth,
  getYear,
  getDaysInMonth,
  compareAsc,
  set,
  parseISO
} from 'date-fns'
import { es } from 'date-fns/locale'

const weekOpts = { weekStartsOn: 1 }

export const endOfWeek = (date) => _endOfWeek(date, weekOpts)
export const startOfWeek = (date) => _startOfWeek(date, weekOpts)
/*
 * Formateo como string
 * TODO: Este código viene del paquete @grupomarea/abrela-content
 * TODO: Este código debería empaquetarse en @grupomarea/abrela-tesoreria
 */

/* const YYYY = new Intl.DateTimeFormat('es', { year: 'numeric' })
const MM = new Intl.DateTimeFormat('es', { month: '2-digit' })
const DD = new Intl.DateTimeFormat('es', { day: '2-digit' }) */

// devuelve un string con el formato DD/MM/AAAA
export const formatSlash = (date, fullYear) => {
  if (date === null || date === '') { return null }
  const yearString = fullYear ? 'yyyy' : 'yy'
  if (typeof date === 'object' || typeof date === 'string') {
    return format(new Date(date), 'dd/MM/' + yearString)
  } else {
    return format(new Date(), 'dd/MM/' + yearString)
  }
}

// devuelve un string con el formato AAAA-MM-DD
export const input = (date) => {
  if (date === null) { return null }
  if (typeof date === 'object' || typeof date === 'string') {
    return format(new Date(date), 'yyyy-MM-dd')
  } else {
    return format(new Date(), 'yyyy-MM-dd')
  }
}

// Combina strings de fecha y hora en un objecto Date
export const combine = (date, time = null) => {
  if (date === '') return null
  const [yyyy, mm, dd] = typeof date === 'object' ? input(date).split('-') : date.split('-')
  const [HH, MM] = (typeof time === 'string' && time !== '')
    ? time.split(':')
    : [0, 0]

  // TODO sería posible también generar un ISO string, pero primero
  // habría que averiguar el offset (dependiendo del caso +01 o +02)
  // const dateObj = new Date(`${date}T${time || '00:00'}+02:00`)
  const dateObj = new Date(yyyy, mm - 1, dd, HH, MM, 0, 0)
  return dateObj
}

/*
 * explotar a objeto "plano", implosionar a objeto fecha
 */
export const explode = (date) => {
  return ({
    dia: getDate(date),
    mes: getMonth(date) + 1,
    ano: getYear(date)
  })
}

export const slashToInput = (slashDate) => {
  const dateArray = slashDate.split('/')
  return (input(new Date(dateArray[2], dateArray[1] - 1, dateArray[0], 0, 0)))
}

export const formatTextDate = (date) => {
  return format(date, "dd' de 'LLLL' de 'yyyy", {
    locale: es
  })
}

export const formatTextMonth = (date) => {
  const month = format(date, 'MMMM', { locale: es })
  return month.charAt(0).toUpperCase() + month.slice(1)
}

export const compare = (firstDate, secondDate) => {
  return compareAsc(set(firstDate, { hours: 0, minutes: 0, seconds: 0 }), secondDate)
}

export const setDateValue = (date, opts = {}) => {
  return set(date, opts)
}

export const toIso = (date) => { return parseISO(date) }

export const implode = (datos = {}) => new Date(datos.ano, datos.mes - 1, datos.dia)

export const getNumberDays = (date) => getDaysInMonth(date)

// calcula una fecha a partir de una base y un número de días
export const fromPlazo = (base, opts = {}) => {
  opts = {
    dias: 0,
    format: false,
    ...opts
  }
  const date = addMonths(typeof base === 'string' ? parseISO(base) : base, opts.dias / 30)
  return opts.format ? formatSlash(date) : date
}

export const getTime = (date) => {
  return format(date, 'kk:mm')
}

/* vim: set expandtab: */
/* vim: set filetype=javascript ts=2 shiftwidth=2: */
