import React from 'react'

import Stack from '@mui/system/Stack'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'

import SaveIcon from '@mui/icons-material/Save'
import UndoIcon from '@mui/icons-material/Undo'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckIcon from '@mui/icons-material/Check'

import {
  ConfigActividades,
  ConfigContactos,
  ConfigFacturas
} from '../../SettingsMenu'

import { useConfigureEB } from '../../../../utils/hooks'

export default function SwitchSettigns (props) {
  const { order, detalles } = props

  const [expandSettings, setExpandSettings] = React.useState(false)
  const [triggerSave, setTriggerSave] = React.useState(false)

  const handleCloseSettings = () => {
    setExpandSettings(false)
    setTriggerSave(true)
  }

  const handleSettings = () => {
    expandSettings
      ? handleCloseSettings()
      : setExpandSettings(true)
  }

  const {
    vo,
    blockLayout,
    onSave,
    changesRef,
    handleBack,
    handleChange,
    handleChangeObject
  } = useConfigureEB()

  React.useEffect(() => {
    if (!triggerSave || !changesRef.current.length) {
      setTriggerSave(false)
      return
    }
    onSave({ pickNext: null }).finally(() => setTriggerSave(false))
  }, [triggerSave, changesRef, setTriggerSave, onSave])

  const config = (() => {
    switch (order) {
      case 1:
        return <ConfigContactos {...{ vo, handleChange, handleChangeObject }} />
      case 2:
        return <ConfigActividades {...{ vo, handleChange, handleChangeObject }} />
      case 3:
        return <ConfigActividades {...{ vo, handleChange, handleChangeObject }} />
      case 4:
        return <ConfigFacturas {...{ vo, handleChange, handleChangeObject }} />
      default:
        return null
    }
  })()
  return (
    <Stack
      className='quick-settings'
      sx={{ width: '100%', padding: '0 1rem 0 1rem' }}
    >
      <Stack direction='row' className='quick-settings'>
        <Button
          onClick={() => handleSettings()}
          sx={{
            width: '100%',
            justifyContent: 'space-between'
          }}
          endIcon={
            <>
              <Collapse
                in={!expandSettings}
                orientation='horizontal'
                mountOnEnter
                unmountOnExit
              >
                <ExpandMoreIcon />
              </Collapse>
              <Collapse
                in={expandSettings}
                orientation='horizontal'
                mountOnEnter
                unmountOnExit
              >
                {
                  changesRef.current.length
                    ? <SaveIcon color='primary' />
                    : <CheckIcon />
                }
              </Collapse>
            </>
          }
        >
          Configuración por defecto de {detalles.name}
        </Button>
        <Collapse
          mountOnEnter
          unmountOnExit
          orientation='horizontal'
          in={!!changesRef.current.length}
        >
          <IconButton onClick={(e) => {
            e.stopPropagation()
            handleBack()
          }}
          >
            <UndoIcon />
          </IconButton>
        </Collapse>
      </Stack>
      <Collapse
        in={expandSettings}
        mountOnEnter
        unmountOnExit
      >
        {blockLayout}
        <Box sx={{ padding: '1rem' }}>
          {config}
        </Box>
      </Collapse>
    </Stack>
  )
}
