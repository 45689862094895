import React from 'react'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import Collapse from '@mui/material/Collapse'
import './Acordeon.scss'

export default function Acordeon (props) {
  const { id, collapsed, onToggle } = props

  const ref = React.useRef()

  const classes = 'Acordeon ' + (collapsed ? 'collapsed' : 'expanded')
  /*
  React.useEffect(() => {
    if (collapsed) return
    ref.current.scrollIntoView()
  }, [collapsed])
  // */

  return (
    <fieldset ref={ref} id={id} className={classes}>
      <legend className='Acordeon-title'>
        <Button
          variant='outlined'
          margin='normal'
          fullWidth
          onClick={() => onToggle(id, collapsed)}
          endIcon={
            collapsed
              ? <ExpandMore />
              : <ExpandLess />
          }
        >
          {props.name || 'Acordeón sin nombre'}
        </Button>
      </legend>
      <Collapse in={!collapsed} className='Acordeon-content' data-cy='tab-content'>
        {props.children}
      </Collapse>
    </fieldset>
  )
}

Acordeon.propTypes = {
  id: PropTypes.string.isRequired,
  collapsed: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
}

/**
 * Facilita el setup necesario para:
 * - manejar expand/collapse de acordeones
 * - cerrar el acordeon abierto cuando se indica que no está seleccionado
 */
export function useAcordeon (selected = true) {
  const [acordeon, setAcordeon] = React.useState(null)

  // Cierra los acordeones cuando no existe selección
  React.useEffect(() => { !selected && setAcordeon(null) }, [selected])

  return [
    acordeon,
    React.useCallback(id => setAcordeon(prev => prev === id ? null : id), [])
  ]
}
