import React from 'react'

import Avatar from '@mui/material/Avatar'
import AvatarGroup from '@mui/material/AvatarGroup'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'

import {
  MoodBad as CaraTristeIcon,
  SentimentSatisfied as CaraNormalIcon,
  SentimentVerySatisfied as CaraFelizIcon,
  Euro as EuroIcon,
  Notes as NotesIcon
} from '@mui/icons-material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

import DialogEstadoActividad from './DialogEstadoActividad'
import DialogEstadoActividadMultiple from './DialogEstadoActividadMultiple'

// export { CaraFelizIcon, CaraTristeIcon, EuroIcon }

function SmallIcon (Component) {
  return (props) => <Component fontSize='small' {...props} />
}

export const CaraNormal = SmallIcon(CaraNormalIcon)
export const CaraTriste = SmallIcon(CaraTristeIcon)
export const CaraFeliz = SmallIcon(CaraFelizIcon)

export const Euro = SmallIcon(EuroIcon)
export const Notas = SmallIcon(NotesIcon)

export function BadgePrecio (props) {
  // console.log(props)
  const {
    vo,
    handleToggleAcordeon,
    idValor,
    selected
  } = props

  const esMultiple = vo.actividades.length > 1

  const tienePrecio = esMultiple
    ? vo.actividades.some(item => item.precio > 0)
    : vo.precio > 0
  const classesPrecio = esMultiple
    ? getClassesPrecioMultiple(vo).join(' ')
    : getClassesPrecio(vo).join(' ')

  return (
    <Fade in={tienePrecio || selected}>
      <IconButton
        data-cy='valor'
        className='eurButton'
        onClick={() => !esMultiple && handleToggleAcordeon(idValor)}
      >
        <Avatar className={classesPrecio + ' sm-avatar'}>
          <Euro />
        </Avatar>
      </IconButton>
    </Fade>
  )
}

export function BadgeEstado (props) {
  const {
    vo,
    contacto,
    handleAction,
    handleShowEstado,
    showEstado,
    onClose,
    onDestroy
  } = props

  const classesEstado = getClassesEstado(vo).join(' ')

  return (
    <>
      <IconButton
        data-cy='face'
        onClick={handleShowEstado}
      >
        <Avatar className={classesEstado + ' sm-avatar'}>
          {
            vo.estado === 'cerrada'
              ? <CaraFeliz />
              : vo.estado === 'anulada'
                ? <CaraTriste />
                : <CaraNormal />
          }
        </Avatar>
      </IconButton>
      <DialogEstadoActividad
        vo={vo}
        contacto={contacto}
        showEmit={false}
        open={!!showEstado}
        onClose={onClose}
        onAction={handleAction}
        onDestroy={onDestroy}
      />
    </>
  )
}

export function BadgeMultiple (props) {
  const {
    vo,
    contacto,
    showMultiple,
    handleShowMultiple,
    handleChangeObject,
    onClose,
    onSave,
    onDestroy,
    onForceClose
  } = props
  const estado = (vo.estado.length < 2) ? vo.estado[0] : 'oculta'
  const { isAbierta, isCerrada, isCancelada, isAtrasada } = getClassesStack(vo)

  return (
    <>
      <IconButton
        data-cy='face'
        className='multiple-button'
        onClick={handleShowMultiple}
      >
        <AvatarGroup
          role='button'
          // onClick={handleShowEstado}
          className='multiple'
        >
          {
            isAbierta &&
              <Avatar className='estado abierta sm-avatar'>
                <CaraNormal />
              </Avatar>
          }
          {
            isAtrasada &&
              <Avatar className='estado atrasada sm-avatar'>
                <CaraNormal />
              </Avatar>
          }
          {
            isCerrada &&
              <Avatar className='estado cerrada sm-avatar'>
                <CaraFeliz />
              </Avatar>
          }
          {
            isCancelada &&
              <Avatar className='estado anulada sm-avatar'>
                <CaraTriste />
              </Avatar>
          }
          <Avatar className={`estado ${estado} sm-avatar`}>
            <CaraNormal />
          </Avatar>
        </AvatarGroup>
      </IconButton>
      <DialogEstadoActividadMultiple
        open={!!showMultiple}
        vo={vo}
        contacto={contacto}
        onClose={onClose}
        onDestroy={onDestroy}
        onSave={onSave}
        handleChangeObject={handleChangeObject}
        onForceClose={onForceClose}
      />
    </>
  )
}

export function Dot (props) {
  const { vo } = props

  const classesEstado = getClassesEstado(vo).join(' ')

  return (
    <FiberManualRecordIcon
      className={'status-dot ' + classesEstado}
      fontSize='small'
    />
  )
}

export function DotMultiple (props) {
  const { vo } = props
  const estado = (vo.estado.length < 2) ? vo.estado[0] : 'oculta'
  const { isAbierta, isCerrada, isCancelada, isAtrasada } = getClassesStack(vo)

  return (
    <>
      {
        isCancelada &&
          <FiberManualRecordIcon
            className='status-dot anulada'
            fontSize='small'
          />
      }
      {
        isCerrada &&
          <FiberManualRecordIcon
            className='status-dot cerrada'
            fontSize='small'
          />
      }
      {
        isAtrasada &&
          <FiberManualRecordIcon
            className='status-dot atrasada'
            fontSize='small'
          />
      }
      {
        isAbierta &&
          <FiberManualRecordIcon
            className='status-dot abierta'
            fontSize='small'
          />
      }
      <FiberManualRecordIcon
        className={'status-dot ' + estado}
        fontSize='small'
      />
    </>
  )
}

export const getClassesEstado = (vo) => ['estado', vo.estado]
export const getClassesPrecio = (vo) => vo.precio > 0
  ? [
      'comercial',
      vo.fk_factura
        ? 'facturada'
        : vo.estado === 'cerrada'
          ? 'pendiente'
          : vo.estado === 'atrasada'
            ? 'pendiente'
            : vo.estado
    ]
  : []

export const getClassesPrecioMultiple = (vo) => {
  const classes = []
  const tienePrecio = vo.actividades.some(item => item.precio > 0)
  const tieneFactura = vo.actividades.some(item => item.fk_factura)

  if (tienePrecio) {
    classes.push('comercial')
    if (tieneFactura) classes.push('facturada')
  }

  return classes
}

export const getClassesStack = (vo) => {
  const isAbierta = vo.estado.includes('abierta')
  const isCerrada = vo.estado.includes('cerrada')
  const isCancelada = vo.estado.includes('anulada')
  const isAtrasada = vo.estado.includes('atrasada')

  return ({ isAbierta, isCerrada, isCancelada, isAtrasada })
}

export const getAllClasses = (vo) => [
  'actividad',
  getClassesEstado(vo),
  getClassesPrecio(vo)
].flat()

export function useAllClassesGetter () {
  return React.useCallback((vo) => getAllClasses(vo), [])
}
