import React from 'react'

export function useMountDetector () {
  const mountedRef = React.useRef(null)

  React.useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [mountedRef])

  return mountedRef
}
