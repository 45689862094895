import React from 'react'
import store from '../../store'
import { historyLocker, historyUnlocker } from '../../store/dispatcher'
import { UNSAFE_NavigationContext } from 'react-router-dom'

const lockHistory = historyLocker(store.dispatch.bind(store))
const unlockHistory = historyUnlocker(store.dispatch.bind(store))

export function useHistoryBlocker () {
  // Almacena un callback de desbloqueo cuando el history está bloqueado
  const unblockRef = React.useRef(null)
  // Almacena la transición de history bloqueada
  const txRef = React.useRef(null)

  const history = React.useContext(UNSAFE_NavigationContext).navigator

  // Esta función abstrae la lógica que bloquea (o no) la navegación
  // Recibe un único parámetro que indica si debe estar bloqueada o no
  const handleBlock = React.useCallback((sense) => {
    if (typeof sense !== 'boolean') {
      throw new TypeError('Se esperaba un único parámetro booleano')
    }
    if (sense) {
      if (unblockRef.current) {
        // console.warn('ya está bloqueado, desbloquear y sacar el mensaje')
        unblockRef.current()
        if (txRef.current) txRef.current = null
        unlockHistory()
      }
      console.warn('Bloquear la navegación!')
      unblockRef.current = history.block(tx => {
        // console.warn('bloqueado un intento de navegar', { sense, tx })
        txRef.current = tx
        lockHistory('Estabas editando una tarjeta. Si continúas, perderás los cambios')
      })
    } else {
      if (!unblockRef.current) {
        return // console.warn('No está bloqueado brother')
      }
      console.warn('Desbloquear el history')
      unblockRef.current()
      unblockRef.current = null
      if (txRef.current) {
        // console.warn('Resumir la transición de history', txRef.current)
        txRef.current.retry()
        txRef.current = null
      }
      unlockHistory()
    }
  }, [unblockRef, history])

  return handleBlock
}
