
function Constants (...args) {
  return args.reduce(
    (acc, str) => Object.defineProperty(acc, str, {
      value: str, // Prefer string over Symbol as symbols aren't serializable
      writable: false,
      enumerable: true,
      configurable: false
    }),
    {} // TODO May fit? → Object.create(null)
  )
}

export const StateKeys = Constants(
  'user',
  'empresa',
  'card',
  'empresas',
  'contactos',
  'conceptos',
  'actividades',
  'actividades_paralelas',
  'eventos',
  'facturas',
  'borradores',
  'authLoading',
  'isLoading',
  'filters',
  'lockHistory',
  'confEmpresa'
)

export const AuthAction = Constants(
  'SIGN_UP',
  'SIGN_IN',
  'LOG_OUT',
  'LOADING',
  'OFFLINE'
)

export const MainAction = Constants(
  'LOCK_HISTORY', // Indica si se ha bloqueado la navegación
  'INIT_LOADING', // Marca urls que se están cargando
  'DONE_LOADING', // Desmarca urls que es estaban cargando
  'MULTIACTION', // Reduce múltiples acciones en un solo paso
  'SET_EMPRESA', // Cambia la empresa seleccionada
  'PUT_EMPRESA', // Actualiza la empresa seleccionada "Si es necesario"
  'PICK_CREATE', // Solicita que se seleccione la tarjeta "nuevo/crear"
  'PICK_SEARCH',
  'SHOW_CREATE', // Actúa como identificador de la tarjeta "nuevo/crear"
  'CARD_SELECT', // Cambia la selección de tarjeta
  'CARD_ERRORS' // Registra errores en la tarjeta seleccionada
  // TODO 'CARD_CHANGE' // Registra los cambios en el contenido
)

export const ListAction = Constants(
  'LOAD_INITIAL',
  'NEXT_PAGE',
  'ITEM_UNSHIFT',
  'ITEM_REPLACE',
  'ITEM_DESTROY',
  'LOAD_REFRESH',
  'ADD_FILTER',
  'POP_FILTER'
)

export const CardAction = Constants(
  'CARD_SWITCH',
  'CARD_DELETE',
  'CANT_DELETE'
)

// claves del estado a resetear cuando se cambia la empresa
export const ResetKeys = [
  StateKeys.contactos,
  StateKeys.conceptos,
  StateKeys.actividades,
  StateKeys.actividades_paralelas,
  StateKeys.eventos,
  StateKeys.facturas,
  StateKeys.borradores,
  StateKeys.filters,
  StateKeys.lockHistory,
  StateKeys.confEmpresa
]

// claves del estado a resetear cuando se añade o elimina un filtro
export const ResetOnFilter = [
  StateKeys.contactos,
  StateKeys.conceptos,
  StateKeys.actividades,
  StateKeys.eventos,
  StateKeys.actividades_paralelas,
  StateKeys.facturas,
  StateKeys.borradores
]
