
import Grid from '@mui/material/Grid'

export function GridContainer (props) {
  // Ojo, spacing utiliza el hack de margen negativo. Puede ser necesario
  // dejarlo a 0 o usar CSS adicional
  // Ver https://material-ui.com/es/components/grid/
  return (
    <Grid container spacing={1} {...props}>
      {props.children}
    </Grid>
  )
}

export function GridItemHOC (WrappedComponent) {
  // Ver https://material-ui.com/es/customization/breakpoints/
  return ({ className, xs, sm, md, lg, xl, ...props }) => (
    <Grid
      className={className}
      item
      {...{ xs: typeof xs === 'undefined' ? 12 : xs, sm, md, lg, xl }}
    >
      <WrappedComponent className={className} {...props} />
    </Grid>
  )
}

export function GridItem (props) {
  return <Grid item {...props} />
}
