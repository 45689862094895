// import Combo from '../FormUtils/Combo'
import ComboDialog from '../FormUtils/ComboDialog'

export const IVA_EXENTO = 4
export const IVA_REBU = 5
export const IVA_PASIVO = 6

// TODO deberá venir de un endpoint y almacenarse en redux
export const listOpIva = [
  { title: '21%', value: 1, tasa: 21, desc: 'I.V.A. General' },
  { title: '10%', value: 2, tasa: 10, desc: 'I.V.A. Reducido' },
  { title: '4%', value: 3, tasa: 4, desc: 'I.V.A. Superreducido' },
  { title: '0%', value: 4, tasa: 0, desc: 'Exento de I.V.A.' },
  { title: '0% · REBU', value: 5, tasa: 0, desc: 'Exento por R.E.B.U.' },
  { title: '0% · ISP', value: 6, tasa: 0, desc: 'Inversión de Sujeto Pasivo' }
]

export function ComboIva (props) {
  return (
    <ComboDialog
      dialogProps={{ 'data-cy': 'selector-iva' }}
      name='fk_iva'
      label='Tipo IVA'
      value={null}
      // error={false}
      options={listOpIva}
      avatar={option => option.tasa}
      describe={option => option.desc}
      required
      {...props}
    />
  )
}

export const TRANSFERENCIA = 1
export const DOMICILIACION = 2
export const PAGO_EFECTIVO = 3

// TODO deberá venir de un endpoint y almacenarse en redux
export const listOpMetodoCobro = [
  {
    title: 'Transferencia',
    value: TRANSFERENCIA,
    desc: 'Incluír los datos bancarios de la empresa en la factura'
  },
  {
    title: 'Domiciliación',
    value: DOMICILIACION,
    desc: 'Incluír los datos bancarios de la entidad cliente en la factura'
  },
  {
    title: 'Efectivo o Tarjeta',
    value: PAGO_EFECTIVO,
    desc: 'El pago se efectúa por otros medios'
  }
]

export function ComboMetodoCobro (props) {
  return (
    <ComboDialog
      dialogProps={{ 'data-cy': 'selector-metodo-cobro' }}
      name='fk_metodo_cobro'
      label='Método de cobro'
      value={null}
      // error={false}
      options={listOpMetodoCobro}
      describe={option => option.desc}
      required
      {...props}
    />
  )
}

export const listOpEmisionFactura = [
  { title: 'Ninguno', value: 1 },
  { title: 'Semanal', value: 2 },
  { title: 'Mensual', value: 3 },
  { title: 'Trimestral', value: 4 }
]

const avatarEmisionFactura = (option) =>
  option.title.split(' ').map(str => str[0]).join('').toUpperCase()

export function ComboEmisionFactura (props) {
  return (
    <ComboDialog
      name='fk_periodo_facturacion'
      label='Período de emisón de facturas'
      value={null}
      // error={false}
      options={listOpEmisionFactura}
      avatar={avatarEmisionFactura}
      describe={option => option.desc}
      required
      {...props}
    />
  )
}

export const listEsProfesional = [
  { title: 'Empresarial', value: 0, desc: 'No aplico retención de IRPF' },
  { title: 'Profesional', value: 1, desc: 'Aplico retención de IRPF' }
]

export function ComboProfesional (props) {
  return (
    <ComboDialog
      name='es_profesional'
      label='Tipo de Actividad'
      value={null}
      // error={false}
      options={listEsProfesional}
      describe={option => option.desc}
      required
      {...props}
    />
  )
}

export const listEsParticular = [
  { title: 'Empresa', value: 0, desc: 'Aplicar la retención IRPF' },
  { title: 'Particular', value: 1, desc: 'No aplicar la retención IRPF' }
]

export function ComboParticular (props) {
  return (
    <ComboDialog
      name='es_particular'
      label='Tipo de contacto'
      value={null}
      // error={false}
      options={listEsParticular}
      describe={option => option.desc}
      required
      {...props}
    />
  )
}

export const listOpPlazoCobro = [
  { title: 'A la vista', value: 1 },
  { title: '30 Días', value: 2 },
  { title: '60 Días', value: 3 },
  { title: '90 Días', value: 4 }
]

export const getPlazoCobroById = (id) => {
  switch (id) {
    case 1: return { dias: 0 }
    case 2: return { dias: 30 }
    case 3: return { dias: 60 }
    case 4: return { dias: 90 }
    default:
      throw new ReferenceError(`Id plazo cobro inválido: ${id}`)
  }
}

export function ComboPlazoCobro (props) {
  return (
    <ComboDialog
      name='fk_plazo_cobro'
      label='Plazo de cobro'
      value={null}
      // error={false}
      options={listOpPlazoCobro}
      describe={option => option.desc}
      required
      {...props}
    />
  )
}

export const listOpEnvioFactura = [
  { title: 'Email automático', value: 1 },
  { title: 'Envío manual', value: 2 }
]

export function ComboEnvioFactura (props) {
  return (
    <ComboDialog
      name='fk_factura_envio'
      label='Envío de facturas'
      value={null}
      // error={false}
      options={listOpEnvioFactura}
      describe={option => option.desc}
      required
      {...props}
    />
  )
}
