import React from 'react'
import { Buffer } from 'buffer'

/**
 * Esta primera aproximación está basada en la demo que encontré en
 * → https://material-ui.com/components/buttons/#upload-button
 */

// import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import CloudUpload from '@mui/icons-material/CloudUpload'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'

import './FormUtils.scss'

const { FileReader } = global

function humanizeFileSize (number) {
  if (number < 1024) {
    return number + ' bytes'
  }
  if (number >= 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + ' KB'
  }
  if (number >= 1048576) {
    return (number / 1048576).toFixed(1) + ' MB'
  }
}

export default function UploadButton (props) {
  const { image, onChange, titulo, texto, name } = props

  const preview = image
    ? typeof image === 'string' ? image : Buffer.from(image).toString()
    : undefined

  const [file, setFile] = React.useState(null)
  const [error, setError] = React.useState(false)

  const imgRef = React.useRef(null)

  const handleChange = (event) => {
    const file = event.target.files[0]

    if (file.size > 100000) {
      setError(true)
      return
    }

    setFile(file)
    setError(false)
    const fileReader = new FileReader()
    fileReader.onload = (event) => {
      // console.log({ event })
      const dataURL = event.target.result
      imgRef.current.src = dataURL
      onChange(dataURL)
    }
    fileReader.readAsDataURL(file)
  }

  // console.log('Render UploadButton', { props })

  return (
    <Grid
      sm={12}
      item
      className='uploadGroup'
      name='logo-wrapper'
      container
    >
      <Grid item sm={12} md={6}>
        <Grid item className='img-title'>
          <Typography>{titulo}</Typography>
        </Grid>
        <Grid
          item
          className='logo'
        >
          <Grid item sm={3} className='img-group'>
            <Avatar
              ref={imgRef}
              data-cy={`${name}-formulario`}
              variant='rounded'
              src={preview}
              alt='Previsualización'
            >
              <ImageOutlinedIcon />
            </Avatar>
            <input
              data-cy={`input-${name}`}
              accept='image/*'
              style={{ display: 'none' }}
              id={`icon-button-file-${name}`}
              type='file'
              name={name}
              onChange={handleChange}
            />
            <label htmlFor={`icon-button-file-${name}`}>
              <IconButton
                color='primary'
                aria-label='Cargar logotipo'
                component='span'
              >
                <CloudUpload />
              </IconButton>
            </label>
          </Grid>
          <Grid item sm={2}>
            {
              file &&
                <span>{humanizeFileSize(file.size)}</span>
            }
          </Grid>
          <Grid item sm={6} className='img-text'>
            <Typography>{texto}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={error} mountOnEnter unmountOnExit>
        <Alert severity='error' data-cy='size-error'>
          Tamaño de archivo demasiado grande
        </Alert>
      </Collapse>

    </Grid>
  )
}
