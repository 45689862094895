import { MainAction } from '../const'
import AuthService from '../../service/auth'
// import { empresa as model } from '../../service/empresas/model'

const { localStorage } = global

export function postDispatcher (dispatch, sent, response, action) {
  dispatch(action)
  AuthService.reloadAccount()
}

export function saveDispatcher (dispatch, sent, response, action) {
  dispatch(action,
    {
      type: MainAction.PUT_EMPRESA,
      payload: response.data.item
    })
}

export function wipeDispatcher (dispatch, sent, response, action) {
  const actions = [action]
  const empresaActiva = JSON.parse(localStorage.getItem('abrela:empresa'))
  if (action.id === empresaActiva.id) {
    actions.push({
      type: MainAction.SET_EMPRESA,
      payload: null
    })
  }
  dispatch(...actions)
  AuthService.reloadAccount()
}
