import {
  getYear,
  getMonth,
  startOfMonth,
  startOfToday,
  startOfTomorrow,
  startOfYesterday,
  endOfMonth,
  endOfToday,
  endOfTomorrow,
  endOfYesterday
} from 'date-fns'

import esLocale from 'date-fns/locale/es'

import {
  startOfWeek,
  endOfWeek
} from '../../utils/fecha'

export const CONTACTO = 'contacto'
export const CONCEPTO = 'concepto'
export const TIEMPO = 'tiempo'

export const filterType = (vo) => {
  if (vo.id_contacto) return CONTACTO
  if (vo.id_concepto) return CONCEPTO
  if (vo.date) return TIEMPO

  console.error({ vo })
  throw new TypeError('Tipo de filtro irreconocible')
}

export const weekInit = () => {
  const hoy = startOfToday()
  return {
    label: 'Esta semana',
    date: true,
    view: 'semana',
    show: true,
    desde: startOfWeek(hoy),
    hasta: endOfWeek(hoy)
  }
}

const meses = Array(12).fill(0).map((_, idx) => {
  const mes = esLocale.localize.month(idx)
  return {
    idx,
    mes: mes.slice(0, 1).toUpperCase() + mes.slice(1)
  }
})

export const createTimeFilters = () => {
  const hoy = startOfToday()
  const _mes = getMonth(hoy)
  const year = getYear(hoy)
  return [
    {
      label: 'Hoy',
      date: true,
      view: 'dia',
      show: true,
      desde: hoy,
      hasta: endOfToday()
    },
    {
      label: 'Mañana',
      date: true,
      view: 'dia',
      show: true,
      desde: startOfTomorrow(),
      hasta: endOfTomorrow()
    },
    {
      label: 'Ayer',
      date: true,
      view: 'dia',
      show: true,
      desde: startOfYesterday(),
      hasta: endOfYesterday()
    },
    weekInit(),
    ...meses
      .map(({ mes, idx }) => {
        const dateObj = new Date(year, idx, 1)
        return {
          label: (
            _mes === idx
              ? `Este mes (${meses[idx].mes})`
              : _mes === idx + 1
                ? `El mes pasado (${meses[idx].mes})`
                : _mes === idx - 1
                  ? `El próximo mes (${meses[idx].mes})`
                  : mes
          ),
          date: true,
          view: 'mes',
          show: _mes === idx,
          desde: startOfMonth(dateObj),
          hasta: endOfMonth(dateObj)
        }
      })
  ]
}
