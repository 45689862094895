import {
  Borrador as model,
  empresa as modelEmpresa,
  contacto as modelContacto,
  factura as modelFactura
} from '../../service/empresas/model'

import { ListAction as List, MainAction, StateKeys } from '../const'

export function multipleDispatcher (dispatch, sent, response, action) {
  const {
    contacto,
    conceptos,
    config_empresa: empresa,
    idBorrador,
    item
  } = response.data

  const actions = []

  if (sent.emitir) {
    actions.push({
      type: List.LOAD_REFRESH,
      plural: modelFactura.plural
    })

    actions.push({
      type: List.ITEM_DESTROY,
      plural: model.plural,
      pk: model.pk,
      id: idBorrador
    })
  } else if (!sent.id_borrador || sent.id_borrador === 'SHOW_CREATE') {
    actions.push({
      type: List.ITEM_UNSHIFT,
      plural: model.plural,
      item
    })
  } else {
    actions.push({
      type: List.ITEM_REPLACE,
      plural: model.plural,
      item,
      pk: model.pk
    })
  }

  for (const concepto of conceptos) {
    actions.push({
      type: List.ITEM_UNSHIFT,
      plural: StateKeys.conceptos,
      item: concepto
    })
  }

  if (!sent.contacto.id_contacto) {
    // console.info('Detectado un contacto nuevo (!id_contacto)', { contacto })
    actions.push({
      type: List.ITEM_UNSHIFT,
      plural: modelContacto.plural,
      item: contacto
    })
  } else {
    // console.info('Actualizar el contacto por si acaso', { contacto })
    actions.push({
      type: List.ITEM_REPLACE,
      plural: modelContacto.plural,
      item: contacto,
      pk: modelContacto.pk
    })
  }

  if (empresa) {
    // console.info('Detectada actualización de empresa', { empresa })
    actions.push({
      type: List.ITEM_REPLACE,
      plural: modelEmpresa.plural,
      item: empresa,
      pk: modelEmpresa.pk
    },
    {
      type: MainAction.PUT_EMPRESA,
      payload: empresa
    })
  }

  // Siempre refrescamos las actividades se emita o no el borrador

  actions.push({
    type: List.LOAD_REFRESH,
    plural: StateKeys.actividades_paralelas
  }
  )

  actions.length && dispatch(...actions)
}
