import React from 'react'
import { useNavigate, useLocation /*, useParams */ } from 'react-router-dom'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

import AuthService from '../../service/auth'
import notify from '../../utils/notify'

import { Field } from '../Abc/FormUtils'
import { Paper } from '@mui/material'

const style = {
  variant: 'outlined', margin: 'normal'
}

function getFollow (location) {
  return new URLSearchParams(location).get('follow')
}

export default function Passwd (props) {
  const navigate = useNavigate()
  const location = useLocation()

  const query = new URLSearchParams(location.search)

  const mode = query.get('mode')
  const code = query.get('code')
  const email = query.get('email')

  // console.warn('Render Passwd', { mode, props })

  const [values, setValues] = React.useState({
    username: email || '',
    password: '',
    repeat: '',
    code: code || ''
  })

  const handleSubmit = (event) => {
    event.preventDefault()

    if (values.password !== values.repeat) return

    AuthService
      .requestPasswdReset({ ...values, mode }, getFollow(location))
      .then(next => navigate(next))
      .catch(error => {
        const { message } = (error.response.data)
        notify({ message })
      })
  }

  const handleChange = (event) => {
    // TODO serialize console.log(event.target.form)
    event.preventDefault()
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  const handleBack = (event) => {
    event.preventDefault()
    navigate(-1)
  }

  return (
    <Container component='main' maxWidth='xs'>
      <Paper sx={{
        marginTop: '8rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      >
        <Avatar>
          <LockOpenIcon />
        </Avatar>
        <Typography component='h1' variant='h4'>
          Cambiar Contraseña
        </Typography>
        {mode === 'force' && (
          <p>Debes cambiar la contraseña para acceder</p>
        )}
        <form onSubmit={handleSubmit}>
          <Field
            {...style}
            name='username'
            label='Email Address'
            value={values.username}
            error={values.error}
            onChange={handleChange}
            disabled={!!values.username}
            required
            autoComplete='email'
          />
          <Field
            {...style}
            name='code'
            label='Codigo de verificación'
            value={values.code}
            error={!values.code}
            onChange={handleChange}
            disabled={!!values.code}
            required
            autoComplete='one-time-code'
          />
          <Field
            {...style}
            type='password'
            name='password'
            label='Nueva Contraseña'
            value={values.password}
            error={values.password !== values.repeat}
            onChange={handleChange}
            required
            autoComplete='new-password'
            autoFocus
          />
          <Field
            {...style}
            type='password'
            name='repeat'
            label='Repetir Contraseña'
            value={values.repeat}
            error={values.password !== values.repeat}
            onChange={handleChange}
            required
            autoComplete='new-password'
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
          >
            Cambiar contraseña
          </Button>
          <Grid container>
            <Grid item xs>
              <Button onClick={handleBack}>Volver</Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  )
}
