import {
  ComboMetodoCobro,
  ComboEmisionFactura,
  ComboParticular,
  ComboPlazoCobro,
  ComboEnvioFactura
} from '../FieldSet/Opciones'

import { GridContainer } from '../FormUtils'

export default function ConfigContactos (props) {
  const vo = props.vo || {}
  const { handleChange/* , handleChangeObject, errored */ } = props

  // console.info('Render ConfigFacturas', { props, vo })

  return (
    <GridContainer
      spacing={2}
      component='form'
      data-cy='form-contactos'
      className='settings'
    >
      <ComboParticular
        label='Tipo de Contacto'
        sm={4}
        value={vo?.es_particular || 0}
        onClick={null}
        // error={!!errored.es_particular}
        required
        onChange={handleChange}
      />

      <ComboEmisionFactura
        sm={4}
        name='fk_periodo_facturacion_defecto'
        value={vo?.fk_periodo_facturacion_defecto || 1}
        // error={!!errored.fk_periodo_facturacion_defecto}
        onChange={handleChange}
      />
      <ComboMetodoCobro
        sm={4}
        name='fk_metodo_cobro_defecto'
        value={vo?.fk_metodo_cobro_defecto || 1}
        // error={!!errored.fk_metodo_cobro_defecto}
        onChange={handleChange}
      />

      <ComboPlazoCobro
        sm={4}
        name='fk_plazo_cobro_defecto'
        value={vo?.fk_plazo_cobro_defecto || 1}
        // error={!!errored.fk_plazo_cobro_defecto}
        required
        onChange={handleChange}
      />

      <ComboEnvioFactura
        sm={4}
        label='Método de envío de Facturas'
        name='fk_factura_envio_defecto'
        value={vo?.fk_envio_factura_defecto || 1}
        // error={!!errored.fk_envio_factura_defecto}
        required
        onChange={handleChange}
      />

    </GridContainer>
  )
}
