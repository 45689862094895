import React from 'react'

import {
  AppBar,
  // InputAdornment,
  useScrollTrigger
} from '@mui/material'

import MainMenu from './MainMenu'
// import SettingsDrawer from './SettingsDrawer'
import ToolMenu from './ToolMenu'

import DialogAjustes from './DialogAjustes.jsx'

export default function TitleBar (props) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  })

  const [dialogOpen, setDialogOpen] = React.useState(false)

  // TODO pendiente de refactor

  // const anchorRef = React.useRef()
  const drawerContainerRef = React.useRef()

  return (
    <>
      <AppBar
        // component='div'
        data-cy='app-bar'
        position='fixed'
        elevation={trigger ? 4 : 0} // defecto=4
      >
        <MainMenu
          destinos={props.destinos}
          setDialog={setDialogOpen}
        />
        <ToolMenu drawerContainerRef={drawerContainerRef} />
      </AppBar>
      <MainMenu offset />
      <ToolMenu offset />
      <div ref={drawerContainerRef} />
      <DialogAjustes
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
    </>
  )
}
