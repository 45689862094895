import React from 'react'
import PropTypes from 'prop-types'

import AvatarGroup from '@mui/material/AvatarGroup'
import Grid from '@mui/material/Grid'

import { BadgeEstado, BadgePrecio, BadgeMultiple, Dot, DotMultiple } from '../Actividad/ActividadItemBadge'

export default function EventActividad ({ vo }) {
  return (
    <Grid
      container
      className='eventContainer'
    >
      <Grid item sm={1} md={0.7} className='dot-wrap'>
        {
          vo.isParalela
            ? <DotMultiple vo={(vo)} />
            : <Dot vo={(vo)} />
        }
      </Grid>
      <Grid item sm={9} className='eventText'>
        <span className='text'>
          {vo.contacto.alias}
        </span>
        <span className='text'>
          {vo.isParalela ? vo.descripcion : vo.concepto?.nombre}
        </span>
      </Grid>
      <Grid item sm={2} className='status-avatar-wrap'>
        <AvatarGroup className='status-avatar'>
          {
            vo.isParalela
              ? <BadgeMultiple vo={(vo)} />
              : <BadgeEstado vo={(vo)} />
          }
          <BadgePrecio
            vo={(vo)}
            selected
          />
        </AvatarGroup>
      </Grid>
    </Grid>
  )
}

EventActividad.propTypes = {
  vo: PropTypes.object.isRequired
}
